import React, {useContext} from "react";
import {ActivityContext} from "../../ActivityContext";
import { SendEmailComponent } from "../SendEmailComponent";
export const SaveTheDate: any = () => {
    const {activity, participants, isEmbedded, isEditor}  = useContext(ActivityContext);

    // @ts-ignore
    return (
        <SendEmailComponent
            srcDoc={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL SAVE-THE-DATE').compiledHtml}
            subject={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL SAVE-THE-DATE').subject}
            action={'SEND_SAVE-THE-DATE'}
            emailTypeName={'saveTheDateSent'}
            participantTabs={{
                tab1: {
                    label: 'Not sent ',
                    canSendEmail: true,
                    canAddParticipant: true,
                    canDeleteParticipant: false,
                    canSelectCustomers: true,
                    participants: participants.filter( (participant: ParticipantType) => !participant.participate && !participant.saveTheDateSent),
                    showField: 'saveTheDateNotSent',
                    title: 'Save the date not sent',
                    freeActionTitle: 'Send save the date to all the selected participants?',
                    actionTitle: 'Send save the date',
                    display: !isEmbedded
                },
                tab2: {
                    canSendEmail: false,
                    canAddParticipant: false,
                    canDeleteParticipant: false,
                    canSelectCustomers: false,
                    label: 'Sent',
                    participants: participants.filter( (participant: ParticipantType) => participant.saveTheDateSent),
                    showField: 'saveTheDateSent',
                    title: 'Save the date sent',
                    display: !isEmbedded
                },
                tab3: {
                    canSendEmail: false,
                    canAddParticipant: false,
                    canDeleteParticipant: false,
                    canSelectCustomers: false,
                    label: 'Viewed',
                    participants: participants.filter( (participant: ParticipantType) => participant.saveTheDateViewed),
                    showField: 'saveTheDateSent',
                    title: 'Save the date viewed',
                    display: !isEmbedded
                },
                isReadOnly: !isEditor
            }
            }
        />
    );
}

import Box from "@mui/material/Box";
import {Avatar as muiAvatar, Button, Grid, TextField} from "@mui/material";
import React from "react";
import {Api} from "../../Api";
import Avatar from 'react-avatar-edit'
import {sendErrorMessage} from "../../Error";
import { useSnackbar } from 'notistack';
import {styled} from "@mui/material/styles";
const {REACT_APP_SPEAKERS_API, REACT_APP_UPLOAD_BUCKET} =  process.env;
interface UpsertSpeakerType {
    speaker: SpeakerType | undefined;
    setSpeaker?: any;
    setOpen: any;
}
const StyledAvatar = styled(muiAvatar)(({ theme }) => ({
    width: theme.spacing(15),
    height: theme.spacing(15),
}))

export const UpsertSpeaker = ({speaker, setOpen, setSpeaker}: UpsertSpeakerType) => {
    const [imageBuffer, setImageBuffer] = React.useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const handleSubmit = async (event: React.SyntheticEvent) => {
        try {
            setDisableSubmit(true);
            event.preventDefault();

            const target = event.target as typeof event.target & {
                email: { value: string };
                fullname: { value: string };
                company: { value: string };
                phone: { value: string };
                title: { value: string };
            }
            if ( speaker ) {
                const tmpSpeaker = {...speaker,
                    email: target.email.value,
                    fullname: target.fullname.value,
                    company: target.company.value,
                    phone: target.phone.value,
                    title: target.title.value,
                    imageBuffer
                }
                const result = await Api({
                    name: 'Speakers::handleSubmit',
                    type: 'POST',
                    url: `${REACT_APP_SPEAKERS_API}${tmpSpeaker._id}`,
                    body: tmpSpeaker
                })
                setSpeaker(result.speaker);
                setOpen(false);
            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/speaker/handleSubmit', "Cannot save speaker", enqueueSnackbar);
        }


    }
        if ( speaker ) {
            return (
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Box sx={{ width: 250, marginTop: '10px' }}>
                        <Grid container>
                            <Grid item xs={7}>
                                <StyledAvatar alt="" src={`${REACT_APP_UPLOAD_BUCKET}${speaker.image ? `${speaker.image}` : `speaker/${speaker._id}.png`}`}  />
                            </Grid>
                            <Grid item xs={5}>
                                <Avatar
                                    width={200}
                                    height={155}
                                    exportSize={240}
                                    label='Click/drag to upload new image'
                                    labelStyle={{fontSize: 13, wordWrap: 'break-word'}}
                                    onCrop={(preview)=> {
                                        setImageBuffer(preview)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <TextField
                        autoFocus
                        required
                        fullWidth
                        margin="dense"
                        id="fullname"
                        label="Name"
                        type="text"
                        variant="standard"
                        defaultValue={speaker.fullname?.replace('Create speaker ', '')}
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        id="title"
                        label="Title"
                        type="text"
                        variant="standard"
                        defaultValue={speaker.title}
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        id="company"
                        label="Company"
                        type="text"
                        variant="standard"
                        defaultValue={speaker.company}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        variant="standard"
                        defaultValue={speaker.email}
                        sx={{marginRight: '10px', width: 'calc(50% - 5px)'}}
                    />
                    <TextField
                        margin="dense"
                        id="phone"
                        label="Phone"
                        type="phone"
                        variant="standard"
                        defaultValue={speaker.phone}
                        sx={{width: 'calc(50% - 5px)'}}
                    />

                    <div style={{ textAlign: 'right', padding: 8, margin: '24px -24px -24px -24px' }}>
                        <Button
                            type="reset"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, mr: 2 }}
                            onClick={()=> {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={disableSubmit}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </Button>
                    </div>
                </Box>
            )
        } else {
            return (<div/>);
        }

}


export const applyEditableFields = (html: string, templateFields: any, displayEmptyFields: boolean) => {
    templateFields.filter( (field: TemplateFieldType) => field.type === 'Image').map( (field: TemplateFieldType) => {
        let fieldValue = field.value;
        html = html.replaceAll(`[[${field.name}]]`, `<img class="updateField" style="cursor: pointer" onclick="sendMessage('${field.name}','', 'updateTemplateImage', ${field.imageWidth})" src="${fieldValue}"/>`);
    });

    templateFields.filter( (field: TemplateFieldType) => field.name.indexOf('__') === -1).map( (field: TemplateFieldType) => {
        //Some day you should fix this in a better way :)
        if (field.name !== 'CTA_URL'){
            if (!field.value && displayEmptyFields) {
                html = html.replaceAll(`[[${field.name}]]`, `<div title="This field is empty and will be collapsed when invitation is sent." class="updateField emptyField" style="padding-top: 20px;padding-left: 20px; font-style: italic; height: 50px; cursor: pointer; border-style: dotted; border-width: 1px" onclick="sendMessage('${field.name}','', 'updateTemplateField')">${field.name}...</div>`)
            } else {
                html = html.replaceAll(`[[${field.name}]]`, `<span class="updateField" style="cursor: pointer" onclick="sendMessage('${field.name}','', 'updateTemplateField')">${field.value}</span>`)
            }
        }


    });
    return html;
}
import axios, {AxiosResponse} from "axios";
import {getIdToken} from "./auth/azureAuthProvider";
import {fireAuthError} from "./auth/fireAuthError";

interface ApiType {
    name: string;
    type?: string;
    url?: string;
    body?: object;
    config?: object;
    isRetry?: boolean;
}

const maxRetries = 20; // Maximalt antal omförsök
const makeRequest = async({url, type, headers, body, config}: {url: string, type: string, headers: any, body?: object, config?: object})=> {
    switch (type.toUpperCase()) {
        case "POST":
            return await axios.post(url, body, { ...headers });
        case "GET":
            return await axios.get(url, { ...config, ...headers });
        case "PATCH":
            return await axios.patch(url, body, { ...headers });
        case "DELETE":
            return await axios.delete(url, { ...headers });
        case "PUT":
            return await axios.put(url, body, { ...headers });
        default:
            return Promise.reject(new Error("Invalid request type"));
    }
}

const handleRequestRetry = async({url, type, body, headers, config, retryCount = 0}: {url: string, type: string, body?: object, headers: any, config?: object, retryCount?: number}): Promise<any> => {
    try {
        return await makeRequest({url, type, body, headers, config});
    } catch (error: any) {
        const isNetworkError = error.message && error.message.includes('Network Error');
        const shouldRetry = isNetworkError && retryCount < maxRetries && type.toUpperCase() === 'GET';
        if (shouldRetry) {
            return handleRequestRetry({url, type, body, headers, config, retryCount: retryCount +1});
        } else {
            console.info(`Retry ${retryCount + 1} of ${maxRetries}`);
            throw error;
        }
    }
}

export const Api = async({name, type = 'GET', url='', body, config}: ApiType) => {
    const t0 = performance.now();
    const headers = {
        headers: {
            'Authorization': `bearer ${await getIdToken()}`
        }
    }
    console.info(`DEBUG-WS-${name}-${type.toUpperCase()}`, url, body, config);
    let wsResponse: AxiosResponse;
    try {
        wsResponse = await handleRequestRetry({url, type, body, headers, config});
        console.info(`DEBUG-WS-${name}-${type.toUpperCase()} response`, wsResponse.data);
        if (type.toUpperCase() === 'GET') return wsResponse;
        return wsResponse.data;
    } catch ( e: any ) {
        if ( e.response ) {
            if (e.response.status) fireAuthError(e.response.status, `${e.toString()} (${e.response.data.reason})` )
            else fireAuthError(999, `${e.toString()}`);
        } else fireAuthError(999, `${e.toString()}`);
    } finally {
        console.log(`DEBUG-WS-${name}-${type.toUpperCase()} took ${performance.now() - t0} milliseconds.`);
    }

}

import TreeItem, {treeItemClasses, TreeItemProps} from "@mui/lab/TreeItem";
import React from "react";
import {SvgIconProps} from "@mui/material/SvgIcon";
import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    level?: number;
    labelIcon?: React.ElementType<SvgIconProps>;
    labelCountryFlag?: string;
    labelInfo?: string;
    labelAction?: any;
    labelText: string;
    key?: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        paddingLeft: theme.spacing(2),
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

export const StyledTreeItem = (props: StyledTreeItemProps) => {
    const {
        bgColor,
        color,
        level,
        labelIcon: LabelIcon,
        labelCountryFlag,
        labelInfo,
        labelText,
        labelAction,
        ...other
    } = props;
    return (
        <StyledTreeItemRoot
            key={props.nodeId}
            label={
                <Box sx={{ display: 'flex', alignItems: 'top', p: 0.5, pr: 0, textAlign: 'left' }}>
                    {
                        labelCountryFlag && <ReactCountryFlag
                            style={{
                                fontSize: '2em'
                            }} countryCode={labelCountryFlag} />
                    }
                    {
                        !labelCountryFlag &&
                        <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    }
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, textAlign: 'left' }}>
                        {labelText}
                    </Typography>
                    {labelAction}
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

interface MenuTypeBrand {
    product: MenuTypeProduct
}
interface MenuTypeProduct {
    country: MenuTypeCountry[]
}
interface MenuTypeCountry {
    current: MenuTypeItem;
    draft: MenuTypeItem;
    history: MenuTypeItem[]
}
interface MenuTypeItem {
    _id: string;
    name: string;
}
export interface MenuType {
    brand: MenuTypeBrand;
}

import {createContext} from 'react'
import {handleTemplateFieldFieldType} from "./handleTemplateField";
import {handleTemplateUpdateTypeV2} from "./handleTemplate";
import {TemplateSuiteContextType} from "./TemplateSuiteContext";

interface TemplateContextType extends TemplateSuiteContextType {
    template: TemplateType | any;
    country: "SE" | "DK" | "FI" | "NO" | "";

}
export const TemplateContext = createContext<TemplateContextType>({
    templateSuite: {},
    handleTemplateUpdateV2: (payload: handleTemplateUpdateTypeV2) => {},
    handleTemplateSuiteChange: (field: string, value: any) => {},
    handleTemplateFieldUpdate: (fields: handleTemplateFieldFieldType[]) => {},
    footer: '',
    isUsingFragmentFooter: false,
    template: {},
    country: ""
});

import {Api} from "../../Api";
import axios from "axios";
import {DropzoneDialog} from "material-ui-dropzone";
import * as React from "react";
import {Button} from "@mui/material";
import {useEffect} from "react";
import {useSnackbar} from "notistack";

export const UploadFragmentFile = ({_id, afterUpload, setFileSrc, fieldName}: UploadFragmentFileInput) => {
    const [localOpen, setLocalOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const uploadFile = async (file: any) => {

        const path = `fragments/images/${_id}`
        const result = await Api( {
            name: 'UploadFile',
            type: 'POST',
            url: `${process.env.REACT_APP_COMMON_API}uploadToS3`,
            body: {path, ContentType: file.type}
        });
        if ( result ) {
            const options = {
                headers: {
                    'Content-Type': file.type
                }
            };
            let res;
            try {
                res =  await axios.put(result.url, file, options);
            } catch (e: any) {
                console.error(e.toString());
                console.log(res);
            }
            return result
        }
    }
    const handleFileDrop = async (files: string | any[]) =>{
        try {
            if (files.length>0) {
                setLocalOpen(false);
                const file = files[0];
                const img = new Image();
                const objectUrl = window.URL.createObjectURL(file);
                img.onload = function () {
                    // @ts-ignore
                    if ( (file.size/1000) > 100 ) {

                        enqueueSnackbar(`The image has a size of ${(file.size/1000)} kb. That is quite big, consider using an image with a smaller size.`, {variant: 'warning', autoHideDuration: 15000})
                    }
                    window.URL.revokeObjectURL(objectUrl);
                    const reader = new FileReader();
                    reader.onload = async function (e) {
                        //Display uploaded file in iframe
                        if(file.type.indexOf('image') > -1) {
                            const res = await uploadFile(file);
                            if (setFileSrc) {
                                // @ts-ignore
                                setFileSrc(e.target.result);
                            }
                            afterUpload(res.key);
                        }
                    };
                    reader.readAsDataURL(file);
                };
                img.src = objectUrl;
            }
        } catch (e: any) {
            console.log(e.toString())
        }


    }
    return (
        <div>
            <DropzoneDialog
                showPreviews={false}
                maxFileSize={1000000}
                open={localOpen}
                showAlerts={['error']}
                filesLimit={1}
                onDrop={handleFileDrop}
                onClose={()=> {
                    setLocalOpen(false)}}
            />
            <Button variant="contained" component="span" onClick={()=>{setLocalOpen(true)}} sx={{mt: '20px'}}>
                    Upload image
                </Button>

        </div>

    )
}
interface UploadFragmentFileInput {
    fieldName: string;
    _id: string;
    afterUpload: any;
    setFileSrc?: any;
}

import {EditQuill} from "../../components/EditDialog";
import React from "react";

export const FragmentTextArea = ({fragment, saveQuillValue}: {fragment: FragmentType, saveQuillValue(value: string): void}) => {
    return (
        <EditQuill
            readOnly={fragment?.status !== 'Draft'}
            onChange={saveQuillValue}
            width="100%" height="60vh" defaultValue={fragment?.value??''}
            fieldId={fragment._id!}
        />
    )
}

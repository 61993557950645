import React, {useContext} from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {ActivityContext} from "../../ActivityContext";
import {SendEmailComponent} from "../SendEmailComponent";


export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto',
    marginTop: '50px',
    flexDirection: 'column',
    width: '1100px'
}))

export const PreEventInfo: any = () => {
    const {activity, participants, isEmbedded, isEditor}  = useContext(ActivityContext);
    return (
        <SendEmailComponent
            srcDoc={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL PRE-EVENT INFO').compiledHtml}
            subject={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL PRE-EVENT INFO').subject}
            action={'SEND_PRE_EVENT_INFO'}
            emailTypeName={'preEventInfoSent'}
            participantTabs={{
                tab1: {
                    label: 'Not sent ',
                    canSendEmail: true,
                    canAddParticipant: false,
                    canDeleteParticipant: false,
                    canSelectCustomers: true,
                    participants: participants.filter( (participant: ParticipantType) => participant.participate && !participant.preEventInfoSent),
                    showField: 'preEventInfoNotSent',
                    title: 'Pre-event info not sent',
                    freeActionTitle: 'Send invitation to all the selected participants?',
                    actionTitle: 'Send invitation',
                    display: !isEmbedded
                },
                tab2: {
                    canSendEmail: false,
                    canAddParticipant: false,
                    canDeleteParticipant: false,
                    canSelectCustomers: false,
                    label: 'Sent',
                    participants: participants.filter( (participant: ParticipantType) => participant.preEventInfoSent),
                    showField: 'preEventInfoSent',
                    title: 'Pre-event info sent',
                    display: !isEmbedded
                },
                tab3: {
                    canSendEmail: false,
                    canAddParticipant: false,
                    canDeleteParticipant: false,
                    canSelectCustomers: false,
                    label: 'Viewed',
                    participants: participants.filter( (participant: ParticipantType) => participant.preEventInfoViewed),
                    showField: 'preEventInfoSent',
                    title: 'Pre-event info viewed',
                    display: !isEmbedded
                },
                isReadOnly: !isEditor
            }
            }
        />
    );
}

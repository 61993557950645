import React, {useEffect} from "react";
import {format} from "date-fns";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";

import {Autocomplete, Stack, TextField} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {sv, nn, da, fi} from 'date-fns/locale';
const timeSlots = Array.from(new Array(24 * 6)).map(
    (_, index) =>
        `${`0${Math.floor(index / 6)}`.slice(-2)}:${
            index % 6 === 0 ? '00' : `0${index % 6 * 10}`.slice(-2)
        }`,
);
interface DateTimePickerInput {
    label: string;
    date: Date;
    setDate(dt: Date): void;
    disabled: boolean;
    minDate?: Date;
}
export const DateTimePicker = ({label,date, setDate, disabled, minDate}: DateTimePickerInput) => {
    const [time, setTime] = React.useState(format(new Date(date), 'HH:mm'));
    const onChange = (newDate: Date | null) => {
        if (newDate)
            setDate(newDate);
    }
    useEffect(() => {
        setTime(format(new Date(date), 'HH:mm'));
    }, [date])
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction="row" spacing={3}>
                <DesktopDatePicker
                    format={'yyyy-MM-dd'}
                    label={`${label}`}
                    disabled={disabled}
                    value={new Date(date)}
                    onChange={onChange}
                    slots={{
                        textField: (params) => <TextField variant="standard" {...params} />
                    }}
                />
                <Autocomplete
                    id={`${label}-time`}
                    options={timeSlots}
                    selectOnFocus
                    sx={{width: '120px'}}
                    autoSelect
                    value={time}
                    onChange={(e: any, val: any) => {
                        const time = val?? '00:00'
                        setDate(new Date(format(new Date(date), 'yyyy-MM-dd')+ ' ' + val))
                    }}
                    disabled={disabled}
                    renderInput={(params) => <TextField sx={{pb: '2px'}} variant="standard" {...params} label={` `} />}
                />
            </Stack>
        </LocalizationProvider>
    )
}

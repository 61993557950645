import {getActivity} from "../../../methods";

interface DefaultInput {
    template: TemplateType;
    handleTemplateUpdate?: any;
    handleTemplateFieldUpdate?: any;
    setTemplateFields: any;
    setActivity?: any;
    activityId?: string;
    setWaitingForBackendMessage?: any
}

export const addQuestion = ({template, handleTemplateFieldUpdate, setActivity, activityId,setWaitingForBackendMessage}: DefaultInput) => {
    const tmpTemplate = JSON.parse(JSON.stringify(template));
    const field = tmpTemplate.fields.find( (field: TemplateFieldType ) => field.name === '__Questions');
    const record = {category: 'New category', label: 'New label', useComment: false, useRating: false, useTextField: false, isRequired: false, sort: -1};
    if ( !field.questions ) {
        field.questions = [record];
    }
    field.questions.push(record)
    handleTemplateFieldUpdate([{field, template, isDeleted: false, action: 'Update'}]);

    setWaitingForBackendMessage('Adding field, hold on...')
    setTimeout( async () => {
        if (activityId)
            setActivity(await getActivity(activityId));
        setWaitingForBackendMessage('');
    }, 3000)
}

interface DeleteQuestionInput {
    _id: string
}
export const deleteQuestion = ({template, handleTemplateFieldUpdate, setTemplateFields, _id}: DefaultInput & DeleteQuestionInput) => {
    //I need to make a copy because if I dont, then diff() will not work since we are updating the source object
    const tmpTemplate = JSON.parse(JSON.stringify(template));
    const field = tmpTemplate.fields.find( (field: TemplateFieldType ) => field.name === '__Questions');
    field.questions = field.questions.filter( (question: TemplateQuestionType) => question._id !== _id);
    handleTemplateFieldUpdate([{field, template, isDeleted: false, action: 'Update'}]);
}

interface HandleQuestionChange {
    event: any;
    _id?: string;
    name?: string;
    value?: any;
    templateFields: TemplateFieldType[];
}
export const handleQuestionChange = ({event, _id, name, value, template, templateFields, handleTemplateFieldUpdate, setTemplateFields}: DefaultInput & HandleQuestionChange) => {
    const splitString = event.target.id.split("-");
    //I need to make a copy because if I dont, then diff() will not work since we are updating the source object
    const tmpTemplate = JSON.parse(JSON.stringify(template));
    const field = tmpTemplate.fields.find( (field: TemplateFieldType ) => field.name === '__Questions');

    handleTemplateFieldUpdate([{
        field: {
            ...field,
            questions: field.questions.map( (question: TemplateQuestionType) => {
                if (question._id === splitString[0] || question._id === (_id?? 'empty')) {
                    if (splitString[1] === 'useTextField' || splitString[1] === 'useComment' || splitString[1] === 'useRating' || splitString[1] === 'useYesNo' || splitString[1] === 'useCombo' || splitString[1] === 'useRadio') {
                        if ( tmpTemplate.type !== 'SURVEY') {
                            question['useComment'] = false;
                            question['useRating'] = false;
                            question['useYesNo'] = false;
                            question['useTextField'] = false;
                        }

                        // @ts-ignore
                        question[splitString[1]] = event.target.checked;
                    } else if (splitString[1] === 'isRequired' || splitString[1] === 'hidden') {
                        // @ts-ignore
                        question[splitString[1]] = event.target.checked;
                    }
                    else if (name && (name === 'parentQuestion' || name === 'fieldType'|| name === 'sort' || name === 'choices')) {
                        question[name] = value;
                    }
                    else {
                        // @ts-ignore
                        question[splitString[1]] = event.target.value;
                    }

                }
                return question;
            })
        },
        template,
        isDeleted: false,
        action: 'Update'}]);
}

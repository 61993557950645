import Grid from '@mui/material/Unstable_Grid2';
import {SendEmailForm} from "../modules/Activity/components/SendEmailForm";
import {
    Box,
    Button
} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {Api} from "../Api";
import {sendErrorMessage} from "../Error";
import {useSnackbar} from "notistack";
import {useLocation} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {AppContext} from "../AppContext";
import {Menu} from "../views/ActivitiesTreeMenu";

const {REACT_APP_ACTIVITY_API, REACT_APP_CUSTOMER_API} =  process.env;

export const SendEmail = ({customerIdInput, cancel}: SendEmailInput) => {
    const {setWaitingForBackendMessage, user} = useContext(AppContext);
    const search = useLocation().search;
    const [activities, setActivities] = React.useState([]);
    const [customers, setCustomers] = React.useState<any[]>([]);
    const [activity, setActivity] = React.useState<undefined | ActivityType>();
    const [selectedNodeItem, setSelectedNodeItem] = React.useState('0');
    const [template, setTemplate] = React.useState<undefined | TemplateType>();
    const [emailDialog, openEmailDialog] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const getActivities = async (customerIds?: string) => {
        try {
            const url = REACT_APP_ACTIVITY_API;
            let resCustomers: any, resActivities ;
            setWaitingForBackendMessage('Get Email templates');
            resActivities = await Api(
                {
                    name: 'Activities:getData',
                    url: `${url}repemails`,
                    config: {
                        params: {
                            filter: JSON.stringify([
                                    {fieldName: 'archived', value: false},
                                    {fieldName: 'countries', value: user?.countries},
                                    {fieldName: 'isApproved', value: true},
                                    {fieldName: 'templateSuite.type', value: ['RepEmail', 'BookingEmail', 'Event']}
                                ]
                            ),
                            returnTemplateSuite: true
                        }
                    }
                })
            if ( resActivities && resActivities.data ) {
                setActivities(
                    resActivities.data.filter((act: ActivityType) => {
                            let display =  false;
                            const template = act?.templateSuite?.templates?.find( (t: TemplateType) => t.type === 'E-MAIL INVITATION');
                            if (template?.countryVersion?.length) {
                                const countryVersion = template?.countryVersion;
                                user?.countries?.map( (uv: any) => {
                                    if (act?.approvalCodes?.find( (ac: any) => ac.country === uv && ac.approvalCode)) {
                                        if ( countryVersion.find( (cv2: any) => cv2.readOnly && cv2.country === uv)) {
                                            display = true;
                                        }
                                    }
                                })
                            } else if(template?.readOnly) {
                                    display = true;
                            }
                            return display

                    } )
                );
            } else {
                await sendErrorMessage({message: 'There are no active campaigns'},  'front/SendEmail/getData', "Cannot get campaigns", enqueueSnackbar);
            }

            if (customerIds) {
                setWaitingForBackendMessage('Prepare selected customers');
                //Get customers
                resCustomers = await Api({
                    name: 'getCustomers',
                    url: `${REACT_APP_CUSTOMER_API}customers`,
                    config: {
                        params: {
                            customerIds,
                            filter: JSON.stringify([{fieldName: 'consent', value: ['Approved']}]),
                            includeOnlyIdInResponse: false
                        }
                    }
                })
                setWaitingForBackendMessage('');
                if ( resCustomers && resCustomers.data ) {
                    setCustomers(resCustomers.data?.records?.filter( (c1: any) => c1.customer)?.map((c: any) => c.customer));
                } else {
                    await sendErrorMessage({message: 'Something went wrong when preparing the customers'},  'front/SendEmail/getCustomers', "Cannot prepare customers", enqueueSnackbar);
                }
            }

        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/SendEmail/getData', "Cannot Get Campaign", enqueueSnackbar);
        } finally {
            setWaitingForBackendMessage('');
        }
    }
    const cancelEvent = () => {
        if ( typeof cancel === 'function') {
            cancel();
        }
        window.parent.postMessage({handshake: "Event Manager", event: 'Close'}, "*")
    }
    useEffect( () => {
        const inputCustomers = new URLSearchParams(search).get('customers');
        ( async() => {
            const custIds  = inputCustomers ?? customerIdInput;
            if (custIds) {
               await getActivities(custIds);
            } else {
                await getActivities();
            }
        })()

    }, [])
    useEffect( () => {
        if ( activity && activity.templateSuite && activity.templateSuite.templates ) {
            const tmpl = activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL INVITATION');
            if ( tmpl && tmpl.subject) {
                setTemplate(tmpl);
                setSelectedNodeItem(activity?._id??'');
            }
        }

    }, [activity])
    useEffect( () => {
        if ( template ){
            openEmailDialog(true);
        }

    }, [template])
    useEffect( () => {
        if (!emailDialog) {
            setActivity(undefined);
            setTemplate(undefined);
        }
    }, [emailDialog])
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={4}>
                    <Grid sx={{textAlign: 'left', pb: '20px'}}>
                        <Button onClick={cancelEvent} variant="contained" endIcon={<ArrowBackIcon />}>
                            Go back
                        </Button>
                    </Grid>
                    <Menu addEVent={() => {}} defaultSelected={selectedNodeItem} activities={activities} onClick={(act: ActivityType) => {openEmailDialog(false); setTimeout(()=> {setActivity(act)}, 200)}}/>

                </Grid>
                <Grid xs={8}>
                    {
                        activity && template &&
                        <SendEmailForm
                            onSent={() => {}}
                            activityCustomerIds={activity.participants?.map( participant => participant.customer)}
                            activityId={activity._id}
                            country={user?.countries?.length ? user?.countries[0] : 'SE'}
                            customerIdsInput={customers}
                            template={template}
                            openForm={openEmailDialog} />
                    }

                </Grid>
            </Grid>
        </Box>

    );
}

interface SendEmailInput {
    customerIdInput?: string | undefined;
    cancel?: any;
}

import React, {useContext,} from "react";
import {ActivityContext} from "../../ActivityContext";
import {AppContext} from "../../../../AppContext";
import {SendEmailComponent} from "../SendEmailComponent";

export const SendSurvey: any = () => {
    const {activity,  participants, isEditor}  = useContext(ActivityContext);
    const {isEmbedded} = useContext(AppContext);


    return (
            <SendEmailComponent
                srcDoc={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL SURVEY').compiledHtml}
                subject={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL SURVEY').subject}
                action={'SEND_SURVEY_URL'}
                emailTypeName={'surveyUrlSent'}
                participantTabs={{
                    tab1: {
                        label: 'Not sent ',
                        canSendEmail: true,
                        canAddParticipant: !activity.selectedTemplates.includes('Invitation'),
                        canDeleteParticipant: false,
                        canSelectCustomers: true,
                        participants: participants.filter( (participant: ParticipantType) => (!activity.selectedTemplates.includes('Invitation') || participant.participate) && !participant.surveyUrlSent),
                        showField: 'surveyNotSent',
                        title: 'Ask for feedback not sent',
                        freeActionTitle: 'Send ask for feedback to all the selected participants?',
                        actionTitle: 'Send ask for feedback',
                        display: !isEmbedded
                    },
                    tab2: {
                        canSendEmail: false,
                        canAddParticipant: false,
                        canDeleteParticipant: false,
                        canSelectCustomers: false,
                        label: 'Sent',
                        participants: participants.filter( (participant: ParticipantType) => participant.surveyUrlSent),
                        showField: 'surveySent',
                        title: 'Ask for feedback sent',
                        display: !isEmbedded
                    },
                    tab3: {
                        canSendEmail: false,
                        canAddParticipant: false,
                        canDeleteParticipant: false,
                        canSelectCustomers: false,
                        label: 'Viewed',
                        participants: participants.filter( (participant: ParticipantType) => participant.surveyUrlViewed),
                        showField: 'surveySent',
                        title: 'Ask for feedback viewed',
                        display: !isEmbedded
                    },
                    isReadOnly: !isEditor
                }
                }
            />
    );
}

import React, {useContext} from "react";
import {ActivityContext} from "../ActivityContext";
import {AppContext} from "../../../AppContext";
import {useSnackbar} from "notistack";
import {sendEmails} from "../methods";
import {sendErrorMessage} from "../../../Error";

import Grid from "@mui/material/Grid";
import {Box, Button, TextField} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import {ParticipantTabs, ParticipantTabsProps} from "./ParticipantTabs";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

export const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto',
    marginTop: '50px',
    flexDirection: 'column',
    width: '900px'
}))
interface SendEmailProps {
    srcDoc: string,
    subject: string,
    action: string,
    emailTypeName: string,
    participantTabs: ParticipantTabsProps,
}
export const SendEmailComponent = ({srcDoc, subject, action, emailTypeName, participantTabs}: SendEmailProps) => {
    const {activity, setActivityPayload, participants,isEditor, activeStep, currentStep} = useContext(ActivityContext);
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const [selectedParticipants, setSelectedParticipants] = React.useState<ParticipantType[] | undefined>();
    const {enqueueSnackbar} = useSnackbar();
    const handleSelectedParticipants = (participantIds: string[]) => {
        setSelectedParticipants(participants.filter((participant: ParticipantType) => participantIds.indexOf(participant?._id ?? '') > -1))
    }
    const handleReminder = (participantIds: string[]) => {
        const customerWith3OrMoreReminders = participants.filter( (participant: ParticipantType) => participantIds.indexOf(participant?._id??'') > -1 && participant?.invitationReminders?.length > 2);
        const customerWith2Reminders = participants.filter( (participant: ParticipantType) => participantIds.indexOf(participant?._id??'') > -1 && participant?.invitationReminders?.length === 2);
        const customerWith1Reminders = participants.filter( (participant: ParticipantType) => participantIds.indexOf(participant?._id??'') > -1 && participant?.invitationReminders?.length === 1);
        const customerWith0Reminders = participants.filter( (participant: ParticipantType) => participantIds.indexOf(participant?._id??'') > -1 && !participant?.invitationReminders?.length);
        if (window.confirm("Here's a breakdown of the reminders that will be sent: \n\n" +
            (customerWith0Reminders.length ? '1st reminder: ' + customerWith0Reminders.length + ' participants\n' : '') +
            (customerWith1Reminders.length ? '2nd reminder: ' + customerWith1Reminders.length + ' participants\n' : '') +
            (customerWith2Reminders.length ? '3rd reminder: ' + customerWith2Reminders.length + ' participants\n' : '') +
            (customerWith3OrMoreReminders.length ? "Participants who have already received three reminders (and won't be sent a fourth): " + customerWith3OrMoreReminders.length + ' participants' : ''))) {
            handleSelectedParticipants(participantIds);
        }
    }
    const sendToSelected = () => {
        if (selectedParticipants && selectedParticipants.length) {
            setWaitingForBackendMessage('Sending email(s)...');
            const participantIds: any = selectedParticipants?.map((par: ParticipantType) => par?._id);
            (async () => {
                try {
                    await sendEmails({
                        activityId: activity._id,
                        participantIds,
                        action
                    });
                    if (!activity[emailTypeName]) {
                        setActivityPayload({
                            refreshActivity: true,
                            payload: {
                                [emailTypeName]: true,
                                status: activity.status > activeStep! ? activity.status : activeStep! + 1
                            },
                        });
                    }
                    setWaitingForBackendMessage('');
                    setSelectedParticipants(undefined);
                    enqueueSnackbar(`Email(s) sent`, {variant: 'success'});

                } catch (e: any) {
                    await sendErrorMessage(e, 'front/activity/methods.sendEmails', "Cannot Send email", enqueueSnackbar, activity._id);
                }

            })()

        }

    }

    // @ts-ignore
    return (
        <Grid>
            {
                !activity.approvalCode &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        fontSize: '20px',
                        zIndex: 999999,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Semi-transparent black background
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Light shadow for a lifted effect
                        border: '1px solid rgba(255, 255, 255, 0.2)', // Thin white border for better edge definition

                        // Centering logic
                        position: 'fixed',   // or 'absolute' if you want it relative to the nearest positioned ancestor
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <strong>Attention!</strong>
                    <p>
                        Veeva number / Approval number is not set, you cannot send e-mails.
                    </p>
                    <p>
                        Please go to step "Approval".
                    </p>
                </Box>

            }

            {
                selectedParticipants?.length && activity.approvalCode &&
                <StyledPaper elevation={24}>
                    <Grid container sx={{marginTop: '10px'}}>
                        <Grid item xs={6} sx={{textAlign: 'left'}}>

                            <Button onClick={() => setSelectedParticipants(undefined)} variant="contained"
                                    endIcon={<CancelIcon/>}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'right'}}>
                            <Button disabled={!isEditor} onClick={sendToSelected} variant="contained"
                                    endIcon={<SendIcon/>}>
                                Send
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="toEmail"
                                label="To:"
                                type="email"
                                variant="standard"
                                defaultValue={selectedParticipants?.map((participant: ParticipantType) => participant.email).join()}
                                fullWidth
                                disabled={true}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="subject"
                                label="Subject:"
                                type="text"
                                variant="standard"
                                disabled={true}
                                defaultValue={subject}
                                fullWidth
                            />
                            <iframe style={{height: '1500px', width: '100%', overflow: 'auto'}} frameBorder={0}
                                    srcDoc={srcDoc} id="body"></iframe>
                        </Grid>
                    </Grid>
                </StyledPaper>
            }
            {
                !selectedParticipants?.length && activity.approvalCode &&
                <ParticipantTabs {...participantTabs} isReadOnly={!isEditor} action={action === 'SEND_INVITATION_REMINDER' ? handleReminder : handleSelectedParticipants}/>
            }
        </Grid>
    );
}
import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
    TextField,
    Accordion,
    AccordionSummary, Typography, AccordionDetails
} from "@mui/material/";


import {Speakers} from "./speakers";
import {styled} from "@mui/material/styles";
import {ComboBox} from "../../../../components/FormElements";

import {ActivityContext} from "../../ActivityContext";

import {EventAdvancedSettings, EventDateTime} from "./Event"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {RepEmail} from "./RepEmail";
import {BookingEmail} from "./BookingEmail";
import {AppContext} from "../../../../AppContext";

export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto auto auto',
    marginTop: '40px',
    flexDirection: 'column',
    marginBottom: '40px',
    width: '900px',
}))
export interface EventInputType {
    listOfValues: any;
}
export const Details: any = () => {
    const {activity, setActivityPayload, isReadOnly, isArchived, isEditor, users}  = useContext(ActivityContext);
    const {getLOV} = useContext(AppContext);
    const [selectedUser, setSelectedUser] = React.useState({value: activity.responsible, name: users?.find( (u: any) => u.value === activity.responsible).name})
    const [listOfValues] = React.useState({
        templates: getLOV('template'),
        country: getLOV('country'),
        brand: getLOV('brand').map( (brand: any) => {
            return {...brand, product: getLOV('product', brand.value)}
        }),
        user: users
    });
    const onChange = (e: any) => {
        let status = activity.status;
        if (status === 0 && e.target.id === 'subject' && e.target.value){
            status = 1
        }
        setActivityPayload(
            {
                refreshActivity: true,
                payload: {
                    [e.target.id]: e.target.value,
                    status
                }
            }
        );
    }

    return (
                <StyledPaper elevation={24}>
                    <Grid container spacing={10}>
                        <Grid item xs={12}>
                            <Grid item xs={12} sx={{paddingBottom: '10px'}}>
                                <TextField
                                    required
                                    id="subject"
                                    name="subject"
                                    label="Title"
                                    disabled={isArchived  || !isEditor}
                                    fullWidth
                                    defaultValue={activity.subject}
                                    autoComplete='off'
                                    onBlur={onChange}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid container spacing={10}>
                                <Grid item xs={6} >
                                    <Grid container spacing={10}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} direction="row" >
                                                <Grid item xs={12}>
                                                    <EventDateTime/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ComboBox
                                                        options={listOfValues.country}
                                                        required={true}
                                                        label="Country"
                                                        placeholder="Select country"
                                                        disabled={isReadOnly || isArchived || !isEditor}
                                                        value={activity.countries.map( (country: any) => {
                                                            return {
                                                                value: country
                                                            }
                                                        })}
                                                        onChange={(e: any, val: any) => {
                                                            setActivityPayload({refreshActivity: true, payload: {countries: val.map( (v:any) => {
                                                                return v.value
                                                                    })}});
                                                        }} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <RepEmail listOfValues={listOfValues}/>
                                                    <BookingEmail listOfValues={listOfValues}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ComboBox
                                                        required={true}
                                                        multiple={false}
                                                        options={listOfValues.user}
                                                        label="Responsible"
                                                        placeholder="Select responsible"
                                                        disabled={isArchived || !isEditor}
                                                        value={selectedUser}
                                                        onChange={(e: any, val: any) => {
                                                            setActivityPayload({refreshActivity: false, payload: {responsible: val.value}});
                                                            setSelectedUser(val);
                                                        }} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="comment"
                                                        name="comment"
                                                        label="Comments"
                                                        fullWidth
                                                        disabled={isArchived || !isEditor}
                                                        multiline
                                                        rows={3}
                                                        variant="outlined"
                                                        onBlur={(e) =>{
                                                            setActivityPayload({refreshActivity: false, payload: {[e.target.id]: e.target.value}});
                                                        }}
                                                        defaultValue={activity.comment}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} hidden={activity.templateSuite.type !== 'Event'}>
                                                    <Accordion sx={{borderColor: 'rgba(0, 0, 0, 0.08)'}}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Advanced settings</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={2} direction="row" >
                                                                <Grid item xs={12}>
                                                                    <EventAdvancedSettings listOfValues={listOfValues}/>
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}
                                      style={{paddingBottom:0}}
                                      hidden={['RepEmail', 'Survey', 'Registration'].includes(activity?.templateSuite?.type) || (activity.selectedTemplates.length === 1 && activity.selectedTemplates.includes('Survey'))}>
                                    <Speakers activity={activity} setActivityPayload={setActivityPayload} isReadOnly={isArchived || !isEditor} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </StyledPaper>
    );
}

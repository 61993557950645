
type Path = (string | number)[];

interface Difference {
    path: Path;
    oldValue: any;
    newValue: any;
}

export const compareObjects = (obj1: any, obj2: any, path: Path = []): Difference[] => {
    const differences: Difference[] = [];
    try {
        // Compare all keys in obj1
        for (const key of Object.keys(obj1)) {
            const newPath = [...path, key];

            if (key in obj2) {
                // Key exists in both objects
                const value1 = obj1[key];
                const value2 = obj2[key];

                if (typeof value1 === 'object' && typeof value2 === 'object') {
                    if (Array.isArray(value1) && Array.isArray(value2)) {
                        // Recursively compare nested arrays
                        if (value1.length !== value2.length) {
                            differences.push({
                                path: newPath,
                                oldValue: value1,
                                newValue: value2
                            });
                        } else {
                            for (let i = 0; i < value1.length; i++) {
                                differences.push(...compareObjects(value1[i], value2[i], [...newPath, i]));
                            }
                        }
                    } else {
                        // Recursively compare nested objects
                        differences.push(...compareObjects(value1, value2, newPath));
                    }
                } else if (value1 !== value2) {
                    // Values are different, add to differences array
                    differences.push({
                        path: newPath,
                        oldValue: value1,
                        newValue: value2
                    });
                }
            } else {
                // Key only exists in obj1, add to differences array
                differences.push({
                    path: newPath,
                    oldValue: obj1[key],
                    newValue: undefined
                });
            }
        }

        // Compare all keys in obj2 that are not in obj1
        for (const key of Object.keys(obj2)) {
            const newPath = [...path, key];
            if (!(key in obj1)) {
                // Key only exists in obj2, add to differences array
                differences.push({
                    path: newPath,
                    oldValue: undefined,
                    newValue: obj2[key]
                });
            }
        }

    } catch (e: any) {
//Do nothing
    }
    return differences;


}

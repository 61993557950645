import React, {useContext, useEffect} from "react";
import {MenuType, StyledTreeItem} from "../components/CustomTreeView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MedicationIcon from "@mui/icons-material/Medication";
import EventIcon from '@mui/icons-material/Event';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import GroupIcon from '@mui/icons-material/Group';
import FeedbackIcon from '@mui/icons-material/Feedback';
import TreeView from '@mui/lab/TreeView';
import EmailIcon from '@mui/icons-material/Email';
import {TemplateParams} from "../modules/Activity/CreateActivity";
import {AppContext} from "../AppContext";

export const Menu = ({templates, onClick, defaultSelected, addEVent}: { templates: TemplateSuiteType[] | undefined, onClick: any, defaultSelected: string, addEVent: any }) => {
    const {getLOV} = useContext(AppContext);
    const [listOfValues] = React.useState({
        country: getLOV('country'),
        brand: getLOV('brand'),
        type: getLOV('type'),
    });
    const sortMenu = (key: string, func: any) => {
        if (templates) {
            templates.sort((a: any, b: any) => (a[key] ?? '').localeCompare(b[key] ?? '')).map((f: any) => {
                func(f);
            })
        }
    }
    useEffect(() => {
        if (templates) {
            let tmpMenu: any = [];
            sortMenu('TemplateType', (f: TemplateSuiteType) => {
                if (f.type)
                    tmpMenu[f.type] = {};
            });
            sortMenu('brand', (f: TemplateSuiteType) => {
                if (f.brand && f.type)
                    tmpMenu[f.type][f.brand] = [];
            });
            sortMenu('template', (f: TemplateSuiteType) => {
                if (f.brand && f.type)
                    tmpMenu[f.type][f.brand].push(f);
            });
        }
    }, [templates])
    const Events = ({templateSuite, index}: {templateSuite: TemplateSuiteType, index: number}) => {
        return (
            <React.Fragment>
                {
                    templateSuite.type === 'Event' &&
                    <StyledTreeItem key={`${index}_0`} nodeId={`${index}_0`} labelText={templateSuite.type??''} labelIcon={EventIcon} >
                        <StyledTreeItem key={`${index}_1`} nodeId={`${index}_1`} labelText="Webinar" labelIcon={OnlinePredictionIcon} >
                            <Event index={`${index}_1`} templateSuite={templateSuite} type={"Webinar"}></Event>
                        </StyledTreeItem>
                        <StyledTreeItem key={`${index}_2`} nodeId={`${index}_2`} labelText="Meeting" labelIcon={GroupIcon} >
                            <Event index={`${index}_2`} templateSuite={templateSuite} type={"Meeting"}></Event>
                        </StyledTreeItem>
                        <StyledTreeItem key={`${index}_3`} nodeId={`${index}_3`} labelText="Survey" labelIcon={FeedbackIcon} >
                            <Event index={`${index}_3`} templateSuite={templateSuite} type={"Survey"}></Event>
                        </StyledTreeItem>
                    </StyledTreeItem>
                }
            </React.Fragment>

        )
    }
    const Event = ({index, templateSuite, type}: {index: string, templateSuite: TemplateSuiteType, type: string}) => {
        const templateParams: TemplateParams = {templateSuite, type, jointEvent: false, brand: undefined, nodeId: "0_0"};
        return (
            <React.Fragment>
                <StyledTreeItem key={`${index}_0`} nodeId={`${index}_0`} labelText="Branded" labelIcon={BrandingWatermarkIcon} >
                    {
                        listOfValues.brand.map( (brand: any, index1: number) => {
                            return (
                                <StyledTreeItem
                                    key={`${index + index1 +1}`}
                                    nodeId={`${index + index1 +1}`}
                                    labelText={`${brand.value}`}
                                    labelIcon={MedicationIcon}
                                    onClick={() => onClick({...templateParams, brand: brand.value, nodeId: `${index + index1 +1}`})}
                                />
                            )
                        })
                    }
                </StyledTreeItem>
                <StyledTreeItem key={`${index}_1`} nodeId={`${index}_1`} labelText="Generic" labelIcon={EventIcon}
                                onClick={() => onClick({...templateParams, nodeId: `${index}_1`})} />
                <StyledTreeItem key={`${index}_2`} nodeId={`${index}_2`} labelText="Joint Event" labelIcon={JoinFullIcon}
                                onClick={() => onClick({...templateParams, jointEvent: true, nodeId: `${index}_2`})} />
            </React.Fragment>
        )
    }
    const RepEmails = ({templateSuite, index}: {templateSuite: TemplateSuiteType, index: number}) => {
        const templateParams: TemplateParams = {templateSuite, type: 'RepEmail', jointEvent: false, brand: undefined, nodeId: ''};
        return (
            <React.Fragment>
                {
                    templateSuite.type === 'RepEmail' &&
                    <StyledTreeItem
                        key={`${index}`}
                        nodeId={`${index}`}
                        labelText={`${templateSuite.brand??''} - ${templateSuite.description}`}
                        labelIcon={EmailIcon}
                        onClick={() => onClick({...templateParams, brand: undefined, nodeId: `${index}`})}

                    />
                }
            </React.Fragment>

        )
    }
    const BookingEmails = ({templateSuite, index}: {templateSuite: TemplateSuiteType, index: number}) => {
        const templateParams: TemplateParams = {templateSuite, type: 'BookingEmail', jointEvent: false, brand: undefined, nodeId: ''};
        return (
            <React.Fragment>
                {
                    templateSuite.type === 'BookingEmail' &&
                    <StyledTreeItem
                        key={`${index}`}
                        nodeId={`${index}`}
                        labelText={`${templateSuite.brand??''} - ${templateSuite.description}`}
                        labelIcon={EmailIcon}
                        onClick={() => onClick({...templateParams, brand: undefined, nodeId: `${index}`})}
                    >
                        {
                            listOfValues.brand.map( (brand: any, index1: number) => {
                                return (
                                    <StyledTreeItem
                                        key={`${index}_${index1 +1}`}
                                        nodeId={`${index}_${index1 +1}`}
                                        labelText={`${brand.value}`}
                                        labelIcon={MedicationIcon}
                                        onClick={() => onClick({...templateParams, brand: brand.value, nodeId: `${index}_${index1 +1}`})}
                                    />
                                )
                            })
                        }
                    </StyledTreeItem>
                }
            </React.Fragment>

        )
    }
    return (

        <TreeView
            aria-label="Template(s)"
            defaultExpanded={['0_0']}
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultExpandIcon={<ArrowRightIcon/>}
            defaultEndIcon={<div style={{width: 24}}/>}
            selected={defaultSelected}
            sx={{flexGrow: 1,  mr: '40px'}}
        >
            {
                templates?.map( (templateSuite: TemplateSuiteType, index: number) => {
            return (
                <>
                    <Events templateSuite={templateSuite} index={index}/>
                    <RepEmails templateSuite={templateSuite} index={index}/>
                    <BookingEmails templateSuite={templateSuite} index={index}/>
                </>

            )
                })
            }

        </TreeView>
    )
}

import React from 'react';
import {makeStyles} from '@mui/styles';
import {Theme, styled, useTheme} from '@mui/material/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';

const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
    drawerOpen?: boolean;
}
const Logo = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '160px',
    backgroundImage: 'url("https://eventmanager.propharma.se/img.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0px center',
    backgroundColor: '#1862a8',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 0px',
    ...theme.mixins.toolbar,
    minHeight: '40px!important',
}));

const Root = styled('div')({
    display: 'flex'
})
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'drawerOpen',}
)<AppBarProps>(({ theme, drawerOpen }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#1862a8',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(drawerOpen && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const useStyles = makeStyles((theme: Theme) => ({}));

export default function Header({appHeader, drawerOpen, setDrawerOpen}: HeaderInput) {
    useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };
    return (
        <Root>
            <CssBaseline />
            <AppBar position="fixed" drawerOpen={drawerOpen}>
                <Toolbar variant={isDesktop ? 'regular' : 'dense'} sx={{paddingRight: 24, maxHeight: isDesktop ? 'unset' : '40px'}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(drawerOpen && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="div" variant="h5" color="inherit" noWrap sx={{flexGrow: 2, textAlign: 'left', fontSize: '20px'}}>
                        {'Event Manager'}
                    </Typography>
                    <Logo/>
                </Toolbar>
            </AppBar>
        </Root>
    );
}
interface HeaderInput {
    appHeader: string;
    actions?: ActionTypes;
    drawerOpen: boolean;
    setDrawerOpen: any;
}


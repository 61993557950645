import {useContext, useEffect, useRef, useState} from "react";
import * as React from "react";
import {
Autocomplete,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import {ComboBox} from "../../../../components/FormElements";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {AppContext} from "../../../../AppContext";

export const FieldsEditDialog = ({templateFields, open, template, handleTemplateFieldChange, setUpdateTemplateFields, setUploadImage, deleteField}: FieldsEditDialogInput) => {
    const {user} = useContext(AppContext);
    const [fieldLOV, setFieldLOV] = useState([{name: '', value: ''}])
    // const updatedFields = useRef([...templateFields])
    const [updatedFields, setUpdatedFields] = useState<any>([])
    const [localTemplateFields, setLocalTemplateFields] = useState<any>([])

    useEffect( () => {
        try {
            if (template && template.fields) {
                setFieldLOV(
                    // @ts-ignore
                    template.fields.filter( (f0: TemplateFieldType) => f0.type !== 'Text' && f0.type !== 'HTML' && f0.type).map( (f1: TemplateFieldType) => {
                        return ({name:`${f1.name}`, value: f1._id});
                    }))

            }
        } catch (e: any) {
            console.error(e.toString())
        }


    }, [template])
    useEffect( () => {
        setLocalTemplateFields([...templateFields]);
    }, [open])
    const handleFieldChange = (field: TemplateFieldType, inputName: string = '', inputValue?: any) => {
        field = {...field, [inputName]: inputValue};
        setLocalTemplateFields(localTemplateFields.map(
            (f: TemplateFieldType) => {
                if (f.name === field.name) {
                    f = field;
                }
                return f;
            }
        ))
        if (!updatedFields.find( (f: TemplateFieldType) => f.name === field.name)) {
            updatedFields.push(field);
        } else {
            setUpdatedFields(updatedFields.map( (f: TemplateFieldType) => {
                if (f.name === field.name) {
                    return field
                }
                return f;
            }))
        }
    }

    const fieldTypes = [
        {value: 'Checkbox', name: 'Checkbox'},
        {value: 'Radiobutton', name: 'Radiobutton'},
        {value: 'Text', name: 'Text/label'},
        {value: 'Image', name: 'Image'},
        {value: 'TextArea', name: 'Textarea'},
        {value: 'TextBox', name: 'Textbox'},
        {value: 'YesNo', name: 'Yes/No'},
    ];
    const fieldTypesAll = [
        {value: 'Checkbox', name: 'Checkbox'},
        {value: 'Radiobutton', name: 'Radiobutton'},
        {value: 'Email', name: 'E-mail', notselectable: true},
        {value: 'HTML', name: 'Text (HTML)'},
        {value: 'Image', name: 'Image'},
        {value: 'SystemField', name: 'SystemField'},
        {value: 'Text', name: 'Text/label'},
        {value: 'TextArea', name: 'Textarea'},
        {value: 'TextBox', name: 'Textbox'},
        {value: 'YesNo', name: 'Yes/No'},
    ];
    const Field = ({field, index, isInput}: {field: TemplateFieldType, index: number, isInput: boolean}) => {
        const [conditionalDisplay, setConditionalDisplay] = React.useState(!!field.parentField);

        return (
            <Grid container sx={{maxWidth: '1000px', border: 'dotted thin', p:2, pb:3}}>
                    <Grid item xs={9} >
                        <TextField
                            id={`${field._id}-value`}
                            fullWidth
                            disabled={field.type==='HTML'}
                            label={field.label ?? field.name??`Field ${index}`}
                            inputProps={{style: {fontSize: 20}}} // font size of input text
                            error={!field.value && !field.name}
                            helperText={!field.value && !field.name ?`A label is mandatory, please give it a value`: ""}
                            variant="standard"
                            defaultValue={field.value??field.name}
                            onBlur={(e: any) => handleFieldChange(field, 'value', e.target.value)}
                        />
                    </Grid>

                <Grid item xs={1}>
                    {
                        !field.defaultField &&
                        <IconButton disabled={!!field.defaultField} aria-label="Delete this question" onClick={() => deleteField(field._id)} >
                            <DeleteIcon sx={{fontSize: 30}}/>
                        </IconButton>
                    }

                </Grid>
                <Grid item xs={2}>
                    {
                        (user?.role === 'Administrator') &&
                        <TextField
                            id={`${field._id}-sort`}
                            label={`Sort`}
                            variant="standard"
                            defaultValue={field.sort}
                            onBlur={(e: any) => handleFieldChange(field, 'sort', e.target.value)}
                        />
                    }
                </Grid>

                <Grid container spacing={1}>
                    {
                        field.type === 'Image' &&
                        <Grid item xs={12}>
                            <FormControlLabel
                                sx={{mb: '10px', alignItems: 'unset!important', marginLeft: '0px!important', fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.6)'}} labelPlacement="top"
                                control={    <Button
                                    onClick={() =>  {
                                        setUploadImage({open: true, fieldName: field.name, imageWidth: field.imageWidth});
                                        setUpdateTemplateFields(false)
                                    }}
                                    size="small" variant="contained" component="span">
                                    Upload new image
                                </Button>} label={field.label ?? field.name} />
                        </Grid>
                    }
                    {
                        field.type !== 'SystemField' &&
                        <React.Fragment>
                            <Grid item xs={4}>
                                <ComboBox
                                    required={true}
                                    multiple={false}
                                    disabled={field.defaultField}
                                    options={field.defaultField ? fieldTypesAll :  fieldTypes}
                                    label="Field type"
                                    placeholder="Select field type"
                                    value={field.defaultField ? fieldTypesAll.find( (f: any) => f.value === field.type) : fieldTypes.find( (f: any) => f.value === field.type)}
                                    onChange={(e: any, val: any) => {
                                        handleFieldChange(field, 'type', val?.value );
                                    }} />
                            </Grid>
                            <Grid item xs={8}>
                                {
                                    field.type === 'YesNo' &&
                                    <Box>
                                        <TextField
                                            id={`${field._id}-labelYes`}
                                            label="Yes"
                                            sx={{width: '40%', paddingRight: '10px'}}
                                            variant="standard"
                                            defaultValue={field.labelYes}
                                            onBlur={(e: any) => handleFieldChange(field, 'labelYes', e.target.value)}
                                        />
                                        <TextField
                                            id={`${field._id}-labelNo`}
                                            label="No"
                                            sx={{width: '40%'}}
                                            variant="standard"
                                            defaultValue={field.labelNo}
                                            onBlur={(e: any) => handleFieldChange(field, 'labelNo', e.target.value)}
                                        />
                                    </Box>
                                }
                                {
                                    field.type === 'Radiobutton' &&
                                    <Autocomplete
                                        multiple
                                        id={`${field._id}-choices`}
                                        onChange={(event, value,reason ) => handleFieldChange(field, 'choices', value)}
                                        value={field.choices}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Choices"
                                                placeholder="Add choices"
                                            />
                                        )}
                                        freeSolo options={[]}/>
                                }
                            </Grid>
                        </React.Fragment>
                    }

                    <Grid item xs={12}>
                        {
                            field.type !== 'Text' && field.type !== 'HTML' &&
                            <FormControlLabel
                                control={
                                    // @ts-ignore
                                    <Checkbox onChange={(e: any) => handleFieldChange(field, 'isRequired', e.target.checked)}
                                              id={`${field._id}-isRequired`}
                                              value={field.isRequired}
                                              checked={field.isRequired}/>}
                                label="Required"/>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            field.defaultField &&
                            <FormControlLabel
                                // @ts-ignore
                                control={<Checkbox onChange={(e: any) => handleFieldChange(field, 'hidden', e.target.checked)}
                                                   id={`${field._id}-hidden`}
                                                   value={field.hidden}
                                                   checked={field.hidden}/>}
                                label="Hide me"/>
                        }
                    </Grid>
                    {
                        index > 0  &&
                        <React.Fragment>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => setConditionalDisplay(e.target.checked)}
                                                       id={`${field._id}-conditionalDisplay`}
                                                       checked={!!field.parentField}/>}
                                    label="Conditional display? (Advanced stuff)"/>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    conditionalDisplay &&
                                    <ComboBox
                                        required={false}
                                        multiple={false}
                                        options={fieldLOV.filter( (f: any) => f.value !== field._id)}
                                        label="Parent field to use as filter"
                                        placeholder="Select parent field"
                                        value={fieldLOV.find( (f: any) => f.value === field.parentField)}
                                        onChange={(event: any, val: any) => {
                                            handleFieldChange(field, 'parentField', val?.value );
                                        }} />
                                }
                            </Grid>

                        </React.Fragment>

                    }

                </Grid>
            </Grid>
        )
    }
    const Fields = () => {
        return (
            <React.Fragment>
                    <Typography variant="h4">Edit fields (Advanced)</Typography>
                        {
                            user?.role !== 'Administrator' &&
                            // @ts-ignore,
                            localTemplateFields.sort((prev: TemplateFieldType, curr: TemplateFieldType) => prev.sort ?? 0 - curr.sort)
                                .filter((field: TemplateFieldType) => ((field.name.indexOf('__') === -1) || field.type === 'Image'))
                                .map((field: TemplateFieldType, index: number) => {
                                    return (
                                        <Grid key={field._id} container spacing={2} sx={{mt: 2, pb: 2}}>
                                            <Field key={index} index={index} field={field} isInput={false}/>
                                        </Grid>
                                    )
                                })
                        }
                        {
                            user?.role === 'Administrator' &&
                            // @ts-ignore,
                            localTemplateFields.sort((prev: TemplateFieldType, curr: TemplateFieldType) => prev.sort ?? 0 - curr.sort)
                                .map((field: TemplateFieldType, index: number) => {
                                    return (
                                        <Grid key={field.name} container spacing={2} sx={{mt: 2, pb: 2}}>
                                            <Field key={index} index={index} field={field} isInput={false}/>
                                        </Grid>
                                    )
                                })
                        }

            </React.Fragment>
        )
    }
    return (
        <Dialog open={open} maxWidth="xl">
            <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
            <DialogContent >
                    <Fields/>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=> setUpdateTemplateFields(false)}>Close</Button>
                <Button onClick={() => handleTemplateFieldChange(updatedFields)}>Save & Close</Button>
            </DialogActions>
        </Dialog>
    )
}


export const FieldEditDialog = ({fieldName, templateField, open, template, handleTemplateFieldChange, setOpen, setUploadImage}: FieldEditDialogInput) => {
    const [fieldLOV, setFieldLOV] = useState([{name: '', value: ''}])
    const [updatedField, setUpdatedField] = useState<any>([])

    useEffect( () => {
        try {
            if (template && template.fields) {
                setFieldLOV(
                    // @ts-ignore
                    template.fields.filter( (f0: TemplateFieldType) => f0.type !== 'Text' && f0.type !== 'HTML' && f0.type).map( (f1: TemplateFieldType) => {
                        return ({name:`${f1.value}`, value: f1._id});
                    }))

            }
        } catch (e: any) {
            console.error(e.toString())
        }


    }, [template])
    useEffect( () => {
        setUpdatedField(templateField);
    }, [open])
    const handleFieldChange = (event: any, inputId?: string, inputName: string = '', inputValue?: any) => {
        let _id = inputId, name = inputName;
        if ( typeof event !== 'undefined') {
            [_id, name] = event.target.id.split("-");
        }
        let val = event?.target?.value;
        if ( name === 'isRequired' || name === 'hidden') {
            val = event.target.checked;
        }
        if ( inputValue ) {
            val = inputValue;
        }
        setUpdatedField( {
            ...updatedField,
            [name]: val
        })

    }
    const fieldTypes = [
        {value: 'Checkbox', name: 'Checkbox'},
        {value: 'Radiobutton', name: 'Radiobutton'},
        {value: 'Text', name: 'Text/label'},
        {value: 'TextArea', name: 'Textarea'},
        {value: 'TextBox', name: 'Textbox'},
        {value: 'YesNo', name: 'Yes/No'},
    ];
    const fieldTypesAll = [
        {value: 'Checkbox', name: 'Checkbox'},
        {value: 'Radiobutton', name: 'Radiobutton'},
        {value: 'Email', name: 'E-mail', notselectable: true},
        {value: 'HTML', name: 'Text (HTML)'},
        {value: 'Image', name: 'Image'},
        {value: 'SystemField', name: 'SystemField'},
        {value: 'Text', name: 'Text/label'},
        {value: 'TextArea', name: 'Textarea'},
        {value: 'TextBox', name: 'Textbox'},
        {value: 'YesNo', name: 'Yes/No'},
    ];
    const Field = ({field}: {field: TemplateFieldType | undefined}) => {
        const [conditionalDisplay, setConditionalDisplay] = React.useState(!!field?.parentField);
        const [isInput, setIsInput] = useState(field?.type !== 'Text');
        if (field) {
            const stripHTML = (html: string) => {
                const div = document.createElement("div");
                div.innerHTML = html;
                return div.textContent || div.innerText || "";
            }

            return (
                <Grid container sx={{width: '1000px', border: 'dotted thin', p:2, pb:3}}>
                    <Grid item xs={9} >
                        <TextField
                            id={`${field._id}-value`}
                            fullWidth
                            disabled={field.type==='HTML'}
                            label={`Value`}
                            inputProps={{style: {fontSize: 20}}} // font size of input text
                            error={!field.value && !field.name}
                            helperText={!field.value && !field.name ?`A label is mandatory, please give it a value`: ""}
                            variant="standard"
                            defaultValue={field.value??field.name}
                            onBlur={handleFieldChange}
                        />
                    </Grid>

                    <Grid container spacing={1}>
                        {
                            field.type === 'Image' &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    sx={{mb: '10px', alignItems: 'unset!important', marginLeft: '0px!important', fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.6)'}} labelPlacement="top"
                                    control={    <Button
                                        onClick={() => setUploadImage({open: true, fieldName: field.name, imageWidth: field.imageWidth})}
                                        size="small" variant="contained" component="span">
                                        Upload new image
                                    </Button>} label={field.label ?? field.name} />
                            </Grid>
                        }
                        {
                            field.type !== 'SystemField' && isInput &&
                            <React.Fragment>
                                <Grid item xs={4}>
                                    <ComboBox
                                        required={true}
                                        multiple={false}
                                        disabled={field.defaultField}
                                        options={field.defaultField ? fieldTypesAll : fieldTypes}
                                        label="Field type"
                                        placeholder="Select field type"
                                        value={field.defaultField ? fieldTypesAll.find( (f: any) => f.value === field.type) : fieldTypes.find( (f: any) => f.value === field.type)}
                                        onChange={(e: any, val: any) => {
                                            handleFieldChange(undefined, field._id, 'type', val?.value );
                                        }} />
                                </Grid>
                                <Grid item xs={8}>
                                    {
                                        field.type === 'YesNo' &&
                                        <Box>
                                            <TextField
                                                id={`${field._id}-labelYes`}
                                                label="Yes"
                                                sx={{width: '40%', paddingRight: '10px'}}
                                                variant="standard"
                                                defaultValue={field.labelYes}
                                                onBlur={handleFieldChange}
                                            />
                                            <TextField
                                                id={`${field._id}-labelNo`}
                                                label="No"
                                                sx={{width: '40%'}}
                                                variant="standard"
                                                defaultValue={field.labelNo}
                                                onBlur={handleFieldChange}
                                            />
                                        </Box>
                                    }
                                    {
                                        field.type === 'Radiobutton' &&
                                        <Autocomplete
                                            multiple
                                            id={`${field._id}-choices`}
                                            onChange={(event, value,reason ) => handleFieldChange(undefined, field._id,'choices', value)}
                                            value={field.choices}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Choices"
                                                    placeholder="Add choices"
                                                />
                                            )}
                                            freeSolo options={[]}/>
                                    }
                                </Grid>
                            </React.Fragment>
                        }

                        <Grid item xs={12}>
                            {
                                field.type !== 'Text' && isInput &&
                                <FormControlLabel
                                    control={
                                        // @ts-ignore
                                        <Checkbox onChange={handleFieldChange}
                                                  id={`${field._id}-isRequired`}
                                                  value={field.isRequired}
                                                  checked={field.isRequired}/>}
                                    label="Required"/>
                            }
                        </Grid>
                        <React.Fragment>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => setConditionalDisplay(e.target.checked)}
                                                       id={`${field._id}-conditionalDisplay`}
                                                       checked={!!field.parentField}/>}
                                    label="Conditional display? (Advanced stuff)"/>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    conditionalDisplay &&
                                    <ComboBox
                                        required={false}
                                        multiple={false}
                                        options={fieldLOV.filter( (f: any) => f.value !== field._id)}
                                        label="Parent field to use as filter"
                                        placeholder="Select parent field"
                                        value={fieldLOV.find( (f: any) => f.value === field.parentField)}
                                        onChange={(event: any, val: any) => {
                                            handleFieldChange(undefined, field._id, 'parentField', val?.value );
                                        }} />
                                }
                            </Grid>

                        </React.Fragment>

                    </Grid>
                </Grid>
            )
        }
        return (
            <></>
        )

    }
    return (
        <Dialog open={open} maxWidth="xl">
            <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
            <DialogContent >
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    sx={{ padding: 5}}
                >
                    {
                        <Field field={updatedField} />
                    }
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=> setOpen()}>Close</Button>
                <Button onClick={() => handleTemplateFieldChange(updatedField)}>Save & Close</Button>
            </DialogActions>
        </Dialog>
    )
}
interface FieldEditDialogInput {
    fieldName?: string;
    open: boolean;
    template: TemplateType;
    handleTemplateFieldChange: any;
    setOpen: any;
    setUploadImage: any;
    templateField?: TemplateFieldType;
}
interface FieldsEditDialogInput {
    open: boolean;
    template: TemplateType;
    deleteField: any;
    handleTemplateFieldChange: any;
    setUpdateTemplateFields: any;
    setUploadImage: any;
    templateFields: TemplateFieldType[];
}

import React, {useContext, useEffect, useRef} from 'react';
import {FormControlLabel, Grid, Paper} from '@mui/material';
import MaterialTable from "material-table";
import PropTypes, {InferProps} from "prop-types";
import {styled} from "@mui/material/styles";
import {ComboBox} from "../FormElements";
import {Api} from "../../Api";
import {sendErrorMessage} from "../../Error";
import { useSnackbar } from 'notistack';

import Switch from '@mui/material/Switch';
import {AppContext} from "../../AppContext";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
const theme = createTheme({
    typography: {
        fontSize: 8, // Anpassa textstorleken här
    },
    overrides: {
        MuiInputBase: {
            input: {
                fontSize: '10px', // Anpassa storleken på inputs här
            },
        },
    },
});
const {REACT_APP_CUSTOMER_API} =  process.env;
export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
}))

const processField = (globalArray: any[], localArray: any[]) => {
    localArray?.forEach((val) => {
        if (!globalArray.find(entry => entry.value === val)) {
            globalArray.push({ value: val });
        }
    });
};

const getLOVS = async (records: any) => {
    const potential: any[] = [];
    const type: { value: any; }[] = [];
    const specialty: any[] = [];
    const tag: any[] = [];
    const country: { value: any; }[] = [];
    const brick: { value: any; }[] = [];
    const responsible: { value: any; name: any }[] = [];
    // tslint:disable-next-line:no-increment-decrement
    for (let i = 0; i < records.length; i++) {
        // @ts-ignore
        const customerPotential = records[i].potential;
        // @ts-ignore
        const customerType = records[i].type;
        // @ts-ignore
        const customerCountry = records[i].country;
        // @ts-ignore
        const customerSpecialty = records[i].specialty;
        // @ts-ignore
        const customerTag = records[i].tag;
        // @ts-ignore
        const customerBrick = records[i].brick;
        // @ts-ignore
        const customerResponsible = records[i].responsible??'';
        processField(potential, customerPotential);
        processField(tag, customerTag);
        processField(specialty, customerSpecialty);

        if (!type.find(entry => entry.value === customerType)) {
            type.push({ value: customerType });
        }
        if (!country.find(entry => entry.value === customerCountry)) {
            country.push({ value: customerCountry });
        }
        if (!brick.find(entry => entry.value === customerBrick)) {
            brick.push({ value: customerBrick });
        }
        if (!responsible.find(entry => entry.value === customerResponsible._id)) {
            responsible.push({ value: customerResponsible._id, name: customerResponsible.name});
        }
    }
    return {
        potential: potential.sort((a, b) => { return (a.value)?.localeCompare(b.value); }),
        type: type.sort((a, b) => { return (a.value)?.localeCompare(b.value); }),
        specialty: specialty.sort((a, b) => { return (a.value)?.localeCompare(b.value); }),
        tag: tag.sort((a, b) => { return (a.value)?.localeCompare(b.value); }),
        country: country.sort((a, b) => { return (a.value)?.localeCompare(b.value); }),
        brick: brick.sort((a, b) => { return (a.value)?.localeCompare(b.value); }),
        responsible: responsible.sort((a, b) => { return (a.name)?.localeCompare(b.name); }),
    };
};
// @ts-ignore
export const Customers = ({title, selectedCustomers, setSelectedCustomers, defaultFilter}: InferProps<typeof Customers.propTypes>) => {
    const tableRef = useRef();
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const [customers, setCustomers] = React.useState([])
    const [listOfValues, setListOfValues] = React.useState({
        potential: [{value: ''}],
        specialty: [{value: ''}],
        type: [{value: ''}],
        country: [{value: ''}],
        brick: [{value: ''}],
        responsible: [{value: '', name: ''}],
    });
    const [selectedSpecialty, setSelectedSpecialty] = React.useState([]);
    const [selectedPotential, setSelectedPotential] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState([]);
    const [selectedBrick, setSelectedBrick] = React.useState([]);
    const [selectedResponsible, setSelectedResponsible] = React.useState([]);
    const [onlyApprovedCustomers, setOnlyApprovedCustomers] = React.useState(true);
    const [initialLoad, setInitialLoad] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const getData = async () => {
        try {
            setWaitingForBackendMessage('Getting customers');
            const filter = [
                {fieldName: 'specialty', value: selectedSpecialty.map( ({value}) => value) },
                {fieldName: 'type', value: selectedType.map( ({value}) => value) },
                {fieldName: 'country', value: selectedCountry.map( ({value}) => value) },
                {fieldName: 'potential', value: selectedPotential.map( ({value}) => value) },
                {fieldName: 'brick', value: selectedBrick.map( ({value}) => value) },
                {type: 'ObjectId', fieldName: 'responsible', value: selectedResponsible.map( ({value}) => value) },
            ];
            if (onlyApprovedCustomers) {
                // @ts-ignore
                filter.push({fieldName: 'consent', value: ['Approved'] })
            }
            const res = await Api({
                name: 'getCustomers',
                url: `${REACT_APP_CUSTOMER_API}customers/`,
                config: {
                    params: {
                        filter: JSON.stringify(filter)
                    },

                }
            })
            if ( res ) {
                // @ts-ignore
                const fetchedCustomers = res.data.records.filter( customer => {
                    return !defaultFilter.includes(customer._id) && customer.status === 'Active' && customer.email
                });
                const calcListOfValues = await getLOVS(fetchedCustomers);
                // Remove filter fields from listOfValues, we don't want new LOV for these
                filter.forEach( entry => {
                    if (entry.value.length > 0 ) {
                        // @ts-ignore
                        delete calcListOfValues[entry.key];
                    }
                })
                setCustomers(fetchedCustomers.map( (customer: any) => {
                    let country =  customer.country;
                    switch (country) {
                        case 'SE':
                            country = 'Sweden';
                            break;
                        case 'DK':
                            country = 'Denmark';
                            break;
                        case 'NO':
                            country = 'Norway';
                            break;
                        case 'FI':
                            country = 'Finland';
                            break;
                    }
                    let firstname = customer.firstname === 'null' ? '': customer.firstname??'';
                    firstname = customer.middlename !== 'null' && customer.middlename ? `${firstname} ${customer.middlename}` : firstname;
                    const middlename = customer.middlename === 'null' ? '': customer.middlename??'';
                    const retVal = {
                        ...customer,
                        siteName:  `${customer.mainSite??''} ${customer.site ? ' - ' : ''}${customer.site ?? ''}`.trim(),
                        firstname,
                        middlename,
                        country,
                        fullname: `${firstname} ${customer.name} ${customer.gender} <${customer.email}>`.trim(),
                        specialty: customer.specialty?.join(),
                        potential: customer.potential?.join(),
                        education: customer.education?.join(),
                        responsible: customer?.responsible?.name??customer?.responsible??''
                    }

                    return retVal
                }));
                //If there are selected values, then those LOV must remain untouched.
                setListOfValues({...listOfValues, ...calcListOfValues});
            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/customer/getData', "Cannot Get Customers", enqueueSnackbar);
        } finally {
            setWaitingForBackendMessage('');
        }

    }

    useEffect( () => {
        ( async () => {
            await getData();
            setInitialLoad(true);
        })();
    }, [])
    useEffect( () => {
        ( async () => {
            if ( initialLoad ) {
                await getData();
            }
        })();
    }, [selectedPotential, selectedType, selectedCountry, selectedSpecialty, onlyApprovedCustomers, selectedBrick, selectedResponsible])
    return (
            <Grid container width={'90vw'} maxWidth={'1200px'}>
                {/* Recent Orders */}
                <Grid item xs={12}>
                    <StyledPaper>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <ComboBox
                                    options={listOfValues.country}
                                    label="Filter on country"
                                    placeholder="Select country"
                                    value={selectedCountry}
                                    onChange={(e: any, val: any) => {
                                        setSelectedCountry(val);
                                    }} />
                            </Grid>
                            <Grid item xs={2}>
                                <ComboBox
                                    options={listOfValues.responsible}
                                    label="Filter on district"
                                    placeholder="Select district"
                                    value={selectedResponsible}
                                    onChange={(e: any, val: any) => {
                                        setSelectedResponsible(val);
                                    }} />
                            </Grid>
                            <Grid item xs={2}>
                                <ComboBox
                                    options={listOfValues.brick}
                                    label="Filter on brick"
                                    placeholder="Select brick"
                                    value={selectedBrick}
                                    onChange={(e: any, val: any) => {
                                        setSelectedBrick(val);
                                    }} />
                            </Grid>
                            <Grid item xs={2}>
                                <ComboBox
                                    options={listOfValues.type}
                                    label="Filter on type"
                                    placeholder="Select type"
                                    value={selectedType}
                                    onChange={(e: any, val: any) => {
                                        setSelectedType(val);
                                    }} />
                            </Grid>
                            <Grid item xs={2}>
                                <ComboBox
                                    options={listOfValues.specialty}
                                    label="Filter on specialty"
                                    placeholder="Select specialty"
                                    value={selectedSpecialty}
                                    onChange={(e: any, val: any) => {
                                        setSelectedSpecialty(val);
                                    }} />
                            </Grid>
                            {

                            }
                            <Grid item xs={1}>
                                <ComboBox
                                    options={listOfValues.potential}
                                    label="Filter on potential"
                                    placeholder="Select potential"
                                    value={selectedPotential}
                                    onChange={(e: any, val: any) => {
                                        setSelectedPotential(val);
                                    }} />
                            </Grid>
                            {


                            }

                            <Grid item xs={1} >
                                <FormControlLabel control={<Switch  size={'small'} defaultChecked onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setOnlyApprovedCustomers(event.target.checked);
                                }} />} label="Consent" />
                            </Grid>
                        </Grid>
                        <MuiThemeProvider theme={theme}>
                        <MaterialTable
                            title={title}
                            tableRef={tableRef}
                            columns={[
                                {
                                    cellStyle: {paddingTop: '2px', paddingBottom: '2px'},
                                    title: 'Name', field: 'fullname', render: (rowData: any) => {
                                            return(
                                                <div>
                                                    <b>
                                                    {rowData.firstname && <span>{rowData.firstname}&nbsp;</span>}
                                                    {rowData.middlename && <span><i>{`${rowData.middlename}`}</i>&nbsp;</span>}
                                                    <span>{`${rowData.name}`}</span>
                                                    </b>
                                                    {rowData.gender && <span><i> ({`${rowData.gender}`}</i>)</span>}
                                                    {rowData.title && <span><br/> {rowData.title}</span>}
                                                    <span><br/>{rowData.email}</span>
                                                    {rowData.phone && <span><br/> {rowData.phone}</span>}
                                                    {rowData.specialty && <div>Specialty: <i>{rowData.specialty}</i></div>}
                                                    {rowData.education && <div>Education: <i>{rowData.education}</i></div>}
                                                    {rowData.responsible && <div>Responsible: <i>{rowData.responsible}</i></div>}
                                                </div>
                                            )

                                    }},
                                {
                                    cellStyle: {paddingTop: '2px', paddingBottom: '2px'},

                                    title: 'Site', field: 'siteName', render: (rowData: any) => {
                                        if (rowData.firstname !== 'Archived') {
                                            if ( rowData.mainSite || rowData.site ) {
                                                return(<div>
                                                        <span>
                                                            <b>{`${rowData.mainSite??rowData.site}`}</b>
                                                            { (rowData.site && rowData.mainSite) && <span><br/> {rowData.site}</span> }
                                                            { rowData.city && <span><br/>{rowData.city} </span> }
                                                            { rowData.brick && <span><br/>Brick: <i>{rowData.brick}</i> </span> }
                                                            { rowData.country && <span><br/>{rowData.country}</span> }
                                                        </span>
                                                </div>

                                                )
                                            } else {
                                                return ('');
                                            }
                                        } else {
                                            return ('')
                                        }


                                    }  },
                                { title: 'Gender', field: 'gender', hidden: true },
                                { title: 'Targeting', field: 'potential', render: (rowData: any) => {
                                        return(
                                            <div>
                                                {rowData.potential && <span><i>{`${rowData.potential}`}</i></span>}

                                            </div>
                                        )
                                }
                                },
                            ]}
                            data={customers}
                            options={{
                                search: true,
                                headerStyle: {
                                    textAlign: 'left',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 'thin',
                                    fontSize: '10px',
                                    paddingBottom: '5px',
                                    fontWeight: 700,
                                    textTransform: 'uppercase',
                                },
                                rowStyle: {
                                    fontSize: '9px',
                                    paddingBottom: '2px!important',
                                    paddingTop: '2px!important',
                                },
                                pageSizeOptions: [30, 60, 120, 240],
                                pageSize: 30,
                                maxBodyHeight: '50vh',
                                paging: true,
                                filtering: false,
                                selection: true
                            }}
                            onRowClick={(event, rowData, togglePanel) => {
                                //setOpen(true);
                            }}
                            onSelectionChange={(rows) => setSelectedCustomers(JSON.parse(JSON.stringify(rows)))}
                        />
                        </MuiThemeProvider>
                    </StyledPaper>
                </Grid>
            </Grid>
    )
}
Customers.propTypes = {
    title: PropTypes.string,
    selectedCustomers: PropTypes.array.isRequired,
    setSelectedCustomers: PropTypes.func.isRequired,
    defaultFilter: PropTypes.array
}

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {App} from "./App";
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@mui/material";
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import {msalInstance} from './auth/authConfig';
import {smallTheme, theme} from "./components/SmallTheme";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {InteractionType} from "@azure/msal-browser";
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY!);
( async() => {
    await msalInstance.initialize();
    ReactDOM.render(
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                <BrowserRouter basename='/'>
                    <ThemeProvider theme={window.innerWidth < 1280 ? smallTheme : theme}>
                        <App />
                    </ThemeProvider>
                </BrowserRouter>
            </MsalAuthenticationTemplate>
        </MsalProvider>,
        document.getElementById('root')
    );
})()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
navigator.serviceWorker.getRegistrations().then(registrations => {
    for(let registration of registrations) {
        registration.unregister();
    }
}).then(() => {
    serviceWorkerRegistration.register();
});


import React, {useEffect, useRef} from 'react';
import MaterialTable from "material-table";
import Grid from "@mui/material/Grid";
import {PersonAdd} from '@material-ui/icons';
import {Root, StyledPaper, AppBarSpacer} from '../components/StyledComponents'
import {Api} from "../Api";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {UpsertSpeaker} from "../components/speaker";
import { useSnackbar } from 'notistack';
import {sendErrorMessage} from "../Error";
const {REACT_APP_SPEAKERS_API} =  process.env;
// @ts-ignore
export const Speakers = () => {
    const tableRef = useRef();
    const [speakers, setSpeakers] = React.useState<SpeakerType[] | []>([])
    const [open, setOpen] = React.useState(false);
    const [selectedSpeaker, setSelectedSpeaker] = React.useState<SpeakerType | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const getData = async () => {
        try {
            const res = await Api({
                name: 'Speakers',
                url: `${REACT_APP_SPEAKERS_API}speakers`
            })
            if (res) {
                setSpeakers(res.data);
            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/speakers/getData', "Cannot Get speakers", enqueueSnackbar);
        }

    }
    useEffect( () => {
        ( async () => {
         await getData();
        })();
    }, [])
    useEffect( () => {
        if (selectedSpeaker)
            setSpeakers( speakers.map( (speaker: SpeakerType) => {
                if (speaker._id === selectedSpeaker._id)
                    return selectedSpeaker;
                return speaker
            }))
    }, [selectedSpeaker])
    return (
        <Root>
            <AppBarSpacer/>
            <Grid container spacing={3}>
                {/* Recent Orders */}
                <Grid item xs={12}>
                    <StyledPaper>
                        <MaterialTable
                            title="Speakers"
                            tableRef={tableRef}
                            columns={[
                                { title: 'Name', field: 'fullname'},
                                { title: 'Title', field: 'title'},
                                { title: 'Company', field: 'company'},
                                { title: 'Phone', field: 'phone'},
                                { title: 'Email', field: 'email'  },
                            ]}
                            data={speakers}
                            options={{
                                search: true,
                                headerStyle: {
                                    backgroundColor: '#e6e6e6',
                                    fontSize: 'medium',
                                    textAlign: 'left',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 'thin',
                                },
                                rowStyle: {
                                    paddingBottom: '5px!important',
                                    paddingTop: '5px!important'
                                },
                                minBodyHeight: '85vh',
                                pageSizeOptions: [45, 90, 180, 600],
                                pageSize: 45,
                                paging: true,
                                filtering: false,
                            }}
                            actions={[
                                {
                                    icon: PersonAdd,
                                    tooltip: 'Add speaker',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        setOpen(true);
                                        setSelectedSpeaker({
                                            company: '',
                                            _id: 'CREATE',
                                            fullname: '',
                                            email: '',
                                            phone: '',
                                            title: ''
                                        })
                                    }
                                }
                            ]}
                            onRowClick={(event, rowData, togglePanel) => {
                                setOpen(true);
                                if (rowData)
                                    setSelectedSpeaker(rowData);
                            }}
                        />
                    </StyledPaper>
                </Grid>
            </Grid>
            <Dialog open={open} maxWidth={'sm'}>
                <DialogTitle>{selectedSpeaker && selectedSpeaker._id !== 'CREATE' ? 'Update' : 'Create'} speaker</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedSpeaker && selectedSpeaker._id !== 'CREATE' ? 'Update' : 'Create'} speaker
                    </DialogContentText>
                    <UpsertSpeaker speaker={selectedSpeaker} setOpen={setOpen} setSpeaker={setSelectedSpeaker} />
                </DialogContent>
            </Dialog>
        </Root>
    )
}

import React, {useEffect} from 'react';
import './App.css';
import AppBar from './views/common/Appbar'
import {LeftMenu} from "./views/common/Drawer";
import {Activities} from "./views/activities";
import {Activity} from "./modules/Activity/";
import {Templates} from "./views/templates";
import {Route, Routes, useLocation, Link as RouterLink,} from "react-router-dom";
import Chart from "./views/common/Chart";
import {Customers} from "./views/customers";
import {Users} from "./views/users";
import {Speakers} from "./views/speakers";
import { SnackbarProvider } from 'notistack';
import {Breadcrumbs, Link} from "@mui/material";
import {Typography} from "@mui/material/";
import {CustomerActivities} from "./views/CustomerActivities";
import {SendEmail} from "./views/SendEmail";
import {AppContext} from "./AppContext";
import {WaitingForBackendResponse} from "./components/WaitingForBackendResponse";
import {Fragment} from "./modules/Fragment";
import { Home } from './views/Home';
import {MessageDisplay} from "./components/MessageDisplay";
import {getUser} from "./components/user/getUser";
import {getIdToken} from "./auth/azureAuthProvider";
import {cacheLOV, getLOV} from "./modules/ListOfValue/getLOV";
import {ListOfValue} from "./modules/ListOfValue/ListOfValue";
import {Translation} from "./modules/Translation/Translation";
const LinkRouter = (props: any) => <Link {...props} component={RouterLink} />;
export const App = () => {
    const [appHeader, setAppHeader] = React.useState('Event Manager');
    const [actions, setActions] = React.useState<ActionTypes | undefined>();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [hideDrawer, setHideDrawer] = React.useState(true);
    const [waitingForBackendMessage, setWaitingForBackendMessage] = React.useState('');
    const [isAuthorized, setIsAuthorized] = React.useState(false);
    const [authStatus, setAuthStatus] = React.useState(102);
    const [authError, setAuthError] = React.useState('');
    const [user, setUser] = React.useState<UserType>();
    const [lovLoaded, setLovLoaded] = React.useState(false);
    const userHasRole = (roles: string[]) => {
        try {
            return roles.some((value: any) => user?.roles!.includes(value));
        } catch (e: any) {

        }
        return false
    }
    const userHasCountries = (countries: string[]) => {
        try {
            return countries.some((value: any) => user?.countries!.includes(value));
        } catch (e: any) {

        }
        return false
    }

    const search = useLocation().search;
    const system = new URLSearchParams(search).get('system');
    const externalSystemActivityList = new URLSearchParams(search).get('systemActivityList');
    const [isEmbedded] = React.useState(!!system)
    useEffect(() => {
        (async () => {
            if (await getIdToken()) {
                setIsAuthorized(true)
                setHideDrawer(false)
                setAppHeader('Event Manager');
                setUser(await getUser())
            }
            setWaitingForBackendMessage('Loading List of Values');
            await cacheLOV()
            setWaitingForBackendMessage('')
            setLovLoaded(true)
        })()

        window.addEventListener('auth.error', (e: any) => {
            setAuthStatus(e.detail.status);
            setAuthError(e.detail.error);
            setIsAuthorized(false);
        })

    }, [])
    return (
                <div className="App" style={{display: isEmbedded ? 'unset' : 'flex'}}>
                    <SnackbarProvider
                        maxSnack={3}
                        autoHideDuration={6000}
                        style={{width: '600px'}}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}

                    >

                            <AppContext.Provider value={{user, setAppHeader, isEmbedded, setHideDrawer, setWaitingForBackendMessage, setActions, userHasRole, actions, userHasCountries, getLOV}}>
                            {
                                !isEmbedded && <AppBar actions={actions} appHeader={appHeader} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
                            }
                            {
                                isEmbedded && !externalSystemActivityList &&
                                        <div>
                                            <Breadcrumbs component="span" aria-label="breadcrumb" sx={{width: '100%'}}>
                                                <LinkRouter underline="hover" color="inherit" to="/">Event Manager
                                                </LinkRouter>
                                                {
                                                    appHeader !== 'Activities' && appHeader !== 'Event Manager' &&
                                                         <Typography component="span" color="text.primary">{appHeader}</Typography>

                                                }
                                            </Breadcrumbs>

                                        </div>
                            }
                            {
                                !isEmbedded && <LeftMenu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} hideDrawer={hideDrawer} />
                            }
                            {
                                user && lovLoaded &&
                                <Routes>
                                    {
                                        !isAuthorized &&
                                        <>
                                            <Route path="/*" element={<MessageDisplay type={authStatus} reason={authError} />}/>
                                            <Route path="/activity/" element={<Activity />}/>
                                        </>

                                    }
                                    {
                                        isAuthorized && user?.role &&
                                        <>
                                            {
                                                ['Editor', 'Administrator', 'Standard'].includes(user?.role??'') &&
                                                <>
                                                    <Route path="/CustomerActivities/:customerId" element={<CustomerActivities/>}/>
                                                    <Route path="/SendEmail/:customerIds" element={<SendEmail />}/>
                                                    <Route path="/SendEmail/" element={<SendEmail />}/>
                                                    <Route path="/repEmails" element={<Activities repEmail={true}  />} />
                                                    <Route path="/bookingEmails" element={<Activities bookingEmail={true} />} />
                                                    <Route path="/activities" element={<Activities repEmail={false} />} />
                                                    <Route path="/activity/" element={<Activity />}/>
                                                </>
                                            }
                                            {
                                                ['Editor', 'Administrator', 'Regulatory'].includes(user?.role??'') &&
                                                <>
                                                    <Route path="/fragments" element={<Fragment />}/>
                                                </>
                                            }
                                            {
                                                ['Administrator'].includes(user?.role??'') &&
                                                <>
                                                    <Route path="/chart" element={<Chart/>}/>
                                                    <Route path="/speakers" element={<Speakers/>}/>
                                                    <Route path="/customers" element={<Customers />}/>
                                                    <Route path="/users" element={<Users/>}/>
                                                    <Route path="/templates" element={<Templates/>}/>
                                                    <Route path="/lov" element={<ListOfValue />} />
                                                    <Route path="/translation" element={<Translation />} />
                                                </>
                                            }
                                            <Route path="/" element={<Home/>} />
                                        </>
                                    }
                                </Routes>
                            }

                        </AppContext.Provider>
                    </SnackbarProvider>
                    <WaitingForBackendResponse messageIfNotEmpty={waitingForBackendMessage}/>
                </div>
  )}

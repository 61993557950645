
export const applyReadOnlyFields = (html: string, templateFields: any) => {
    templateFields.filter( (field: TemplateFieldType) => field.name.indexOf('__') === -1).map( (field: TemplateFieldType) => {
        html = html.replaceAll(`[[${field.name}]]`, `${field.value}`);
    });
    templateFields.filter( (field: TemplateFieldType) => field.type === 'Image').map( (field: TemplateFieldType) => {
        let fieldValue = field.value;
        html = html.replaceAll(`[[${field.name}]]`, `<img src="${fieldValue}"/>`);
    });
    return html;
}
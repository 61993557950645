import React, {useContext, useEffect, useRef} from 'react';
import MaterialTable from "material-table";
import Grid from "@mui/material/Grid";
import {PersonAdd} from '@material-ui/icons';
import {Root, StyledPaper, AppBarSpacer} from '../components/StyledComponents'
import {Api} from "../Api";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import { useSnackbar } from 'notistack';
import {sendErrorMessage} from "../Error";
import {UpsertUser} from "../components/user";
import {AppContext} from "../AppContext";
const {REACT_APP_USER_API, REACT_APP_UPLOAD_BUCKET} =  process.env;
// @ts-ignore
export const Users = () => {
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const tableRef = useRef();
    const [users, setUsers] = React.useState<UserType[] | []>([])
    const [open, setOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<UserType | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const getData = async () => {
        try {
            const res = await Api({
                name: 'Users',
                url: `${REACT_APP_USER_API}usersDetails`
            })
            if (res) {
                setUsers(res.data);
            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/users/getData', "Cannot Get users", enqueueSnackbar);
        }

    }
    useEffect( () => {
        ( async () => {
            setWaitingForBackendMessage('Getting users...')
            await getData();
            setWaitingForBackendMessage('')
        })();
    }, [])
    useEffect( () => {
        if (selectedUser)
            setUsers( users.map( (user: UserType) => {
                if (user._id === selectedUser._id)
                    return selectedUser;
                return user
            }))
    }, [selectedUser])
    return (
        <Root>
            <AppBarSpacer/>
            <Grid container spacing={3}>
                {/* Recent Orders */}
                <Grid item xs={12}>
                    <StyledPaper>
                        <MaterialTable
                            title="Users"
                            tableRef={tableRef}
                            columns={[
                                { title: 'Avatar', field: '_id', render: rowData => <img src={`${REACT_APP_UPLOAD_BUCKET}${rowData.image ? `${rowData.image}` : `user/${rowData._id}.png`}`}  style={{width: 120, borderRadius: '50%'}}/> },
                                { title: 'Name', field: 'name'},
                                { title: 'Title', field: 'title'},
                                { title: 'Role', field: 'role'},
                                { title: 'Roles', field: 'roles'},
                                { title: 'Phone', field: 'phone'},
                                { title: 'Email', field: 'email'  },
                            ]}
                            data={users}
                            options={{
                                search: true,
                                headerStyle: {
                                    backgroundColor: '#e6e6e6',
                                    fontSize: 'medium',
                                    textAlign: 'left',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 'thin',
                                },
                                rowStyle: {
                                    paddingBottom: '5px!important',
                                    paddingTop: '5px!important'
                                },
                                minBodyHeight: '85vh',
                                pageSizeOptions: [15, 30, 45],
                                pageSize: 15,
                                paging: true,
                                filtering: false,
                            }}
                            actions={[
                                {
                                    icon: PersonAdd,
                                    tooltip: 'Add user',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        setOpen(true);
                                        setSelectedUser({
                                            _id: 'CREATE',
                                            name: '',
                                            email: '',
                                            phone: '',
                                            title: '',
                                            active: true,
                                        })
                                    }
                                }
                            ]}
                            onRowClick={(event, rowData, togglePanel) => {
                                setOpen(true);
                                if (rowData)
                                    setSelectedUser(rowData);
                            }}
                        />
                    </StyledPaper>
                </Grid>
            </Grid>
            <Dialog open={open} maxWidth={'sm'}>
                <DialogTitle>{selectedUser && selectedUser._id !== 'CREATE' ? 'Update' : 'Create'} user</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedUser && selectedUser._id !== 'CREATE' ? 'Update' : 'Create'} user
                    </DialogContentText>
                    <UpsertUser user={selectedUser} setOpen={setOpen} setUser={setSelectedUser} />
                </DialogContent>
            </Dialog>
        </Root>
    )
}

import React, {forwardRef, useContext, useEffect, useRef} from 'react';
import {convertDate, copyActivity} from "../modules/Activity/methods";
import ReactCountryFlag from "react-country-flag"
import {
    Badge,
    Box,
    Grid,
    Tooltip,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Api} from "../Api";
import { useSnackbar } from 'notistack';
import {sendErrorMessage} from "../Error";
import {AppContext} from "../AppContext";
import {Root, AppBarSpacer, StyledPaper} from "../components/StyledComponents";
import MaterialTable from "material-table";
import {Add} from "@material-ui/icons";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import DoneIcon from "@mui/icons-material/Done";
import {FileCopy} from "@material-ui/icons";
import {green} from "@mui/material/colors";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from '@mui/material/Checkbox';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import {CreateActivity} from "../modules/Activity/CreateActivity";
const {REACT_APP_ACTIVITY_API, REACT_APP_UPLOAD_BUCKET} =  process.env;

// @ts-ignore
const tableIcons = {
    // @ts-ignore
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    // @ts-ignore
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
// @ts-ignore
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
// @ts-ignore
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
// @ts-ignore
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
// @ts-ignore
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
// @ts-ignore
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
// @ts-ignore
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
// @ts-ignore
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
// @ts-ignore
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
// @ts-ignore
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
// @ts-ignore
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
// @ts-ignore
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
// @ts-ignore
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
// @ts-ignore
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
// @ts-ignore
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
// @ts-ignore
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    // @ts-ignore
    CheckBox: forwardRef((props, ref) => <CheckBox {...props} ref={ref} />),
// @ts-ignore
    CheckBoxOutlineBlank: forwardRef((props, ref) => <CheckBoxOutlineBlank {...props} ref={ref} />),
};
export const Activities = ({repEmail, bookingEmail}: {repEmail?: boolean, bookingEmail?: boolean}) => {
    const {setAppHeader, isEmbedded, user, setActions, userHasRole} = useContext(AppContext);
    const [rows, setRows] = React.useState([]);
    const [type, setType] = React.useState<'BookingEmail' | 'RepEmail' | 'Event'>(repEmail ? 'RepEmail' : bookingEmail ? 'BookingEmail' :'Event');
    const [openNewEventDialog, setOpenNewEventDialog] = React.useState(false)
    const tableRef = useRef();
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const history = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [filterArchivedActivities, setFilterArchivedActivities] = React.useState(true);
    const [filterMyActivities, setFilterMyActivities] = React.useState((user?.role === 'Standard' && !isEmbedded) && !repEmail );
    const [filterMyCountryActivities, setFilterMyCountryActivities] = React.useState(isEmbedded);
    const getData = async () => {
        try {
            setWaitingForBackendMessage(bookingEmail ? 'Fetching Booking Emails' : !repEmail ? 'Fetching activities' : 'Fetching Rep Emails')
                const res = await Api({name: 'Activities:getData', url: `${REACT_APP_ACTIVITY_API}activities`})
                if (res) {
                    setRows(res.data.map( (act: ActivityType) => {
                        return {
                            ...act,
                            start: convertDate(new Date(act.start)),
                            end: convertDate(new Date(act.end)),
                            updatedAt: convertDate(new Date(act.updatedAt)),
                            speaker: act.speakers.length > 0 ? act.speakers.map( (speaker: SpeakerType) => {
                                return speaker.fullname;
                            }).toString() : ''
                        }
                    })
                        .filter( (act: ActivityType) => {
                            if (bookingEmail) {
                                return  act?.TemplateType === 'BookingEmail'
                            }
                            if (repEmail)
                                return act?.TemplateType === 'RepEmail'
                            if (isEmbedded && act?.isApproved) {
                                return act?.TemplateType !== 'BookingEmail'
                            }

                            if (!isEmbedded)
                                return act?.TemplateType !== 'BookingEmail' && act?.TemplateType !== 'RepEmail'
                        })
                        .sort((prev: ActivityType, next: ActivityType) => {
                            const dateA = new Date(prev.start);
                            const dateB = new Date(next.start);

                            if (dateA > dateB) {
                                return -1;
                            } else if (dateA < dateB) {
                                return 1;
                            } else {
                                return 0;
                            }
                        })
                    );
                }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/activities/getData', "Cannot Get activities", enqueueSnackbar);
        } finally {
            setWaitingForBackendMessage('')
        }
    }

    const copyAct = (event: any, rowData: any) => {
        ( async() => {
            setWaitingForBackendMessage('Just a sec, I am copying the activity...')
            const newActivity: ActivityType = await copyActivity(rowData._id);
            setWaitingForBackendMessage('');
            history(`/activity/?_id=${newActivity._id}`);
        })()
    }
    useEffect( () => {

            if (repEmail) {
                setType('RepEmail');
                setAppHeader("Rep Emails");
                setActions();
            } else if (bookingEmail) {
                setAppHeader('Booking Emails');
                setType('BookingEmail')
                setActions();
            } else {
                setType('Event')
                setAppHeader("Activities");
                setActions();
            }
            ( async () => {
                await getData();
            })();
    },[repEmail, bookingEmail])

    const Status = ({activity}: any) => {
        let InvitationSentNumCustomers = activity.InvitationSentNumCustomers;
        let InvitationViewedNumCustomers = activity.InvitationViewedNumCustomers;
        if ( activity.TemplateType === 'RepEmail' && !userHasRole(['Administrator', 'Editor'])) {
            InvitationSentNumCustomers = activity.UserCountryInvitationSentNumCustomers;
            InvitationViewedNumCustomers = activity.UserCountryInvitationViewedNumCustomers;
        }
        return (
            <React.Fragment>
                {
                    InvitationSentNumCustomers ?
                        <Tooltip sx={{mr: '10px'}}
                            title={`E-mail/invitation has been sent to ${InvitationSentNumCustomers} customers.`}>
                            <Badge color="primary" badgeContent={InvitationSentNumCustomers}>
                                <EmailIcon/>
                            </Badge>
                        </Tooltip>
                        : ''
                }
                {
                    InvitationViewedNumCustomers ?
                        <Tooltip sx={{mr: '10px'}}
                                 title={`E-mail/invitation has been viewed by  ${InvitationViewedNumCustomers} customers.`}>
                            <Badge color="primary" badgeContent={InvitationViewedNumCustomers}>
                                <VisibilityIcon/>
                            </Badge>
                        </Tooltip>
                        : ''
                }
                {
                    activity.RegistrationViewedNumCustomers ?
                        <Tooltip sx={{mr: '10px'}}
                                 title={`${activity.RegistrationViewedNumCustomers} customers has opened the registration page.`}>
                            <Badge color="primary" badgeContent={activity.RegistrationViewedNumCustomers}>
                                <MeetingRoomIcon/>
                            </Badge>
                        </Tooltip>
                        : ''
                }
                {
                    activity.RegistrationDoneNumCustomers ?
                        <Tooltip
                            title={`${activity.RegistrationDoneNumCustomers} has registered to the meeting'`}>
                            <Badge color="primary" badgeContent={activity.RegistrationDoneNumCustomers}>
                                <DoneIcon style={{ color: green[500] }}/>
                            </Badge>
                        </Tooltip>
                        : ''
                }

            </React.Fragment>
        )

    }
    // @ts-ignore
    return (
        <Root>
            <AppBarSpacer hidden={isEmbedded}/>
            <Grid container sx={{maxWidth: '1500px', marginLeft: 'auto', marginRight: 'auto', mt: '20px', mb: '20px'}}>
                    {/* Recent Orders */}
                    <Grid item xs={12} >
                        <StyledPaper elevation={24}>
                            <Grid container sx={{mb: 1, ml: 1}}>
                                {
                                    /*
                                <Grid item xs={2} hidden={isEmbedded}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFilterTemplateActivities(event.target.checked);
                                        }} />} label="Hide templates" />
                                    </FormGroup>
                                </Grid>

                                     */
                                }

                                <Grid item xs={2} hidden={isEmbedded}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFilterArchivedActivities(event.target.checked);
                                        }} />} label="Hide archived" />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={2} hidden={isEmbedded || repEmail}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked={filterMyActivities} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFilterMyActivities(event.target.checked);
                                        }} />} label="My activities" />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={2} hidden={filterMyActivities || isEmbedded|| repEmail}>
                                    <FormGroup>
                                        <FormControlLabel disabled={filterMyActivities} control={<Checkbox defaultChecked={filterMyCountryActivities} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFilterMyCountryActivities(event.target.checked);
                                        }} />} label="My country activities" />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <MaterialTable
                            title={isEmbedded ? "Events/Rep-emails" : 'Events'}
                                // @ts-ignore
                            icons={tableIcons}
                            tableRef={tableRef}
                            columns={[
                                { title: 'Type', field: 'TemplateType', hidden: !isEmbedded},
                                { title: '', hidden: !repEmail && !bookingEmail,  field: '_id', render: (rowData: any) => {
                                    return (
                                        <Box>
                                            {
                                                rowData?.TopHeader?.imageURL &&
                                                    <img height={50} src={`${rowData?.TopHeader?.imageURL}`}/>

                                            }
                                        </Box>

                                    )
                                    }  },
                                { title: 'Title', field: 'subject'},
                                { title: 'Date', hidden: repEmail || bookingEmail, field: 'start', render: (rowData: any) => `${rowData.TemplateType === 'RepEmail' ? '' : rowData.start.substring(0,10)}`},
                                { title: 'Country', field: 'countries', render: (rowData: any) => {
                                        return(
                                            rowData.TemplateType === 'RepEmail' && !userHasRole(['Administrator', 'Editor']) ?
                                                <ReactCountryFlag
                                                    style={{
                                                        fontSize: '2em',
                                                        lineHeight: '2em',
                                                    }} countryCode={user?.countries ? user?.countries[0] : 'SE'} />
                                                :
                                            rowData.countries.map( (country: any) => {
                                                return(
                                                    <ReactCountryFlag
                                                        style={{
                                                            fontSize: '2em',
                                                            lineHeight: '2em',
                                                        }} countryCode={country} />)
                                            })
                                        )
                                    }},
                                { title: 'Responsible', field: '_id', render: rowData =>
                                        <Box>
                                            <img title={rowData.responsible.name} src={`${REACT_APP_UPLOAD_BUCKET}${rowData.responsible.image ? `${rowData.responsible.image}` : `user/${rowData.responsible._id}.png`}`}  style={{width: 60, borderRadius: '50%'}}/>
                                        </Box>
                                            },
                        { title: 'Description', field: 'comment'},
                        { title: 'Status', field: 'RegistrationDoneNumCustomers', render: (rowData: any) =>  <Status activity={rowData}/>},
                        { title: 'Updated',  field: 'updatedAt', render: (rowData: any) => `${rowData.updatedAt}`},
                            ]}
                            data={
                                rows.filter( (act: any) => {
                                    if (filterArchivedActivities && (act?.archived??false)) {
                                        return false;
                                    }

                                    if (filterMyActivities && act.responsible._id !== user?.userId) {
                                        return false
                                    }
                                    if (filterMyCountryActivities) {
                                        let returnVal = false;
                                        if (user?.countries) {
                                            user?.countries.map( country => {
                                                if (act?.countries.indexOf(country) > -1) {
                                                    returnVal = true;
                                                }
                                            } )
                                        }
                                        return returnVal;
                                    }
                                    return true;
                                })
                            }
                            options={{
                            search: true,
                                rowStyle: rowData => ({
                                    verticalAlign: 'top',
                                    backgroundColor: (rowData?.archived??false) ? '#eee' : '#FFF',
                                    fontSize: '9px'
                                }),
                                headerStyle: {
                                    fontSize: '11px',
                                    fontWeight: 700,
                                    fontStyle: 'bold',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: 'thin',
                                    borderBottomColor: 'black',
                                    textTransform: 'uppercase',
                                },
                                pageSizeOptions: [100, 300, 500],
                                pageSize: 100,
                                paging: true,
                                filtering: false,
                            }}
                            localization={{
                                header: {
                                    actions: ''
                                },
                            }
                            }
                            actions={[
                                {
                                    icon: () => <Add style={{fontSize: 40}} />,
                                    hidden: user?.role === 'Standard' && type !== 'Event',
                                    disabled: isEmbedded,
                                    tooltip: 'Add event',
                                    isFreeAction: true,
                                    onClick: ()=> setOpenNewEventDialog(true)
                                },
                                rowData => (
                                {
                                    icon: FileCopy,
                                    hidden:  (!rowData.TemplateIsEnabled || (user?.role === 'Standard' && !rowData.TemplateIsRepEnabled)),
                                    disabled: isEmbedded,
                                    tooltip: 'Copy this event',
                                    onClick: copyAct
                                })
                            ]}
                            onRowClick={(event, rowData, togglePanel) => {
                                    history(`/activity/?_id=${rowData._id}`);

                        }}
                            />
                        </StyledPaper>
                    </Grid>
            </Grid>
            {
                !isEmbedded && <CreateActivity type={type} setOpenNewEventDialog={setOpenNewEventDialog} openNewEventDialog={openNewEventDialog}/>
            }

        </Root>
    )
}

import {isTemplateActive} from "../../methods";
import {compareObjects} from "../../../../components/CompareObjects";

export interface handleTemplateUpdateTypeV2 {
    template: TemplateType;
    fieldName: string;
    fieldValue: any;
    forceSave?: boolean;
    setApprovalDate?: boolean;
    isDeletion?: boolean;
    activity: ActivityType;
    setActivityPayload: (payload: any) => void;
    country?: string;
}
export const handleTemplateV2 = async (
    {
        template,
        fieldName,
        fieldValue,
        activity,
        setActivityPayload,
        country
    }: handleTemplateUpdateTypeV2) => {
    const templateId = template._id;

    //const approvalDate = setApprovalDate ? Date.now() : activity.templateSuite.approvalDate;

    let updateStatus = false;
    if ( fieldName === 'readOnly' && fieldValue) {
        updateStatus = activity?.templateSuite?.templates?.filter(
            (template: TemplateType) => isTemplateActive(template.type, activity.selectedTemplates) && template._id !== templateId)
            .filter(
                (template: TemplateType) => {
                    //Superlong statement below is here to make sure that Registration and Survey is not considered (today) when scanning countryVersion
                    return typeof template.readOnly === 'undefined' || !template.readOnly || (template.countryVersion && template.countryVersion.filter( (cv:TemplateCountryVersion) => (typeof cv.readOnly === 'undefined' || !cv.readOnly) && (template.type.indexOf('E-MAIL') > -1 )).length > 0 )
                }).length === 0 && activity.status === 1;
    }

    let diff = compareObjects(template, {
        ...template,
        [fieldName]: fieldValue})

    if ( country  ) {
        diff = compareObjects(
            template,
            {
                ...template,
                countryVersion: template.countryVersion.map( (cv: TemplateCountryVersion) => {
                    if (cv.country === country) {
                        return {
                            ...cv,
                            [fieldName]: fieldValue
                        }
                    }
                    return cv;
                })
            }
        )
    }
    if (diff.length) {
        setActivityPayload({
                refreshActivity: true,
                templatePayload: {
                    templateId,
                    fieldName,
                    fieldValue,
                    country,
                    diff
                }
            }
        )
        if (updateStatus) {
            setTimeout( () => {
                setActivityPayload({
                    refreshActivity: true,
                    payload: {
                        status: activity.status + 1
                    }
                })
            }, 1000)

        }
    }

}

import {Api} from "../../Api";
const url =`${process.env.REACT_APP_USER_API}`;
export const getUser = async () => {
    try {
        const response = await Api({
            name: 'getUser',
            url: `${url}authentication`
        })
        if (response.data.user) localStorage.setItem('user', JSON.stringify(response.data.user));

        return response.data.user;
    } catch (error: any) {
        console.error(error);
    }
    return localStorage.getItem('user');
}
import {
    AppBar,
    Dialog,
    FormControlLabel,
    Grid,
    IconButton,
    Slide, Slider,
    Switch,
    TextField,
    Toolbar,
    Tooltip
} from "@mui/material";
import {SendTemplateAsPDf} from "../dialogs";
import CodeIcon from "@mui/icons-material/Code";
import PreviewIcon from '@mui/icons-material/Preview';
import {getRepEmailFragmentFooter, handleReadOnlyUpdate, handleSubjectChange} from "../methods";
import React, {useContext, useEffect} from "react";
import {ActivityContext} from "../../../ActivityContext";
import {TemplateSuiteContext} from "../TemplateSuiteContext";
import CloseIcon from "@mui/icons-material/Close";
import {TransitionProps} from "@mui/material/transitions";
import {getRegulatoryText} from "../../../../Fragment/methods";
import { EmailSchedule } from "./dialogs";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {AppContext} from "../../../../../AppContext";
import {processTemplate} from "./processTemplate";
import WarningIcon from '@mui/icons-material/Warning';
import {useSnackbar} from "notistack";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {Assistant} from "../Assistant";
import {CalendarEntry} from "./CalendarEntry";
const {REACT_APP_UPLOAD_BUCKET} = process.env;
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
enum TypeOfForm {
    CONFIRMATION = 'E-MAIL CONFIRMATION',
}

const integrationScript = '' +
    '<script>' +
    '   function sendMessage(fieldName, fieldValue, type, imageWidth) {' +
    '       window.parent.postMessage({type: type, fieldName: fieldName, fieldValue: fieldValue, imageWidth: imageWidth}, "*");' +
    '   }; window.addEventListener("load", function(){Object.assign(document.querySelector("td.OpenInBrowser").style, {pointerEvents: "none",cursor: "default"});document.body.style.overflow = "hidden";window.parent.postMessage({type: "iframeHeightAndWidth", height: document.body.scrollHeight, width: document.querySelector("body table.OuterTable table.InnerTable").scrollWidth}, "*")})' +
    '</script>';
interface EmailInput {
    templateReadOnly: boolean;
    setUpdateField: any;
    template: TemplateType;
    country: string;
    setTemplateReadOnly: any;
    templateSubject: string;
    templateFields: TemplateFieldType[];
}
export const Email = ({templateFields, templateReadOnly, setUpdateField, template, country, setTemplateReadOnly, templateSubject}: EmailInput) => {
    const {activity,  isEditor, isArchived, setActivityPayload, repEmailEditor} = useContext(ActivityContext);
    const {userHasRole, user, setWaitingForBackendMessage} = useContext(AppContext);
    const {templateSuite, handleTemplateUpdateV2, footer, isUsingFragmentFooter} = useContext(TemplateSuiteContext);
    const [open, setOpen] = React.useState(false);
    const [previewHTML, setPreviewHTML] = React.useState('');
    const [regulatoryText, setRegulatoryText] = React.useState('Regulatory text could not be fetched, check brand, product and country on first page');
    const [iframeHeight, setIframeHeight] = React.useState(2800);
    const [iframeWidth, setIframeWidth] = React.useState(600);
    const [scale, setScale] = React.useState(1);
    const { enqueueSnackbar } = useSnackbar();
    const [openAssistant, setOpenAssistant] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect( () => {
        try {
            ( async()=> {
                const regText = await getRegulatoryText({
                    country: country??activity.countries[0],
                    type: 'Regulatory',
                    brand: activity.brand,
                    product: activity.product
                })
                if (regText) setRegulatoryText(regText?.value)
            })()
        } catch (e: any) {
            console.log('Probably not an error')
            console.error(e);
        }
        const eListener = (event:any) => {
            if (event.data.type && event.data.type === 'iframeHeightAndWidth') {
                setIframeHeight(event.data.height + 20);
                setIframeWidth(event.data.width);
                if (event.data.width > 600) {
                    enqueueSnackbar('The template is wider than 600px, please adjust the width of the template', {variant: 'error', autoHideDuration: 5000});
                    document.getElementById('templatePreviewFullscreen')?.setAttribute('style', 'width: 600px');
                }
            }
        }
        window.addEventListener("message", eListener, false);
        return () => window.removeEventListener("message", eListener, false);

    }, [])
    useEffect( () => {
        let displayEmptyFields = !open;
        // User must be initialized
        if (user) {
            (async () => {
                let localFooter = footer;
                if (templateSuite.type === 'RepEmail' && isUsingFragmentFooter) {
                    setWaitingForBackendMessage('Fetching RepEmail Country Footer');
                    localFooter = await getRepEmailFragmentFooter({ country: country??activity.countries[0] });
                    setWaitingForBackendMessage('');
                }
                setPreviewHTML(
                    await processTemplate(
                        {
                            integrationScript,
                            template,
                            templateFields,
                            templateReadOnly,
                            regulatoryText,
                            templateSuite,
                            activity,
                            isEditor: isEditor??false,
                            REACT_APP_UPLOAD_BUCKET,
                            repEmailEditor,
                            footer: localFooter,
                            user
                        },
                        displayEmptyFields));
            })();
        }

    }, [template.html, templateFields, templateReadOnly, regulatoryText, activity.templateSuite.smallLogoImages, open, footer])
    const getLabel = () => {
        switch (template.type) {
            case 'E-MAIL INVITATION':
                return 'Send invitation';
            case 'E-MAIL WEBINAR':
                return 'Send webinar e-mail'
            case 'E-MAIOL SURVEY':
                return 'Send survey e-mail'
        }
        return 'Send e-mail';
    }
    const getFieldName = () => {
        switch (template.type) {
            case 'E-MAIL INVITATION':
                return 'scheduled_invitation';
            case 'E-MAIL WEBINAR URL':
                return 'scheduled_webinar_url'
            case 'E-MAIL SURVEY':
                return 'scheduled_survey_url'
        }
        return '';
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6} sx={{textAlign: 'left'}}>
                    {
                        !templateReadOnly && userHasRole(['Administrator']) &&
                        <Tooltip title="Edit template source html">
                            <IconButton onClick={()=>{setUpdateField({open: true, fieldName: 'html', fieldValue: template.html, fieldType: ''})}}>
                                <CodeIcon  sx={{fontSize: 30}}/>
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        !templateReadOnly && userHasRole(['Administrator'])  && templateSuite.type !== 'RepEmail' ?
                            <Tooltip title="Edit color Presets">
                                <IconButton onClick={()=>{setUpdateField({open: true, fieldName: 'colorPresets', fieldValue: templateSuite.colorPresets, fieldType: ''})}}>
                                    <BorderColorIcon  sx={{fontSize: 30}}/>
                                </IconButton>
                            </Tooltip>
                            : ''
                    }
                    <Tooltip title="Preview template in fullscreen">
                        <IconButton onClick={handleClickOpen}>
                            <PreviewIcon  sx={{fontSize: 30}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Zoom in/out">
                        <Slider
                            sx={{width: '100px', height: 10, verticalAlign: 'middle', mr: '20px'}}
                            value={scale}
                            min={0.1}
                            max={1}
                            step={0.1}
                            onChange={(event, newValue) => {
                                if (!Array.isArray(newValue)) setScale(newValue);
                            }}
                        />
                    </Tooltip>
                    {(template.type === 'E-MAIL INVITATION' || template.type === 'E-MAIL SAVE-THE-DATE') && parseInt(templateSuite.generation) > 2 &&
                        <Tooltip title="When enabled, agneda will be displayed">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={!isEditor || isArchived || templateReadOnly }
                                        onChange={ (event) => handleTemplateUpdateV2({
                                            template,
                                            activity,
                                            setActivityPayload,
                                            fieldName: 'displayAgenda',
                                            fieldValue: event.target.checked,
                                            country
                                        })}
                                        value={template.displayAgenda}
                                        checked={template.displayAgenda} />
                                } label="Display agenda" />
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right'}}>
                    {
                        template.type === TypeOfForm.CONFIRMATION && <CalendarEntry templateReadOnly={templateReadOnly} />
                    }
                    {
                        template.type !== 'E-MAIL CONFIRMATION' && template.type !== 'E-MAIL SAVE-THE-DATE' && templateSuite.type !== 'RepEmail' && isEditor &&
                        <EmailSchedule
                            setActivityPayload={setActivityPayload}
                            activity={activity}
                            label={getLabel()}
                            fieldName={getFieldName()}
                            isArchived={isArchived??false}
                            reminder={template.type === 'E-MAIL INVITATION'}
                        />
                    }
                    <Tooltip title="AI Assistant">
                        <IconButton onClick={()=> setOpenAssistant(true)} disabled={process.env.REACT_APP_ENV === 'prod'}>
                            <SmartToyIcon  sx={{fontSize: 30}}/>
                        </IconButton>
                    </Tooltip>
                    <SendTemplateAsPDf activity={activity} country={country} template={template}/>

                    <Tooltip title="When you are done, change switch to Approved to prevent future changes">
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={
                                        (!isEditor && (templateSuite.type === 'RepEmail' && !repEmailEditor.editor)) || (!isEditor && templateSuite.type !== 'RepEmail') || (template.type.indexOf('E-MAIL') > -1 && !templateSubject && !templateReadOnly) || isArchived || iframeWidth > 600
                                }
                                    onChange={ (event) => handleReadOnlyUpdate(event, country, template, handleTemplateUpdateV2, setTemplateReadOnly, activity)}
                                    value={templateReadOnly}
                                    checked={templateReadOnly} />} label="Done" />
                    </Tooltip>
                    {
                        iframeWidth > 600 &&  <Tooltip title={`The e-mail is to wide, this is most likely due to an image.`}>
                            <WarningIcon sx={{color: 'red', fontSize: 30}} />
                        </Tooltip>
                    }

                </Grid>

            </Grid>
            <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            id="subject"
                            label="Email title:"
                            type="text"
                            variant="standard"
                            sx={!templateSubject ? {
                                borderColor: 'red', // Change border color based on the subject's value
                                borderWidth: 1,
                                borderStyle: 'solid',
                            } : {}}
                            defaultValue={templateSubject}
                            fullWidth
                            disabled={templateReadOnly || ((!isEditor && (templateSuite.type === 'RepEmail' && !repEmailEditor.editor)) || !isEditor && templateSuite.type !== 'RepEmail')}
                            onBlur={(event) => handleSubjectChange(event,country,template, handleTemplateUpdateV2)}
                        />
                    </Grid>
                            {
                                !open &&
                                <Grid item xs={12}>
                                    <iframe
                                        style={{
                                            height: `${iframeHeight}px`,
                                            width: '100%',
                                            overflow: 'auto',
                                            transform: `scale(${scale})`,
                                            transformOrigin: 'top center'  // Ändrad här
                                        }}
                                        frameBorder={0}
                                        srcDoc={previewHTML}
                                        id="templatePreview">
                                    </iframe>
                                </Grid>
                            }


            </Grid>
            <Assistant openDialog={openAssistant} setOpenDialog={setOpenAssistant} html={previewHTML} />
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {
                    open &&
                    <Grid item xs={12}>
                        <iframe style={{
                            height: `${iframeHeight}px`,
                            width: '100%',
                            overflow: 'auto',
                            transform: `scale(${scale})`,
                            transformOrigin: 'top center'
                        }} frameBorder={0} srcDoc={previewHTML} id="templatePreviewFullscreen"></iframe>
                    </Grid>
                }
            </Dialog>
        </React.Fragment>
    )
}

import {Box} from "@mui/material";
import {UploadFragmentFile} from "./UploadFragmentFile";
import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
const {REACT_APP_UPLOAD_BUCKET} =  process.env;
export const Image = ({fragment, setImage, setDescription}: {fragment: FragmentType, setImage(path: string): void, setDescription(text: string): void}) => {
    return (
        <Box>
            <Box sx={{textAlign: 'center', paddingTop: 5}}>
                <img width={fragment.type === 'Logo' ? '145px': '100%'} hidden={!fragment?.image} alt="Här ska det vara en bild :) " src={`${REACT_APP_UPLOAD_BUCKET}${fragment?.image}`}/>
            </Box>
            <Box sx={{textAlign: 'center'}}>
                <UploadFragmentFile
                    fieldName=""
                    _id={fragment?._id??''}
                    afterUpload={(filePath: string) => {
                        setImage(filePath)
                    }}/>
            </Box>
            <TextField defaultValue={fragment?.description} id="standard-basic" label="Description" required variant="standard" fullWidth onBlur={event => setDescription(event.target.value)} />

        </Box>
    )
}


export const applySmallLogos = (html: string, templateSuite: TemplateSuiteType, template: TemplateType, activity: ActivityType, REACT_APP_UPLOAD_BUCKET: string) => {
    if (templateSuite.smallLogoImages?.length) {
        let logos = `<table cellpadding="0" cellspacing="0" ><tbody><tr>`;
        let numRows = 0;
        let paddingLeft = '0px'
        templateSuite.smallLogoImages.map( (fragment: FragmentType) => {
            if ( numRows === 3) {
                paddingLeft = '0px'
                logos = `${logos}</tr><tr>`;
            }
            const onClick = activity.jointEvent && !template.readOnly ? "onclick=\"sendMessage('__smallLogos','', 'updateTemplateImage')\"" : "";
            logos = `${logos}<td class="smallLogo"><img class="updateField" style="cursor: ${activity.jointEvent && !template.readOnly && 'pointer'};" ${onClick} src="${REACT_APP_UPLOAD_BUCKET}${fragment.image}" /></td>`
            numRows++;
            paddingLeft = '20px;'
        })
        logos = `${logos}</tr></tbody></table>`;
        html = html.replaceAll(`[[__smallLogos]]`, `${logos}`);
        logos = `<tr><td class="whiteBackground ${!activity.jointEvent ? 'AboutTillottsLogo' : 'jointEventLogos'}">${logos}</td></tr>`
        if (!activity.jointEvent) {
            //Make sure to blank out
            html = html.replaceAll(`[[__smallLogosFooterJointEvent]]`, ``);
            html = html.replaceAll(`[[__smallLogosFooter]]`, `${logos}`);
        } else {
            html = html.replaceAll(`[[__smallLogosFooter]]`, ``);
            html = html.replaceAll(`[[__smallLogosFooterJointEvent]]`, `${logos}`);
        }
    }
    return html
}
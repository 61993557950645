import PropTypes, {InferProps} from "prop-types";
import Grid from "@mui/material/Grid";
import {Tooltip
} from "@mui/material";
import React, {useContext, useEffect, useRef} from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import MaterialTable from "material-table";
import DoneIcon from "@mui/icons-material/Done";
import {green} from "@mui/material/colors";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmailIcon from "@mui/icons-material/Email";
import {SendEmailForm} from "../SendEmailForm";
import {ActivityContext} from "../../ActivityContext";
import {AppContext} from "../../../../AppContext";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    margin: '20px',
    flexDirection: 'column',
}))
export const RepEmails: any = () => {
    const {activity, participants, isEmbedded, repEmailEditor}  = useContext(ActivityContext);
    const {user, userHasRole} = useContext(AppContext);
    const tableRef = useRef();
    const [template] = React.useState(activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL INVITATION'))
    const [emailDialog, openEmailDialog] = React.useState(false);
    const [data, setData] = React.useState<any>([])
    const [filterMyActivities, setFilterMyActivities] = React.useState((user?.role === 'Standard') );
    const [stat, setStat] = React.useState({
        sent: 0,
        viewed: 0,
        viewRatio: 0
    })

    useEffect( () => {
        setData(convertParticipants())
    }, [participants])
    const convertParticipants = () => {
        return participants.sort((prev: ParticipantType, next: ParticipantType) => {
            if (prev?.invitationViewed && next?.invitationViewed) {
                const dateA = new Date(prev.invitationViewed);
                const dateB = new Date(next.invitationViewed);

                if (dateA > dateB) {
                    return -1;
                } else if (dateA < dateB) {
                    return 1;
                } else {
                    return 0;
                }
            }
            return 0;
        }).map((participant: ParticipantType) => {
            const firstname = participant.firstname === 'null' ? '': participant.firstname??'';
            const middlename = participant.middlename === 'null' ? '': participant.middlename??'';

            return {
                ...participant,
                siteName:  `${participant.mainSite??''} ${participant.site ? ' - ' : ''}${participant.site ?? ''}`.trim(),
                fullname: `${participant.title} ${firstname} ${middlename} ${participant.name} <${participant.email}>`.trim(),
                sentBy: participant.invitationSentBy?.email,
            }
        })
    }
    useEffect( () => {
        if(!userHasRole(['Administrator', 'Editor'])) {
            const filteredParticipants = convertParticipants().filter( (participant: ParticipantType) => {
                if (participant.country !== repEmailEditor.country)
                    return false;

                if (filterMyActivities) {
                    return participant?.invitationSentBy?._id === user?.userId
                }
                return true;
            })
            const viewed = filteredParticipants.filter( (p: ParticipantType) => p.invitationViewed).length;
            const sent = filteredParticipants.length;
            setStat( {
                sent,
                viewed,
                viewRatio: viewed > 0 ? viewed/sent : 0
            })
            setData(filteredParticipants)
        }

    }, [filterMyActivities])
    // @ts-ignore
    return (
        <Grid sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
            margin: 'auto auto',
            width: emailDialog ? '800px': '100%'}}>
            <StyledPaper elevation={24}>
                {
                    emailDialog ?
                            <SendEmailForm activityCustomerIds={participants.map( (participant: ParticipantType) => participant.customer)} activityId={activity._id} template={template} openForm={openEmailDialog} country={user?.countries?.length ? user?.countries[0] : 'SE'} />
                        :
                        <React.Fragment>
                            <Grid paddingLeft={1} hidden={userHasRole(['Administrator', 'Editor'])}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked={filterMyActivities} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFilterMyActivities(event.target.checked);
                                    }} />} label="Sent by me" />
                                </FormGroup>
                            </Grid>
                            <MaterialTable
                                title={`Sent (${stat.sent}), Viewed (${stat.viewed}), ViewRate (${Math.round(stat.viewRatio*100)}%)`}
                                tableRef={tableRef}
                                columns={[
                                    { title: 'STATUS', export: false,  field: 'invitationSent', render: ((rowData: ParticipantType) => {
                                            if ( rowData.registrationDone ) {
                                                if(rowData.participate) {
                                                    return <Tooltip title="Participant will participate"><DoneIcon style={{ color: green[500] }}/></Tooltip>;
                                                }
                                                if(!rowData.participate) {
                                                    return <Tooltip title="Participant will not participant"><NotInterestedIcon style={{ color: "red" }}/></Tooltip>;
                                                }
                                            } else {
                                                if(rowData.registrationViewed) {
                                                    return <Tooltip title="Registration form is opened"><MeetingRoomIcon/></Tooltip>;
                                                }
                                                if(rowData.invitationViewed) {
                                                    return <Tooltip title="Email is viewed"><VisibilityIcon/></Tooltip>;
                                                }
                                                if(rowData.invitationSent) {
                                                    return <Tooltip title="Email is sent"><EmailIcon/></Tooltip>;
                                                }
                                            }
                                            return ''
                                        })  },

                                    { title: 'Name', field: 'fullname', render: (rowData: any) => {
                                            return(
                                                <div>
                                                    <span>{`${rowData.firstname} ${rowData.name}`}</span>
                                                    {
                                                        rowData.title ? <span><br/> {rowData.title}</span> : ''
                                                    }
                                                    <span><br/>{rowData.email}</span>
                                                </div>
                                            )
                                        }},
                                    { title: 'Site', field: 'siteName', render: (rowData: any) => {
                                            if ( rowData.mainSite || rowData.site ) {
                                                return(
                                                    <span><b>{`${rowData.mainSite}`}</b><br/>{`${rowData.site ? rowData.site : ''}`}</span>
                                                )
                                            } else {
                                                return ('');
                                            }

                                        }  },
                                    { title: 'Country', field: 'country'},
                                    { title: 'Sent by',  field: 'sentBy'},
                                    { title: 'Sent',  field: 'invitationSent', type: 'datetime', dateSetting: {locale: 'sv-SE'}  },
                                    { title: 'Viewed',  field: 'invitationViewed', type: 'datetime', dateSetting: {locale: 'sv-SE'}  },
                                ]}
                                data={data}
                                options={{
                                    pageSizeOptions: [30, 60, 120],
                                    pageSize: 30,
                                    paging: true,
                                    selection: false,
                                    rowStyle: rowData => ({
                                        verticalAlign: 'top',
                                        fontSize: '9px'
                                    }),
                                    headerStyle: {
                                        fontSize: '11px',
                                        fontWeight: 700,
                                        fontStyle: 'bold',
                                        borderBottomStyle: 'solid',
                                        borderBottomWidth: 'thin',
                                        borderBottomColor: 'black',
                                        textTransform: 'uppercase',
                                    },
                                    exportButton: true
                                }}
                                actions={[
                                    {
                                        icon: 'send',
                                        disabled: isEmbedded,
                                        tooltip: 'Send E-mail',
                                        isFreeAction: true,
                                        onClick: () => openEmailDialog(true)
                                    }
                                ]}

                            />
                        </React.Fragment>

                }

            </StyledPaper>
        </Grid>
    );
}

import {Box, Button, Dialog, DialogActions, DialogTitle, Grid, Slide, TextField} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {TransitionProps} from "@mui/material/transitions";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Typography} from "@mui/material/";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import {PickImage} from "../modules/Fragment/PickImage";
import {ActivityContext} from "../modules/Activity/ActivityContext";
import {Assistant, TextAssistant} from "../modules/Activity/components/Templates/Assistant";
import {Attachments} from "../modules/Activity/components/Attachments";
const pretty = require('pretty');
const {REACT_APP_UPLOAD_BUCKET} = process.env;
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, pt:1 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const RichTextField = ({defaultValue, onBlur, onChange, width, height, maxLength, readOnly, fieldId}: {onChange?: any, onBlur?: any, defaultValue: any, width?: string, height?: string, maxLength?: number, readOnly?: boolean, fieldId: string }) => {
    const [updateFieldValue, setUpdateFieldValue] = React.useState('');
    const insertImageCallbackRef = React.useRef<null | ((imageUrl: string) => void)>(null);
    const {activity} = useContext(ActivityContext);
    const [openImagePicker, setOpenImagePicker] = React.useState(false);
    const [numCharacter, setNumCharacter] = React.useState(0)
    const fontSizes = ['custom',7,'default', 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72];
    const ckEditor = React.useRef<null | any>(null);

    useEffect(() => {
        setUpdateFieldValue(defaultValue);
    }, [])
    useEffect(()=> {
        if ( typeof onChange === 'function')
            onChange(updateFieldValue);
    }, [updateFieldValue])
    return (
        <>
            <CKEditor
                editor={ClassicEditor}
                data={defaultValue}
                disabled={readOnly}
                onReady={(editor: any) => {
                    ckEditor.current = editor;
                    // You can store the "editor" and use when it is needed.
                }}
                onBlur={(event: any, editor: any) => {
                    if ( typeof onBlur === 'function')
                        onBlur(pretty(editor.getData().replace(/<figure[^>]*>|<\/figure>/g,"")));
                }}
                onChange={(event: any, editor: any) => {
                    const value = editor.getData().replace(/<figure[^>]*>|<\/figure>/g,"");
                    const characterCount = value.replace(/<[^>]*>/g, '').length
                    if (maxLength && characterCount > maxLength) {
                        alert('You are only allowed to insert ' + maxLength + ' characters');
                        return false;
                    }
                    setNumCharacter(characterCount);
                    setUpdateFieldValue(pretty(value))
                }}
                config={{
                    forcePasteAsPlainText: true,
                    fontSize: {
                        options: fontSizes,
                        supportCustomValues: true
                    },
                    image: {
                        toolbar: [
                            'imageTextAlternative',
                            'toggleImageCaption',
                            'imageStyle:inline',
                            'imageStyle:block',
                            'imageStyle:side',
                            'linkImage',
                            'imageResize'
                        ]
                    },
                    toolbar: [
                        'fontSize','bold', 'italic', 'underline', 'strikethrough','superscript', 'subscript', '|',
                        'numberedList', 'bulletedList', '|',
                        'outdent', 'indent', '|',
                        'imageSelector', '|',
                        'link','insertTable', '|',
                        'alignment', 'removeFormat', '|',
                        'undo', 'redo', '|',
                        'blockQuote', '|',
                        'horizontalLine',  '|',
                        'findAndReplace',  '|',
                    ],
                    imageSelector: {
                        onButtonPress: (insertImageCallback: any) => {
                            insertImageCallbackRef.current = insertImageCallback;
                            setOpenImagePicker(true);
                        }
                    }
                }}
            />
            <PickImage
                open={openImagePicker}
                fieldName={'Tester'}
                cancel={() => setOpenImagePicker(false)}
                object={'activity'}
                _id={activity._id}
                afterUpload={(filePath: string) => {
                    if (insertImageCallbackRef.current) insertImageCallbackRef.current(`${REACT_APP_UPLOAD_BUCKET}${filePath}`);
                    setOpenImagePicker(false);
                }}
            />
        </>)
}

export const EditQuill = ({defaultValue, onBlur, onChange, width, height, maxLength, readOnly, fieldId}: {onChange?: any, onBlur?: any, defaultValue: any, width?: string, height?: string, maxLength?: number, readOnly?: boolean, fieldId: string }) => {
    const [updateFieldValue, setUpdateFieldValue] = React.useState('');
    const insertImageCallbackRef = React.useRef<null | ((imageUrl: string) => void)>(null);
    const {activity} = useContext(ActivityContext);
    const [value, setValue] = React.useState(0);
    const [openImagePicker, setOpenImagePicker] = React.useState(false);
    const [numCharacter, setNumCharacter] = React.useState(0)
    const fontSizes = ['custom',7,'default', 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72];
    const ckEditor = React.useRef<null | any>(null);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        setUpdateFieldValue(defaultValue);
    }, [])
    useEffect(()=> {
        if ( typeof onChange === 'function')
            onChange(updateFieldValue);
    }, [updateFieldValue])
    return (
        <Box sx={{ width: '700px', height: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {// <TextAssistant ckEditor={ckEditor} html={updateFieldValue} fieldId={fieldId} />
                     }
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Basic (WYSIWYG)" {...a11yProps(0)} />
                    <Tab label="Advanced (HTML)" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Attachments ckEditor={ckEditor.current} />
                <CKEditor
                    editor={ClassicEditor}
                    data={defaultValue}
                    disabled={readOnly}
                    onReady={(editor: any) => {
                        ckEditor.current = editor;
                        // You can store the "editor" and use when it is needed.
                    }}
                    onBlur={(event: any, editor: any) => {
                        if ( typeof onBlur === 'function')
                            onBlur(pretty(editor.getData().replace(/<figure[^>]*>|<\/figure>/g,"")));
                    }}
                    onChange={(event: any, editor: any) => {
                        const value = editor.getData().replace(/<figure[^>]*>|<\/figure>/g,"");
                        const characterCount = value.replace(/<[^>]*>/g, '').length
                        if (maxLength && characterCount > maxLength) {
                            alert('You are only allowed to insert ' + maxLength + ' characters');
                            return false;
                        }
                        setNumCharacter(characterCount);
                        setUpdateFieldValue(pretty(value))
                    }}
                    config={{
                        forcePasteAsPlainText: true,
                        fontSize: {
                            options: fontSizes,
                            supportCustomValues: true
                        },
                        image: {
                            toolbar: [
                                'imageTextAlternative',
                                'toggleImageCaption',
                                'imageStyle:inline',
                                'imageStyle:block',
                                'imageStyle:side',
                                'linkImage',
                                'imageResize'
                            ]
                        },
                        toolbar: [
                            'fontSize','bold', 'italic', 'underline', 'strikethrough','superscript', 'subscript', '|',
                            'numberedList', 'bulletedList', '|',
                            'outdent', 'indent', '|',
                            'imageSelector', '|',
                            'link','insertTable', '|',
                            'alignment', 'removeFormat', '|',
                            'undo', 'redo', '|',
                            'blockQuote', '|',
                            'horizontalLine',  '|',
                            'findAndReplace',  '|',
                        ],
                        imageSelector: {
                            onButtonPress: (insertImageCallback: any) => {
                                insertImageCallbackRef.current = insertImageCallback;
                                setOpenImagePicker(true);
                            }
                        }
                    }}
                />
                <Grid sx={{textAlign: 'right'}}>
                    <Typography component="div" sx={{ color: maxLength && numCharacter >= maxLength ? 'red' : ''}} hidden={!maxLength} variant="caption">{`${numCharacter} of ${maxLength}`}</Typography>
                </Grid>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <TextField
                    id="Raw source"
                    label="Raw source"
                    sx={{width: width??'550px', height: height??'100%'}}
                    multiline
                    fullWidth
                    maxRows={40}
                    onBlur={(inputEl) => setUpdateFieldValue(pretty(inputEl.target.value))}
                    defaultValue={defaultValue}
                />
            </TabPanel>
            <PickImage
                open={openImagePicker}
                fieldName={'Tester'}
                cancel={() => setOpenImagePicker(false)}
                object={'activity'}
                _id={activity._id}
                afterUpload={(filePath: string) => {
                    if (insertImageCallbackRef.current) insertImageCallbackRef.current(`${REACT_APP_UPLOAD_BUCKET}${filePath}`);
                    setOpenImagePicker(false);
                }}
            />
        </Box>
    )
}

export const EditDialog = ({open, setOpen, defaultValue, setFieldValue, maxLength, fieldId}: EditDialogInput) => {
    const [value, setValue] = React.useState('');

    const handleQuillChange = (event: any) => {
        setFieldValue(value);
        setValue('');
        setOpen(false);
    }
    const handleQuillClose = (event: any) => {
        setValue('');
        setOpen(false);
    }
    return (
        <Dialog
            open={open}
            maxWidth="md"
            disableEnforceFocus={true}
            TransitionComponent={Transition}
        >
            <DialogTitle sx={{color: 'black'}}>Edit</DialogTitle>
                <EditQuill maxLength={maxLength} onChange={setValue} defaultValue={defaultValue} fieldId={fieldId}/>
            <DialogActions>
                <Button onClick={handleQuillClose}>Close</Button>
                <Button onClick={handleQuillChange}>Save & Close</Button>
            </DialogActions>
        </Dialog>
    )
}
export const EditTextDialog = ({open, setOpen, defaultValue, setFieldValue}: EditDialogInput) => {
    const [value, setValue] = React.useState('');

    const handleChange = (event: any) => {
        setFieldValue(value);
        setValue('');
        setOpen(false);
    }
    const handleClose = (event: any) => {
        setValue('');
        setOpen(false);
    }
    return (
        <Dialog
            open={open}
        >
            <DialogTitle sx={{color: 'black'}}>Edit Text</DialogTitle>
            <TextField
                id="Text"
                label="Text..."
                sx={{width: '450px', ml: '10px', mr:'10px'}}
                fullWidth
                variant="standard"
                onBlur={(inputEl) => setValue(inputEl.target.value)}
                defaultValue={defaultValue}
            />
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleChange}>Save & Close</Button>
            </DialogActions>
        </Dialog>
    )
}
export const EditFieldFullscreen = ({open, setOpen, defaultValue, setFieldValue, label}: EditDialogInput) => {
    const [updateFieldValue, setUpdateFieldValue] = React.useState('');

    const handleQuillChange = (event: any) => {
        setFieldValue(updateFieldValue);
        setUpdateFieldValue('');
        setOpen(false);
    }
    const handleQuillClose = (event: any) => {
        setUpdateFieldValue('');
        setOpen(false);
    }
    useEffect(() => {
        setUpdateFieldValue(defaultValue);
    }, [open])
    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
        >
            <DialogTitle sx={{color: 'black'}}>Edit</DialogTitle>
            <Box sx={{ width: '100vw' }}>
                <TextField
                    id={label?.replaceAll(" ","")}
                    label={label}
                    sx={{width: '100%'}}
                    multiline
                    fullWidth
                    maxRows={40}
                    onBlur={(inputEl) => setUpdateFieldValue(inputEl.target.value)}
                    defaultValue={defaultValue}
                />
            </Box>

            <DialogActions>
                <Button onClick={handleQuillClose}>Close</Button>
                <Button onClick={handleQuillChange}>Save & Close</Button>
            </DialogActions>
        </Dialog>
    )
}

interface EditDialogInput {
    open: boolean;
    maxLength?: number;
    defaultValue: string;
    setOpen: any;
    setFieldValue: any;
    label?: string;
    fieldId: string;
}

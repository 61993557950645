import React, {useContext} from "react";
import {AppContext} from "../AppContext";
import {Activities} from "./activities";
import {Fragment} from "../modules/Fragment";

export const Home = () => {
    const {user} = useContext(AppContext);
    return (
        <React.Fragment>
            {
                ['Editor', 'Administrator', 'Standard'].includes(user?.role??'') && <Activities repEmail={false} />
            }
            {
                user?.role === 'Regulatory' && <Fragment />
            }
        </React.Fragment>


    )
}

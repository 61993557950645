import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import {ComboBox} from "../../../../components/FormElements";

import {EventInputType} from "./index";
import {ActivityContext} from "../../ActivityContext";


export const RepEmail = ({listOfValues}: EventInputType) => {
    const {activity, setActivityPayload, isReadOnly, isArchived, isEditor}  = useContext(ActivityContext);

    return (
        <React.Fragment>
            {
                activity?.templateSuite?.type === "RepEmail" &&
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ComboBox
                        options={listOfValues.brand}
                        multiple={false}
                        required={false}
                        label="Brand"
                        placeholder="Select brand"
                        disabled={isArchived || !isEditor}
                        value={activity.brand && {value: activity.brand}}
                        onChange={(e: any, val: any) => {
                            setActivityPayload({refreshActivity: true, payload: {brand: val.value}});
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <ComboBox
                        options={listOfValues.brand.find( (lb: any) => lb.value === activity.brand)?.product}
                        multiple={false}
                        required={true}
                        label="Product"
                        placeholder="Select product"
                        disabled={isArchived || !isEditor}
                        value={activity.product && {value: activity.product}}
                        onChange={(e: any, val: any) => {
                            setActivityPayload({refreshActivity: true, payload: {product: val.value}});
                        }} />
                </Grid>
            </Grid>
            }
        </React.Fragment>
    );
}

import {compareObjects} from "../../../../components/CompareObjects";

export interface handleTemplateFieldFieldType {
    field: TemplateFieldType;
    action: 'Delete' | 'Update' | 'Create'
    isDeleted?: boolean;
    template: TemplateType;
    country?: string;
}
export interface handleTemplateFieldType {
    fields: handleTemplateFieldFieldType[];
    setActivityPayload: (payload: any) => void;
}

export const parseTemplateFieldPayload = async (
    {
        fields,
        setActivityPayload
    }: handleTemplateFieldType) => {
    setActivityPayload({
        refreshActivity: true,
        templateFieldPayload: fields.filter( (field: handleTemplateFieldFieldType) => compareObjects(field.template.fields.find( (f: TemplateFieldType) => f.name === field.field.name)??{}, field.field).length || field.isDeleted).map( (field: handleTemplateFieldFieldType) => {
            return {
                action: field.action,
                isDeleted: field.isDeleted,
                templateId: field.template._id,
                fieldName: field.field.name,
                field: field.field,
                country: field.country,
                diff: compareObjects(field.template.fields.find( (f: TemplateFieldType) => f.name === field.field.name), field.field)
            }
        })
    });

}
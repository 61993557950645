import {Autocomplete, TextField} from "@mui/material";
import React from "react";

// @ts-ignore
export const ComboBox = ({label, placeholder, onChange, options, value, disabled, required, multiple, fullWidth, sx}: ComboBoxInputTypes) => {
    if ( typeof multiple === 'undefined') {
        multiple = true;
    }
    try {
        return (
            <Autocomplete
                multiple={multiple}
                fullWidth={fullWidth??true}
                sx={sx?? {}}
                options={options??[]}
                value={value}
                disabled={disabled}
                getOptionLabel={(option) => !option?.name ? option.value : option.name}
                filterSelectedOptions
                isOptionEqualToValue={(option, value) => {
                    return option.value === value?.value;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        disabled={disabled}
                        placeholder={placeholder}
                        required={required}
                        variant="standard"
                    />
                )}
                onChange={onChange}
            />
        )
    } catch ( e:any ) {
        console.error(label, e.toString(), e);
    }
    return (<span/>)
}
interface ComboBoxInputOptionsTypes {
    value: string;
    name?: string;
}
interface ComboBoxInputTypes {
    options: ComboBoxInputOptionsTypes[] | undefined;
    value: ComboBoxInputOptionsTypes[] | any;
    label: string;
    placeholder: string;
    onChange: any;
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    fullWidth?: boolean;
    sx?: object;
}

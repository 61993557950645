import axios from "axios";
import {getIdToken} from "./auth/azureAuthProvider";
const {REACT_APP_ERROR_API} =  process.env;

export const sendErrorMessage = async(error: any, script: string, friendlyErrorMessage: string, enqueueSnackbar: any, activity?: string) => {
    const headers = {
        headers: {
            'Authorization': `bearer ${await getIdToken()}`
        }
    }
    let errorMessage = error.message
    if ( error.response ) {
        errorMessage = `${errorMessage}, [${JSON.stringify(error.response.data)}]`
    }
    console.error(errorMessage, error.stack, script);
    enqueueSnackbar(`Error: ${friendlyErrorMessage}. Detailed: ${errorMessage}.`, {variant: 'error'});
    try {
        await axios.put(`${REACT_APP_ERROR_API}`, {errorMessage, errorStack: error.stack, script, activity}, {...headers});
    } catch ( e: any ) {
        if ( e.response ) {
            console.log(e);
            console.error(e.response.data, e.response);
        } else {
            console.error(e.toString(), e);
        }
    }

}

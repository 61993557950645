import {Box, Tab, Tabs} from "@mui/material";
import React from "react";
import PropTypes, {InferProps} from "prop-types";
import {Template} from "./Template";
import {TabPanel} from "../../../components/TabPanel";

function a11yProps(index: number) {
    return {
        id: `template-tab-${index}`,
        'aria-controls': `template-tabpanel-${index}`,
    };
}
// @ts-ignore
export const TemplateTabs = ({templateSuite, handleTemplateUpdate, addNewTemplate}: InferProps<typeof TemplateTabs.propTypes>) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                    {// @ts-ignore
                        templateSuite.templates ? templateSuite.templates.map( (template, index) => {
                        return (
                            <Tab key={!template._id ? 'new_template' : template._id} label={template.type} {...a11yProps(index)} />
                        )
                    }) : ''}
                    <Tab key={"add_new"} sx={{fontSize: '30px'}} label={"+"} {...a11yProps(9999)} onClick={addNewTemplate} />
                </Tabs>
            </Box>
            {// @ts-ignore
                templateSuite.templates ? templateSuite.templates.map( (template, index) => {
                return (
                    <TabPanel value={value} index={index} key={template._id} >
                        <Template template={template} handleTemplateUpdate={handleTemplateUpdate} key={template._id} templateSuite={templateSuite} />
                    </TabPanel>
                )
            }) : ''}
        </Box>
    )
}
TemplateTabs.propTypes = {
    templateSuite: PropTypes.object.isRequired,
    handleTemplateUpdate: PropTypes.func.isRequired,
    addNewTemplate: PropTypes.func.isRequired,

};

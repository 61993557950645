import React, {useContext} from "react";
import {ActivityContext} from "../../ActivityContext";
import {SendEmailComponent} from "../SendEmailComponent";


export const SendReminder: any = () => {
    const {activity, participants, isEmbedded, isEditor}  = useContext(ActivityContext);
    const customerWith3OrMoreReminders = participants.filter( (participant: ParticipantType) => !participant.registrationDone &&  participant?.invitationReminders?.length > 2);
    const customerWith2Reminders = participants.filter( (participant: ParticipantType) => !participant.registrationDone &&  participant?.invitationReminders?.length === 2);
    const customerWith1Reminders = participants.filter( (participant: ParticipantType) => !participant.registrationDone && participant?.invitationReminders?.length === 1);
    const customerWith0Reminders = participants.filter( (participant: ParticipantType) =>  !participant.registrationDone && participant.invitationSent && !participant?.invitationReminders?.length);

    // @ts-ignore
    return (
        <SendEmailComponent
            srcDoc={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL INVITATION').compiledHtml}
            subject={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL INVITATION').subject}
            action={'SEND_INVITATION_REMINDER'}
            emailTypeName={'invitationReminderSent'}
            participantTabs={{
                tab1: {
                    label: 'Reminder not sent ',
                    title: 'Reminder not sent',
                    canSendEmail: true,
                    canAddParticipant: false,
                    canDeleteParticipant: false,
                    canSelectCustomers: true,
                    participants: customerWith0Reminders,
                    showField: 'invitationSent',
                    freeActionTitle: 'Send reminder to all the selected participants?',
                    actionTitle: 'Send reminder',
                    display: !isEmbedded
                },
                tab2: {
                    canSendEmail: true,
                    canAddParticipant: true,
                    canDeleteParticipant: false,
                    canSelectCustomers: true,
                    label: '1st reminder',
                    participants: customerWith1Reminders,
                    showField: 'invitationSent',
                    title: '1st reminder sent',
                    freeActionTitle: 'Send reminder to all the selected participants?',
                    actionTitle: 'Send reminder',
                    display: !isEmbedded
                },
                tab3: {
                    canSendEmail: true,
                    canAddParticipant: true,
                    canDeleteParticipant: false,
                    canSelectCustomers: true,
                    label: '2nd reminder',
                    participants: customerWith2Reminders,
                    showField: 'invitationSent',
                    title: '2nd reminder sent',
                    freeActionTitle: 'Send reminder to all the selected participants?',
                    actionTitle: 'Send reminder',
                    display: !isEmbedded
                },
                tab4: {
                    canSendEmail: true,
                    canAddParticipant: true,
                    canDeleteParticipant: false,
                    canSelectCustomers: true,
                    label: '3rd reminder',
                    participants: customerWith3OrMoreReminders,
                    showField: 'invitationSent',
                    title: '3rd reminder sent',
                    display: !isEmbedded
                },
                isReadOnly: !isEditor
            }
            }
        />
    );
}

import {createContext} from 'react'

interface AppContextType {
    user?: UserType;
    isEmbedded?: boolean;
    setHideDrawer?: any;
    setAppHeader?: any;
    setActions?: any;
    actions?: ActionTypes;
    setWaitingForBackendMessage?: any;
    userHasRole: (roles: string[]) => boolean;
    userHasCountries: (countries: string[]) => boolean;
    getLOV: (type: string, parent?: string, country?: string, onlyReturnValue?: boolean) => ListOfValueType[] | Pick<ListOfValueType, 'value'>[];
}
export const AppContext = createContext<AppContextType>({
    userHasRole: (roles: string[]) => false,
    userHasCountries: (countries: string[]) => false,
    getLOV: () => {
        return [];
    },
});

import axios from "axios";
const {REACT_APP_UPLOAD_BUCKET} = process.env;
const formatSpeakersField = (speaker: any, fieldName: string) => {
    if ( fieldName === 'email') {
        return speaker[fieldName] ? `<tr><td class="${fieldName}"><a mailto:${speaker[fieldName]}><span>${speaker[fieldName]}</span></td></tr>` : ''
    }
    return speaker[fieldName] ? `<tr><td class="${fieldName}">${speaker[fieldName]}</td></tr>` : ''
}
export const formatSpeakers = async (speakers: SpeakerType[]) => {
    let retVal = '<tbody>';
    for ( const speaker of speakers ) {
        const image = `<tr><td><img onerror="this.style.display='none'" src="${REACT_APP_UPLOAD_BUCKET}${speaker.image ? `${speaker.image}` : `speaker/${speaker._id}.png`}" width="114" height="114"/></td></tr>`;
        retVal = `${retVal}
                    <tr>
                        <td class="speakerContainer" style="text-align: center">
                            <table>
                                <tbody>
                                ${image}
                                    <tr>
                                        <td class="SpeakerName">
                                           ${speaker.fullname}
                                       </td>
                                   </tr>
                                   ${formatSpeakersField(speaker, 'title')}
                                   ${formatSpeakersField(speaker, 'company')}
                                   ${formatSpeakersField(speaker, 'email')}
                                   ${formatSpeakersField(speaker, 'phone')} 
                               </tbody>
                           </table>
                        </td>
                    </tr>
`
    }
    return `${retVal}</tbody>`
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
interface ConfirmPromptType {
    show: boolean;
    onConfirm: () => void;
    onClose: () => void;
    title: string;
    body: any;
    confirmText?: string;
    cancelText?: string;
}
export const ConfirmPrompt = ({show, onConfirm, title, body, onClose, confirmText = 'Yes', cancelText = 'Cancel'}: ConfirmPromptType) => {
    const [open, setOpen] = React.useState(false);

    const close = () => {
        setOpen(false);
        onClose();
    }
    const executeAction = () => {
        onConfirm();
        close();
    }
    React.useEffect( () => {
            setOpen(show)
    }, [show])
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" sx={{color: 'black'}}>
                {title}
            </DialogTitle>
            <DialogContent>
                {body}
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} autoFocus onClick={close}>
                    {cancelText}
                </Button>
                <Button variant={"contained"} onClick={executeAction} autoFocus>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>

    )
}

import {compareObjects} from "../../../../components/CompareObjects";

export interface handleTemplateSuiteChangeType {
    field: string;
    value: string;
    activity: ActivityType;
    setActivityPayload: (payload: any) => void;
}
export const handleTemplateSuite = async ({field, setActivityPayload, activity, value}: handleTemplateSuiteChangeType) => {
    setActivityPayload({
        refreshActivity: true,
        templateSuitePayload: {
            fieldName: field,
            fieldValue: value,
            diff: compareObjects(activity.templateSuite, {
                ...activity.templateSuite,
                [field]: value})

        }
    });
}
import {Api} from "../../Api";
const {REACT_APP_TEMPLATE_API} =  process.env;

export const getTemplateSuites = async (
    onlyEnabled: boolean = false,
    type?: string[],
    countries?: string[]
    ) => {
    try {
        const res = await Api({
            name: 'getTemplateSuites',
            url: `${REACT_APP_TEMPLATE_API}templates`,
            config: onlyEnabled ? {
                params: {
                    enabled: true,
                    type: JSON.stringify(type),
                    countries: JSON.stringify(countries)
                },
            } : {}
        })
        if (res) {
            return res.data;
        }

    } catch ( e: any ) {
        console.error("Cannot Get Template Suites", e.toString());
    }
}
export const getTemplateSuite = async ( id: string) => {
    try {
        const res = await Api({
            name: 'getTemplateSuite',
            url: `${REACT_APP_TEMPLATE_API}template/${id}`
        })
        if (res) {
            return res.data;
        }
    } catch ( e: any ) {
        console.error("Cannot Get Template Suite", e.toString());
    }
}
export const saveTemplate = async (templatesuite: TemplateSuiteType) => {
    try {
        const res = await Api( {
            name: 'saveActivity',
            type: 'POST',
            url: `${REACT_APP_TEMPLATE_API}template/${templatesuite._id}`,
            body: {...templatesuite, name: 'New template'}
        });
        if (res) {
            return res.data;
        }
    } catch ( e: any ) {
        console.error("Cannot Save Template", e.toString());
    }
}

export const compileApprovalCode = (templateSuite: TemplateSuiteType, approvalCode: string) => {
    // @ts-ignore
    const tmpTemplates = templateSuite.templates.map( (template: TemplateType) => {
        if( template.fields.find( field => field.name === '__src')) {
            return template;
        } else {
            let tmpHTML = template.html;
            for (const field of template.fields) {
                field.value = field.name === "__approvalCode" ? approvalCode : field.value;
                console.log("DEBUG:compileApprovalCode:mapping", field.name, field.value);
                tmpHTML = tmpHTML.replaceAll(`[[${field.name}]]`, field.value);
            }
            return {
                ...template,
                compiledHtml: tmpHTML
            }
        }
    })
    return tmpTemplates;
}

export const checkAndCreateDefaultFields = (template: TemplateType, handleTemplateUpdate: any) => {
    let fields = template?.fields;
        const defaultFields = [
            {type: 'SystemField',name: '__src', label: 'Preview src', hidden: true},
            {type: 'Image', name: '__LogoImage', label: 'Logo Image', hidden: false},
            {type: 'HTML', name: 'TopHeader', label: 'Top Header', hidden: false},
            {type: 'HTML', name: 'Header', label: 'Header', hidden: false},
            {type: 'HTML', name: 'Header2', label: 'Header 2', hidden: false},
            {type: 'HTML', name: 'Header3', label: 'Header 3', hidden: false},
            {type: 'HTML', name: 'DateTime', label: 'Date Time', hidden: false},
            {type: 'HTML', name: 'GeneralInformation', label: 'General Information', hidden: false},
            {type: 'TextBox', name: 'Firstname', label: 'First name', hidden: false},
            {type: 'TextBox', name: 'Lastname', label: 'Last name', oldTemplateFieldName: 'lastNameLabel', hidden: false},
            {type: 'TextBox', name: 'Middlename', label: 'Middle name', oldTemplateFieldName: 'middleNameLabel', hidden: true},
            {type: 'Email', name: 'Email', label: 'E-mail', oldTemplateFieldName: 'emailLabel', hidden: false},
            {type: 'TextBox', name: 'Phone', label: 'Phone', oldTemplateFieldName: 'phoneLabel', hidden: true},
            {type: 'TextBox', name: 'Site', label: 'Site', oldTemplateFieldName: 'siteLabel', hidden: false},
            {type: 'TextBox', name: 'Address', label: 'Address', oldTemplateFieldName: 'addressLabel', hidden: true},
            {type: 'TextArea', name: 'OtherInformation', label: 'Other Information', oldTemplateFieldName: 'otherInformationLabel', hidden: false},
            {type: 'HTML', name: 'LegalText', label: 'Legal text', oldTemplateFieldName: '', hidden: false},
            {type: 'Image', name: '__FooterImage', label: 'Footer Image', oldTemplateFieldName: '', hidden: false},
            {type: 'Text', name: 'Submit', label: 'Submit Label', oldTemplateFieldName: 'submitLabel', hidden: false},
            {type: 'HTML', name: 'ConfirmationText', label: 'Confirmation Text', oldTemplateFieldName: '', hidden: false},
            {type: 'HTML', name: 'ConfirmationThankYouText', label: 'Confirmation Thank You Text', oldTemplateFieldName: '', hidden: false}
        ].map( defaultField => {
            const old =  template?.fields.find((f: TemplateFieldType) => f.name === defaultField.oldTemplateFieldName) || fields.find( (f1: TemplateFieldType) => f1.name === defaultField.name);
            if (old) {
                return {
                    ...defaultField,
                    value: old.value
                }
            }

            return defaultField;
        })

            //Remove a bunch of standard fields
            const fieldsToRemove = ['submitLabel','otherInformationLabel','addressLabel','siteLabel','phoneLabel','emailLabel','middleNameLabel','lastNameLabel','firstNameLabel','header','generalInformation','EventName','QuestionLabel', 'QuestionYesLabel', 'QuestionNoLabel', '__Questions', 'notParticipateLabel', 'participateLabel', 'participateLabel', 'participateNoLabel']
            fields = fields.filter( (f: TemplateFieldType) => {
                return !fieldsToRemove.includes(f.name) && !defaultFields.find( df => df.name === f.name)
            })
            let updatedFields = [...defaultFields, ...fields];

            handleTemplateUpdate({
                ...template,
               fields: updatedFields
            })

}

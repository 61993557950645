import {getColorPreset} from "../methods";

export const applyColorPreset = (html: string, templateSuite: TemplateSuiteType, activity: ActivityType) => {
    if (!templateSuite?.colorPresets) {
        return html;
    }

    const colorPreset = getColorPreset(activity, activity.templateSuite);
    if (!colorPreset) {
        return html;
    }

    const keysToReplace = [
        'Header.Image.display',
        'Header.Image.Desktop.src',
        'Header.Image.Mobile.src',
        'Header.Logo.height',
        'Header.Logo.width',
        'Header.Subject.color',
        'Header.SubSubject.color',
        'Header.EventType.paddingTop',
        'Header.EventType.backgroundColor',
        'Header.EventLocation.backgroundColor',
        'Button.backgroundColor',
        'Agenda.backgroundColor'
    ];

    for (let val of keysToReplace) {
        try {
            html = html.replaceAll(`[[${val}]]`, eval(`colorPreset?.${val}`));
        } catch (e) {
            console.error('Something wrong with', val);
        }
    }

    return html;
}
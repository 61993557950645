import * as React from 'react';
import {TemplateSuiteCard} from "../modules/Template/components/TemplateSuiteCard";
import {useEffect} from "react";
import {getTemplateSuites} from "../modules/Template/methods";
import {TemplateSuite} from "../modules/Template";
import { useSnackbar } from 'notistack';
import {sendErrorMessage} from "../Error";
import {getTopHeader} from "../modules/Activity/methods";
import {Grid} from "@mui/material";
export const Templates = () =>   {
    const [templates, setTemplates] = React.useState<Array<TemplateSuiteType>>([{}])
    const [selectedTemplate, setSelectedTemplate] = React.useState<TemplateSuiteType>({});
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleClick = (template: TemplateSuiteType) => {
        setSelectedTemplate(template);
        setOpen(true);
    }
    useEffect( () => {
        ( async () => {
            try {
                setTemplates( await getTemplateSuites())
            } catch ( e: any ) {
                await sendErrorMessage(e,  'front/templates/methods.getTemplateSuites', "Cannot Get templates", enqueueSnackbar);
            }
        })()
    }, [])
    return (
        <Grid container>
            {templates && templates.length >0 && templates[0].brand ? templates.map( (template: TemplateSuiteType) => {
                const {imageURL, topHeader} = getTopHeader(undefined, template);
                return (
                    <TemplateSuiteCard
                        key={template._id}
                        handleClick={()=>{handleClick(template)}}
                        imageURL={imageURL ?? false}
                        topHeader={topHeader}
                        headline={`${template.brand} - ${template.type}`}
                        description={`${template.description}`}
                    ></TemplateSuiteCard>
                )
            }) : ''}
            {open ? <TemplateSuite template={selectedTemplate} open={open} setOpen={setOpen}  /> : ''}
        </Grid>

    );
}


import {Api} from "../Api";
import axios from "axios";
import {DropzoneDialog} from "material-ui-dropzone";
import * as React from "react";
import {Button} from "@mui/material";
import {useContext, useEffect} from "react";
import {useSnackbar} from "notistack";
import {createFragment} from "../modules/Fragment/methods";
import {AppContext} from "../AppContext";

export const UploadFile = ({open, uploadButtonLabel, object, _id, afterUpload, setFileSrc, fieldName, imageWidth, cancel}: UploadFileInput) => {
    const [localOpen, setLocalOpen] = React.useState(false);
    const {user, setWaitingForBackendMessage} = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const uploadFile = async (file: any) => {
        setWaitingForBackendMessage('Uploading...')
        const path = `${object}/${_id}/${fieldName}`
        const result = await Api( {
            name: 'UploadFile',
            type: 'POST',
            url: `${process.env.REACT_APP_COMMON_API}uploadToS3`,
            body: {path, ContentType: file.type}
        });
        if ( result ) {
            const options = {
                headers: {
                    'Content-Type': file.type
                }
            };
            let res;
            try {
                res =  await axios.put(result.url, file, options);
                await createFragment({
                    type: 'Image',
                    description: `Added by: ${user?.name}`,
                    status: 'Active',
                    image: result.key,
                    private: true

                })
            } catch (e: any) {
                console.error(e.toString());
                console.log(res);
            }
            setWaitingForBackendMessage('')
            return result
        }
    }
    useEffect( () => {
        setLocalOpen(open);
    }, [open])
    const handleFileDrop = async (files: string | any[]) =>{
        try {
            if (files.length>0) {
                setLocalOpen(false);
                const file = files[0];
                const img = new Image();
                const objectUrl = window.URL.createObjectURL(file);
                img.onload = function () {
                    const imageWidth = img.width;
                    if (imageWidth > 600) {
                        enqueueSnackbar(`The image width is ${imageWidth}px, which exceeds the limit of 600px. Please crop the image to a maximum of 600px and try again.`, {variant: 'error', autoHideDuration: 15000});
                        window.URL.revokeObjectURL(objectUrl);
                        return;
                    }
                    // @ts-ignore
                    if ( (file.size/1000) > 300 ) {
                        enqueueSnackbar(`The image has a size of ${(file.size/1000)} kb. That is quite big, consider using an image with a smaller size.`, {variant: 'warning', autoHideDuration: 15000})
                    }
                    window.URL.revokeObjectURL(objectUrl);
                    const reader = new FileReader();
                    reader.onload = async function (e) {
                        //Display uploaded file in iframe
                        if(file.type.indexOf('image') > -1) {
                            const res = await uploadFile(file);
                            if (setFileSrc) {
                                // @ts-ignore
                                setFileSrc(e.target.result);
                            }
                            afterUpload(res.key);
                        }
                    };
                    reader.readAsDataURL(file);
                };
                img.src = objectUrl;
            }
        } catch (e: any) {
            console.log(e.toString())
        }


    }
    return (
        <div>
            <DropzoneDialog
                showPreviews={false}
                maxFileSize={1000000}
                open={localOpen}
                showAlerts={['error']}
                filesLimit={1}
                onDrop={handleFileDrop}
                onSave={handleFileDrop}
                onClose={()=> {
                    setLocalOpen(false); cancel()}} />
            {
                uploadButtonLabel ? <Button variant="contained" component="span" onClick={()=>{setLocalOpen(true)}} sx={{mt: '20px'}}>
                    {uploadButtonLabel}
                </Button>
                    : ''
            }
        </div>

    )
}
interface UploadFileInput {
    object: string;
    fieldName: string;
    _id: string;
    afterUpload: any;
    cancel: any;
    setFileSrc?: any;
    uploadButtonLabel?: string;
    open: boolean;
    imageWidth?: any;
    maxHeight?: number;
}


export const handlePayload =  (payload: PayloadType, activity: ActivityType) => {
    payload?.templateFieldPayload?.forEach( (payloadInstance: TemplateFieldPayloadInstanceType) => {
        const {fieldName, field, templateId, country} = payloadInstance;
        if ( activity.templateSuite.templates) {
            switch (payloadInstance.action) {
                case 'Create':
                    activity.templateSuite.templates = activity.templateSuite.templates.map(template => {
                        if (template._id === templateId) {
                            if (country) {
                                template.countryVersion = template.countryVersion.map(cv => {
                                    if (cv.country === country) {
                                        cv.fields.push(field);
                                    }
                                    return cv;
                                })
                            } else {
                                template.fields.push(field);
                            }
                        }
                        return template;
                    })
                    break;
                case 'Delete':
                    activity.templateSuite.templates = activity?.templateSuite?.templates.map(template => {
                        if (template?._id === templateId) {
                            template.fields = template.fields.filter(f => f.name !== fieldName);
                        }
                        return template;
                    })
                    break;
                case 'Update':
                    activity.templateSuite.templates = activity.templateSuite.templates.map(template => {
                        if (template._id === templateId) {
                            if (country) {
                                template.countryVersion = template.countryVersion.map(cv => {
                                    if (cv.country === country) {
                                        cv.fields = cv.fields.map(f => {
                                            if (f.name === fieldName) {
                                                f = field;
                                            }
                                            return f;
                                        })
                                    }
                                    return cv;
                                })
                            } else {
                                template.fields = template.fields.map(f => {
                                    if (f.name === fieldName) {
                                        f = field;
                                    }
                                    return f;
                                })
                            }
                        }
                        return template;
                    })
                    break;
            }
        }
    })
    if (payload.templatePayload) {
        const {fieldName, fieldValue, templateId, country} = payload.templatePayload;
        activity.templateSuite.templates = activity?.templateSuite?.templates?.map( template => {
            if (template?._id?.toString() === templateId) {
                if (country) {
                    template.countryVersion = template.countryVersion.map( cv => {
                        if (cv.country === country) {
                            // @ts-ignore
                            cv[fieldName] = fieldValue;
                        }
                        return cv;
                    })
                } else {
                    // @ts-ignore
                    template[fieldName] = fieldValue;
                }
            }
            return template;
        })
    }
    if (payload.templateSuitePayload) {
        const {fieldName, fieldValue} = payload.templateSuitePayload;
        activity = {
            ...activity,
            templateSuite: {
                ...activity.templateSuite,
                [fieldName]: fieldValue
            }
        }
    }
    if (payload.payload) {
        activity = {
            ...activity,
            ...payload.payload
        }
    }
    return activity;
}
import React, {useContext, useEffect, useRef} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, IconButton, Paper, Popover, Switch, TextField, Tooltip, Typography,

} from "@mui/material";

import {Thread} from "./Thread";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import {EditQuill} from "../../../../../components/EditDialog";
import {TemplateContext} from "../TemplateContext";
// Fix för worker



export const Assistant = ({openDialog, setOpenDialog, html}: {openDialog: boolean, setOpenDialog: any, html: string}) => {
    const {template} = useContext(TemplateContext);
    const [value, setValue] = React.useState(0);
    const [initiateReview, setInitiateReview] = React.useState(false);
    const [pdfUrl, ] = React.useState<string | undefined>();

    const handleClose = () => {
        // Här skulle du anropa din backend för att avbryta granskningen
        // Lägg till svar från backend i reviewHistory för respektive steg
        setOpenDialog(false);
        setValue(0);
    };

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth
            open={openDialog}
            onClose={handleClose}
        >
            <DialogTitle color={'black'}>Review center</DialogTitle>
            <DialogContent >
                <Paper elevation={5} sx={{padding: 2}}>
                    <Thread externalId={template._id!} reviewStep={value} initiateReview={initiateReview} setInitiateReview={setInitiateReview} template={template} pdfUrl={pdfUrl} html={html} assistant={'Text'} />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}

export const TextAssistant = ({ckEditor, html, fieldId}: { ckEditor: any, html: string, fieldId: string}) => {
    const {template} = useContext(TemplateContext);
    const [value, setValue] = React.useState(0);
    const [initiateReview, setInitiateReview] = React.useState(false);
    const [pdfUrl, ] = React.useState<string | undefined>();
    const [openAssistant, setOpenAssistant] = React.useState(false);
    const anchorRef = useRef(null);
    const handleClose = () => {
        // Här skulle du anropa din backend för att avbryta granskningen
        // Lägg till svar från backend i reviewHistory för respektive steg
        setOpenAssistant(false);
        setValue(0);
    };
    useEffect(() => {
        if(!openAssistant) return;

    }, [openAssistant]);
    return (
        <React.Fragment>
            <Tooltip title="AI Assistant">
                <IconButton onClick={()=> setOpenAssistant(true)} disabled={process.env.REACT_APP_ENV === 'prod'}  ref={anchorRef}>
                    <SmartToyIcon  sx={{fontSize: 30}}/>
                </IconButton>
            </Tooltip>
            <Popover
                open={openAssistant}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box  >
                    <Paper elevation={5} sx={{maxWidth: '600px', minWidth: '600px', m: 3}}>
                        <Thread externalId={`${template._id}_${fieldId}`} reviewStep={value} initiateReview={initiateReview} setInitiateReview={setInitiateReview} template={template} pdfUrl={pdfUrl} html={html} assistant={'Text'} />
                    </Paper>
                </Box>
                <Box p={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleClose}>Close</Button>
                </Box>
            </Popover>
        </React.Fragment>

    )

}
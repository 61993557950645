import React, {useContext} from 'react';
import {Box, Button, TextField, Avatar as muiAvatar, Grid, FormControlLabel} from "@mui/material";
import Avatar from 'react-avatar-edit'
import {useSnackbar} from "notistack";
import {Api} from "../../Api";
import {sendErrorMessage} from "../../Error";
import {styled} from "@mui/material/styles";
import {ComboBox} from "../FormElements";
import {AppContext} from "../../AppContext";
import Checkbox from "@mui/material/Checkbox";
const {REACT_APP_USER_API, REACT_APP_UPLOAD_BUCKET} =  process.env;

const StyledAvatar = styled(muiAvatar)(({ theme }) => ({
    width: theme.spacing(15),
    height: theme.spacing(15),
}))
interface UpsertUserType {
    user: UserType | undefined;
    setUser?: any;
    setOpen: any;
}
export const UpsertUser = ({user, setOpen, setUser}: UpsertUserType) => {
    const {userHasRole} = useContext(AppContext);
    const [imageBuffer, setImageBuffer] = React.useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [roles, setRoles] = React.useState(user?.roles?.map( val => {
        return {
            value: val
        }
    }))
    const handleSubmit = async (event: React.SyntheticEvent) => {
        try {
            event.preventDefault();

            const target = event.target as typeof event.target & {
                email: { value: string };
                name: { value: string };
                phone: { value: string };
                title: { value: string };
                active: { checked: boolean };
            }
            if ( user ) {
                const tmpUser = {
                    ...user,
                    email: target.email.value,
                    name: target.name.value,
                    phone: target.phone.value,
                    title: target.title.value,
                    roles: roles?.map( (role: any) => role.value),
                    active: target?.active?.checked??true,
                    imageBuffer
                }
                const result = await Api({
                    name: 'Users::handleSubmit',
                    type: 'POST',
                    url: `${REACT_APP_USER_API}user/${tmpUser._id}`,
                    body: tmpUser
                })
                setUser(result.result);
                setOpen(false);
            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/user/handleSubmit', "Cannot save user", enqueueSnackbar);
        }
    }
    if ( user ) {
        return (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <Box sx={{ width: 250, marginTop: '10px' }}>
                    <Grid container>
                        <Grid item xs={7}>
                            <StyledAvatar alt="Remy Sharp" src={`${REACT_APP_UPLOAD_BUCKET}${user.image ? `${user.image}` : `user/${user._id}.png`}`}  />
                        </Grid>
                        <Grid item xs={5}>
                            <Avatar
                                width={200}
                                height={155}
                                exportSize={240}
                                label='Click/drag to upload new image'
                                labelStyle={{fontSize: 13, wordWrap: 'break-word'}}
                                onCrop={(preview)=> {
                                    setImageBuffer(preview)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <TextField
                    autoFocus
                    required
                    fullWidth
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    variant="standard"
                    defaultValue={user.name}
                />
                <TextField
                    required
                    margin="dense"
                    fullWidth
                    id="title"
                    label="Title"
                    type="text"
                    variant="standard"
                    defaultValue={user.title}
                />
                <TextField
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    variant="standard"
                    defaultValue={user.email}
                    sx={{marginRight: '10px', width: 'calc(50% - 5px)'}}
                />
                <TextField
                    margin="dense"
                    id="phone"
                    label="Phone"
                    type="phone"
                    variant="standard"
                    defaultValue={user.phone}
                    sx={{width: 'calc(50% - 5px)'}}
                />
                <ComboBox
                    multiple={true}
                    disabled={!userHasRole(['Administrator'])}
                    options={[{value: 'Administrator'}, {value: 'Regulatory'}, {value: 'Standard'}, {value: 'Editor'}, {value: 'RepEmail Editor'}, {value: 'KAM'}]}
                    label="Roles"
                    placeholder="Select roles"
                    value={roles}
                    onChange={(e: any, val: any) => {
                        setRoles(val)
                    }} />
                {
                    userHasRole(['Administrator']) && <FormControlLabel control={<Checkbox name={'active'} id={'active'} defaultChecked={user.active} />} label="Is active" />
                }

                <div style={{ textAlign: 'right', padding: 8, margin: '24px -24px -24px -24px' }}>
                    <Button
                        type="reset"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, mr: 2 }}
                        onClick={()=> {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>
                </div>
            </Box>
        )
    } else {
        return (<div/>);
    }

}


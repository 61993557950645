import {Api} from "../../Api";
const {REACT_APP_TEMPLATE_API} =  process.env;
export const createFragment = async (payload: FragmentType) => {
    try {
        const res = await Api({
            name: 'createFragment',
            type: 'POST',
            url: `${REACT_APP_TEMPLATE_API}/fragment`,
            body: payload
        })
        if (res) {
            return res
        }

    } catch ( e: any ) {
        console.error("Cannot create Fragment", e.toString());
    }
}
export const deleteFragmentRequest = async (fragmentId: string) => {
    try {
        const res = await Api({
            name: 'deleteFragment',
            type: 'DELETE',
            url: `${REACT_APP_TEMPLATE_API}/fragment/${fragmentId}`
        })
        if (res) {
            return res
        }

    } catch ( e: any ) {
        console.error("Cannot delete Fragment", e.toString());
    }
}
export const cloneFragment = async (fragmentId: string) => {
    try {
        const res = await Api({
            name: 'cloneFragment',
            type: 'POST',
            url: `${REACT_APP_TEMPLATE_API}/fragment/${fragmentId}`
        })
        if (res) {
            return res
        }
    } catch ( e: any ) {
        console.error("Cannot copy Fragment", e.toString());
    }
}

export const updateFragment = async ( fragmentId: string, payload: FragmentType) => {
    if ( fragmentId && payload ) {
        const fragment = await Api( {
            name: 'updateFragment',
            type: 'PATCH',
            url: `${REACT_APP_TEMPLATE_API}fragment/${fragmentId}`,
            body: payload
        });
        if ( fragment ) {
            return fragment;
        }

    }
    return false;
}
export const activateFragmentRequest = async ( fragmentId: string) => {
    if ( fragmentId ) {
        const fragment = await Api( {
            name: 'activateFragment',
            type: 'PATCH',
            url: `${REACT_APP_TEMPLATE_API}activatefragment/${fragmentId}`
        });
        if ( fragment ) {
            return fragment;
        }

    }
    return false;
}
export const getFragments = async () => {
    try {
        const res = await Api({
            name: 'getFragments',
            url: `${REACT_APP_TEMPLATE_API}fragments`
        })
        if (res) {
            return res.data;
        }
    } catch ( e: any ) {
        console.error("Cannot Get Fragment", e.toString());
    }

}
export const getFragment = async ({type, country, brand, product, jointEvent, print, repEmail}: {type: string, country: string, brand?: string, product?: string, jointEvent?: boolean, print?: boolean, repEmail?: boolean}) => {
    try {
        const res = await Api({
            name: 'getFragment',
            url: `${REACT_APP_TEMPLATE_API}fragment`,
            config: {
                params: {
                    country,
                    brand,
                    product,
                    type,
                    jointEvent,
                    print,
                    repEmail,
                    status: 'Active'
                },

            }
        })
        if (res) {
            return res.data;
        }
    } catch ( e: any ) {
        console.error("Cannot Get Fragment", e.toString());
    }
}
export const getRegulatoryText = async ({type, country, brand, product}: {type: string, country: string, brand: string, product: string}) => {
    try {
        return await getFragment({type, country, brand, product})
    } catch ( e: any ) {
        console.error("Cannot Get Regulatory Text", e.toString());
    }
}
export const getFooter = async ({jointEvent, country, repEmail}: {jointEvent: boolean, country: string, repEmail: boolean}) => {
    try {
        return await getFragment({type: 'Footer', country, jointEvent, repEmail})
    } catch ( e: any ) {
        console.error("Cannot Get Footer Text", e.toString());
    }
}
export const getImageFragments = async () => {
    try {
        const res = await Api({
            name: 'getImageFragments',
            url: `${REACT_APP_TEMPLATE_API}fragments`,
            config: {
                params: {
                    type: 'Image',
                    status: 'Active'
                },

            }
        })
        console.log(res.data);
        if (res) {
            return res.data;
        }
    } catch ( e: any ) {
        console.error("Cannot Get Fragment", e.toString());
    }

}
export const getLogoFragments = async () => {
    try {
        const res = await Api({
            name: 'getLogoFragments',
            url: `${REACT_APP_TEMPLATE_API}fragments`,
            config: {
                params: {
                    type: 'Logo',
                    status: 'Active'
                },

            }
        })
        console.log(res.data);
        if (res) {
            return res.data;
        }
    } catch ( e: any ) {
        console.error("Cannot Get Fragment", e.toString());
    }

}

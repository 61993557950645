import PropTypes, {InferProps} from "prop-types";
import React, {useEffect} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
    Button,
    Grid,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ComboBox} from "../../../components/FormElements";
import {checkAndCreateDefaultFields} from "../methods";
import { getLOVFull} from "../../ListOfValue/getLOV";

export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto',
    marginTop: 'o0px',
    flexDirection: 'column',
    width: '800px',
    height: '99%'
}))
// @ts-ignore
export const Template = ({template, handleTemplateUpdate, templateSuite}: InferProps<typeof Template.propTypes>) => {
    const [listOfValues] = React.useState({
        type: [
            {value: 'TextBox'},
            {value: 'Email'},
            {value: 'TextArea'},
            {value: 'SystemField'},
            {value: 'Text'},
            {value: 'CTA'},
            {value: 'HTML'},
            {value: 'Image'},
            {value: 'Snippet'},
            {value: 'Color'},
            {value: 'Color (RGB)'}
        ]
    });
    const countries: any = getLOVFull('country').map((v: any) => ({name: v.description, value: v.value}));
    const [previewHTML, setPreviewHTML] = React.useState('');
    const [previewSRC, setPreviewSRC] = React.useState('');
    const addField = () => {
        handleTemplateUpdate({
            ...template,
            fields: [...template.fields, {name: 'New Field', value: "new Value"}]
        }, true);
    }
    const addDefaultFields = () => {
        checkAndCreateDefaultFields(template, handleTemplateUpdate);
    }
    const handleChange = (event: any) => {
        const splitString = event.target.id.split("-");
        handleTemplateUpdate({
            ...template,
            fields: template.fields.map( (field: TemplateFieldType ) => {
                return field._id === splitString[1] ? {...field, [splitString[2]]: event.target.value} : field
            })
        })
    }
    const handleHTMLChange = (event: any) => {
        handleTemplateUpdate({
            ...template,
            html: event.target.value
        })
    }
    const handleTemplateTypeChange = (event: any) => {
        handleTemplateUpdate({
            ...template,
            type: event.target.value
        })

    }

    useEffect( () => {
        if (previewSRC) {
            //This should be previewed in an external site
            // @ts-ignore
            let iframe = document.getElementById('templatePreview').contentWindow;
            iframe.postMessage({type: "updatePreview", fields: template.fields}, "*");
        } else {
            let tmpHTML = template.html;
            if (templateSuite?.colorPresets) {
                const colorPresets = templateSuite?.colorPresets && JSON.parse(templateSuite?.colorPresets);
                const colorPresetBrand = colorPresets.brands[templateSuite.brand];
                const colorPreset = colorPresetBrand?? colorPresets.generic;
                if (colorPreset?.headerFont) {
                    tmpHTML = tmpHTML.replaceAll('[[ColorPreset.headerFont]]', colorPreset.headerFont);
                    tmpHTML = tmpHTML.replaceAll('[[ColorPreset.subHeaderFont]]', colorPreset.subHeaderFont);
                    tmpHTML = tmpHTML.replaceAll('[[ColorPreset.eventTypeBackground]]', colorPreset.eventTypeBackground);
                    tmpHTML = tmpHTML.replaceAll('[[ColorPreset.eventLocationBackground]]', colorPreset.eventLocationBackground);
                    tmpHTML = tmpHTML.replaceAll('[[ColorPreset.button]]', colorPreset.button);
                    tmpHTML = tmpHTML.replaceAll('[[ColorPreset.eventAgendaBackground]]', colorPreset.eventAgendaBackground);
                    tmpHTML = tmpHTML.replaceAll('[[ColorPreset.headerLogoRightDisplay]]', colorPreset.headerLogoRightDisplay);
                    tmpHTML = tmpHTML.replaceAll('[[ColorPreset.headerLogoRightDesktopSrc]]', colorPreset.headerLogoRightDesktopSrc);
                    tmpHTML = tmpHTML.replaceAll('[[ColorPreset.eventTypeTopPadding]]', colorPreset.eventTypeTopPadding);

                }
            }

            //First set __ fields
            if (template.fields.find((field: TemplateFieldType) => field.name === '__backgroundWithDateTime'))
                tmpHTML = tmpHTML.replaceAll('[[__backgroundWithDateTime]]', template.fields.find((field: TemplateFieldType) => field.name === '__backgroundWithDateTime').value);
            template.fields.filter( (field: TemplateFieldType) => field.type === 'Image').map( (field: TemplateFieldType) => {
                let fieldValue = field.value;
                tmpHTML = tmpHTML.replaceAll(`[[${field.name}]]`, `<img src="${fieldValue}"/>`);
            });
            template.fields.filter( (field: TemplateFieldType) => field.name.search('__') > -1).map( (field: TemplateFieldType) => {
                tmpHTML = tmpHTML.replaceAll(`[[${field.name}]]`, field.value);
            });
            // @ts-ignore
            template.fields.map( (field: TemplateFieldType) => {
                tmpHTML = tmpHTML.replaceAll(`[[${field.name}]]`, field.value);
            });
            setPreviewHTML(tmpHTML);
        }
    }, [template.html,template.fields, templateSuite.brand, templateSuite.colorPresets])
    useEffect( () => {
        const __src =template.fields.find( (field: TemplateFieldType) => field.name === '__src');
        if ( __src ) {
            //This should be previewed in an external site
            setPreviewSRC(__src.value);
            let iframe = document.getElementById('templatePreview');
            // @ts-ignore
            iframe.removeAttribute("srcDoc");
            setTimeout( () => {
                // @ts-ignore
                iframe?.contentWindow?.postMessage({type: "updatePreview", fields: template.fields}, "*");
            }, 1000)

        }
    }, [])
    return (
    <React.Fragment key={template._id}>
        <CssBaseline />
        <Grid container sx={{flexGrow: 1, maxHeight: '68vh', overflow: 'auto'}} spacing={2}>
            <Grid item xs={6}>
                <Paper  >
                    <TextField
                        required
                        id={`${template._id}-name`}
                        fullWidth
                        label="Template type"
                        defaultValue={template.type}
                        autoComplete='off'
                        onBlur={handleTemplateTypeChange}
                        minRows={1}
                        multiline
                        variant="standard"
                    /> <br/><br/><br/>
                    <TextField
                        id={template._id}
                        label="Source"
                        multiline
                        fullWidth
                        maxRows={40}
                        onBlur={handleHTMLChange}
                        defaultValue={template.html}
                    />
                </Paper>
            </Grid>
            <Grid item xs={6} >
                <Paper sx={{height:'100%'}} >
                    <iframe style={{height: '100%', width: '100%', overflow: 'hidden'}} frameBorder={0} srcDoc={previewHTML} id="templatePreview" src={previewSRC}></iframe>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{marginTop: '20px'}} >
                    <Typography sx={{color: 'black'}} variant='h5' gutterBottom>Template fields
                        <Button sx={{ml: 5, mr:5}} onClick={addField} variant="contained">Add field</Button>
                        <Button disabled={template.type !== 'REGISTRATION'} onClick={addDefaultFields} variant="contained">Add default fields</Button>
                    </Typography>
                    {// @ts-ignore
                        template.fields.sort((prev: TemplateFieldType, curr: TemplateFieldType) => prev.sort - curr.sort).map( field => {
                            return (
                                <Grid key={field._id} container spacing={2}>
                                    <Grid item xs={1} sx={{marginBottom: '25px'}}>
                                        <TextField
                                            required
                                            id={`${template._id}-${field._id}-name`}
                                            fullWidth
                                            label="Field name"
                                            defaultValue={field.name}
                                            autoComplete='off'
                                            onBlur={handleChange}
                                            minRows={1}
                                            multiline
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={1} sx={{marginBottom: '25px'}}>
                                        <ComboBox
                                            options={listOfValues.type}
                                            required={true}
                                            multiple={false}
                                            fullWidth={false}
                                            label="Type"
                                            placeholder="Select type"
                                            value={{value: field.type}}
                                            onChange={(e: any, val: any) => {
                                                handleTemplateUpdate({
                                                    ...template,
                                                    fields: template.fields.map( (field1: TemplateFieldType ) => {
                                                        return field1._id === field._id ? {...field1, type: val.value} : field1;
                                                    })
                                                })
                                            }} />
                                    </Grid>
                                    <Grid item xs={1}  sx={{marginBottom: '25px'}}>
                                        <TextField
                                            id={`${template._id}-${field._id}-value`}
                                            label="Value"
                                            multiline
                                            fullWidth
                                            minRows={1}
                                            maxRows={3}
                                            variant="standard"
                                            defaultValue={field.value}
                                            onBlur={handleChange}
                                        />
                                    </Grid>
                                    {
                                        countries.map( (country: any) => {
                                            return (
                                                <Grid item xs={1}  sx={{marginBottom: '25px'}}>
                                                    <TextField
                                                        id={`${template._id}-${field._id}-${country.value}-value`}
                                                        label={country.name}
                                                        multiline
                                                        fullWidth
                                                        minRows={1}
                                                        maxRows={3}
                                                        variant="standard"
                                                        //Gör en liten funktion som hämtar värdet
                                                        defaultValue={'country_value' in field && country.value in field.country_value ? field.country_value[country.value] : ''}
                                                        onBlur={(event: any) => {
                                                            handleTemplateUpdate({
                                                                ...template,
                                                                fields: template.fields.map( (f: TemplateFieldType ) => {
                                                                    if (field._id === f._id) {
                                                                        if (!f.country_value){
                                                                            f.country_value = {
                                                                                DK: '',
                                                                                FI: '',
                                                                                NO: '',
                                                                                SE: '',
                                                                                IS: '',
                                                                                US: '',
                                                                            }
                                                                        }
                                                                        // @ts-ignore
                                                                        f.country_value[country.value] = event.target.value;
                                                                    }
                                                                    return f
                                                                })
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            )
                        })}
                </Paper>
            </Grid>

        </Grid>
    </React.Fragment>
    );
}

Template.propTypes = {
    template: PropTypes.object.isRequired,
    templateSuite: PropTypes.object.isRequired,
    handleTemplateUpdate: PropTypes.func.isRequired
};

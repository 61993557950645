import React, {useEffect} from "react";
import {MenuType, StyledTreeItem} from "../components/CustomTreeView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Label from "@mui/icons-material/Label";
import MedicationIcon from "@mui/icons-material/Medication";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import TreeView from '@mui/lab/TreeView';
import EmailIcon from '@mui/icons-material/Email';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
export const Menu = ({activities, onClick, defaultSelected, addEVent}: { activities: ActivityType[] | undefined, onClick: any, defaultSelected: string, addEVent: any }) => {
    const [menu, setMenu] = React.useState<MenuType | undefined>()
    const sortMenu = (key: string, func: any) => {
        if (activities) {
            activities.sort((a: any, b: any) => (a[key] ?? '').localeCompare(b[key] ?? '')).map((f: any) => {
                func(f);
            })
        }
    }
    const beautifyName = (name: string ) => {
        if (name === 'BookingEmail') return 'Booking Email'
        if (name === 'RepEmail') return 'Rep Email'
        if (name === 'Webinar') return name;
        if (name === 'Event') return name;
        return "Meeting";
    }
    useEffect(() => {
        if (activities) {
            let tmpMenu: any = [];
            sortMenu('TemplateType', (f: any) => {
                if (f.TemplateType)
                    tmpMenu[f.TemplateType] = {};
            });
            sortMenu('brand', (f: any) => {
                if (f.TemplateType) {
                    if (['Webinar', 'Generic', 'Meeting', 'Event'].includes(f.TemplateType)) {
                        if (!Array.isArray(tmpMenu[f.TemplateType]['no_brand'])) {
                            tmpMenu[f.TemplateType]['no_brand'] = [];
                        }

                        tmpMenu[f.TemplateType]['no_brand'].push(f)
                    } else if (f.brand && f.TemplateType)
                        tmpMenu[f.TemplateType][f.brand] = [];
                }
            });
            sortMenu('subject', (f: any) => {
                if (f.brand && f.TemplateType && !['Webinar', 'Generic', 'Meeting', 'Event'].includes(f.TemplateType))
                    tmpMenu[f.TemplateType][f.brand].push(f);
            });
            setMenu(tmpMenu)
        }

    }, [activities])

    return (
        <TreeView
            aria-label="E-mail template(s)"
            defaultExpanded={['0']}
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultExpandIcon={<ArrowRightIcon/>}
            defaultEndIcon={<div style={{width: 24}}/>}
            selected={defaultSelected}
            sx={{flexGrow: 1, maxWidth: 350, mr: '40px'}}
        >
            <StyledTreeItem key={`0`} nodeId="0" labelText="E-mails" labelIcon={Label} >
                {
                    menu && Object.entries(menu).map((TemplateType: any, index: number) => {
                        return (
                            <StyledTreeItem
                                key={`${index + 1}`}
                                nodeId={`${index + 1}`}
                                labelText={`${beautifyName(TemplateType[0])}`}
                                labelIcon={EmailIcon}
                                labelInfo={`${activities?.filter((a: ActivityType) => a.TemplateType === TemplateType[0])?.length}`}
                            >
                                {
                                    Object.entries(TemplateType[1]).map((row: any, index2: number) => {
                                        const label = row[0];
                                        const object = row[1];
                                        if ( label === 'no_brand') {
                                            return (
                                                Object.entries(object).map((activity: any) => {
                                                    return (
                                                        <StyledTreeItem
                                                            key={activity[1]._id}
                                                            nodeId={activity[1]._id}
                                                            labelText={`${activity[1].subject}`}
                                                            labelIcon={CalendarMonthIcon}
                                                            onClick={() => onClick(activity[1])}
                                                        />
                                                    )
                                                })
                                            )

                                        } else {
                                            return (
                                                <StyledTreeItem
                                                    key={`${index + 1}_${index2}`}
                                                    nodeId={`${index + 1}_${index2}`}
                                                    labelText={`${label}`}
                                                    labelIcon={VaccinesIcon}
                                                    labelInfo={`${Object.entries(object).length}`}
                                                >
                                                    {
                                                        Object.entries(object).map((activity: any) => {
                                                            return (
                                                                <StyledTreeItem
                                                                    key={activity[1]._id}
                                                                    nodeId={activity[1]._id}
                                                                    labelText={`${activity[1].subject}`}
                                                                    labelIcon={MedicationIcon}
                                                                    onClick={() => onClick(activity[1])}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </StyledTreeItem>
                                            )
                                        }

                                    })
                                }
                            </StyledTreeItem>
                        )
                    })
                }
            </StyledTreeItem>
        </TreeView>
    )
}

import Grid from "@mui/material/Grid";
import {
    Autocomplete,
    Button, FormControl, FormLabel,
    IconButton, Radio, RadioGroup,
    TextField
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import React, {useContext, useEffect} from "react";
import {addParticipant, getUserCountries, sendEmails} from "../methods";
import { useSnackbar } from 'notistack';
import {sendErrorMessage} from "../../../Error";
import {EditDialog} from "../../../components/EditDialog";
import {Api} from "../../../Api";
import {AppContext} from "../../../AppContext";
import FormControlLabel from "@mui/material/FormControlLabel";
const {REACT_APP_CUSTOMER_API, REACT_APP_UPLOAD_BUCKET} =  process.env;
export const SendEmailForm = ({activityId, customerIdsInput, template, openForm, onSent, activityCustomerIds, country}: SendEmailFormInput) => {
    const getTemplateField = (countryVersion: boolean, fieldName: string) => {
        if (countryVersion)
            return template.countryVersion.find( (c: TemplateCountryVersion) => c.country === country)?.fields.find( (field: TemplateFieldType) => field.name === fieldName)?.value
        else
            return template.fields.find( (field: TemplateFieldType) => field.name === fieldName)?.value
    }
    const {setWaitingForBackendMessage, user} = useContext(AppContext);
    const [srcDoc, setSrcDoc] = React.useState('');
    // @ts-ignore
    const [emailBody, setEmailBody] = React.useState<undefined | string>(template.countryVersion.length ? getTemplateField(user?.countries[0], 'Email Body') : getTemplateField(false, 'Email Body'));
    const [customerIds] = React.useState(customerIdsInput || '')
    const [subject, setSubject] = React.useState(template.countryVersion.length ? template.countryVersion.find( (c: TemplateCountryVersion) => c.country === country)?.subject : template.subject)
    const [sendOneEmailToAll, setSendOneEmailToAll] = React.useState(false)
    const [customers, setCustomers] = React.useState([]);
    // @ts-ignore
    const [selectedCustomers, setSelectedCustomers] = React.useState<undefined | CustomerType[]>(customerIdsInput);
    const [editEmailBody, setEditEmailBody] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const send = () => {
        ( async ()=> {
            try {
                setWaitingForBackendMessage(`Sending ${selectedCustomers?.length} e-mails`);
                if (selectedCustomers) {
                    const selectedCustomerIds = selectedCustomers.map( customer => customer._id);

                        await addParticipant(activityId, selectedCustomerIds);
                        await sendEmails({
                            activityId: activityId,
                            customerIds: selectedCustomerIds,
                            action: 'SEND_REP_EMAIL',
                            country,
                            emailBody,
                            emailSubject: subject,
                            sendOneEmailToAll,
                            listOfEmails: sendOneEmailToAll ? selectedCustomers.map( customer => customer.email) : []
                        });

                }
                enqueueSnackbar(`Email(s) sent`, {variant: 'success'});
                setWaitingForBackendMessage('');
                if (typeof onSent === 'function') {
                    setTimeout( () => {
                        setSelectedCustomers([])
                        onSent();
                    }, 2000)
                }
            } catch ( e: any ) {
                await sendErrorMessage(e,  'front/activity/methods.sendEmails', "Cannot Send Email", enqueueSnackbar,activityId);
            }
            setWaitingForBackendMessage('');

        })()
    }
    const getCustomers = async () => {
        try {
            setWaitingForBackendMessage('Fetching customers ');
            const customerCountries = getUserCountries();
            const filter = [
                {
                    fieldName: '_id',
                    value: activityCustomerIds?.filter((id: any) => id !== undefined ),
                    op: '$nin'
                },
                {
                    fieldName: 'country',
                    value: customerCountries
                },
                {
                    fieldName: 'consent',
                    value: ['Approved']
                }
            ];
            const res = await Api({
                name: 'getCustomers',
                url: `${REACT_APP_CUSTOMER_API}customers/`,
                config: {
                    params: {
                        filter: JSON.stringify(filter)
                    },
                }
            })
            setWaitingForBackendMessage('');
            if(res.data && res.data.records && res.data.records.length > 0) {
                setCustomers(res.data.records);
                if ( customerIdsInput ) {
//                    setSelectedCustomers(res.data.records.filter( (cust: CustomerType) => customerIdsInput.indexOf(cust._id) > -1));
                }
            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/customer/getData', "Cannot Get Customers", enqueueSnackbar,activityId);
        }
    }
    const displayEmail = () => {
        let compiledHtml = template.compiledHtml;
        if ( country && template && template.countryVersion.length ) {
            compiledHtml = template.countryVersion.find( (cv: TemplateCountryVersion) => cv.country === country)?.compiledHtml ?? '';
        }
        if ( emailBody && user ) {
            setSrcDoc(
                compiledHtml
                    .replace('[[Email Body]]', emailBody)
                    .replaceAll('[[__Sales Rep Name]]', user.name!)
                    .replace('[[__Sales Rep Title]]', user.title!)
                    .replace('[[__Sales Rep Phone]]', user.phone!)
                    .replaceAll('[[__Sales Rep Email]]', user.email!)
                    .replace('[[__Sales Rep Image]]', `${REACT_APP_UPLOAD_BUCKET}${user.image ? `${user.image}` : `user/${user._id}.png`}`)
            )
        } else {
            setSrcDoc(compiledHtml);
        }
    }
    const handleEmailBody = (message: string) => {
        setEmailBody(message);
    }
    useEffect(()=> {
        displayEmail();
    }, [emailBody])
    useEffect( () => {
        ( async()=> {
            //await getCustomers();
            displayEmail();
        })()
    }, [])
    return (
        <Grid container sx={{marginTop: '10px', marginBottom: '10px'}}>
                <Grid item xs={12} sx={{textAlign: 'right'}}>
                    <Button onClick={send} variant="contained" endIcon={<SendIcon />} disabled={(!selectedCustomers || !selectedCustomers?.length)}>
                        Send
                    </Button>
                </Grid>

            <Grid item xs={12}>
                {
                    ((customerIds?.length && selectedCustomers) || !customerIds?.length) ?
                        <Autocomplete
                            multiple
                            sx={{marginTop: '15px'}}
                            id="toEmail"
                            options={customers}
                            onFocus={() => {
                             if (!customers.length) {
                                 (async()=>{await getCustomers()})()
                             }
                            }}
                            getOptionLabel={(option: CustomerType) => `${option?.fullname}<${option?.email}>`}
                            filterSelectedOptions
                            // @ts-ignore
                            value={selectedCustomers}
                            groupBy={(option: CustomerType) => option.fullSiteName}
                            onChange={(event, value) => {
                                setSelectedCustomers(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="To: "
                                    placeholder="Search"
                                />
                            )}
                        />
                        : ''
                }
                {
                    //Hide this for now, we should display it again when i implement it on activity
                }
                <Grid hidden={true}>
                    <FormControl >
                        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="separateEmail"
                            name="radio-buttons-group"
                            onChange={(event) => setSendOneEmailToAll(event.target.value === 'oneEmail')}
                        >
                            <FormControlLabel value="separateEmail" control={<Radio />} label="Send separate e-mail to each recipient (default)" />
                            <FormControlLabel value="oneEmail" control={<Radio />} label="Send one e-mail to all recipient (recipent e-mails will be visible for all recipients)" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {
                    <TextField
                        autoFocus
                        margin="dense"
                        id="subject"
                        label="Subject:"
                        type="text"
                        variant="standard"
                        defaultValue={subject}
                        onBlur={(event)=> setSubject(event.target.value)}
                        fullWidth
                    />
                }
                <Grid item xs={12} sx={{textAlign: 'right'}} hidden={!emailBody}>
                    <IconButton onClick={()=>setEditEmailBody(true)} >
                        <EditIcon sx={{fontSize: 30}}/>
                    </IconButton>

                </Grid>
                <iframe style={{height: '3000px', width: '100%', overflow: 'auto'}} frameBorder={0} srcDoc={srcDoc} id="body"></iframe>
            </Grid>
            <EditDialog maxLength={500} open={editEmailBody} setOpen={setEditEmailBody} defaultValue={emailBody??''} setFieldValue={handleEmailBody} fieldId={'emailBody'} />
        </Grid>
    )

}

interface SendEmailFormInput {
    activityCustomerIds?: string[];
    activityId: string;
    customerIdsInput?: string[] | CustomerType[];
    template: TemplateType;
    openForm: any;
    onSent?: any;
    country: string;
}

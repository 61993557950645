import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

export const Root = styled(Grid)({
    flexGrow: 1,
    overflow: 'auto',
})
// @ts-ignore
export const AppBarSpacer = styled('div')(({ theme }) => ({
    ...theme.mixins.toolbar
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
}))

import Grid from "@mui/material/Grid";
import React, {useContext, useEffect} from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {ActivityContext} from "../../ActivityContext";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';

import {Box, Stack, Typography} from "@mui/material";
import ToolTipMui from "@mui/material/Tooltip";

import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from "@mui/icons-material/Visibility";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import DoneIcon from "@mui/icons-material/Done";
import PollIcon from '@mui/icons-material/Poll';
import {format} from 'date-fns'
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto',
    marginTop: '50px',
    flexDirection: 'column',
    width: '97%',
    marginBottom: '40px',
    maxWidth: '1000px'
}))

interface ActivityData {
    name: string;
    SentEmails: number;
    ViewedEmails: number;
    CTR: number;
    CTA: number;
}
export const Summary = () => {
    const countReminders = () => {
        return participants.reduce((acc: number, p: ParticipantType) => {
            return acc + (p.invitationReminders?.length ?? 0);
        }, 0)
    }
    const calculateNumber = (field: string) => {
        // @ts-ignore
        return participants.filter((p: ParticipantType) => p[field])?.length
    }
    const {activity, participants}  = useContext(ActivityContext);
    const [numSentEmails, setNumSentEmails] = React.useState(calculateNumber('invitationSent'))
    const [numSentReminderEmails, setNumSentReminderEmails] = React.useState(countReminders())
    const [numViewedEmails, setNumViewedEmails] = React.useState(calculateNumber('invitationViewed'))
    const [numCTR, setNumCTR] = React.useState(calculateNumber('registrationViewed'))
    const [numCTA, setNumCTA] = React.useState(calculateNumber('registrationDone'))
    const [numSurvey, setNumSurvey] = React.useState(activity.survey?.length)
    const [data, setData] = React.useState<ActivityData[] | undefined>()
    const [hasInvitationTemplate] = React.useState(activity.templateSuite?.templates?.find( (template: TemplateType) => template.type === 'E-MAIL INVITATION'))
    const [hasSurveyTemplate] = React.useState(activity.templateSuite?.templates?.find( (template: TemplateType) => template.type === 'SURVEY'))
    const [hasRegistrationTemplate] = React.useState(activity.templateSuite?.templates?.find( (template: TemplateType) => template.type === 'REGISTRATION'))
    const [hasRepEmailTemplate] = React.useState(activity.templateSuite?.type === 'RepEmail');
    const [marginLeft] = React.useState(
        hasInvitationTemplate && hasSurveyTemplate && hasRegistrationTemplate ? '0%' :
            hasRepEmailTemplate ? '20%' :
                hasRegistrationTemplate && hasInvitationTemplate ? '20%' :'40%')

    const ItemChild = ({Icon, title, number, tooltip}: {Icon: any, title: string, number: string, tooltip: string}) => {
        return (
            <React.Fragment>
                <ToolTipMui title={tooltip}>
                    <Box sx={{backgroundColor: 'lightgray'}}>
                        <Icon  sx={{verticalAlign: 'middle', mr: '5px'}}/>
                        <Typography variant="overline" sx={{fontSize: '9px'}}>
                            {title}
                        </Typography>
                    </Box>
                </ToolTipMui>
                <Typography variant="h5" sx={{color: 'black', paddingLeft: '0px', pb: '10px', pt: '10px'}}>
                    {number}
                </Typography>
            </React.Fragment>
        )
    }
    const ItemChild2 = ({Icon, title, tooltip}: {Icon: any, title: string, tooltip: string}) => {
        return (
            <React.Fragment>
                <ToolTipMui title={tooltip}>
                    <React.Fragment>
                        <Icon  sx={{verticalAlign: 'middle', mr: '5px'}}/>
                        <Typography variant="overline">
                            {title}
                        </Typography>
                    </React.Fragment>
                </ToolTipMui>
            </React.Fragment>
        )
    }
    const updateData = () => {
        const availableDates: any = [];
        participants.filter((p: ParticipantType)=> p.invitationSent || p.registrationViewed || p.registrationDone).map( (participant: ParticipantType) => {
            if (participant.invitationSent)
                availableDates.push(format(new Date(participant.invitationSent), 'yyyy-MM-dd'));
            if (participant.invitationReminders) {
                participant.invitationReminders.map( (reminder: Date) => {
                    availableDates.push(format(new Date(reminder), 'yyyy-MM-dd'));
                })
            }
            if (participant.invitationViewed)
                availableDates.push(format(new Date(participant.invitationViewed), 'yyyy-MM-dd'));
            if (participant.registrationViewed)
                availableDates.push(format(new Date(participant.registrationViewed), 'yyyy-MM-dd'));
            if (participant.registrationDone)
                availableDates.push(format(new Date(participant.registrationDone), 'yyyy-MM-dd'));
        })

        const dumma: ActivityData[] = [];
        // @ts-ignore
        for(const dt of [...new Set(availableDates.sort())]) {
            dumma.push({
                name: dt,
                SentEmails: participants.reduce(
                    (acc: number, p: ParticipantType) => {
                        if (format(new Date(p.invitationSent??'1970-01-01'), 'yyyy-MM-dd') === dt) acc++;
                        p.invitationReminders?.map( (reminder: Date) => {
                            if (format(new Date(reminder), 'yyyy-MM-dd') === dt) acc++;
                        })
                        return acc;
                    }, 0),
                ViewedEmails: participants.filter( (p: ParticipantType) => format(new Date(p.invitationViewed??'1970-01-01'), 'yyyy-MM-dd') === dt)?.length,
                CTR: participants.filter( (p: ParticipantType) => format(new Date(p.registrationViewed??'1970-01-01'), 'yyyy-MM-dd') === dt)?.length,
                CTA: participants.filter( (p: ParticipantType) => format(new Date(p.registrationDone??'1970-01-01'), 'yyyy-MM-dd') === dt)?.length
            })
        }
        setData(dumma);
        setNumSentEmails(calculateNumber('invitationSent'));
        setNumSentReminderEmails(countReminders())
        setNumViewedEmails(calculateNumber('invitationViewed'))
        setNumCTR(calculateNumber('registrationViewed'))
        setNumCTA(calculateNumber('registrationDone'))
        setNumSurvey(activity.survey?.length);
    }
    useEffect( () => {
        updateData();
    }, [participants])
    useEffect( () => {
        updateData();
    }, [])
    const Chart = ({dataKey}: {dataKey: string}) => {
        return (
            <LineChart
                width={890}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 60,
                    left: 0,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey={dataKey} stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
        )
    }
    // @ts-ignore
    return (
        <Grid sx={{ml: '20px'}}>
            <StyledPaper elevation={24}>
                <Typography variant="h4" sx={{textAlign: 'left', color: 'black', paddingLeft: '0px', pb: '10px', pt: '10px'}}>
                    {activity.subject}
                </Typography>
                <Typography variant="h5" sx={{textAlign: 'left', color: 'black', paddingLeft: '0px', pb: '10px', pt: '40px'}}>
                    Summary
                </Typography>
                <Stack direction="row" spacing={2}>
                    {
                        hasInvitationTemplate &&
                        <React.Fragment>
                            <Item sx={{width: '16%'}}>
                                <ItemChild Icon={SendIcon} tooltip="Number of emails sent" title="Sent emails" number={numSentEmails.toString()}/>
                            </Item>
                            <Item sx={{width: '16%'}}>
                                <ItemChild Icon={SendIcon} tooltip="Number of reminder emails sent" title="Reminder emails" number={numSentReminderEmails.toString()}/>
                            </Item>
                            <Item sx={{width: '16%'}}>
                                <ItemChild Icon={VisibilityIcon} tooltip="Number of emails that has been viewed/opened" title="Viewed emails" number={`${numViewedEmails} (${numViewedEmails && Math.round((numViewedEmails/numSentEmails)*100)}%)`}/>
                            </Item>
                        </React.Fragment>
                    }
                    {
                        hasRegistrationTemplate && hasInvitationTemplate &&
                        <Item sx={{width: '16%'}}>
                            <ItemChild Icon={MeetingRoomIcon} tooltip="CTR = Click Through Rate, this is the number of unique times a customer has clicked the button (if a button is available)" title="CTR" number={`${numCTR} (${numCTR && Math.round((numCTR/numSentEmails)*100)}%)`}/>
                        </Item>
                    }
                    {
                        (hasRegistrationTemplate) &&
                        <Item sx={{width: '16%', ml: marginLeft}}>
                            <ItemChild Icon={DoneIcon} tooltip="CTA = Click To Action, this is the number of unique times a customer has e.g registered to an event" title="CTA" number={`${numCTA} (${numCTA && numSentEmails && Math.round((numCTA/numSentEmails)*100)}%)`}/>
                        </Item>
                    }
                    {
                        (hasSurveyTemplate) &&
                        <Item sx={{width: '16%', ml: marginLeft}}>
                            <ItemChild Icon={PollIcon} tooltip="Number of times someone has filled out the survey" title="Survey" number={`${numSurvey}`}/>
                        </Item>
                    }
                </Stack>
                <Stack spacing={2}>
                    {
                        hasInvitationTemplate &&
                        <React.Fragment>
                            <Item>
                                <Typography component={"div"} variant="h6" sx={{textAlign: 'left', color: 'black', paddingLeft: '0px', pb: '10px', pt: '10px'}}>
                                    <ItemChild2 Icon={SendIcon} tooltip="Number of emails sent" title="Sent emails" />
                                </Typography>
                                <Chart dataKey={"SentEmails"}/>

                            </Item>
                            <Item>
                                <Typography component={"div"} variant="h6" sx={{textAlign: 'left', color: 'black', paddingLeft: '0px', pb: '10px', pt: '10px'}}>
                                    <ItemChild2 Icon={VisibilityIcon} tooltip="Number of emails that has been viewed/opened" title="Viewed emails"/>
                                </Typography>
                                <Chart dataKey={"ViewedEmails"}/>
                            </Item>
                        </React.Fragment>
                    }

                    {
                        hasRegistrationTemplate && hasInvitationTemplate &&
                        <Item>
                            <Typography component={"div"} variant="h6" sx={{textAlign: 'left', color: 'black', paddingLeft: '0px', pb: '10px', pt: '10px'}}>
                                <ItemChild2 Icon={MeetingRoomIcon} tooltip="CTR = Click Through Rate, this is the number of unique times a customer has clicked the button (if a button is available)" title="CTR" />
                            </Typography>
                            <Chart dataKey={"CTR"}/>
                        </Item>
                    }

                    {
                        (hasRegistrationTemplate) &&
                        <Item>
                            <Typography component={"div"} variant="h6" sx={{textAlign: 'left', color: 'black', paddingLeft: '0px', pb: '10px', pt: '10px'}}>
                                <ItemChild2 Icon={DoneIcon} tooltip="CTA = Click To Action, this is the number of unique times a customer has e.g registered to an event" title="CTA"/>
                            </Typography>
                            <Chart dataKey={"CTA"}/>
                        </Item>
                    }


                </Stack>

            </StyledPaper>
        </Grid>
    );
}

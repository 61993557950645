import {Api} from "../../Api";

const {REACT_APP_TEMPLATE_API} =  process.env;
const lovs: ListOfValueType[] = []

const convertToLOV = (data: ListOfValueType[]) => {
    return data.map((lov) => {
        return {value: lov.value}
    })
}
export const getLOVAsSimpleArray = (type: string, parent?: string, country?: string): string[] => {
    let result: ListOfValueType[] = lovs.filter((lov) => lov.type === type && lov.active).sort((a, b) => a.sort - b.sort);
    if(country) result = result.filter((lov) => lov.country === country)
    if(parent) result = result.filter((lov) => lov.parent === parent)
    return result.map((lov) => lov.value);
}
export const getLOV = (type: string, parent?: string, country?: string, onlyReturnValue: boolean = true): ListOfValueType[] | Pick<ListOfValueType, 'value'>[] => {
    let result: ListOfValueType[] = lovs.filter((lov) => lov.type === type && lov.active).sort((a, b) => a.sort - b.sort);
    if(country) result = result.filter((lov) => lov.country === country)
    if(parent) result = result.filter((lov) => lov.parent === parent)
    return onlyReturnValue ? convertToLOV(result) : result;
}
export const getLOVFull = (type: string, parent?: string, country?: string): ListOfValueType[] | Pick<ListOfValueType, 'value'>[] => {
    let result: ListOfValueType[] = lovs.filter((lov) => lov.type === type && lov.active).sort((a, b) => a.sort - b.sort);
    if(country) result = result.filter((lov) => lov.country === country)
    if(parent) result = result.filter((lov) => lov.parent === parent)
    return result;
}

export const cacheLOV = async () => {
    if (lovs.length) return;
    const res = await Api({name: 'ListOfValue:getData', url: `${REACT_APP_TEMPLATE_API}lov`})
    if (res) lovs.push(...res.data);
}
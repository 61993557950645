import React, {useContext, useEffect, useState} from "react";
import {Api} from "../Api";
import {sendErrorMessage} from "../Error";
import { useSnackbar } from 'notistack';
import {useParams} from "react-router-dom";
import {format, parseISO} from "date-fns";
import {
    Box,
    Tooltip
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {green} from "@mui/material/colors";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmailIcon from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";
import {FormatDateFromUTC} from "../modules/Activity/methods";
import useWebSocket from "react-use-websocket";
import {AppContext} from "../AppContext";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {DataGridPro, GridToolbarContainer, GridToolbarQuickFilter, GridRow} from "@mui/x-data-grid-pro";
import styled from '@emotion/styled';
const socketUrl =`${process.env.REACT_APP_WEBSOCKET_API}`;
const {REACT_APP_ACTIVITY_API, REACT_APP_UPLOAD_BUCKET} =  process.env;
export const CustomerActivities = ({customerId}: CustomerActivitiesInput) => {
    const params = useParams();
    const {setWaitingForBackendMessage, user} = useContext(AppContext);
    const [participantActivities, setParticipantActivities] = useState<any>([]);
    const [currentCustomerId, setCurrentCustomerId] = useState('');
    const [updatedParticipant, setUpdatedParticipant] = useState('');
    const [selectedEvent, setSelectedEvent] = React.useState<any | undefined>();
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const CustomRow = styled(GridRow)(({ theme }) => ({
        // Lägg till dina egna stilar här, exempelvis:
        '&.updatedParticipant': {
            backgroundColor: 'lightgreen',
        },
        // Du kan också använda andra props och villkor för att anpassa stilen
    }));

    const {lastMessage} = useWebSocket(socketUrl,
        {
            queryParams: {userId: `${user?.userId}`},
            onOpen: () => console.log('DEBUG: web socket opened'),
            //Will attempt to reconnect on all close events, such as server shutting down
            shouldReconnect: (closeEvent) => true,
        });
    useEffect(() => {
        if (lastMessage !== null) {
            ( async () => {
                const payload = JSON.parse(lastMessage.data)
                if (payload.customerId && payload.customerId === currentCustomerId ) {
                    setUpdatedParticipant(payload.participantId);
                    await getActivities();
                }
            })();
        }

    }, [lastMessage]);

    const getActivities = async () => {
        const t0 = performance.now();
        try {
            setWaitingForBackendMessage('Getting activities...');
            const url = REACT_APP_ACTIVITY_API;
            const res = await Api({
                name: 'getCustomerActivities',
                url: `${url}customerparticipants/${customerId??params.customerId}`
            })
            if ( res && res.data ) {
                if (Array.isArray(res.data)) {
                    setCurrentCustomerId(res.data[0]?.customer);
                    setParticipantActivities( res.data?.filter( (p: any) => p.start).map( (participant: any) => {
                        return {
                            ...participant,
                            id: participant._id,
                            start: format(parseISO(participant.start), "yyyy-MM-dd HH:mm"),
                            updated: format(parseISO(participant.updated), "yyyy-MM-dd HH:mm")
                        }
                    }))
                } else if (!Array.isArray(res.data) && res.data.customerId) {
                    console.log(res.data.message);
                    setCurrentCustomerId(res.data.customerId);
                }

            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/customers/getCustomerActivities', "Cannot get customer activities", enqueueSnackbar);
        } finally {
            setWaitingForBackendMessage('');
            const t1 = performance.now();
            console.log(`Call to getActivities took ${t1 - t0} milliseconds.`);
        }
    }
    const ParticipantStatus = ({participant, field}: any) => {
        return (
            <React.Fragment>
                {
                    participant.invitationSent ?
                        <Tooltip
                            title={`Email was sent '${FormatDateFromUTC(participant.invitationSent)}'`}>
                            <EmailIcon/>
                        </Tooltip>
                        : ''
                }
                {
                    participant.invitationViewed ?
                        <Tooltip
                            title={`Email was viewed '${FormatDateFromUTC(participant.invitationViewed)}'`}>
                            <VisibilityIcon/>
                        </Tooltip>
                        : ''
                }
                {
                    participant.registrationViewed ?
                        <Tooltip
                            title={`Registration form was opened/viewed '${FormatDateFromUTC(participant.registrationViewed)}'`}>
                            <MeetingRoomIcon/>
                        </Tooltip>
                        : ''
                }
                {
                    participant.registrationDone && participant.participate ?
                        <Tooltip
                            title={`Customer will participate, confirmed '${FormatDateFromUTC(participant.registrationDone)}'`}>
                            <DoneIcon style={{ color: green[500] }}/>
                        </Tooltip>
                        : ''
                }

            </React.Fragment>
        )

    }
    useEffect( () => {
        if (customerId || params.customerId) {
            ( async() => {
                await getActivities();
            })()
        }
    }, [])
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div/>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }
    return (
        <React.Fragment>
                <DataGridPro
                    rows={participantActivities}
                    columns={[
                        { field: '_id', headerName: 'id', width: 180},
                        {
                            field: 'responsible',
                            headerName: '',
                            width: 100,
                            valueGetter: (cellValues) => `${cellValues}`,
                            renderCell: (cellValues) => {
                                return (
                                    <Box>
                                        <img title={cellValues.row.responsible.name} src={`${REACT_APP_UPLOAD_BUCKET}${cellValues.row.responsible?.image ? `${cellValues.row.responsible?.image}` : `user/${cellValues.row.responsible?._id}.png`}`}  style={{width: 40, borderRadius: '50%'}}/>
                                    </Box>
                                )
                            }
                        },
                        { field: 'TemplateType', headerName: 'Type', width: 80, type: 'string'},
                        {
                            field: 'RegistrationDoneNumCustomers',
                            headerName: 'Status',
                            width: 100,
                            renderCell: (cellValues) => <ParticipantStatus participant={cellValues.row}/>
                        },
                        { field: 'subject', headerName: 'Title', width: 250},
                        {
                            field: 'start',
                            valueGetter: (cellValue) => cellValue && new Date(cellValue),
                            valueFormatter: (cellValue) => new Intl.DateTimeFormat('sv-SE').format(new Date(cellValue)),
                            headerName: 'Date', width: 80, type: 'dateTime'},
                        { field: 'comment', headerName: 'Description', width: 250, type: 'string'},
                        {
                            field: 'updated',
                            valueGetter: (cellValue) => cellValue && new Date(cellValue),
                            valueFormatter: (cellValue) => new Intl.DateTimeFormat('sv-SE').format(new Date(cellValue)),
                            headerName: 'Updated', width: 80, type: 'dateTime'},
                    ]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 100 } },
                        columns: {
                            columnVisibilityModel: {
                                // Hide columns status and traderName, the other columns will remain visible
                                _id: false
                            },
                        }
                    }}
                    onCellDoubleClick={(cellValues) => {
                        setOpen(true);
                        setSelectedEvent(cellValues.row);
                    }}
                    onRowClick={(cellValues) => {
                        setOpen(true);
                        setSelectedEvent(cellValues.row);
                    }}
                    pagination
                    pageSizeOptions={[100, 200, 500]}
                    getRowClassName={(cellValues) => {
                        // Logik för att bestämma vilken rad som ska ha stilen
                        if (cellValues.row._id === updatedParticipant) {
                            return 'updatedParticipant';
                        }
                        return '';
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                        row: CustomRow,
                }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    sx={{
                        height: '90vh',
                        minHeight: '90vh',
                        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
                        '.MuiDataGrid-columnsContainer': {
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                        },
                        '.MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        },
                        '.MuiDataGrid-cell': {
                            fontSize: '10px',
                        },
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        },
                        // Add more styles as needed
                    }}
                />
            <Dialog open={open} maxWidth="xl">
                <DialogTitle id="scroll-dialog-title">Activity</DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                        sx={{width: '800px', padding: 5}}
                    >

                        <Typography component="div" variant="h5" textAlign="center">{selectedEvent?.invitationEmail?.subject??'I cannot display the invitation, the reason is most likely that the invitation was not sent via Event Manager'}</Typography>
                        <Box dangerouslySetInnerHTML={{__html: selectedEvent?.invitationEmail?.body}}>

                        </Box>

                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={()=> setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

interface CustomerActivitiesInput {
    customerId?: string;
}

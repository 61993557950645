/** @jsxImportSource @emotion/react */
import React, {useContext, useEffect, useRef, useState} from "react";
import {Api} from "../../../../../Api";
import {AppContext} from "../../../../../AppContext";
import ListItem from "@mui/material/ListItem";
import {Avatar, Box, Button, ListItemAvatar, TextField} from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {styled} from "@mui/material/styles";
import {ActivityContext} from "../../../ActivityContext";
import {marked} from "marked";
import { css } from '@emotion/react';
const {REACT_APP_UPLOAD_BUCKET, REACT_APP_SKYNET_API} = process.env;
const TurndownService = require('turndown').default;
const turndownService = new TurndownService();

type ChatMessage = {
    avatar?: string;
    date: string;
    text: string;
};
const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
}))

interface ThreadType {
    reviewStep: number;
    initiateReview: boolean;
    setInitiateReview: any;
    template: TemplateType;
    pdfUrl: string | undefined;
    html?: string;
    externalId: string;
    assistant: 'Invitation' | 'Text';
}

// Define the base styles for the chat content area
const chatArea = css`
      padding-left: 40px;
      white-space: normal!important;

      & strong {
        display: block;
      }
      & h2 {
        color: unset;
        padding: unset;
      }
      & ul, p {
        white-space: normal;
      }
      & li {
        margin: 0;
        margin-bottom: 5px; // Adjusts the spacing between list items
      }
      & p {
        margin-top: 2px;
        margin-bottom: 2px;
        font-size: 16px;
      }
    `;

// Styles specific to the assistant's chat bubble
const chatBubbleAssistant = css`
      ${chatArea}; // Include the base chat area styles
      background-color: #DAE8FC; // Soft blue for assistant
      border-radius: 15px;
      padding: 10px;
      maxWidth: '95%';
      alignSelf: 'flex-start';
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); // Optional shadow for floating effect
    `;

// Styles specific to the user's chat bubble
const chatBubbleUser = css`
      ${chatArea}; // Include the base chat area styles
      background-color: #F5F5F5; // Neutral grey for user
      border-radius: 15px;
      padding: 10px;
      maxWidth: '95%';
      alignSelf: 'flex-end';
    `;

// Add this style for the input area
const inputArea = css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0 0px; // Same horizontal padding as chatArea for consistency
    
      & .MuiTextField-root {
        width: 100%;
        margin-right: 20px; // Spacing between the input field and send button
      }
    `;

export const Thread = ({reviewStep, initiateReview, setInitiateReview, html, externalId, assistant = 'Invitation'}: ThreadType) => {
    const {user, setWaitingForBackendMessage} = useContext(AppContext);
    const{activity} = useContext(ActivityContext);
    const [reviewHistory, setReviewHistory] = useState<ChatMessage[] | null>();

    // Add a state to handle the input text
    const [inputText, setInputText] = useState(assistant === 'Text' ? turndownService.turndown(html)??'' : '');

    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [reviewHistory]); // Dependency array säger till useEffect att köra igen när reviewHistory ändras

    const calculateRows = () => {
        // Exempel: öka raderna för varje 50 tecken
        return Math.max(4, Math.ceil(inputText.length / 50)) ?? 2;
    };
    // Function to handle sending a message
    const handleSendMessage = async () => {
        setWaitingForBackendMessage('Hold on, we are waiting for the assistant to reply')
        try {
            if (inputText.trim()) {
                // Implement the send message logic here
                // For example, sending the message to the backend
                const res = await Api({
                    name: 'postMessageToAssistant',
                    type: 'POST',
                    url: REACT_APP_SKYNET_API,
                    body: {
                        externalId: `${activity._id}_${externalId}`,
                        userInput: inputText.trim(),
                        type: assistant
                    }
                });
                // Clear the input after sending
                setInputText('');
                await getMessages(res.messages);
                // You may want to fetch the updated messages here as well
            }
        } catch (e) {
            console.error('Error sending message', e);
        }

        setWaitingForBackendMessage('');
    };

    const runReview = async () => {
        setWaitingForBackendMessage('Hold on, we are running the review')

        const res = await Api( {
            name: 'postMessageToAssistant',
            type: 'POST',
            url: REACT_APP_SKYNET_API,
            body: {
                externalId: `${activity._id}_${externalId}`,
                html,
                type: assistant
            }
        });
        console.log('runReview', res);
        await getMessages(res.messages);
        setWaitingForBackendMessage('')

    };
    const getMessages = async (messages?: any) => {
        setWaitingForBackendMessage('Hold on, we are fetching your chat with the assistant')
        if (!messages) {
            const res = await Api({
                name: 'getThread',
                type: 'GET',
                url: `${REACT_APP_SKYNET_API}${activity._id}_${externalId}`
            })
            if (res?.data) messages = res.data;
        }
        console.log('messages', messages)
        if (messages) {
            const sortedMessages = messages.sort((a: any, b: any) => {
                // Convert date strings to Date objects and then to time values
                const dateA = new Date(a.created_at * 1000).getTime();
                const dateB = new Date(b.created_at * 1000).getTime();
                return dateA - dateB; // Sort in descending order by time
            });
            setReviewHistory(
                sortedMessages.map( (message: any) => {
                    const date = new Date(message.created_at*1000).toLocaleString('sv-SE');
                    const text = message?.content[0]?.text?.value?.includes('<!doctype') ? 'Please review my invitation' : message.content[0].text.value;
                    return {text, avatar: message.role, date}
                })
            )
        }
        setWaitingForBackendMessage('')
    }
    const renderChatMessage = (message: ChatMessage) => {
        const isAssistant = message.avatar === 'assistant';
        // Konvertera markdown till HTML
        const createMarkup = (markdownText: string) => {
            const adjustedText = markdownText.replace(/\n\n/g, '\n');
            return { __html: marked(adjustedText) as string };
        };
        const chatBubbleStyle = isAssistant ? chatBubbleAssistant : chatBubbleUser;

        return (
            <ListItem alignItems="flex-start" style={{ flexDirection: 'row' }}>
                <ListItemAvatar>
                    {
                        isAssistant ? <Avatar><SmartToyIcon /></Avatar>
                            : <StyledAvatar alt="Remy Sharp" src={`${REACT_APP_UPLOAD_BUCKET}${user?.image}`} />
                    }
                </ListItemAvatar>
                <ListItemText
                    primary={`${isAssistant ? `AI assistant` : user?.name}`}
                    secondary={
                        <div ref={messagesEndRef}>
                            <div style={{ marginBottom: '10px' }}>{message.date}</div>
                            <div css={{...chatBubbleStyle}}  dangerouslySetInnerHTML={createMarkup(message.text)} />
                        </div>
                    }
                />
            </ListItem>
        );
    };
    useEffect(() => {
        (async () => {
                await getMessages()
            }
        )()

    }, [reviewStep]);

    useEffect(() => {
        (async () => {
            if (initiateReview) {
                await runReview();
                setInitiateReview(false);
            }
        })()

    }, [initiateReview])

    return (
        <Box paddingTop={0}>
            <List sx={{ bgcolor: 'background.paper'}}>
                {reviewHistory &&  reviewHistory.map((message, index) => renderChatMessage(message))}
                {((reviewHistory?.length??0) > 0 || assistant === 'Text' ) &&
                <ListItem alignItems="flex-start" style={{ flexDirection: 'row' }}>
                    <ListItemAvatar>
                        {
                            <StyledAvatar alt="Remy Sharp" src={`${REACT_APP_UPLOAD_BUCKET}${user?.image}`} />
                        }
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${user?.name}`}
                        secondary={
                            <div css={inputArea}>
                                <TextField
                                    label="Type your message..."
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={calculateRows()}
                                    value={inputText}
                                    onChange={(e) => setInputText(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            handleSendMessage();
                                        }
                                    }}
                                />
                                <Button variant="contained" color="primary" onClick={handleSendMessage}>
                                    Send
                                </Button>
                            </div>
                        }
                    />
                </ListItem>
                }

            </List>
            {(reviewHistory?.length??0) === 0 && assistant === 'Invitation' && <Button variant={'contained'} onClick={() => setInitiateReview(true)}>{`Initiate Review`}</Button>}
            {(reviewHistory?.length??0) > 0 && assistant === 'Invitation' && <Button variant={'contained'} onClick={() => setInitiateReview(true)}>{`Review again`}</Button>}
        </Box>
    )
}
import PropTypes, {InferProps} from "prop-types";
import {Details} from "../components/Details";
import {Api} from "../../../Api";
import {format, parseISO} from "date-fns";
import {TemplateParams} from "../CreateActivity";
import {getLogoFragments} from "../../Fragment/methods";
import {sendErrorMessage} from "../../../Error";
import {handlePayload} from "../functions/handlePayload";
import {refreshCache} from "./refreshCache";
import {getIdToken} from "../../../auth/azureAuthProvider";
import {fireAuthError} from "../../../auth/fireAuthError";
const {REACT_APP_ACTIVITY_API, REACT_APP_CUSTOMER_API, REACT_APP_USER_API} =  process.env;
export const convertDate = (dt: Date) => {
    return `${dt.getFullYear()}-${(dt.getMonth() + 1)<10?'0':''}${dt.getMonth()+1}-${(dt.getDate())<10?'0':''}${dt.getDate()} ${dt.getHours()<10?'0':''}${dt.getHours()}:${dt.getMinutes()<10?'0':''}${dt.getMinutes()}`;
}

interface sendEmailsType {
    activityId: string;
    action: string;
    participantIds?: string[] | undefined;
    customerIds?: string[];
    emailBody?: string;
    emailSubject?: string;
    country?: string;
    sendOneEmailToAll?: boolean;
    listOfEmails?: string[];
}
export const archiveActivity = async (activityId: string) => {
    try {
        let activity = await Api( {
            name: 'archiveActivity',
            type: 'POST',
            url: `${REACT_APP_ACTIVITY_API}archiveActivity/${activityId}`
        });
        if (activity) {
             activity = {
                ...activity,
                start: convertDate(new Date(activity.start)),
                end: convertDate(new Date(activity.end))
            };
            //Save version from DB to
            return activity;
        }
    } catch ( e: any ) {
        console.error( e.toString() );
    }

}
export const FormatDateFromUTC = (date: string) => {
    try {
        return format(parseISO(date), "yyyy-MM-dd HH:mm")
    } catch (e: any) {
        //console.error(e.toString(), date)
    }
    return '';

}
export const sendEmails = async ({activityId, participantIds, action, emailBody, customerIds, country, emailSubject, sendOneEmailToAll,listOfEmails}: sendEmailsType) => {
        const res = await Api( {
            name: 'sendEmails',
            type: 'PUT',
            url: `${REACT_APP_ACTIVITY_API}activity`,
            body: {
                action: action,
                payload: {
                    _id: activityId,
                    participantIds: participantIds,
                    customerIds: customerIds,
                    country,
                    emailBody,
                    emailSubject,
                    sendOneEmailToAll,
                    listOfEmails,
                    userId: getUserId(),
                }
            }
        });
        if (res) {
            return res.data;
        }
}
export const getParticipants = async ( activityId: string) => {
    try {
        const res = await Api({
            name: 'getParticipants',
            url: `${REACT_APP_ACTIVITY_API}participants/${activityId}`
        })
        if (res) {
            return res.data.map( (participant: ParticipantType) => {
                // @ts-ignore
                return {...participant, updatedAt: format(parseISO(participant.updatedAt), "yyyy-MM-dd HH:mm")}
            });
        }
    } catch ( e: any ) {
        console.error("Cannot Get Participants", e.toString());
    }
}
export const getUsers = async (countries: string[], responsibleId?: string) => {
        const res = await Api({
            name: 'getUsers',
            url: `${REACT_APP_USER_API}users/${countries.join(',')}/${responsibleId}`
        })
        if (res) {
            return res.data;
        }

}
export const addParticipant = async (activityId: string, customerIds: string[]) => {
    try {
        const activity = await Api( {
            name: 'addParticipant',
            type: 'POST',
            url: `${REACT_APP_ACTIVITY_API}participant/`,
            body: {activityId, customerIds}
        });
        if ( activity ) {
            return activity;
        }
    }catch ( e: any ) {
        console.error("Cannot addParticipant", e.toString());
    }

}

export const removeParticipant = async (participantId: string) => {
    try {
        const activity = await Api( {
            name: 'removeParticipant',
            type: 'DELETE',
            url: `${REACT_APP_ACTIVITY_API}participant/${participantId}`,
        });
        if ( activity ) {
            return activity;
        }
    }catch ( e: any ) {
        console.error("Cannot removeParticipant", e.toString());
    }
}
export const eventDate = (dtStart: Date) => {
    return `${dtStart.toLocaleDateString('sv-SE', { year: 'numeric', month: 'long', day: 'numeric' })} ${dtStart.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' })}`
}
export const isTemplateActive = (templateType: string, selectedTemplates: ActivitySelectedTemplateType[]) => {
    switch(true) {
        case selectedTemplates.includes('Invitation') && /(E-MAIL INVITATION|REGISTRATION|E-MAIL CONFIRMATION)/.test(templateType):
            return true;
        case selectedTemplates.includes('Registration') && /(REGISTRATION|E-MAIL CONFIRMATION)/.test(templateType):
            return true;
        case selectedTemplates.includes('Webinar') && /(WEBINAR|E-MAIL WEBINAR URL)/.test(templateType):
            return true;
        case selectedTemplates.includes('Survey') && /(SURVEY|E-MAIL SURVEY)/.test(templateType):
            return true;
        case selectedTemplates.includes('Print invitation') && /(PRINT INVITATION)/.test(templateType):
            return true;
        default:
            return false;
    }
}
export const enableApproval = (activity: ActivityType) => {
    let result: {yes: boolean, countries: string[]} = {
        yes: true,
        countries: []
    }
    if (activity.templateSuite.type === 'RepEmail') {
        //RepEmail only has one template.
        const template = activity.templateSuite.templates?.find( (template: TemplateType) => template.type === 'E-MAIL INVITATION');

        if (template && activity.countries.length > 1) {
            result.yes = false;
            template?.countryVersion?.forEach( (cv:TemplateCountryVersion) => {
                if(cv.readOnly) {
                    result.countries.push(cv.country)
                    result.yes = true;
                }
            })
        } else if (template && activity.countries.length === 1) {
            if (typeof template.readOnly === 'undefined' || !template.readOnly) result.yes = false;
            else result.countries.push(activity.countries[0])
        }
    } else {
        activity.templateSuite.templates?.forEach( (template: TemplateType) => {
            if (isTemplateActive(template.type, activity.selectedTemplates)) {
                if (typeof template.readOnly === 'undefined' || !template.readOnly) result.yes = false;
            }
        })
    }

    return result;
}
const mapSmallLogoFragments = async (activity: any) => {
    if (activity.templateSuite) {
        const fragments = await getLogoFragments()
        const smallLogoImages = activity.templateSuite.smallLogoImages;
        activity = {
            ...activity,
            templateSuite: {
                ...activity.templateSuite,
                smallLogoImages: smallLogoImages?.map( (v: any) => fragments.find( (f: any) => f._id === v))
            }
        }
    }
    return activity;
}
export const getActivity = async ( activityId: string) => {
    try {
        const res = await Api({
            name: 'getActivity',
            url: `${REACT_APP_ACTIVITY_API}activity/${activityId}`
        })
        if (res) {
            let activity = {
                ...res.data,
                start: convertDate(new Date(res.data.start)),
                end: convertDate(new Date(res.data.end)),
            };
            //Save version from DB to
            return mapSmallLogoFragments(activity);
        }

    } catch ( e: any ) {
        console.error("Cannot Get Activity", e.toString());
    }

}

export const saveActivityProp = async ( activityId: string, activity: ActivityType | null) => {
    try {
        if ( activity ) {
            await Api( {
                name: 'saveActivity',
                type: 'POST',
                url: `${REACT_APP_ACTIVITY_API}activity/${activityId}`,
                body: activity
            });
        }

    } catch ( e: any ) {
        console.error( e.toString() );
    }
}
export const saveActivity = async ( activityId: string, payload: any | null, sendMessage: any) => {
        if ( activityId && payload ) {
            try {
                sendMessage(JSON.stringify({
                    action: 'patchActivity', // detta kommer bli routeKey i servern
                    type: 'patchActivity1',
                    jwtToken: `bearer ${await getIdToken()}`,
                    activityId,
                    payload
                }));
                console.log('Debug: saveActivity', payload)
            } catch( e: any ) {
                console.error( e.toString() );
                const activity = await Api( {
                    name: 'saveActivity',
                    type: 'PATCH',
                    url: `${REACT_APP_ACTIVITY_API}activity/${activityId}`,
                    body: payload
                });
                if ( activity ) {
                    await refreshCache({object: 'Activity', objectId: activity._id})
                    return mapSmallLogoFragments(activity);
                }
            }
        }
        return false;
}
export const deleteActivity = async (activityId: string) => {
    try {
        await Api( {
            name: 'deleteActivity',
            type: 'DELETE',
            url: `${REACT_APP_ACTIVITY_API}activity/${activityId}`
        });
        await refreshCache({object: 'Activity'})
    } catch ( e: any ) {
        console.error( e.toString() );
    }

}
export const copyActivity = async (activityId: string) => {
    try {
        const activity = await Api( {
            name: 'copyActivity',
            type: 'POST',
            url: `${REACT_APP_ACTIVITY_API}copyActivity/${activityId}`
        });
        if (activity) {
            await refreshCache({object: 'Activity', objectId: activity._id})
            return activity;
        }


    } catch ( e: any ) {
        console.error( e.toString() );
    }

}

export const createActivity = async ({templateSuite, type, jointEvent, brand, country}: TemplateParams) => {
    try {
        let selectedTemplates = ['Invitation', 'Registration', 'Webinar', 'Survey']
        if (type === 'Meeting')
            selectedTemplates = ['Invitation', 'Registration', 'Survey']
        if (type === 'Survey')
            selectedTemplates = ['Survey']
        if (type === 'RepEmail' || type === 'BookingEmail')
            selectedTemplates = ['Invitation']

        const activity = await Api( {
            name: 'createActivity',
            type: 'POST',
            url: `${REACT_APP_ACTIVITY_API}activity/`,
            body: {
                status: 0,
                statusText: 'Step 0: Just created',
                templateSuiteId: templateSuite._id,
                templateSuite,
                countries: [country],
                brand,
                selectedTemplates,
                jointEvent
            }
        });
        if (activity) {
            await refreshCache({object: 'Activity', objectId: activity._id})
            return activity;
        }

    } catch ( e: any ) {
        console.error( e.toString() );
    }

}

interface webSocketHandlerTypes {
    payload: any;
    activity: ActivityType | null;
    activityId: string;
    setActivity: any;
    participants: ParticipantType[];
    setParticipants: any;
    fetchActivity: any;
    enqueueSnackbar: any;

}
export const webSocketHandler = async ({payload, activity, activityId, setActivity, participants, setParticipants, fetchActivity, enqueueSnackbar}: webSocketHandlerTypes) => {
    //Don't print out confirmation message from aws
    if (payload.message !== 'websocket_message_ok')
        console.log('DEBUG: Websocket payload', payload);
    if (payload.message === 'Internal server error' || payload.statusCode === 500) {
        await sendErrorMessage({
            message: payload.reason ?? 'No more information available (Joakim will be able to see more in the logs)',
            stack: 'Websocket error, unknown, check error log for websocket on aws',

        },  'front/activity/', 'IMPORTANT! An internal error has occurred. Your changes may not have been saved, please verify! If the problem continues, please contact the administrator (Joakim).', enqueueSnackbar, activity?._id);
        setTimeout( () => {
            fireAuthError(999, payload.reason ??'Websocket error')
        }, 1500)
    }
    switch (payload.object) {
        case 'Activity':
            await refreshCache({object: 'Activity', objectId: payload?.activityId})
            switch ( payload.event ) {
                case 'SUBMIT_SURVEY' :
                    if ( activity && activityId === payload.activityId ) {
                        setActivity({
                            ...activity,
                            survey: activity.survey ? activity.survey.concat(payload.survey): payload.survey
                        })
                    }
                    break;
                case 'APPROVE_TEMPLATES':
                    fetchActivity();
                    break;
                case 'patchActivity':
                    try {
                        if ( payload?.activityId !== activityId) return;
                        if ( payload?.reason === 'ACCESS DENIED' ) throw new Error('Apologies for the inconvenience. It appears that you attempted to update an activity for which you are not listed as the responsible party. Please note that you can only modify your own activities unless you have Editor or Administrator privileges.');
                        if ( payload?.statusCode === 500) throw new Error('Apologies for the inconvenience. An internal error occurred. Please try again. If the problem persists, please contact the administrator (Joakim).')
                        if ( payload?.userId === getUserId()) {
                            //enqueueSnackbar(`Your changes were successfully saved`, {variant: 'success'});
                            return;
                        }
                        setActivity(
                            handlePayload(payload.payload, JSON.parse(JSON.stringify(activity)))
                        )
                        enqueueSnackbar(`This activity was updated by ${payload?.userName}`, {variant: 'success', autoHideDuration: 10000});
                    } catch ( e: any ) {
                        await sendErrorMessage(e,  'front/activity/methods.saveActivity', "Error saving activity", enqueueSnackbar, activity?._id);
                        console.error( e.toString() );
                    }
                break;
            }
            break;
        case 'Participant':
            if (participants) {
                switch ( payload.event ) {
                    case 'trackingPixel':
                        setParticipants(participants.map( (participant: ParticipantType) => {
                            if (participant._id === payload.participantId) {
                                // @ts-ignore
                                participant[payload.fieldName] = new Date()
                            }
                            return participant;
                        }));
                    break;
                    case 'Updated':
                        //If new participant is added, fetch all
                        if (participants.find( (participant: ParticipantType) => participant._id === payload._id.$oid )) {
                            setParticipants(participants.map( (participant: ParticipantType) => {
                                if ( participant._id === payload._id.$oid ) {
                                    if (payload.updatedFields.saveTheDateViewed) participant.saveTheDateViewed = new Date();
                                    if (payload.updatedFields.saveTheDateSent) participant.saveTheDateSent = new Date();
                                    if (payload.updatedFields.invitationViewed) participant.invitationViewed = new Date();
                                    if (payload.updatedFields.invitationSent) participant.invitationSent = new Date();
                                    if (payload.updatedFields.registrationDone) participant.registrationDone = new Date();
                                    if (payload.updatedFields.registrationViewed) participant.registrationViewed = new Date();
                                    if (payload.updatedFields.meetingInformationSent) participant.meetingInformationSent = new Date();
                                    if (payload.updatedFields.meetingInformationViewed) participant.meetingInformationViewed = new Date();
                                    if (payload.updatedFields.preEventInfoSent) participant.preEventInfoSent = new Date();
                                    if (payload.updatedFields.preEventInfoViewed) participant.preEventInfoViewed = new Date();
                                    if (payload.updatedFields.surveyUrlViewed) participant.surveyUrlViewed = new Date();
                                    if (payload.updatedFields.surveyUrlSent) participant.surveyUrlSent = new Date();
                                    if (payload.updatedFields.loggedOnToWebinar) participant.loggedOnToWebinar = new Date();
                                }
                                return participant;
                            }))
                        }
                        break;
                    case 'addParticipants':
                        if ( payload.activityId === activityId ) {
                            setParticipants(participants.concat(payload.participants));
                        }

                        break;
                    case 'deleteParticipant':
                        if ( payload.activityId === activityId ) {
                            setParticipants(participants.filter((participant: ParticipantType) => participant._id !== payload.participantId));
                        }
                        break;
                    case 'SEND_INVITATION':
                    case 'SEND_SAVE-THE-DATE':
                    case 'SEND_PRE_EVENT_INFO':
                    case 'SEND_INVITATION_REMINDER':
                    case 'SEND_WEBINAR_URL':
                    case 'SEND_SURVEY_URL':
                    case 'SEND_REP_EMAIL':
                        if ( payload.activityId === activityId ) {
                            let tempParticipants = participants;
                            if ( payload.participants) tempParticipants = tempParticipants.concat(payload.participants);

                            setParticipants(tempParticipants.map( (participant: ParticipantType) => {
                                if (participant._id === payload.participantId) {
                                    if (payload.updatedField === 'invitationReminder') {
                                        if ( !participant.invitationReminders ) participant.invitationReminders = [];
                                        participant.invitationReminders.push(new Date());
                                    }
                                    // @ts-ignore
                                    participant[payload.updatedField] = format(new Date(), "yyyy-MM-dd HH:mm")
                                }
                                return participant;
                            }));
                        }
                        break;
                    case 'SUBMIT_REGISTRATION':
                        setParticipants(participants.map( (participant: ParticipantType) => {
                            if (participant._id === payload.participantId) {
                                if ( payload.relatedParticipant) {
                                    participant.relatedParticipant = payload.relatedParticipant
                                }
                                participant.updatedAt = format(new Date(), "yyyy-MM-dd HH:mm")
                                participant.registrationDone = new Date();
                                participant.participate = true
                                participant.otherInformation = payload?.participant.otherInformation;
                                participant.questions = payload?.participant.questions;
                                participant.customFields = payload?.participant.customFields;
                            }
                            return participant;
                        }));
                        break;
                    case 'SUBMIT_REGISTRATION-EXTERNAL':
                        if ( payload.participant.activity === activityId) {
                            payload.participant.updatedAt = format(parseISO(payload.participant.updatedAt), "yyyy-MM-dd HH:mm")
                            setParticipants(participants.concat(payload.participant));
                        }
                        break;
                }

            }
            break;
    }
}

export const getCustomers = async ({selectedSpecialty, selectedType, selectedCountry, activity, participants}: InferProps<typeof Details.propTypes>) => {
    // @ts-ignore
    const filter = [
        // @ts-ignore
        {fieldName: 'specialty', value: selectedSpecialty.map( ({value}) => value) },
        // @ts-ignore
        {fieldName: 'type', value: selectedType.map( ({value}) => value) },
        // @ts-ignore
        {fieldName: 'country', value: selectedCountry.map( ({value}) => value) },
    ];
    if (activity.countries.length > 0 && activity.customerTypes.length > 0 && activity.customerSpecialties.length > 0) {
        const res = await Api({
            name: 'getCustomers',
            url: `${REACT_APP_CUSTOMER_API}customers/`,
            config: {
                params: {
                    filter: JSON.stringify(filter)
                },

            }
        })
        if ( res ) {
            const fetchedCustomers = res.data.records.map( ( customer: any) => {
                return {
                    name: customer.name,
                    firstname: customer.firstname,
                    mainSite: !customer.mainSite ? customer.site : customer.mainSite,
                    site: customer.mainSite ? customer.site : '',
                    email: customer.email,
                    emailPixelFired: false,
                    customer: customer._id,
                    activity: activity._id,
                    manuallyPicked: false
                }
            }).filter( (customer: ParticipantType) => !activity.participants.find( (participant: ParticipantType) => customer.customer === participant.customer));
            return fetchedCustomers;
        }


    } else {
        console.log("DEBUG: Remove customer", activity.participants.filter( (participant: ParticipantType) => !participant.manuallyPicked ));
        //await removeParticipant(activity.participants.filter( (participant: ParticipantType) => !participant.manuallyPicked ));
        //setActivity({...activity, participants: activity.participants.filter( (participant: ParticipantType) => participant.manuallyPicked )});
        return activity.participants.filter( (participant: ParticipantType) => participant.manuallyPicked );
    }
}
getCustomers.propTypes = {
    activity: PropTypes.object.isRequired,
    participants: PropTypes.array.isRequired,
    setActivityPayload: PropTypes.func.isRequired,
    selectedCountry: PropTypes.array.isRequired,
    selectedSpecialty: PropTypes.array.isRequired,
    selectedType: PropTypes.array.isRequired
};
export const getUserCountries = () => {
    const user = localStorage.getItem('user');
    if ( user ) {
        return JSON.parse(user).countries;
    }
    throw 'Could not parse UserId from JWT Token';
}
export const getUserId = () => {
    const user = localStorage.getItem('user');
    if ( user ) {
        return JSON.parse(user).userId;
    }
    throw 'Could not parse UserId from JWT Token';
}

export const getTopHeader = (activity: ActivityType | undefined, template: TemplateSuiteType | undefined = undefined) => {
    let imageURL = '';
    let topHeader = '';
    const templateSuite = activity?.templateSuite ?? template
    if (templateSuite && templateSuite?.templates)
        templateSuite.templates.map( (template: TemplateType) => {
            let imageField = template.fields.find( (field: TemplateFieldType) => field.name === '__logo-image');
            const TopHeader = template.fields.find( (field: TemplateFieldType) => field.name === 'TopHeader');
            if (!imageURL && !topHeader) {
                if (!imageURL && imageField) {
                    imageURL = imageField.value;
                } else if (!imageField && !topHeader && (TopHeader?.value)) {
                    topHeader = TopHeader?.value;
                }
            }
        })
    return {imageURL, topHeader}
}

export const flattenJSON = (json: string, indentSpace: number = 0) => {
    try {

        return indentSpace ? JSON.stringify(JSON.parse(json), null, indentSpace) : JSON.stringify(JSON.parse(json)) ;
    } catch (e: any) {
        //console.error()
    }
    return ''
}

export const convertDiffToArrayOfObjects = (diffObj: any, path = ''): any => {
    const updates = [];

    if (typeof diffObj === 'object' && !Array.isArray(diffObj)) {
        Object.keys(diffObj).forEach(key => {
            const childPath = `${path}${path && '.'}${key}`;
            const childUpdates = convertDiffToArrayOfObjects(diffObj[key], childPath);

            updates.push(...childUpdates);
        });
    } else {
        const update = {
            path,
            value: diffObj
        };
        updates.push(update);
    }

    return updates;
};

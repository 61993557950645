import PropTypes from "prop-types";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box,
     Divider,

    List,
    ListItem,
    ListItemIcon,
    ListItemText, ListSubheader,
    Rating,
    Stack,
    Typography
} from "@mui/material";
import React, {useContext} from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ActivityContext} from "../../ActivityContext";
import {Bar, BarChart, CartesianGrid, Cell, Tooltip, XAxis, YAxis} from "recharts";
import CommentIcon from '@mui/icons-material/Comment';



export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto',
    marginTop: '50px',
    flexDirection: 'column',
    marginBottom: '40px',
    width: '800px'
}))

export const Survey: any = () => {
    const {activity, setActivityPayload}  = useContext(ActivityContext);

    const SurveyAccordianNew = () => {
        let questions: any;
        let questionField: TemplateFieldType | undefined;
        let categories: any = [];
        activity.templateSuite.templates.map( (template: TemplateType) => {
            if (template.type === 'SURVEY') {
                questionField = template.fields.find( (field: TemplateFieldType) => field.name === '__Questions');
                if (questionField && questionField.questions) {
                    questions = questionField.questions;
                }
            }
        })
        if (questions) {
            questions = questions.map( (question: TemplateQuestionType) => {
                let ratings = 0;
                let numRatings = 0;
                let numAnswers = 0;
                let yesNoAnswers = {
                    yes: 0,
                    no: 0
                }
                let multipleChoiceAnswers: any = [];
                let ratingAnswers: any = [];
                question?.choices?.map( (q,i) => {
                    multipleChoiceAnswers.push({
                        name: q,
                        index: i,
                        numAnswers: 0
                    })
                })
                activity.survey.map( (reply: any) => {
                    if ( question._id && reply.survey[question._id] && !reply.survey[question._id].notApplicable) {
                        const rating = reply.survey[question._id].rating;
                        const yesNo = reply.survey[question._id]?.yesNo;
                        const multiAnswer = reply.survey[question._id]?.radio || reply.survey[question._id]?.combo;
                        const comment = reply.survey[question._id]?.comment;

                        if (rating || yesNo || multiAnswer || comment) {
                            numAnswers++;
                        }

                        if (rating && rating !== 'N/A') {
                            ratings = rating ? parseInt(rating) + ratings : ratings;
                            numRatings = rating ? numRatings + 1 : numRatings;
                            if (!ratingAnswers.find( (ra: any) => ra.name === rating)) {
                                ratingAnswers.push({
                                    name: rating,
                                    numAnswers: 0
                                })
                            }
                            ratingAnswers = ratingAnswers.map( (v: any) => {
                                if (v.name === rating) {
                                    v.numAnswers = v.numAnswers + 1
                                }
                                return v;
                            })
                        }
                        if (yesNo) {
                            yesNoAnswers.yes = yesNoAnswers.yes + 1;
                        } else {
                            yesNoAnswers.no = yesNoAnswers.no + 1;
                        }
                        if (multiAnswer) {
                            multipleChoiceAnswers = multipleChoiceAnswers.map( (v: any) => {
                               if (v.name === multiAnswer) {
                                   v.numAnswers = v.numAnswers + 1
                               }
                               return v;
                           })
                        }
                    }

                });
                if (categories.indexOf(question.category) === -1) {
                    categories.push(question.category);
                }

                return ({
                    ...question,
                    averageRating: numRatings ? ratings/numRatings : 0,
                    yesNoAnswers,
                    multipleChoiceAnswers,
                    ratingAnswers: ratingAnswers.sort((prev: any, curr: any) => prev.name - curr.name),
                    numAnswers
                })
            })
        }
        return (
            <div>
                {
                    categories.map( (category: any) => {
                        return(
                            <div>
                                <Typography component="h5" variant="h5" sx={{textAlign: 'left', paddingLeft: '0px', pb: '10px', pt: '10px'}}>
                                    {category}
                                </Typography>
                                {
                                    questions ?
                                        questions.map( (question: TemplateQuestionType) => {
                                            const labelYes = question.labelYes??'Yes';
                                            const labelNo = question.labelNo??'No';
                                            const barColors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#413ea0"];
                                            const data = [{name: labelYes, r: question.yesNoAnswers.yes, }, {name: labelNo, r: question.yesNoAnswers.no, }];
                                            if ( question.category === category) {
                                                return (
                                                    <React.Fragment>
                                                        <Typography component={"div"} variant="h6" sx={{textAlign: 'left', color: 'black', paddingLeft: '0px', pb: '10px', pt: '10px'}}>
                                                            {question.label && `${question.label}`} ({question.numAnswers})
                                                        </Typography>
                                                        {
                                                            question.useRating &&
                                                            <Accordion sx={{  '&:before': {
                                                                    backgroundColor: 'transparent !important',
                                                                }}}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Box>
                                                                        <Typography sx={{textAlign: 'left', color: 'black', paddingLeft: '0px', pb: '10px', pt: '10px'}} component="span" variant="h6">{question.averageRating?.toFixed(1)}</Typography>
                                                                    </Box>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Stack>
                                                                        { question.useRating &&
                                                                        question.ratingAnswers.map( (ra: any) => {
                                                                            return (
                                                                                <Box>
                                                                                    <Typography sx={{textAlign: 'left', color: 'black', paddingLeft: '0px', pb: '10px', pt: '10px'}} component="span" variant="h6">{ra.name} ({ra.numAnswers})</Typography>
                                                                                </Box>
                                                                            )
                                                                        })
                                                                        }

                                                                    </Stack>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        }
                                                        {
                                                            question.useYesNo &&
                                                            <BarChart
                                                                width={500}
                                                                height={300}
                                                                data={[{name: labelYes, r: question.yesNoAnswers.yes, }, {name: labelNo, r: question.yesNoAnswers.no, }]}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="name" />
                                                                <YAxis />
                                                                <Tooltip />

                                                                <Bar barSize={40} dataKey="r" >
                                                                    {
                                                                        data.map((entry, index) => (
                                                                            <Cell key={`cell-${index}`} fill={barColors[index]} />
                                                                        ))
                                                                    }
                                                                </Bar>

                                                            </BarChart>
                                                        }
                                                        {
                                                            (question.useRadio || question.useCombo) &&
                                                            <BarChart
                                                                width={500}
                                                                height={300}
                                                                data={question.multipleChoiceAnswers}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="name" />
                                                                <YAxis />
                                                                <Tooltip />

                                                                <Bar barSize={40} dataKey="numAnswers" >
                                                                    {
                                                                        data.map((entry, index) => (
                                                                            <Cell key={`cell-${index}`} fill={barColors[index]} />
                                                                        ))
                                                                    }
                                                                </Bar>

                                                            </BarChart>
                                                        }
                                                        {
                                                            question.useComment &&
                                                            <nav aria-label="main mailbox folders">
                                                                <List      subheader={
                                                                    <ListSubheader component="div" id="nested-list-subheader">
                                                                        Comments
                                                                    </ListSubheader>
                                                                }>
                                                                    {
                                                                        activity.survey.map( (reply: any, index: number) => {
                                                                            if ( question._id && reply.survey[question._id] && reply.survey[question._id].comment) {
                                                                                return (
                                                                                    <ListItem >
                                                                                            <ListItemIcon>
                                                                                                <CommentIcon />
                                                                                            </ListItemIcon>
                                                                                            <ListItemText sx={{fontStyle: 'italic'}} secondary={reply.survey[question._id].comment} />

                                                                                    </ListItem>
                                                                                )
                                                                            }

                                                                        })
                                                                    }
                                                                </List>
                                                            </nav>
                                                        }
                                                        <Divider />

                                                    </React.Fragment>
                                                )
                                            }

                                        })
                                        : ''
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <StyledPaper elevation={24} >
            <SurveyAccordianNew/>
        </StyledPaper>
    );
}
Survey.propTypes = {
    participants: PropTypes.array
};

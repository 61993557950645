import React, {useContext, useEffect} from 'react';
import './styling.css';
import {TemplateTabs} from "./TemplateTabs";
import {Box, Tab, Tabs} from "@mui/material";
import {Typography} from "@mui/material/";
import {ActivityContext} from "../../ActivityContext";
import {TemplateSuiteContext} from "./TemplateSuiteContext";
import {AppContext} from "../../../../AppContext";
import {handleTemplateSuite} from "./handleTemplateSuite";
import {handleTemplateUpdateTypeV2, handleTemplateV2} from "./handleTemplate";
import {parseTemplateFieldPayload, handleTemplateFieldFieldType} from "./handleTemplateField";
import {getFragmentFooter} from "./methods";
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
enum Scenario {
    Standard = 'Standard',
    RepEmailSingleCountry = 'RepEmailSingleCountry',
    RepEmailCountryEditor = 'RepEmailCountryEditor',
    RepEmailMultipleCountries = 'RepEmailMultipleCountries'
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{width: '100%'}}
        >
            {value === index && (
                <Box sx={{ p: 3, pt: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
export const Templates: any = () => {
    const {activity, setActivityPayload, repEmailEditor} = useContext(ActivityContext);
    const [countries, setCountries] = React.useState<string[]>([]);
    const [value, setValue] = React.useState(0);
    const {userHasRole, setWaitingForBackendMessage} = useContext(AppContext);
    const [footer, setFooter] = React.useState('Footer could not be fetched');
    const [waitForFotter, setWaitForFooter] = React.useState(true);
    const [isUsingFragmentFooter] = React.useState(activity.templateSuite.templates.find( (t: TemplateType) => t.type === 'E-MAIL INVITATION')?.html?.includes('[[__Footer]]'));
    const [scenario, setScenario] = React.useState<Scenario>(Scenario.Standard);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const handleTemplateFieldChange = (fields: handleTemplateFieldFieldType[]) => {
        parseTemplateFieldPayload({fields, setActivityPayload})
    }
    const handleTemplateUpdateV2 = async (payload: handleTemplateUpdateTypeV2) => {
        await handleTemplateV2({
            ...payload,
            activity,
            setActivityPayload
        })
    }
    const handleTemplateSuiteChange = async (field: string, value: any) => {
        await handleTemplateSuite({field, value, activity, setActivityPayload})
    }
    useEffect( () => {
        setCountries( activity.countries.map( (country: string) => {
            (async () => {
                setWaitingForBackendMessage('Fetching footers');
                await getFragmentFooter({activity, setFooter, country, isUsingFragmentFooter});
                setWaitForFooter(false);
                setWaitingForBackendMessage('');

            })()
            switch ( country ) {
                case 'DK': return 'Denmark'
                case 'SE': return 'Sweden'
                case 'NO': return 'Norway'
                case 'FI': return 'Finland'
                default: return country
            }
        }).sort())
        if (activity.templateSuite.type === 'RepEmail' && activity.countries.length === 1) setScenario(Scenario.RepEmailSingleCountry)
        else if (activity.templateSuite.type === 'RepEmail' && activity.countries.length > 1 && !userHasRole(['Administrator', 'Editor'])) setScenario(Scenario.RepEmailCountryEditor)
        else if (activity.templateSuite.type === 'RepEmail' && activity.countries.length > 1 && userHasRole(['Administrator', 'Editor'])) setScenario(Scenario.RepEmailMultipleCountries)

    }, [])
    return (
        <TemplateSuiteContext.Provider value={{
            templateSuite: activity.templateSuite,
            handleTemplateSuiteChange,
            handleTemplateUpdateV2,
            handleTemplateFieldUpdate: handleTemplateFieldChange,
            footer,
            isUsingFragmentFooter
        }}>
                {!waitForFotter && [Scenario.Standard, Scenario.RepEmailSingleCountry].includes(scenario) && <TemplateTabs />}
                {!waitForFotter &&  [Scenario.RepEmailCountryEditor].includes(scenario) && <TemplateTabs country={repEmailEditor.country} />}
                {!waitForFotter &&  [Scenario.RepEmailMultipleCountries].includes(scenario) &&
                        <Box sx={{ bgcolor: 'background.paper', display: 'flex', width: '100%'}}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                            >
                            {
                                activity.countries.map( (country: string, index: number) =>
                                    <Tab sx={{width: '100%'}} label={country} {...a11yProps(index)} />
                                )
                            }
                            </Tabs>
                            {
                                activity.countries.map( (country: "SE" | "DK" | "FI" | "NO" | "", index: number) =>
                                    <TabPanel value={value} index={index}> <TemplateTabs country={country} /></TabPanel>
                                )
                            }
                        </Box>
                    }
        </TemplateSuiteContext.Provider>

    );
}

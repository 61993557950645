import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';

export default class ImageSelectorPlugin extends Plugin {
    init() {
        const editor = this.editor;
        const customImageConfig = editor.config.get('imageSelector');

        // Skapa knappen
        editor.ui.componentFactory.add('imageSelector', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Insert image',
                icon: imageIcon, // URL eller SVG-innehåll för din ikon
                tooltip: true
            });

            // Knappklickhändelse
            view.on('execute', () => {
                // Här anropar du den callback som levereras i konfigurationen vid knapptryckningen
                customImageConfig.onButtonPress(this.insertImageIntoEditor.bind(this));
            });

            return view;
        });
    }

    insertImageIntoEditor(imageUrl) {
        this.editor.execute( 'imageInsert', { source: imageUrl } );
    }
}

import React, { useEffect, useState} from 'react';
import {DataGridPro, GridColDef, GridRenderEditCellParams, GridToolbarContainer} from '@mui/x-data-grid-pro';
import {Button, TextareaAutosize} from '@mui/material';
import {Api} from "../../Api";
import ReactCountryFlag from "react-country-flag";
import {getLOVAsSimpleArray} from "../ListOfValue/getLOV";
const {REACT_APP_TEMPLATE_API} =  process.env;
export const Translation = () => {
    const [rows, setRows] = useState<TranslationType[]>([]);
    const [columns] = useState<GridColDef[]>([
        { field: 'id', headerName: 'ID', width: 90 },
        {field: 'country', headerName: 'Country', width: 130, editable: true, type: 'singleSelect', valueOptions: getLOVAsSimpleArray('country'),
            renderCell: (params) => (
                <ReactCountryFlag
                    svg
                    style={{
                        fontSize: '2em',
                        lineHeight: '2em',
                    }}
                    countryCode={params.value??'US'}  // Du kan byta ut 'SE' mot vilken som helst dynamisk värde.
                />
            ),
        },
        { field: 'context', headerName: 'Context', width: 130, editable: true, type: 'singleSelect', valueOptions: getLOVAsSimpleArray('translationContext')  },
        { field: 'fieldName', headerName: 'Field Name', width: 130, editable: true},
        { field: 'value', headerName: 'Text', editable: true,
            width: 300,
            renderEditCell: (params: GridRenderEditCellParams) => (
                <TextareaAutosize
                    minRows={3}
                    maxRows={10}
                    style={{
                        width: '100%',
                        fontSize: '14px',
                        padding: '8px',
                        boxSizing: 'border-box',
                    }}
                    value={params.value || ''}
                    onChange={(event) =>{
                        console.log(params);
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: event.target.value,
                        })
                    }
                    }
                    autoFocus
                />
            ),},
        { field: 'description', headerName: 'Description', width: 130, editable: true },
        { field: 'active', headerName: 'Active', width: 130, editable: true, type: 'boolean' },
        {
            field: 'createdAt',
            headerName: 'Created',
            width: 150,
            type: 'date',
            valueGetter: ( value ) => value && new Date(value),
            valueFormatter: ( value ) => value && new Intl.DateTimeFormat('sv-SE').format(new Date(value)) ,
        },
        {
            field: 'updatedAt',
            headerName: 'Updated',
            width: 150,
            type: 'date',
            valueGetter: ( value ) => value && new Date(value),
            valueFormatter: ( value ) => value && new Intl.DateTimeFormat('sv-SE').format(new Date(value)) ,
        },
    ]);
    const getLovs = async () => {
        const res = await Api({name: 'Translation:getData', url: `${REACT_APP_TEMPLATE_API}translation`})
        if (res) setRows(res.data);
    }
    const upsertRow = async (newRow: TranslationType) => {
        await Api({name: 'Templates:upsertData', url: `${REACT_APP_TEMPLATE_API}translation`, body: newRow, type: 'POST'})
        if (!newRow._id) await getLovs();
        else setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? newRow : row)));
    }
    const handleAddRow = async () => {
        const newRow: TranslationType = {
            context: '',
            fieldName: '',
            country: 'US',
            value: '',
            description: '',
            active: true,
        };
        await upsertRow(newRow);
    };

    const handleProcessRowUpdate = async (newRow: any) => {
        await upsertRow(newRow);
        return newRow;
    };

    const CustomToolbar = ({ handleAddRow }: {handleAddRow: any}) => {
        return (
            <GridToolbarContainer>
                <Button color="primary" onClick={handleAddRow}>
                    Add New Field
                </Button>
            </GridToolbarContainer>
        );
    };

    useEffect(() => {
        (async () => {
            await getLovs();
        })();
    }, [])

    return (
            <>
                {
                    rows.length > 0 &&
                    <DataGridPro
                    slots={{ toolbar: () => <CustomToolbar handleAddRow={handleAddRow} />, headerFilterMenu: null }}
                    rows={rows}
                    columns={columns}
                    processRowUpdate={handleProcessRowUpdate}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    disableColumnFilter
                    columnVisibilityModel={{
                        id: false,
                    }}
                    headerFilters
                    sx={{ mt: '95px',  ml: '30px', mr: '30px' }}
                />
                }
            </>

    );
};
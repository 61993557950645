import React, {useContext, useEffect, useState} from 'react';

import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from "@mui/material/ListItem";
import {Link} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ListItemText from "@mui/material/ListItemText";
import WidgetsIcon from '@mui/icons-material/Widgets';
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SpeakerIcon from "@mui/icons-material/RecordVoiceOver";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ListIcon from '@mui/icons-material/List';
import {Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip} from "@mui/material";
import { styled, Theme, useTheme, CSSObject } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LanguageIcon from '@mui/icons-material/Language';
import PropTypes, {InferProps} from "prop-types";
import {UpsertUser} from "../../components/user";
import {AppContext} from "../../AppContext";
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(5)} + 1px)`,
    paddingLeft: `1px`,
    marginLeft: '1px',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

// @ts-ignore
export const LeftMenu = ({drawerOpen, setDrawerOpen, hideDrawer}: InferProps<typeof LeftMenu.propTypes>) => {
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const {user} = useContext(AppContext);
    const [openUserProfile, setOpenUserProfile] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState<UserType | undefined>( undefined);
    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };
    const handleMyProfileUpdate = (payload: UserType) => {
        setCurrentUser(payload);
        setOpenUserProfile(false);
        window.location.reload();
    }
    useEffect( () => {
        if ( openUserProfile ) {
            try {
                setCurrentUser({
                    _id: user?.userId!,
                    email: user?.email!,
                    title: user?.title!,
                    name: user?.name!,
                    phone: user?.phone!,
                    roles: user?.roles!,
                    image: user?.image!,
                    active: user?.active??false
                })
            } catch ( e ) {
                //Do nothing, we will be sent to login
            }
        }


    }, [openUserProfile])

    return (
        <Drawer variant="permanent" open={drawerOpen} hidden={hideDrawer}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <List hidden={user?.role?.includes('Regulatory')}>
                    <ListItem
                        button
                        selected={selectedIndex === 0}
                        component={Link} to="/"
                        onClick={() => setSelectedIndex(0)}
                    >
                        <ListItemIcon>
                            <Tooltip title="Activities">
                                <AssignmentIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary="Activities"  />
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedIndex === 1}
                        component={Link} to="/repEmails"
                        onClick={() => setSelectedIndex(1)}
                    >
                        <ListItemIcon>
                            <Tooltip title="Rep Email(s)">
                                <ContactMailIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary="Rep Email(s)"  />
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedIndex === 2}
                        component={Link} to="/bookingEmails"
                        onClick={() => setSelectedIndex(2)}
                    >
                        <ListItemIcon>
                            <Tooltip title="Booking Email(s)">
                                <InsertInvitationIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary="Booking Email(s)"  />
                    </ListItem>
                </List>
                <Divider />
            <Divider />
            <List  component="div" disablePadding hidden={user?.role?.includes('Standard')}>
                <ListItem
                    button
                    selected={selectedIndex === 3}
                    component={Link} to="/fragments"
                    onClick={() => {
                        setSelectedIndex(3);
                    }}
                >
                    <ListItemIcon>
                        <Tooltip title="Fragments">
                           <WidgetsIcon />
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary="Fragments" />
                </ListItem>
            </List>
                <List hidden={user?.role?.includes('Regulatory')}>
                            <ListItem
                                button
                                selected={selectedIndex === 4}
                                onClick={() => {
                                    setSelectedIndex(4);
                                    setOpenUserProfile(true);
                                }}
                            >
                                <ListItemIcon>
                                    <Tooltip title="My profile">
                                        <AccountBoxIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="My profile" />
                            </ListItem>
                        </List>
                        <List component="div" disablePadding hidden={user?.role !== 'Administrator'}>
                            <ListItem
                                button
                                selected={selectedIndex === 5}
                                component={Link} to="/customers"
                                onClick={() => setSelectedIndex(5)}
                            >
                                <ListItemIcon>
                                    <Tooltip title="Customers">
                                        <PeopleIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Customers" />
                            </ListItem>
                            <ListItem
                                button
                                selected={selectedIndex === 6}
                                component={Link} to="/speakers"
                                onClick={() => setSelectedIndex(6)}
                            >
                                <ListItemIcon>
                                    <Tooltip title="Speakers">
                                        <SpeakerIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Speakers" />
                            </ListItem>
                            <ListItem
                                button
                                selected={selectedIndex === 7}
                                component={Link} to="/users"
                                onClick={() => setSelectedIndex(7)}
                            >
                                <ListItemIcon>
                                    <Tooltip title="Users">
                                        <PeopleAltIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                            <ListItem
                                button
                                selected={selectedIndex === 8}
                                component={Link} to="/templates"
                                onClick={() => setSelectedIndex(8)}
                            >
                                <ListItemIcon>
                                    <Tooltip title="Templates">
                                        <ContentCopyIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Templates" />
                            </ListItem>
                            <ListItem
                                button
                                selected={selectedIndex === 9}
                                component={Link} to="/lov"
                                onClick={() => setSelectedIndex(9)}
                            >
                                <ListItemIcon>
                                    <Tooltip title="List Of Value">
                                        <ListIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="List Of Value" />
                            </ListItem>
                            <ListItem
                                button
                                selected={selectedIndex === 10}
                                component={Link} to="/translation"
                                onClick={() => setSelectedIndex(10)}
                            >
                                <ListItemIcon>
                                    <Tooltip title="Translation">
                                        <LanguageIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Translation" />
                            </ListItem>
                </List>
            <Dialog open={openUserProfile} maxWidth={'sm'}>
                <DialogTitle>Update your profile user</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update profile
                    </DialogContentText>
                    <UpsertUser user={currentUser} setOpen={setOpenUserProfile} setUser={handleMyProfileUpdate} />
                </DialogContent>
            </Dialog>
        </Drawer>

);
}
LeftMenu.propTypes = {
    drawerOpen: PropTypes.bool.isRequired,
    setDrawerOpen: PropTypes.func.isRequired,
    hideDrawer: PropTypes.bool.isRequired
};

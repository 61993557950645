import {IconButton, Menu, MenuItem} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from "react";

export const ActionBar = ({actions, isDesktop}: ActionBarInput) => {
    const DisplayActions = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const deleteAction = actions?.Delete;
        const copyAction = actions?.Copy;
        const archiveAction = actions?.Archive;
        const unArchiveAction = actions?.UnArchive;

        const handleClick = (event: any) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    sx={isDesktop ? {} : { pt: '0px', pb: '0px' }}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {copyAction &&
                        <MenuItem onClick={copyAction.onClick} disabled={copyAction.disabled}>
                            <FileCopyIcon style={{ marginRight: '8px' }} /> Copy
                        </MenuItem>
                    }
                    {deleteAction &&
                        <MenuItem onClick={deleteAction.onClick} disabled={deleteAction.disabled}>
                            <DeleteIcon style={{ marginRight: '8px' }} /> Delete
                        </MenuItem>
                    }
                    {archiveAction &&
                        <MenuItem onClick={archiveAction.onClick} disabled={archiveAction.disabled}>
                            <ArchiveIcon style={{ marginRight: '8px' }} /> Archive
                        </MenuItem>
                    }
                    {unArchiveAction &&
                        <MenuItem onClick={unArchiveAction.onClick} disabled={unArchiveAction.disabled}>
                            <UnarchiveIcon style={{ marginRight: '8px' }} /> Un-archive
                        </MenuItem>
                    }
                </Menu>
            </div>
        );
    }
    return (
        actions ? <DisplayActions/> : <React.Fragment/>
    )
}
interface ActionBarInput {
    actions?: ActionTypes;
    isDesktop: boolean;
}

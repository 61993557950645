import PropTypes, {InferProps} from "prop-types";
import MaterialTable from "material-table";
import {Box, Button, Dialog, DialogContent, DialogTitle, Tooltip} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {green} from "@mui/material/colors";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmailIcon from "@mui/icons-material/Email";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import React, {useContext, useEffect, useRef, useState} from "react";
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CheckBox from '@mui/icons-material/CheckBox';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import {ActivityContext} from "../ActivityContext";
import SendIcon from '@mui/icons-material/Send';
import { DialogActions } from "@material-ui/core";

import {convertDate, removeParticipant} from "../methods";
import {Api} from "../../../Api";
import {AppContext} from "../../../AppContext";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {SelectCustomers} from "./Customers/SelectCustomers";
import {sendErrorMessage} from "../../../Error";
import {useSnackbar} from "notistack";
const {REACT_APP_PUBLIC_FRONT, REACT_APP_ACTIVITY_API} =  process.env;
// @ts-ignore
const tableIcons = {
    // @ts-ignore
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    // @ts-ignore
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
// @ts-ignore
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
// @ts-ignore
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
// @ts-ignore
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
// @ts-ignore
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
// @ts-ignore
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
// @ts-ignore
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
// @ts-ignore
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
// @ts-ignore
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
// @ts-ignore
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
// @ts-ignore
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
// @ts-ignore
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
// @ts-ignore
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
// @ts-ignore
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
// @ts-ignore
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
// @ts-ignore
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    // @ts-ignore
    CheckBox: forwardRef((props, ref) => <CheckBox {...props} ref={ref} />),
// @ts-ignore
    CheckBoxOutlineBlank: forwardRef((props, ref) => <CheckBoxOutlineBlank {...props} ref={ref} />),
};
export const Participants: any = ({participants, showField, title, action, freeActionTitle, isReadOnly, questions, canAddParticipant, canDeleteParticipant, canSendEmail,canSelectCustomers}: ParticipantsProps) => {
    const tableRef = useRef();
    const {activity, isEditor, isArchived, setActivityPayload, setActivity}  = useContext(ActivityContext);
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const fields = activity.templateSuite.templates.find( (t: TemplateType) => t.type === 'REGISTRATION')?.fields;
    const [selectedParticipants, setSelectedParticipants] = useState<object | null>();
    const [openEditParticipant, setOpenEditParticipant] = useState<boolean>(false);
    const [selectedParticipantId, setSelectedParticipantId] = useState<string>('')
    const [markedForDeletion, setMarkedForDeletion] = React.useState('');
    const [data, setData] = useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const isColumnHidden = (name: string) => {
        return fields?.find( (q: TemplateFieldType) => q.name?.toLowerCase() === name)?.hidden;
    }
    const removeCustomer = async() => {
        try {
            setWaitingForBackendMessage('Removing customer...');
            const tmpActivity = await removeParticipant(markedForDeletion);
            setActivity(tmpActivity);
            setMarkedForDeletion('');
            setOpenDialog(false);
            setWaitingForBackendMessage('');
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/activity/methods.removeParticipant', "Cannot remove selected customer(s)", enqueueSnackbar,activity._id);
        }
    }

    const exportToExcel = async (columns: any) => {
        const activeColumns = columns.filter( (col: any ) => !col.hidden && col.title.toUpperCase() !== 'STATUS').map( (col: any) => {
            return {header: col.title, key: col.field}
        });
        setWaitingForBackendMessage('Exporting to excel, please hold...')
        let excelFile = await Api( {
            name: 'participantsExport',
            type: 'POST',
            url: `${REACT_APP_ACTIVITY_API}participantsExport/`,
            body: {
                    columns: activeColumns,
                    rows: data
            }
        });
        setWaitingForBackendMessage('')
        window.open(excelFile.body);

    }
    const getDefaultFieldTitle = (name: string) => {
        return  fields.find( (q: TemplateFieldType) => q.name?.toLowerCase() === name.toLowerCase())?.value;

    }
    const titleMapping: any = {
        'Ask for feedback viewed': { label: 'Viewed', field: 'surveyUrlViewed' },
        'Registered customers': { label: 'Registered', field: 'registrationDone' },
        'Ask for feedback not sent': { label: 'Registered', field: 'registrationDone' },
        'Webinar URL not sent': { label: 'Registered', field: 'registrationDone' },
        'Invitation viewed': { label: 'Viewed', field: 'invitationViewed' },
        'Invited customers': { label: 'Sent', field: 'invitationSent' },
        'Invitation sent': { label: 'Sent', field: 'invitationSent' },
        'Invitation not sent': { label: 'Added', field: 'createdAt' },
        'All customers': { label: 'Added', field: 'createdAt' },
        'Save the date not sent': { label: 'Added', field: 'createdAt' },
        'Save the date sent': { label: 'Sent', field: 'saveTheDateSent' },
        'Save the date viewed': { label: 'Viewed', field: 'saveTheDateViewed' },
        'Ask for feedback sent': { label: 'Sent', field: 'surveyUrlSent' },
        'Webinar URL sent': { label: 'Sent', field: 'meetingInformationSent' },
        'Webinar URL viewed': { label: 'Viewed', field: 'meetingInformationViewed' },
        'Pre-event info sent': { label: 'Sent', field: 'preEventInfoSent' },
        'Pre-event info viewed': { label: 'Viewed', field: 'preEventInfoViewed' },
        'Reminder not sent': { label: 'Invitation sent', field: 'invitationSent' },
        'Pre-event info not sent': { label: 'Invitation sent', field: 'invitationSent' },
        '1st reminder sent': { label: '1st reminder', field: 'invitationReminders[0]' },
        '2nd reminder sent': { label: '2nd reminder', field: 'invitationReminders[1]' },
        '3rd reminder sent': { label: '3rd reminder', field: 'invitationReminders[2]' }
    };

    const getUpdatedField = (rowData?: any) => {
        const mapping = titleMapping[title];

        if (!mapping) return null;

        // Special handling for reminders since they're an array
        if (/reminder/i.test(title)) {
            return {
                label: mapping.label,
                value: rowData ? convertDate(new Date(eval(`rowData.${mapping.field}`))) : null
            };
        }

        return {
            label: mapping.label,
            value: rowData ? convertDate(new Date(rowData[mapping.field])) : null
        };
    };


    useEffect( () => {
        //This code snippet will make sure to support export to csv/pdf
        setData(participants.map( (participant: ParticipantType) => {
            let external = 'No';
            // @ts-ignore
            if (participant.type && participant.relatedParticipant) {
                external = `Forwarded by: ${participant.relatedParticipant.firstname} ${participant.relatedParticipant.name}`;
                // @ts-ignore
            } else if (participant.type === 'External' && !participant.relatedParticipant) {
                external = 'Yes';
            }
            const firstname = participant.firstname === 'null' ? '': participant.firstname??'';
            const middlename = participant.middlename === 'null' ? '': participant.middlename??'';
            const retVal = {
                ...participant,
                updatedAt: getUpdatedField(participant)?.value,
                siteName:  `${participant.mainSite??''} ${participant.site ? ' - ' : ''}${participant.site ?? ''}`.trim(),
                firstname,
                middlename,
                fullname: `${firstname} ${middlename} ${participant.name} <${participant.email}>`.trim(),
                invitationSentBy: participant.invitationSentBy?.email,
                external
            }
            if (participant.questions?.length && questions) {
                participant.questions.map( (question: TypeSignUpQuestions) => {
                    const q = questions.find( (q: TypeSurveyQuestion) => q._id === question._id);
                    if (question._id)
                        { // @ts-ignore
                            retVal[question._id] = question.type === 'yesNo' ? question.label ? q?.labelYes : q?.labelNo : question.value
                        }
                })
            }

            if (participant.customFields) {
                for (const cusField in participant.customFields) {
                    const f = fields.find( (f: TemplateFieldType) => f.name.toLowerCase() === cusField);
                    // @ts-ignore
                    const partVal = participant.customFields[cusField];
                    // @ts-ignore
                    retVal[cusField] = f?.type === 'YesNo' ? partVal ? f?.labelYes : f?.labelNo : partVal;
                }

            }
            return retVal
        }));
    }, [participants])
    return (
            <Box >
            <MaterialTable
                // @ts-ignore
                icons={tableIcons}
                title={title}
                tableRef={tableRef}
                columns={[
                    { title: 'STATUS', export: false, hidden: showField !== 'invitationSent', field: 'invitationSent', render: ((rowData: ParticipantType) => {
                            if ( rowData.registrationDone ) {
                                if(rowData.participate) {
                                    return <Tooltip title="Participant will participate"><DoneIcon style={{ color: green[500] }}/></Tooltip>;
                                }
                                if(!rowData.participate) {
                                    return <Tooltip title="Participant will not participant"><NotInterestedIcon style={{ color: "red" }}/></Tooltip>;
                                }
                            } else {
                                if(rowData.registrationViewed) {
                                    return <Tooltip title="Registration form is opened"><MeetingRoomIcon/></Tooltip>;
                                }
                                if(rowData.invitationViewed) {
                                    return <Tooltip title="Email is viewed"><VisibilityIcon/></Tooltip>;
                                }
                                if(rowData.invitationSent) {
                                    return <Tooltip title="Email is sent"><EmailIcon/></Tooltip>;
                                }
                            }
                            return ''
                        })  },
                    { title: 'Status', export: false, hidden: !['meetingInformationSent'].includes(showField??''), field: 'meetingInformationSent', render: ((rowData: ParticipantType) => {
                        if(rowData.meetingInformationViewed) return <Tooltip title="Email is viewed"><VisibilityIcon/></Tooltip>;
                        if(rowData.meetingInformationSent) return <Tooltip title="Email is sent"><EmailIcon/></Tooltip>;
                        return ''
                        })  },
                    { title: 'Status', export: false, hidden: !['preEventInfoSent'].includes(showField??''), field: 'preEventInfoSent', render: ((rowData: ParticipantType) => {
                            if(rowData.preEventInfoViewed) return <Tooltip title="Email is viewed"><VisibilityIcon/></Tooltip>;

                            if(rowData.preEventInfoViewed) return <Tooltip title="Email is sent"><EmailIcon/></Tooltip>;

                            return ''
                        })  },
                    { title: 'Status', export: false, hidden: !['saveTheDateSent'].includes(showField??''), field: 'saveTheDateSent', render: ((rowData: ParticipantType) => {
                            if(rowData.saveTheDateViewed) return <Tooltip title="Email is viewed"><VisibilityIcon/></Tooltip>;
                            if(rowData.saveTheDateSent) return <Tooltip title="Email is sent"><EmailIcon/></Tooltip>;
                            return ''
                        })  },
                    { title: 'Status', export: false, hidden: showField !== 'surveySent', field: 'surveySent', render: ((rowData: ParticipantType) => {
                            if(rowData.surveyUrlViewed) {
                                return <Tooltip title="Email is viewed"><VisibilityIcon/></Tooltip>;
                            }
                            if(rowData.surveyUrlSent) {
                                return <Tooltip title="Email is sent"><EmailIcon/></Tooltip>;
                            }
                            return ''
                        })  },
                    { title: 'Status', export: false, hidden: showField !== 'loggedOnToWebinar', field: 'loggedOnToWebinar', render: ((rowData: ParticipantType) => {
                            if(rowData.loggedOnToWebinar) {
                                return <Tooltip title="Participated on webinar"><DoneIcon style={{ color: green[500] }}/></Tooltip>;
                            }
                            return ''
                        })  },
                    { title: 'Name', field: 'fullname', render: (rowData: any) => {
                        if (rowData.firstname !== 'Archived') {
                            return(
                                <div>
                                {rowData.firstname && <span>{rowData.firstname}&nbsp;</span>}
                                {
                                    rowData.middlename && <span><i>{`${rowData.middlename}`}</i>&nbsp;</span>
                                }
                                <span>{`${rowData.name}`}</span>
                                    {
                                        rowData.title ? <span><br/> {rowData.title}</span> : ''
                                    }
                                    <span><br/>{rowData.email}</span>
                                    {
                                        rowData.phone ? <span><br/> {rowData.phone}</span> : ''
                                    }
                                </div>
                            )
                        } else {
                            return (<div>Customer is archived</div>)
                        }

                        }},
                    { title: getDefaultFieldTitle('site')??'Site', field: 'siteName', render: (rowData: any) => {
                            if (rowData.firstname !== 'Archived') {
                                if ( rowData.mainSite || rowData.site ) {
                                    return(
                                        <span><b>{`${rowData.mainSite}`}</b><br/>{`${rowData.site ? rowData.site : ''}`}</span>
                                    )
                                } else {
                                    return ('');
                                }
                            } else {
                                return ('')
                            }


                        }  },
                    { title: getDefaultFieldTitle('address')??'Address', field: 'address', hidden: isColumnHidden('address'),render: (rowData: any) => {
                            if (rowData.firstname !== 'Archived') {
                                return (rowData.address)
                            } else {
                                return ('');
                            }
                    }
                    },
                    { title: 'Country', field: 'country'},
                    { title: 'Consent',hidden: showField !== 'invitationSent', field: 'consent', render: (rowData: any) => {
                            if (rowData.firstname !== 'Archived') {
                                if (rowData?.consent) {
                                    return (
                                        <span>{rowData.consent}</span>
                                    )
                                } else {
                                    return (
                                        <span>Unknown</span>
                                    )
                                }
                            } else {
                                return ('')
                            }
                        }},
                    ...fields.filter((q1: any) => q1.name && !q1.defaultField && q1.type !== 'Text' && q1.type !== 'HTML' && q1.name.indexOf('__') === -1 && q1.type && !q1.hidden).map( (q: TemplateFieldType) => {
                        const fieldName = q.name.toLowerCase();
                        return {
                            title: q.value, hidden: false, field: fieldName?? '1', render: (rowData: any) => {
                                const val = rowData?.customFields ? rowData.customFields[fieldName] : '';
                                switch (q.type) {
                                    case 'Checkbox':
                                        return (
                                            <div>
                                                {
                                                    val ? <CheckBox/> : <CheckBoxOutlineBlank/>
                                                }
                                            </div>
                                        )
                                        break;
                                    case 'YesNo':
                                        return(
                                            <span>{val ? q.labelYes : val!=='' ? q.labelNo: ''}</span>
                                        )
                                        break;
                                }
                                return(
                                    <span>{val}</span>
                                )
                            }
                        }
                    }),
                    { title: getDefaultFieldTitle('otherInformation')??'Comment', hidden: fields.find( (q: TemplateFieldType) => q.name === 'OtherInformation')?.hidden, field: 'otherInformation', render: ((rowData: ParticipantType) => {
                            return rowData.participate ? rowData.otherInformation : rowData.notParticipateReason
                        })},
                    { title: 'External',hidden: showField !== 'invitationSent', field: 'external', render: (rowData: any) => {
                            if ( rowData.type && rowData.relatedParticipant) {
                                return(
                                    <span> {`Forwarded by: ${rowData.relatedParticipant.firstname} ${rowData.relatedParticipant.name}`}</span>
                                )
                            } else if ( rowData.type === 'External' && !rowData.relatedParticipant ) {
                                return(
                                    <span>Yes</span>
                                )
                            }
                            else {
                                return(
                                    <span>No</span>
                                )
                            }

                        }},
                    { title: 'E-mail sent by',  field: 'invitationSentBy', render: (rowData: any) => {
                            return rowData?.invitationSentBy
                        } },
                    { title: getUpdatedField()?.label,  field: 'updatedAt', render: (rowData: any) => getUpdatedField(rowData)?.value  },
                ]}
                data={data}
                // @ts-ignore
                onRowClick = {(event, rowData: any) => {
                    if(!isArchived) {
                        setSelectedParticipantId(rowData?._id??'');
                        setOpenEditParticipant(true)
                    }
                }}
                localization={{
                    toolbar: {
                        exportCSVName: "Export to excel",
                    },
                    header : {
                        actions: ''
                    }
                }}
                options={{
                    search: true,
                    minBodyHeight: '60vh',
                    pageSizeOptions: [20, 60, 120, 400],
                    pageSize: 20,
                    paging: true,
                    selection: canSelectCustomers,
                    rowStyle: rowData => ({
                        verticalAlign: 'top',
                        fontSize: '9px'
                    }),
                    headerStyle: {
                        fontSize: '10px',
                        fontWeight: 700,
                        fontStyle: 'bold',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 'thin',
                        borderBottomColor: 'black',
                        textTransform: 'uppercase',
                    },
                    exportButton: true,
                    exportCsv: exportToExcel
                }}
                actions={!isReadOnly ? [
                    {
                        icon: PersonAddIcon,
                        tooltip: 'Add customers',
                        hidden: !canAddParticipant,
                        isFreeAction: true,
                        disabled: !isEditor || isArchived,
                        onClick: (event) => {setOpen(true)}
                    },
                    {
                        icon: () => <SendIcon />,
                        tooltip: freeActionTitle,
                        hidden: !canSendEmail || isReadOnly,
                        isFreeAction: true,
                        disabled: !isEditor || isArchived,
                        onClick: () => window.alert('You need to select one or more participants')
                    },
                    {
                        icon: () => <PersonRemoveIcon/>,
                        tooltip: 'Delete participant',
                        hidden: !canDeleteParticipant || isReadOnly,
                        disabled: !isEditor || isArchived,
                        onClick: (event, rowData) => {
                            // @ts-ignore
                            setMarkedForDeletion(rowData._id);
                            setOpenDialog(true);
                        }
                    },
                    {
                        icon: () => <SendIcon />,
                        disabled: !isEditor || isArchived,
                        hidden: !canSendEmail || isReadOnly,
                        tooltip: freeActionTitle,
                        onClick: () => {
                            if (selectedParticipants && action) action(selectedParticipants);
                            if (tableRef.current) {
                                // @ts-ignore
                                tableRef.current.onAllSelected(false)
                            }
                        }
                    },
                ] : []}
                onSelectionChange={(rows) => {
                    const rowIds = rows.map( (row: any) => row._id);
                    setSelectedParticipants(rowIds)
                }}
            />
            <Dialog
                open={openDialog}
                onClose={() => {setOpenDialog(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle color="black" id="alert-dialog-title">Do you really want to remove this customer?</DialogTitle>
                <DialogActions>
                    <Button onClick={()=>{setOpenDialog(false)}} color="primary">
                        Nope
                    </Button>
                    <Button onClick={removeCustomer} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <SelectCustomers setActivityPayload={setActivityPayload} open={open} setOpen={setOpen} activity={activity} setActivity={setActivity}></SelectCustomers>

            <Dialog fullWidth={false} maxWidth={'md'} onClose={()=> setSelectedParticipantId('')} open={openEditParticipant}>
                <DialogTitle>Update participant</DialogTitle>
                <DialogContent sx={{textAlign: 'center'}}>
                    <iframe style={{width: '700px', height: '300vh'}} src={`${REACT_APP_PUBLIC_FRONT}register/edit/${selectedParticipantId}`}></iframe>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> setOpenEditParticipant(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

interface ParticipantsProps {
    participants: ParticipantType[];
    showField?: string;
    title: string;
    freeActionTitle?: string;
    actionTitle?: string;
    action?: Function;
    isReadOnly: boolean;
    display: boolean;
    question?: string;
    questions?: any[];
    canAddParticipant: boolean;
    canDeleteParticipant: boolean;
    canSendEmail: boolean;
    canSelectCustomers: boolean;
}

import {createContext} from 'react'
import {handleTemplateFieldFieldType} from "./handleTemplateField";
import {handleTemplateUpdateTypeV2} from "./handleTemplate";

export interface TemplateSuiteContextType {
    templateSuite: TemplateSuiteType | any;
    handleTemplateUpdateV2(payload: handleTemplateUpdateTypeV2): void;
    handleTemplateSuiteChange(field: string, value: any): void;
    handleTemplateFieldUpdate(fields: handleTemplateFieldFieldType[]): void;
    footer?: string;
    isUsingFragmentFooter?: boolean;
}
export const TemplateSuiteContext = createContext<TemplateSuiteContextType>({
    templateSuite: {},
    handleTemplateUpdateV2: (payload: handleTemplateUpdateTypeV2) => {},
    handleTemplateSuiteChange: (field: string, value: any) => {},
    handleTemplateFieldUpdate: (fields: handleTemplateFieldFieldType[]) => {},
    footer: '',
    isUsingFragmentFooter: false
    });

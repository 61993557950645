import {applyColorPreset} from "./applyColorPreset";
import {applySmallLogos} from "./applySmallLogos";
import {applyButtonText} from "./applyButtonText";
import {applyReadOnlyFields} from "./applyReadOnlyFields";
import {applyEditableFields} from "./applyEditableFields";
import {applySystemFields} from "./applySystemFields";
import {applySignature} from "./applySignature";
import {formatSpeakers} from "./formatSpeakers";
import {formatAgenda, formatAgendaLabel} from "./formatAgenda";

interface processTemplateType {
    template: any;
    integrationScript: string;
    regulatoryText: string;
    templateSuite: any;
    activity: any;
    templateFields: any;
    templateReadOnly: boolean;
    isEditor: boolean;
    REACT_APP_UPLOAD_BUCKET: string | undefined;
    repEmailEditor: any;
    footer?: string;
    user: UserType;
}
export const processTemplate = async ({template, integrationScript, regulatoryText, templateSuite, activity, templateFields, templateReadOnly, isEditor,REACT_APP_UPLOAD_BUCKET, repEmailEditor, footer, user}: processTemplateType, displayEmptyFields: boolean = true) => {
    let tmpHTML = template.html;
    tmpHTML = tmpHTML.replaceAll('[[Agenda Label]]', formatAgendaLabel(template.displayAgenda));
    tmpHTML = tmpHTML.replaceAll('[[Agenda]]', formatAgenda(template.displayAgenda));
    tmpHTML = tmpHTML.replaceAll('[[__integrationScript]]', integrationScript);
    tmpHTML = tmpHTML.replaceAll('[[__RegulatoryText]]', regulatoryText);
    tmpHTML = tmpHTML.replaceAll('[[__Footer]]', footer);
    tmpHTML = applyColorPreset(tmpHTML, templateSuite, activity);
    tmpHTML = applySmallLogos(tmpHTML, templateSuite, template, activity, REACT_APP_UPLOAD_BUCKET??'');
    tmpHTML = applyButtonText(tmpHTML, templateFields);
    const editor: boolean = !templateReadOnly && (isEditor || (templateSuite.type === 'RepEmail' && repEmailEditor.editor));
    tmpHTML = editor ? applyEditableFields(tmpHTML, templateFields, displayEmptyFields) : applyReadOnlyFields(tmpHTML, templateFields);
    tmpHTML = applySignature(tmpHTML, templateFields, REACT_APP_UPLOAD_BUCKET, user);
    tmpHTML = applySystemFields(tmpHTML, templateFields);
    tmpHTML = tmpHTML.replaceAll('[[__Speaker]]', await formatSpeakers(activity.speakers));
    return tmpHTML;
}
import React, {useEffect, useRef} from 'react';
import MaterialTable from "material-table";
import Grid from "@mui/material/Grid";
import {Root, StyledPaper, AppBarSpacer} from '../components/StyledComponents'
import {Api} from "../Api";
import {
    Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField
} from '@mui/material';
import Box from "@mui/material/Box";
import { useSnackbar } from 'notistack';
import {sendErrorMessage} from "../Error";
let {REACT_APP_CUSTOMER_API} =  process.env;
// @ts-ignore
export const Customers = () => {
    const tableRef = useRef();
    const [customers, setCustomers] = React.useState<CustomerType[] | []>([]);
    const [open, setOpen] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = React.useState<CustomerType | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const handleSubmit = async (event: React.SyntheticEvent) => {
        try {
            event.preventDefault();
            const target = event.target as typeof event.target & {
                email: { value: string };
                firstname: { value: string };
                name: { value: string };
                mainSite: { value: string };
                site: { value: string };
            }
            if ( selectedCustomer ) {
                const tmpSelectedCustomer = {...selectedCustomer,
                    email: target.email.value,
                    firstname: target.firstname.value,
                    name: target.name.value,
                    mainSite: target.mainSite.value,
                    site: target.site.value,
                    fullname: `${target.firstname.value} ${target.name.value}`
                }
                setCustomers(customers.map( (customer: CustomerType) => {
                    if ( customer._id === selectedCustomer._id) {
                        return tmpSelectedCustomer;
                    }
                    return customer;
                }))
                const result = await Api({
                    name: 'Customers::handleSubmit',
                    type: 'POST',
                    url: `${REACT_APP_CUSTOMER_API}/${tmpSelectedCustomer._id}`,
                    body: tmpSelectedCustomer
                })
                if ( result ) {
                    setOpen(false);
                }
            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/customers/handleSubmit', "Cannot save customer", enqueueSnackbar);
        }
    }

    const getData = async () => {
        try {
            const res = await Api({
                name: 'getCustomers',
                url: `${REACT_APP_CUSTOMER_API}customers/`
            })
            if ( res ) {
                setCustomers(res.data.records);
            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/customers/getData', "Cannot get customers", enqueueSnackbar);
        }

    }
    useEffect( () => {
        ( async () => {
         await getData();
        })();
    }, [])
    const EditCustomer = () => {
        if (selectedCustomer) {
            return (
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    autoFocus
                    sx={{marginRight: '10px', width: 'calc(50% - 5px)'}}
                    margin="dense"
                    id="firstname"
                    label="Firstname"
                    type="text"
                    variant="standard"
                    defaultValue={selectedCustomer.firstname}
                />
            <TextField
                margin="dense"
                sx={{width: 'calc(50% - 5px)'}}
                id="name"
                label="Last name"
                type="text"
                variant="standard"
                defaultValue={selectedCustomer.name}
            />
            <TextField
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                defaultValue={selectedCustomer.email}
            />
            <TextField
                margin="dense"
                id="mainSite"
                label="Hospital / main site"
                type="text"
                variant="standard"
                defaultValue={selectedCustomer.mainSite}
                sx={{marginRight: '10px', width: 'calc(50% - 5px)'}}
            />
            <TextField
                margin="dense"
                id="site"
                label="Department / Site"
                type="text"
                variant="standard"
                defaultValue={selectedCustomer.site}
                sx={{width: 'calc(50% - 5px)'}}
            />
                    <div style={{ textAlign: 'right', padding: 8, margin: '24px -24px -24px -24px' }}>
                        <Button
                            type="reset"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, mr: 2 }}
                            onClick={()=> {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </Button>
                    </div>
                </Box>
            )
        } else {
            return(<div/>);
        }
    }
    // @ts-ignore
    return (
        <Root>
            <AppBarSpacer />
            <Grid container spacing={3}>
                {/* Recent Orders */}
                <Grid item xs={12}>
                    <StyledPaper>
                        <MaterialTable
                            title='Customers'
                            tableRef={tableRef}
                            columns={[
                                { title: 'Name', field: 'fullname'},
                                { title: 'Status', field: 'status'  },
                                { title: 'Type', field: 'type'  },
                                { title: 'Email', field: 'email'  },
                                { title: 'Potential', field: 'potential'},
                                // @ts-ignore
                                { title: 'Specialty', field: 'specialty' },
                                { title: 'Hospital', field: 'mainSite' },
                                { title: 'Country', field: 'country'  },
                                { title: 'Site', field: 'site'  },
                                { title: 'City', field: 'city' },
                                { title: 'Brick', field: 'brick' },
                                { title: 'External System', field: 'externalSystem' },
                            ]}
                            data={customers}
                            options={{
                                search: true,
                                minBodyHeight: '80vh',
                                maxBodyHeight: '80vh',
                                pageSizeOptions: [100, 250, 500],
                                pageSize: 250,
                                paging: true,
                                filtering: true,
                                selection: false
                            }}
                            onRowClick={(event, rowData, togglePanel) => {
                                setOpen(true);
                                if (rowData) {
                                    setSelectedCustomer(rowData);
                                }

                            }}
                        />
                    </StyledPaper>
                </Grid>
            </Grid>
            <Dialog open={open} maxWidth={'md'}>
                <DialogTitle>Update customer</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update customer
                    </DialogContentText>
                    <EditCustomer/>
                </DialogContent>
            </Dialog>
        </Root>
    )
}

import React, {useContext, useEffect} from "react";
import Grid from "@mui/material/Grid";
import {
    TextField,
    Autocomplete,
    Checkbox, FormControlLabel
} from "@mui/material/";

import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {sv, nn, da, fi} from 'date-fns/locale';
import {ComboBox} from "../../../../components/FormElements";
import {EventInputType} from "./index";
import {ActivityContext} from "../../ActivityContext";
import {format, isBefore} from "date-fns";
import {AppContext} from "../../../../AppContext";
const timeSlots = Array.from(new Array(24 * 12)).map(
    (_, index) =>
        `${`0${Math.floor(index / 12)}`.slice(-2)}:${
            index % 12 === 0 ? '00' : `0${index % 12 * 5}`.slice(-2)
        }`,
);

export const EventDateTime = () => {
    const {user} = useContext(AppContext);
    const {activity, setActivityPayload, isArchived, isEditor}  = useContext(ActivityContext);
    const [startTime, setStartTime] = React.useState('12:00');
    const [endTime, setEndTime] = React.useState('13:00');
    const [local, setLocal] = React.useState(sv)
    useEffect( () => {
        setStartTime(format(new Date(activity.start), 'HH:mm'))
        setEndTime(format(new Date(activity.end), 'HH:mm'))
        if ( user?.countries) {
            const country = user?.countries[0];
            if (country === 'DK')
                setLocal(da);
            if (country === 'FI')
                setLocal(fi);
            if (country === 'NO')
                setLocal(nn);
        }

    }, [])
    const changeStart = (newDateTime: any) => {
        try{
            if (newDateTime instanceof Date && !isNaN(newDateTime.getTime()) ) {
                const start = new Date(activity.start);
                const end = new Date(activity.end);
                const diffTime = end.getTime() - start.getTime();
                end.setTime(newDateTime.getTime() + diffTime);
                const start_registration = new Date(newDateTime);
                const start_survey = new Date(end);
                const send_invitation_reminder = new Date(newDateTime);
                send_invitation_reminder.setDate(send_invitation_reminder.getDate() - 30);
                const send_webinar_url = new Date(newDateTime);
                send_webinar_url.setDate(send_webinar_url.getDate() - 1);
                const end_registration = new Date(newDateTime);
                const end_survey = new Date(end);
                const send_survey_url = new Date(end);
                send_survey_url.setTime(send_survey_url.getTime() + (10*60000));
                start_registration.setDate(start_registration.getDate() - 90);
                end_survey.setDate(end_survey.getDate() + 7);
                setActivityPayload(
                    {
                        refreshActivity: true,
                        payload: {
                            'start': newDateTime.toUTCString(),
                            'end': end.toUTCString(),
                            'start_registration': start_registration.toUTCString(),
                            'end_registration': end_registration.toUTCString(),
                            'start_survey': start_survey.toUTCString(),
                            'end_survey': end_survey.toUTCString(),
                            'scheduled_invitation': {
                                ...activity.scheduled_invitation,
                                scheduledDate: start_registration.toUTCString(),
                                scheduledReminderDate: send_invitation_reminder.toUTCString(),
                            },
                            'scheduled_webinar_url': {
                                ...activity.scheduled_webinar_url,
                                scheduledDate: send_webinar_url.toUTCString(),
                            },
                            'scheduled_survey_url': {
                                ...activity.scheduled_survey_url,
                                scheduledDate: send_survey_url.toUTCString(),
                            }
                        }}
                );
                setStartTime(format(newDateTime, 'HH:mm'))
                setEndTime(format(end, 'HH:mm'));
            }

        }catch (e: any) {
            console.error(e.toString())
        }
    }
    const changeEnd = (newDateTime: any) => {
        try{
            if (newDateTime instanceof Date && !isNaN(newDateTime.getTime()) ) {
                setActivityPayload({refreshActivity: true, payload: {end: newDateTime.toUTCString()}});
            }

        }catch (e: any) {
            console.error(e.toString())
        }
    }
    return (
        <React.Fragment>
            {
                activity?.templateSuite?.type === 'Event' &&
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <Grid container spacing={1} direction="row" >
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={local}>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        label="Start"
                                        disabled={isArchived || !isEditor}
                                        value={new Date(activity.start)}
                                        onChange={changeStart}
                                        slots={{
                                        textField: (params) => <TextField variant="standard" {...params} />
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        id="starttime"
                                        options={timeSlots}
                                        fullWidth
                                        selectOnFocus
                                        autoSelect
                                        value={startTime}
                                        onChange={(e: any, val: any) => {
                                            changeStart(new Date(format(new Date(activity.start), 'yyyy-MM-dd')+ ' ' + val))
                                        }}
                                        disabled={isArchived || !isEditor}
                                        renderInput={(params) => <TextField sx={{pb: '2px'}} variant="standard" {...params} label="Starttime" />}
                                    />

                                </Grid>
                                <Grid item xs={6} >
                                    <DesktopDatePicker

                                        label="End"
                                        disabled={isArchived || !isEditor}
                                        value={new Date(activity.end)}
                                        minDate={new Date(activity.start)}
                                        onChange={changeEnd}
                                        slots={{
                                            textField: (params) => <TextField variant="standard" {...params} />
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        id="endtime"
                                        options={timeSlots}
                                        fullWidth
                                        selectOnFocus
                                        autoSelect
                                        value={endTime}
                                        onChange={(e: any, val: any) => {
                                            const newDate = format(new Date(activity.end), 'yyyy-MM-dd')+ ' ' + val;
                                            if(!isBefore(new Date(newDate), new Date(activity.start))) {
                                                setActivityPayload({refreshActivity: false, payload: {end: (new Date(newDate).toUTCString())}});
                                                setEndTime(val);
                                            } else {
                                                alert('The meeting cannot end before it has started :)')
                                                setEndTime(endTime);
                                                return false;
                                            }
                                        }}
                                        disabled={isArchived || !isEditor}
                                        renderInput={(params) => <TextField  variant="standard" {...params} label="Endtime" />}
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}

export const EventAdvancedSettings = ({listOfValues}: EventInputType) => {
    const {activity, setActivityPayload, isReadOnly, isArchived, isEditor}  = useContext(ActivityContext);

    return (
        <React.Fragment>
            {
                activity?.templateSuite?.type === 'Event' &&
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControlLabel control={
                            <Checkbox
                                value={activity.jointEvent}
                                checked={activity.jointEvent}
                                disabled={isArchived || !isEditor}
                                onChange={(e: any, val: any) => {
                                    setActivityPayload({refreshActivity: true, payload:
                                            {jointEvent: val }});
                                }} />} label="Joint Event" />
                    </Grid>
                    <Grid item xs={12} hidden={activity.jointEvent}>
                        <ComboBox
                            options={listOfValues.brand}
                            multiple={false}
                            required={false}
                            label="Brand"
                            placeholder="Select brand"
                            disabled={isArchived || !isEditor}
                            value={activity.brand && {value: activity.brand}}
                            onChange={(e: any, val: any) => {
                                setActivityPayload({refreshActivity: true, payload: {brand: val?.value??null}});
                            }} />
                    </Grid>
                    <Grid item xs={12}>
                        <ComboBox
                            options={listOfValues.templates.filter( (t: any) => {
                                if (t.value === 'Pre-event info')
                                    return activity.templateSuite.templates?.find((t: TemplateType) => t.type === 'E-MAIL PRE-EVENT INFO')
                                if (t.value === 'Save the date')
                                    return activity.templateSuite.templates?.find((t: TemplateType) => t.type === 'E-MAIL SAVE-THE-DATE')
                                return true;
                            })}
                            required={true}
                            label="Templates"
                            placeholder="Select template(s)"
                            disabled={isArchived || !isEditor}
                            value={activity.selectedTemplates.map( (template: any) => {
                                return {
                                    value: template
                                }
                            })}
                            onChange={(e: any, val: any) => {
                                //Make sure to never allow a scenario with only invitation
                                if ( val && val.find( (v: any) => v.value === 'Invitation') && !val.find( (v: any) => v.value === 'Registration')) {
                                    val.push({value: 'Registration'})
                                }
                                setActivityPayload({refreshActivity: true, payload:
                                        {selectedTemplates: val.map( (v:any) => {
                                                return v.value
                                            })}});
                            }} />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}

import Card from "@mui/material/Card";
import {CardActionArea} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import PropTypes, {InferProps} from "prop-types";

export const TemplateSuiteCard: any = ({imageURL, topHeader,headline, description, handleClick}: InferProps<typeof TemplateSuiteCard.propTypes>) => {

    return (
        <Card sx={{ minWidth: 250,maxWidth: 250, minHeight: 240, maxHeight: '240px',marginTop: '80px', marginLeft: '20px' }}>
            <CardActionArea sx={{maxHeight: '240px'}} onClick={handleClick}>
                {
                    imageURL
                        ?
                        <CardMedia
                            component="img"
                            sx={{
                                width: "auto",
                                maxHeight: "97px"
                            }}
                            image={imageURL}
                            alt=""
                        />
                        : <CardContent sx={{height: 100, backgroundColor: 'darkgray', color: 'white', textAlign: 'center', padding: 0}} dangerouslySetInnerHTML={{__html: topHeader}}/>
                }
                <CardContent sx={{textAlign: 'left'}}>
                    <Typography variant="h5" component="div">{headline}</Typography>
                    <Typography variant="body2" color="text.secondary">{description}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
TemplateSuiteCard.propTypes = {
    imageURL: PropTypes.string.isRequired,
    topHeader: PropTypes.string,
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
};

import {FormControlLabel, Grid, IconButton, Switch, Tooltip} from "@mui/material";
import {
    OpenRegistrationForm,
    QuestionDialog,
    SendTemplateAsPDf,
    WebFormSchedule
} from "../dialogs";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import {getColorPreset, getPrintFragmentFooter, handleReadOnlyUpdate} from "../methods";
import React, {useContext, useEffect} from "react";
import {ActivityContext} from "../../../ActivityContext";
import {TemplateSuiteContext} from "../TemplateSuiteContext";
import {handleChange} from "./methods";
import {addQuestion, deleteQuestion, handleQuestionChange} from "./methods_survey";
import {FieldEditDialog, FieldsEditDialog} from "../FieldEditDialog";
import {
    addField,
    deleteField,
    handleTemplateFieldChange, handleTemplateFieldsChange, moveFieldDown,
    moveFieldUp
} from "./methods_registration";
import {AppContext} from "../../../../../AppContext";
import {LimitParticipants} from "./LimitParticipants";
const {REACT_APP_PUBLIC_FRONT, REACT_APP_UPLOAD_BUCKET} = process.env;

interface WebFormInput {
    templateReadOnly: boolean;
    template: TemplateType;
    country: string;
    setTemplateReadOnly: any
    setUploadImage: any;
    templateFields: TemplateFieldType[];
    setTemplateFields: any;
    setUpdateField:any;
    clearUpdatedField: any
    updateField: any;
}
enum TypeOfForm {
    REGISTRATION = 'REGISTRATION',
    SURVEY = 'SURVEY',
    PRINT_INVITATION = 'PRINT INVITATION',
    SURVEY_SLIDE = 'SURVEY SLIDE'
}
const getPath = (type: string) => {
    switch (type) {
        case TypeOfForm.REGISTRATION:
            return 'register';
        case TypeOfForm.SURVEY:
            return 'survey';
        case TypeOfForm.PRINT_INVITATION:
            return 'print/invitation';
        case TypeOfForm.SURVEY_SLIDE:
            return 'print/survey';
    }
}
export const WebForm = (
    { updateField,clearUpdatedField, setUpdateField, setTemplateFields, templateFields,setUploadImage,templateReadOnly, template, country, setTemplateReadOnly}:WebFormInput) => {
    const {activity,  isEditor, isArchived, setActivityPayload, setActivity} = useContext(ActivityContext);
    const {templateSuite, handleTemplateFieldUpdate, handleTemplateUpdateV2, isUsingFragmentFooter, footer} = useContext(TemplateSuiteContext);
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const [url, setURL] = React.useState('');
    const [previewSRC, setPreviewSRC] = React.useState('');
    const [moveField, setMoveField] = React.useState({name: '', direction: ''});
    const [addInputField, setAddInputField] = React.useState(false);
    const [deleteInputField, setDeleteInputField] = React.useState({name: ''});
    const [updateQuestions, setUpdateQuestions] = React.useState(false);
    const [updateTemplateFields, setUpdateTemplateFields] = React.useState(false);
    const path = getPath(template.type)
    const [printFooter, setPrintFooter] = React.useState('');
    const [speakers, setSpeakers] = React.useState<any[]>(
        activity?.speakers?.map( (s: any) => {
          return {
              ...s,
              image: s.image ? `${REACT_APP_UPLOAD_BUCKET}${s.image}` :  `${REACT_APP_UPLOAD_BUCKET}speaker/${s._id}.png`
          }
        })

    );

    useEffect( () => {
        const colorPreset: ColorPresetBrandType = getColorPreset(activity, activity.templateSuite);

        const __src = `${REACT_APP_PUBLIC_FRONT}${path}/`;
        setURL(`${__src}${activity._id}`);
        //This should be previewed in an external site
        setPreviewSRC(`${__src}/PREVIEW`);
        if (__src) {
            setURL(`${REACT_APP_PUBLIC_FRONT}${path}/${activity._id}`);
            //This should be previewed in an external site
            setPreviewSRC(`${REACT_APP_PUBLIC_FRONT}${path}/PREVIEW`);
        }
        let iframe = document.getElementById('templatePreview');
        if (template.type !== TypeOfForm.PRINT_INVITATION) {
            setTimeout(() => {
                // @ts-ignore
                iframe?.contentWindow?.postMessage(
                    {
                        type: "updatePreview",
                        fields: templateFields,
                        country: country ?? activity?.countries[0],
                        Speakers: speakers,
                        isEditor: isEditor && !templateReadOnly && !isArchived,
                        activityId: activity._id,
                        enabled: template.enabled,
                        template: template,
                        colorPreset,
                        jointEvent: activity?.jointEvent ?? false,
                        smallLogoImages: templateSuite.smallLogoImages,
                        Footer: template.type === TypeOfForm.PRINT_INVITATION ? printFooter : isUsingFragmentFooter ? footer : ''
                    }, "*");
            }, 1000)
        }


        const eListener = (event:any) => {
            if (event.data.type) {
                switch (event.data.type) {
                    case 'addTemplateInputField':
                        setAddInputField(true)
                        break;
                    case 'deleteTemplateInputField':
                        if (event.data.name) {
                            setDeleteInputField({name: event.data.name})
                        }
                        break;
                    case 'moveFieldUp':
                        setMoveField({name: event.data.name, direction: 'up'});
                        break;
                    case 'moveFieldDown':
                        setMoveField({name: event.data.name, direction: 'down'});
                        break;
                }
            }
        }
        window.addEventListener("message", eListener, false);
        return () => {
            window.removeEventListener("message", eListener, false);
        };
    }, [])
    useEffect( () => {
            const colorPreset: ColorPresetBrandType = getColorPreset(activity, activity.templateSuite);
            //This should be previewed in an external site
            // @ts-ignore
            let iframe = document.getElementById('templatePreview').contentWindow;
            console.log('iframe', iframe)
            iframe.postMessage(
                {type: "updatePreview",
                    fields: templateFields,
                    Speakers: speakers,
                    country: country??activity?.countries[0],
                    activityId: activity._id,
                    isEditor: isEditor && !templateReadOnly && !isArchived,
                    template: template,
                    enabled: template.enabled,
                    colorPreset,
                    jointEvent: activity?.jointEvent??false,
                    smallLogoImages: templateSuite.smallLogoImages,
                    Footer: template.type === TypeOfForm.PRINT_INVITATION ? printFooter : isUsingFragmentFooter ? footer : ''
                }, "*");


    }, [templateFields, templateReadOnly, printFooter])

    useEffect( () => {
        if (addInputField) {
            addField({
                templateFields, template, setUpdateField, handleTemplateFieldUpdate
            });
            setAddInputField(false);
        }

    }, [addInputField])

    useEffect( () => {
        if ( moveField.name) {
            if (moveField.direction === 'up')
                moveFieldUp(moveField.name, templateFields, handleTemplateFieldUpdate, template);
            if (moveField.direction === 'down')
                moveFieldDown(moveField.name, templateFields, handleTemplateFieldUpdate, template);
            setMoveField({name: '', direction: ''});
        }
    }, [moveField])


    useEffect( () => {
        if (deleteInputField.name) {
            deleteField(deleteInputField.name, template, handleTemplateFieldUpdate, setTemplateFields);
            setDeleteInputField({name: ''})
        }
    }, [deleteInputField])
    useEffect( () => {
        if (template.type === TypeOfForm.PRINT_INVITATION) {
            (async () => {
                const printFoot = await getPrintFragmentFooter({country, activity});
                setPrintFooter(printFoot);
            })()
        }
    }, [templateFields])
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6} textAlign={'left'}>
                    {
                        template.type === TypeOfForm.PRINT_INVITATION &&
                        <Tooltip title="When enabled, synchronization of content between E-MAIL Invitation and PRINT Invitation will stop">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={!isEditor || isArchived || template.enabled}
                                        onChange={ (event) => handleTemplateUpdateV2({
                                            template,
                                            activity,
                                            setActivityPayload,
                                            fieldName: 'enabled',
                                            fieldValue: event.target.checked,
                                            country
                                        })}
                                        value={template.enabled}
                                        checked={template.enabled} />
                                } label="Enabled" />
                        </Tooltip>
                    }
                    {
                        template.type === TypeOfForm.PRINT_INVITATION &&
                        <Tooltip title="When enabled, agneda will be displayed">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={!isEditor || isArchived || !template.enabled || templateReadOnly}
                                        onChange={ (event) => handleTemplateUpdateV2({
                                            template,
                                            activity,
                                            setActivityPayload,
                                            fieldName: 'displayAgenda',
                                            fieldValue: event.target.checked,
                                            country
                                        })}
                                        value={template.displayAgenda}
                                        checked={template.displayAgenda} />
                                } label="Display agenda" />
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right'}}>
                    {
                            template.type === TypeOfForm.REGISTRATION && <LimitParticipants template={template} country={country} templateFields={templateFields}/>
                    }
                    {
                         isEditor && template.type !== TypeOfForm.PRINT_INVITATION && template.type !== TypeOfForm.SURVEY_SLIDE &&
                         <WebFormSchedule activity={activity} setActivityPayload={setActivityPayload} isReg={template.type === TypeOfForm.REGISTRATION} isArchived={isArchived??false}/>
                    }


                    {
                        template.type === TypeOfForm.REGISTRATION &&
                            <OpenRegistrationForm country={activity.countries.length === 1 ? activity.countries[0] : false} url={url}/>

                    }
                    {
                        template.type === TypeOfForm.SURVEY &&
                            <Tooltip title="Open survey form in new window">
                                <IconButton aria-label="Open survey form in new window" onClick={() => window.open(url)} >
                                    <OpenInNewIcon sx={{fontSize: 30}}/>
                                </IconButton>
                            </Tooltip>

                    }
                    {
                        !templateReadOnly && template.type === 'SURVEY' && isEditor &&
                        <Tooltip title="Edit form">
                            <IconButton onClick={()=>setUpdateQuestions(true)}>
                                <EditIcon sx={{fontSize: 30}}/>
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        !templateReadOnly && template.type === 'REGISTRATION' && isEditor &&
                        <Tooltip title="Advanced edit">
                            <IconButton onClick={()=>activity.createdAt > '2022-08-24' ? setUpdateTemplateFields(true) : setUpdateQuestions(true) }>
                                <EditIcon sx={{fontSize: 30}}/>
                            </IconButton>
                        </Tooltip>
                    }

                    <SendTemplateAsPDf activity={activity} country={country} template={template}/>
                    <Tooltip title="When you are done, change switch to Approved to prevent future changes">
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={!isEditor || isArchived}
                                    onChange={ (event) => handleReadOnlyUpdate(event, country, template, handleTemplateUpdateV2, setTemplateReadOnly)}
                                    value={templateReadOnly}
                                    checked={templateReadOnly} />
                            } label="Done" />
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid >
                <iframe style={{height: template.type === TypeOfForm.PRINT_INVITATION || template.type === TypeOfForm.SURVEY_SLIDE ? '330mm' : '2800px', width: '100%', overflow: 'auto'}} frameBorder={0} id="templatePreview" src={previewSRC}></iframe>
            </Grid>
            <QuestionDialog
                setUploadImage={setUploadImage}
                open={updateQuestions}
                template={template}
                handleChange={(field: TemplateFieldType, fieldValue: any) => handleChange(field, fieldValue, handleTemplateFieldUpdate, template)}
                addQuestion={() => addQuestion({template, handleTemplateFieldUpdate, setTemplateFields, setActivity, activityId: activity._id, setWaitingForBackendMessage}) }
                deleteQuestion={(_id: string) => deleteQuestion({template, handleTemplateFieldUpdate, setTemplateFields, _id})}
                handleQuestionChange={(event: any, _id: string, name: string, value: any) => handleQuestionChange({event, _id, name, value, template, templateFields, setTemplateFields, handleTemplateFieldUpdate})}
                setUpdateQuestions={setUpdateQuestions}/>
            <FieldsEditDialog
                setUploadImage={setUploadImage}
                open={updateTemplateFields}
                template={template}
                deleteField={(_id: string) => deleteField(_id, template, handleTemplateFieldUpdate, setTemplateFields)}
                handleTemplateFieldChange={
                    (updatedFields: TemplateFieldType[]) => handleTemplateFieldsChange(updatedFields, setUpdateTemplateFields, clearUpdatedField, handleTemplateFieldUpdate, template)}
                setUpdateTemplateFields={setUpdateTemplateFields}
                templateFields={templateFields}
            />
            <FieldEditDialog
                setUploadImage={setUploadImage}
                fieldName={updateField.fieldName}
                open={updateField.open && updateField.fieldType !== 'Text' && updateField.fieldType !== 'Image' && updateField.fieldType !== 'HTML' && updateField.fieldType !== '' && updateField.fieldType !== 'CTA'}
                setOpen={clearUpdatedField}
                template={template}
                handleTemplateFieldChange={
                    (updatedField: TemplateFieldType) => handleTemplateFieldChange(updatedField, templateFields, clearUpdatedField, handleTemplateFieldUpdate, template)}
                templateField={templateFields.find((field: TemplateFieldType) => field.name === updateField.fieldName)}
            />
        </React.Fragment>
    )
}

import * as React from "react";
import {Box, FormControlLabel, IconButton, Popover, Stack, Switch, Tooltip} from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import {DateTimePicker} from "../../../../../components/DateTimePicker";
import {FormatDateFromUTC} from "../../../methods";
import DoneIcon from "@mui/icons-material/Done";

interface EmailScheduleInput {
    reminder: boolean;
    fieldName: string;
    label: string;
    activity: any;
    setActivityPayload: any;
    isArchived: boolean;
}
export const EmailSchedule = ({reminder, label, fieldName, activity, setActivityPayload,isArchived }: EmailScheduleInput) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [isEnabled, setIsEnabled] = React.useState<boolean>(activity[fieldName]?.enabled)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <React.Fragment>
            <Tooltip title="Schedule send e-mail">
                <IconButton aria-label="Schedule" onClick={handleClick} >
                    <ScheduleSendIcon sx={{fontSize: 30}}/>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{padding: '15px', minWidth: '300px'}}>
                    <Tooltip title="Schedule send">
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={isArchived}
                                    onChange={ (event) => {
                                        setActivityPayload({refreshActivity: true, payload: {[fieldName]: {...activity[fieldName],enabled: event.target.checked}}})
                                        setIsEnabled(event.target.checked);
                                    }}
                                    value={isEnabled}
                                    checked={isEnabled} />} label="Schedule send" />
                    </Tooltip>
                    {

                    }
                    <Stack direction={'row'} spacing={3}   justifyContent="center" alignItems="center" >
                        <DateTimePicker
                            label={label}
                            date={activity[fieldName]?.scheduledDate??new Date}
                            setDate={(newDateTime: Date) => setActivityPayload({refreshActivity: true, payload: {[fieldName]: {...activity[fieldName],scheduledDate: newDateTime.toUTCString()}}})}
                            disabled={(isArchived??false) || !isEnabled || activity[fieldName]?.isSent}
                        />
                            <Tooltip title={`Email(s) sent ${FormatDateFromUTC(activity[fieldName]?.sentDate)}`}>
                                {
                                    activity[fieldName]?.isSent ? <DoneIcon color="success" fontSize="large" /> : <ScheduleSendIcon fontSize="large"/>
                                }
                            </Tooltip>
                    </Stack>
                    <Stack direction={'row'} spacing={3}   justifyContent="center" alignItems="center" >
                        {
                            reminder &&
                            <DateTimePicker
                                label={'Send reminder'}
                                date={activity[fieldName]?.scheduledReminderDate??new Date}
                                setDate={(newDateTime: Date) => setActivityPayload({refreshActivity: true, payload: {[fieldName]: {...activity[fieldName],scheduledReminderDate: newDateTime.toUTCString()}}})}
                                disabled={(isArchived??false) || !isEnabled || activity[fieldName]?.reminderIsSent}
                            />
                        }
                        {
                            reminder &&
                            <Tooltip title={`Reminder email(s) sent ${FormatDateFromUTC(activity[fieldName]?.sentReminderDate)}`}>
                                {
                                    activity[fieldName]?.reminderIsSent ? <DoneIcon color="success" fontSize="large" /> : <ScheduleSendIcon fontSize="large"/>
                                }
                            </Tooltip>
                        }

                    </Stack>
                </Box>

            </Popover>
        </React.Fragment>

    )
}

import React, {useEffect, useState} from 'react';
import { DataGridPro, GridColDef, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import {Api} from "../../Api";
const {REACT_APP_TEMPLATE_API} =  process.env;
export const ListOfValue = () => {
    const [rows, setRows] = useState<ListOfValueType[]>([]);
    const [contexts] = useState<string[]>(['System', 'Front', 'Application']);
    const [countries, setCountries] = useState<string[]>(['SE', 'DK', 'NO', 'FI']);
    const getLovs = async () => {
        const res = await Api({name: 'ListOfValue:getData', url: `${REACT_APP_TEMPLATE_API}lov`})
        if (res) setRows(res.data);
        setCountries(res.data.filter((row: ListOfValueType) => row.type === 'Country').map((row: ListOfValueType) => row.value));
    }
    const upsertRow = async (newRow: ListOfValueType) => {
        await Api({name: 'Templates:upsertData', url: `${REACT_APP_TEMPLATE_API}lov`, body: newRow, type: 'POST'})
        if (!newRow._id) await getLovs();
        else setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? newRow : row)));
    }
    const handleAddRow = async () => {
        const newRow: ListOfValueType = {
            context: 'System',
            type: '',
            value: '',
            description: '',
            active: true,
            sort: rows.length + 1,
            country: ''
        };
        await upsertRow(newRow);
    };

    const handleProcessRowUpdate = async (newRow: any) => {
        await upsertRow(newRow);
        return newRow;
    };

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90, filterable: true },
        { field: 'context', headerName: 'Context', width: 130, editable: true, type: 'singleSelect', valueOptions: contexts, filterable: true },
        { field: 'type', headerName: 'Type', width: 130, editable: true, filterable: true },
        { field: 'value', headerName: 'Value', width: 130, editable: true, filterable: true },
        { field: 'description', headerName: 'Description', width: 130, editable: true, filterable: true },
        { field: 'active', headerName: 'Active', width: 130, editable: true, type: 'boolean', filterable: true },
        { field: 'sort', headerName: 'Sort', width: 130, editable: true, type: 'number', filterable: true },
        { field: 'parent', headerName: 'Parent', width: 130, editable: true, filterable: true },
        { field: 'defaultValue', headerName: 'Default Value', width: 130, editable: true, filterable: true },
        {
            field: 'createdAt', filterable: true,
            headerName: 'Created',
            width: 150,
            type: 'date',
            valueGetter: ( value ) => value && new Date(value),
            valueFormatter: ( value ) => value && new Intl.DateTimeFormat('sv-SE').format(new Date(value))} ,
        {
            field: 'updatedAt', filterable: true,
            headerName: 'Updated',
            width: 150,
            type: 'date',
            valueGetter: ( value ) => value && new Date(value),
            valueFormatter: ( value ) => value && new Intl.DateTimeFormat('sv-SE').format(new Date(value)),
        },
        { field: 'country', headerName: 'Country', width: 130, editable: true, type: 'singleSelect', valueOptions: countries },
    ];
    const CustomToolbar = ({ handleAddRow }: {handleAddRow: any}) => {
        return (
            <GridToolbarContainer>
                <Button color="primary" onClick={handleAddRow}>
                    Add New LOV
                </Button>
            </GridToolbarContainer>
        );
    };

    useEffect(() => {
        (async () => {
            await getLovs();
        })();
    }, [])
    return (
            <DataGridPro
                slots={{ toolbar: () => <CustomToolbar handleAddRow={handleAddRow} />, headerFilterMenu: null, }}
                rows={rows.length ? rows : []}
                columns={columns}
                processRowUpdate={handleProcessRowUpdate}
                autoHeight
                disableColumnFilter
                columnVisibilityModel={{
                    id: false,
                }}
                headerFilters
                sx={{ mt: '95px',  ml: '30px', mr: '30px' }}
            />

    );
};
import React from 'react';
import {Box, Typography, CircularProgress, List, ListItem, ListItemText} from '@mui/material';
import {useMsal} from "@azure/msal-react";
export enum Status {
    SUCCESS = 200,
    NOT_AUTHENTICATED = 401,
    NOT_AUTHORIZED = 403,
    PROCESSING = 102,
    AZURE_ERROR = 999,
}
type Messages = {
    [key: number]: {
        title: string,
        subtitle?: string,
        body?: string,
        instructions?: string[]
    }
};

const messages: Messages = {
    403: {
        title: "Access Denied",
        body: "You're employed by Tillotts, but your access to this dashboard is limited based on your region. Currently, this dashboard is exclusively available to our employees in the Nordics. If you believe this is an error, please follow the steps below."
    },
    401: {
        title: "Authentication Failed",
        body: "This application is exclusively for Tillotts employees. It seems you're not authenticated. Please log in to gain access. If you believe this is an error, please follow the steps below."
    },
    500: {
        title: "An error occurred",
        body: `It is not you, it is us, we apologize, but something went wrong, please follow the steps below.`
    },
    102: {
        title: "Authenticating",
        body: "Hang tight! We're currently verifying your credentials. You will have access shortly."
    },
    999: {
        title: "An error occurred",
        body: `It is not you, it is us, we apologize, but something went wrong, please follow the steps below.`
    }
};

export const MessageDisplay = ({ type = Status.PROCESSING, reason = "", ...otherProps }) => {
    const { instance } = useMsal();
    const instructions = [
        '1. Start by refreshing the page.',
        '2. If the problem continues, try closing and reopening your browser.',
        '3. Should the issue persist, consider restarting your computer.',
        '4. If you\'re still facing the problem, try to log out ',
        '5. Finally, if all above fails, kindly contact support (Joakim) and provide him with the following information -> '
    ];
    const handleLogout = () => {
        instance.logoutPopup().then(() => {
        }).catch(e => {
            console.error(e);
        });
    };
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width="100%"
            bgcolor="background.default"
        >
            <Box width={'500px'}>
                <Typography variant="h3" color="primary.main" gutterBottom align={'center'}>
                    {messages[type].title}
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom align={'left'}>
                    {messages[type].body}
                </Typography>

                <Typography component="div" variant="body2" color="text.primary" gutterBottom align={'left'}>
                    { type !== Status.PROCESSING &&
                        <Box >
                            {instructions?.map((instruction, index) => (
                                <List key={index}>
                                    <ListItem disablePadding>
                                        <ListItemText primary={
                                            <Box>
                                                {instruction}
                                                {instructions?.length === index +1 && reason && <i style={{color: "red"}}>{reason}
                                                </i>}
                                                {index === 3 && <button onClick={handleLogout}>Log out</button> }
                                            </Box>} />


                                    </ListItem>
                                </List>
                            ))}
                        </Box>
                    }
                    {type === Status.PROCESSING && (
                        <Box mt={2} alignItems={'center'}>
                            <CircularProgress color="primary" />
                        </Box>
                    )}
                </Typography>
            </Box>
        </Box>
    );
}

export const getMessageAsString = (type: Status, reason: string) => {
    return `${messages[type].title}: ${messages[type].body} ${reason && ' "' + reason + '"'}`
}


import {InferProps} from "prop-types";
import React, {useCallback, useContext, useEffect} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
    Paper
} from "@mui/material";

import {styled} from "@mui/material/styles";
import {
    EditDialog,
    EditFieldFullscreen,
    EditTextDialog
} from "../../../../components/EditDialog";
import {ActivityContext} from "../../ActivityContext";
import {PickImage, PickLogos} from "../../../Fragment/PickImage";
import {
    getFieldType
} from './WebForm/methods_registration'
import {
    handleHTMLChange,
    handleImageUpdate,
    handleQuillChange
} from './methods'
import {WebForm} from "./WebForm";
import {Email} from "./Email";
import {flattenJSON} from "../../methods";
import {TemplateContext} from "./TemplateContext";
const pretty = require('pretty');
export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto',
    marginTop: 'o0px',
    flexDirection: 'column',
    width: '900px',
    height: '99%'
}))

// @ts-ignore
export const Template = ({}: InferProps<typeof ActivityTemplate.propTypes>) => {
    const {activity, isArchived} = useContext(ActivityContext);
    const {handleTemplateSuiteChange, templateSuite, handleTemplateFieldUpdate, handleTemplateUpdateV2, template, country} = useContext(TemplateContext);

    const setTemplateField = (field: string) => {
        if (country && template?.countryVersion.find((cv: TemplateCountryVersion) => cv.country === country)) {
            return template?.countryVersion.find((cv: TemplateCountryVersion) => cv.country === country)[field];
        }
        return template[field];
    }
    const [uploadImage, setUploadImage] = React.useState({open: false, fieldName: '', imageWidth: 0, value: ''});
    const [updateField, setUpdateField] = React.useState({open: false, fieldName: '', fieldValue: '', fieldType: 'Text'});
    const [templateFields, setTemplateFields] = React.useState(setTemplateField('fields'));
    const [templateReadOnly, setTemplateReadOnly] = React.useState(setTemplateField('readOnly') || isArchived);
    const [templateSubject, setTemplateSubject] = React.useState(setTemplateField('subject'));
    const [isWebForm] = React.useState(['REGISTRATION', 'SURVEY', 'PRINT INVITATION', 'SURVEY SLIDE'].includes(template.type));

    const clearUpdatedField = () => {
        setUpdateField({open: false, fieldName: '', fieldValue: '', fieldType: 'Text'});
    }

    useEffect( () => {
        setTemplateFields(setTemplateField('fields'));
        setTemplateSubject(setTemplateField('subject'));
        setTemplateReadOnly(setTemplateField('readOnly') || isArchived);
    }, [template])

    useEffect( () => {
        setTemplateReadOnly(setTemplateField('readOnly') || isArchived);
    }, [template.readOnly])

    useEffect(() => {
        //Ugly fix for now, but this is because the issue that message is run in another context
        if (!updateField.open && updateField.fieldName && !updateField.fieldValue) {
            setUpdateField({...updateField, open: true, fieldValue: templateFields.find( (field: TemplateFieldType) => field.name === updateField.fieldName).value})
        }
    }, [updateField.fieldName])
    const eListener = useCallback((event:any) => {
        if (event.data.type) {
            switch (event.data.type) {
                case "updateTemplateField":
                    //This is a ugly fix, but we need to do this due to the fact that this function is run another context
                    setUpdateField({
                        open: false,
                        fieldName: event.data.fieldName,
                        fieldValue: '',
                        fieldType: event.data.fieldType??getFieldType(event.data.fieldName, templateFields)??'HTML'
                    });
                    break;
                case 'updateTemplateImage':
                    setUploadImage({
                        open: true,
                        fieldName: event.data.fieldName,
                        imageWidth: templateFields.find( (f: TemplateFieldType) => f.name===event.data.fieldName)?.imageWidth,
                        value: ''
                    });
                    break;
                case 'updateTemplateInputField':
                    setUpdateField({
                        open: false,
                        fieldName: event.data.fieldName,
                        fieldValue: '',
                        fieldType: event.data.fieldType
                    })
                    break;
            }
        }
    }, [templateFields, uploadImage])
    useEffect( () => {
        window.addEventListener("message", eListener, false);
        return () => {
            window.removeEventListener("message", eListener, false);
        };
    }, [])
    return (
            <React.Fragment key={template._id}>
                <CssBaseline />
                <StyledPaper elevation={1}>
                    {
                        isWebForm ?
                            <WebForm
                                template={template}
                                templateReadOnly={templateReadOnly}
                                setTemplateReadOnly={setTemplateReadOnly}
                                country={country}
                                setUpdateField={setUpdateField}
                                clearUpdatedField={clearUpdatedField}
                                templateFields={templateFields}
                                setTemplateFields={setTemplateFields}
                                setUploadImage={setUploadImage}
                                updateField={updateField}
                            />
                        :
                            <Email
                                template={template}
                                templateSubject={templateSubject}
                                templateReadOnly={templateReadOnly}
                                setTemplateReadOnly={setTemplateReadOnly}
                                setUpdateField={setUpdateField}
                                templateFields={templateFields}
                                country={country} />
                    }

                </StyledPaper>
                <PickLogos
                    open={uploadImage.open && uploadImage.fieldName === '__smallLogos'}
                    cancel={() => setUploadImage({open: false, fieldName: '', imageWidth: 0, value: ''})}
                    inputValue={templateSuite.smallLogoImages.filter( (fragment: FragmentType) => !fragment.description?.includes('__'))[0]}
                    afterUpload={(logo: FragmentType) => {
                        handleTemplateSuiteChange('smallLogoImages', [logo])
                        setUploadImage({open: false, fieldName: '', imageWidth: 0, value: ''})

                    }}
                />

                <PickImage
                    open={uploadImage.open && uploadImage.fieldName !== '__smallLogos'}
                    fieldName={uploadImage.fieldName}
                    cancel={() => setUploadImage({open: false, fieldName: '', imageWidth: 0, value: ''})}
                    object={'activity'}
                    _id={activity._id}
                    afterUpload={(filePath: string) => {
                        setUploadImage({...uploadImage, value: filePath});
                        handleImageUpdate(uploadImage.fieldName, filePath, templateFields, country, template, handleTemplateFieldUpdate);
                        setUploadImage({open: false, fieldName: '', imageWidth: 0, value: ''})
                    }}
                />
                <EditFieldFullscreen
                    fieldId={updateField.fieldName}
                    open={updateField.open && updateField.fieldName === 'html'}
                    label={"HTML"}
                    defaultValue={updateField.fieldValue}
                    setOpen={()=>setUpdateField({open: false, fieldName: '', fieldValue: '', fieldType: ''})}
                    setFieldValue={(updatedHtml: string) => handleHTMLChange(pretty(updatedHtml), handleTemplateUpdateV2, template, setUpdateField)}/>
                <EditFieldFullscreen
                    fieldId={updateField.fieldName}
                    open={updateField.open && updateField.fieldName === 'colorPresets'}
                    label={"ColorPresets"}
                    defaultValue={flattenJSON(updateField.fieldValue, 4)}
                    setOpen={()=>setUpdateField({open: false, fieldName: '', fieldValue: '', fieldType: ''})}
                    setFieldValue={(updatedColorPresets: string) => handleTemplateSuiteChange(updateField.fieldName, flattenJSON(updatedColorPresets))}/>
                <EditDialog
                    fieldId={updateField.fieldName}
                    open={updateField.open && updateField.fieldName !== 'html' && updateField.fieldName !== 'colorPresets' && (updateField.fieldType === 'HTML' || updateField.fieldType === '')}
                    defaultValue={updateField.fieldValue}
                    setOpen={clearUpdatedField}
                    setFieldValue={(updateFieldValue: any) => handleQuillChange({template, templateFields, setTemplateFields, updateFieldValue, updateField, setUpdateField, handleTemplateFieldUpdate, handleTemplateSuiteChange, templateSuite, country})}/>

                <EditTextDialog
                    fieldId={updateField.fieldName}
                    open={updateField.open && updateField.fieldName !== 'html' && updateField.fieldName !== 'colorPresets' && (updateField.fieldType === 'CTA' || updateField.fieldType === 'Text')}
                    defaultValue={updateField.fieldValue}
                    setOpen={clearUpdatedField}
                    setFieldValue={(updateFieldValue: any) => handleQuillChange({template, templateFields, setTemplateFields, updateFieldValue, updateField, setUpdateField, handleTemplateFieldUpdate, handleTemplateSuiteChange, templateSuite, country})}

                />
            </React.Fragment>
    );
}

Template.propTypes = {

};

import React, {useEffect} from 'react';
import {
    List, ListItem, Grid, Typography, Divider, ListItemText, ListItemAvatar,
    Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, Box
} from "@mui/material";
import {SelectSpeakers} from "./SelectSpeakers";
import PropTypes, {InferProps} from "prop-types";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import {styled} from "@mui/material/styles";
import {UpsertSpeaker} from "../../../../components/speaker";
const {REACT_APP_UPLOAD_BUCKET} =  process.env;
const StyledList = styled(List)(({ theme }) => ({
    width: '100%',
    overflow: 'auto',

    backgroundColor: theme.palette.background.paper,
}))

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(10),
    height: theme.spacing(10),
}))

// @ts-ignore
export const Speakers = ({activity, setActivityPayload, isReadOnly}: InferProps<typeof Speakers.propTypes>) => {
    const [open, setOpen] = React.useState(false);
    const [selectedSpeaker, setSelectedSpeaker] = React.useState<SpeakerType | undefined>(undefined);
    const [updatedSpeaker, setUpdatedSpeaker] = React.useState<SpeakerType | undefined>(undefined);
    const [createdSpeaker, setCreatedSpeaker] = React.useState<SpeakerType | undefined>(undefined);
    const deleteSpeaker = ( _id: string ) => {
        setActivityPayload({refreshActivity: true, payload: {
            speakers: activity.speakers.filter( (speaker: { _id: any; }) => speaker._id !== _id)}});
    }
    useEffect( () => {
        if (createdSpeaker && createdSpeaker._id !== 'CREATE') {
            //Check if speaker already exist
            if (!activity.speakers.find( (speaker: SpeakerType) => speaker._id === createdSpeaker._id)) {
                const tmpSpeakers = activity.speakers;
                tmpSpeakers.push(createdSpeaker);
                setActivityPayload({refreshActivity: true, payload: {speakers: tmpSpeakers}})
            }
        }
    }, [createdSpeaker])

    useEffect( () => {
        if (updatedSpeaker) {
            const tmpSpeakers = activity?.speakers.map( (speaker: SpeakerType) => speaker._id === updatedSpeaker?._id ? updatedSpeaker : speaker)
            setActivityPayload({refreshActivity: true, payload: {speakers: tmpSpeakers}})
        }


    }, [updatedSpeaker])
    useEffect( () => {
        if (selectedSpeaker?._id === 'CREATE') {
            setOpen(true);
        }
    }, [selectedSpeaker])
    const SpeakerField = ({field}: {field: string | undefined}) => {
        return (
            <React.Fragment>
                {
                    field && <React.Fragment>{field}<br/> </React.Fragment>
                }
            </React.Fragment>

        )
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <SelectSpeakers activity={activity} setActivityPayload={setActivityPayload} setSelectedSpeaker={setSelectedSpeaker} isReadOnly={isReadOnly} />
            </Grid>
            <Grid item >
                <StyledList >
                    {activity.speakers && activity.speakers.length >0 ? activity.speakers.slice().reverse().map( (speaker: SpeakerType) => {
                        return (
                            <React.Fragment key={speaker._id}>
                                <Box sx={{textAlign: 'right'}}>
                                    <IconButton disabled={isReadOnly} onClick={ () => {
                                        setOpen(true);
                                        setSelectedSpeaker(speaker)
                                    }} aria-label="edit">
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton disabled={isReadOnly} onClick={() => {deleteSpeaker(speaker._id)}} aria-label="delete">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>

                                <ListItem alignItems="flex-start" onClick={ () => {
                                    setOpen(true);
                                    setSelectedSpeaker(speaker)
                                }} >
                                    <ListItemAvatar>
                                        <StyledAvatar alt="Remy Sharp" src={`${REACT_APP_UPLOAD_BUCKET}${speaker.image ? `${speaker.image}` : `speaker/${speaker._id}.png`}`}  />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{paddingLeft: '10px', paddingTop: '10px'}}
                                        primary={`${speaker.fullname}`}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    fontSize={window.outerHeight < 730 ? '10px' : '14px'}
                                                    sx={{display: 'inline'}}
                                                    color="textPrimary"
                                                >
                                                    <SpeakerField field={speaker.title}/>
                                                    <SpeakerField field={speaker.company}/>
                                                    <SpeakerField field={speaker.email}/>
                                                    <SpeakerField field={speaker.phone}/>
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />

                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        )
                    }) : ''}

                </StyledList>
            </Grid>
            <Dialog open={open} maxWidth={'sm'}>
                <DialogTitle>{selectedSpeaker && selectedSpeaker._id !== 'CREATE' ? 'Update' : 'Create'} speaker</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedSpeaker && selectedSpeaker._id !== 'CREATE' ? 'Update' : 'Create'} speaker
                    </DialogContentText>
                    <UpsertSpeaker speaker={selectedSpeaker} setOpen={setOpen} setSpeaker={selectedSpeaker?._id !== 'CREATE' ? setUpdatedSpeaker : setCreatedSpeaker} />
                </DialogContent>
            </Dialog>
        </Grid>

    );
}
Speakers.propTypes = {
    activity: PropTypes.object.isRequired,
    setActivityPayload: PropTypes.func,
    isReadOnly: PropTypes.bool
};

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {Typography} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import React, {useState} from "react";

export const HowTo = ({videoName}: {videoName: string}) => {
    const [expanded, setExpanded] = useState(false);

    const openVideoInNewTab = () => {
        const newTab = window.open()!;
        newTab.document.body.innerHTML = `
    <video controls autoplay style=" width: 100%; height: 100%; objectFit: contain; ">
      <source src="/${videoName}" type="video/mp4">
      Din webbläsare stöder inte video-taggen.
    </video>
  `;
    };
    return (
        <Accordion expanded={expanded} defaultExpanded={false} onChange={(event, isExpanded) => setExpanded(isExpanded)} sx={{mb: 1, width: '100%'}} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(270deg)', transition: 'transform 0.25s' }} />}
                aria-controls="panel-instructions-content"
                id="panel-instructions-header"
                sx={{
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        order: -1,
                        marginRight: '8px',
                    },
                    '& .MuiAccordionSummary-content': {
                        marginLeft: '8px',
                    },
                }}
            >
                <HelpOutlineIcon />
                <Typography sx={{ ml: 1, mt:0.5}} variant={"body2"}  color="textSecondary">Need help? Watch this short video for a quick tutorial</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <video controls style={{ width: '100%' }} controlsList={'nofullscreen'}>
                    <source src={`/${videoName}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <Button onClick={openVideoInNewTab}>
                    Open in new tab
                </Button>
            </AccordionDetails>
        </Accordion>
    )
}
export const applySignature = (html: string, templateFields: any, REACT_APP_UPLOAD_BUCKET: string | undefined, user: UserType) => {
    templateFields.filter( (field: TemplateFieldType) => field.name.indexOf('__Sales Rep') > -1).map( (field: TemplateFieldType) => {
        switch (field.name) {
            case '__Sales Rep Name':
                html = html.replaceAll(`[[${field.name}]]`, `${user.name}`);
                break;
            case '__Sales Rep Title':
                html = html.replaceAll(`[[${field.name}]]`, `${user.title}`);
                break;
            case '__Sales Rep Phone':
                html = html.replaceAll(`[[${field.name}]]`, `${user.phone}`);
                break;
            case '__Sales Rep Email':
                html = html.replaceAll(`[[${field.name}]]`, `${user.email}`);
                break;
            case '__Sales Rep Image':
                html = html.replaceAll(`[[${field.name}]]`, `${REACT_APP_UPLOAD_BUCKET}${user.image ? user.image : `user/${user._id}.png`}`);
                break;
        }
        html = html.replaceAll(`[[${field.name}]]`, `${field.value}`);
    });
    return html;
}
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    AppBar, Autocomplete,
    Box,
    Checkbox, FormControl,
    FormControlLabel, Grid,
    IconButton, InputLabel, MenuItem,
    Paper, Popover, Select, Switch,
    Toolbar, Tooltip, Typography
} from "@mui/material";
import {Api} from "../../../../Api";
import {sendErrorMessage} from "../../../../Error";
import { useSnackbar } from 'notistack';
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useContext, useEffect, useState} from "react";
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from '@mui/icons-material/Delete';
import {ComboBox} from "../../../../components/FormElements";
import {DateTimePicker} from "../../../../components/DateTimePicker";
import {AppContext} from "../../../../AppContext";
const {REACT_APP_ACTIVITY_API} =  process.env;

interface SendTemplateAsPDfInput {
    activity: ActivityType,
    template: TemplateType,
    country: string;
}
export const SendTemplateAsPDf = ({activity, country, template}: SendTemplateAsPDfInput) => {
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const sendPDFInvitation = async () => {
        try {
            setWaitingForBackendMessage('Sending e-mail...');
            await Api( {
                name: 'SEND_DOCUMENT_EMAIL',
                type: 'PUT',
                url: `${REACT_APP_ACTIVITY_API}activity`,
                body: {
                    action: 'SEND_DOCUMENT_EMAIL',
                    payload: {
                        _id: activity._id,
                        country,
                        template: template._id
                    }
                }
            });
            setWaitingForBackendMessage('');
            enqueueSnackbar(`Email with a pdf sent, check your inbox in a few minutes`, {variant: 'success'});
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/activity/SEND_PDF', "Cannot Send Email", enqueueSnackbar, activity._id);
        }
    }
    return (
        <React.Fragment>
            {
                template.type === 'SURVEY SLIDE' &&
                <Tooltip title="Get as Power point slide">
                    <IconButton aria-label="Send as Power Point slide" onClick={sendPDFInvitation}>
                        <MicrosoftIcon/>  <span style={{fontSize: '16px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: 'rgba(0, 0, 0, 0.87)'}}>Power Point</span>
                    </IconButton>
                </Tooltip>
            }
            {
                template.type !== 'SURVEY SLIDE' &&
                <Tooltip title="Get template as PDF">
                    <IconButton aria-label="Send template as PDF" onClick={sendPDFInvitation}>
                        <PictureAsPdfIcon/>
                    </IconButton>
                </Tooltip>
            }

        </React.Fragment>

    )
}

export const SendApprovalEmailDialog = ({activity, country}: SendApprovalEmailDialogType) =>{
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [validatedEmail, setValidatedEmail] = React.useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const sendApprovalEmail = async() => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email) setValidatedEmail(re.test(email));

        try {
            setWaitingForBackendMessage('Sending e-mail...')
            await Api( {
                name: 'SEND_DOCUMENT_EMAIL',
                type: 'PUT',
                url: `${REACT_APP_ACTIVITY_API}activity`,
                body: {
                    action: 'SEND_DOCUMENT_EMAIL',
                    payload: {
                        _id: activity._id,
                        country,
                        to: email,
                        template: false
                    }
                }
            });
            setWaitingForBackendMessage('')
            enqueueSnackbar(`Approval email sent`, {variant: 'success'});
            setOpen(false);
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/activity/SEND_APPROVAL_EMAIL', "Cannot Send Email", enqueueSnackbar, activity._id);
        }
    }
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box component="form" id="form" onSubmit={sendApprovalEmail} sx={{width: '100%', textAlign: 'right'}}>
            <Tooltip title="Send template as PDF">
                <IconButton aria-label="Send template as PDF" onClick={handleClickOpen}>
                    <ForwardToInboxIcon/>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth={true}>
                <DialogTitle>Subscribe</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Send all templates as PDF
                    </DialogContentText>
                    <TextField
                        autoFocus
                        helperText={!validatedEmail ? 'Wrong format': ''}
                        error={!validatedEmail}
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(e: any) => {
                            if ( e.target.value.length > 5 ) {
                                setEmail(e.target.value);
                            }

                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={sendApprovalEmail}>Send</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
interface SendApprovalEmailDialogType {
    activity: ActivityType,
    country?: string
}

export const Fullscreen = ({open, onClose, transition, previewHTML, previewSRC }:FullscreenInput) => {
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {
                open ? <iframe style={{height: '2800px', width: '100%', overflow: 'auto'}} frameBorder={0} srcDoc={previewHTML} id="templatePreviewFullscreen" src={previewSRC}></iframe> : ''
            }
        </Dialog>
    )

}
interface FullscreenInput {
    open: boolean;
    onClose: any;
    transition: any;
    previewHTML: string;
    previewSRC: string;
}

export const QuestionDialogSandbox = ({open, template, handleChange, addQuestion, handleQuestionChange, setUpdateQuestions, setUploadImage, deleteQuestion}: QuestionDialogInput) => {
    const [questionLOV, setQuestionLOV] = useState([{name: '', value: ''}])
    const typeLabel = template.type === 'SURVEY' ? 'Question': 'Field';
    const defaultFields = ['firstNameLabel','lastNameLabel','emailLabel','siteLabel', 'middleNameLabel'];
    useEffect( () => {
        try {
            if (template && template.fields) {
                setQuestionLOV(
                    // @ts-ignore
                    template.fields.filter( (f1: TemplateFieldType) => f1.name === '__Questions').map( (field: TemplateFieldType) => {
                        return (
                            // @ts-ignore
                            field.questions.map( (question: TemplateQuestionType) => {
                                return ({name: question.label, value: question._id});
                            })
                        )
                    })[0])

            }
        } catch (e: any) {
            console.error(e.toString())
        }


    }, [template])
    const fieldTypes = [
        {value: 'Address', name: 'Textbox (Address)', notselectable: true},
        {value: 'Checkbox', name: 'Checkbox'},
        {value: 'Email', name: 'Textbox (E-mail)', notselectable: true},
        {value: 'Firstname', name: 'Textbox (First name)', notselectable: true},
        {value: 'Lastname', name: 'Textbox (Last name)', notselectable: true},
        {value: 'Middlename', name: 'Textbox (Middle name)', notselectable: true},
        {value: 'OtherInformation', name: 'Textarea (Comment)', notselectable: true},
        {value: 'Phone', name: 'Textbox (Phone)', notselectable: true},
        {value: 'Site', name: 'Textbox (Site)', notselectable: true},
        {value: 'Text', name: 'Text/label'},
        {value: 'TextArea', name: 'Textarea (several rows)'},
        {value: 'TextBox', name: 'Textbox (one row)'},
        {value: 'YesNo', name: 'Yes/No'},
    ];
    const Survey = ({question, index}: {question: TemplateQuestionType, index: number}) => {
        return (
            <Grid container xs={12} sx={{border: 'dotted thin', p:2, pb:3}}>
                <Typography component="h6" variant="h6" sx={{color: 'black', pl:2,  pt: '5px'}} > Question {index+1} </Typography>
                <IconButton aria-label="Delete this question" onClick={() => deleteQuestion(question._id)} >
                    <DeleteIcon sx={{fontSize: 30}}/>
                </IconButton>
                <Grid item xs={12}>
                    {
                        template.type === 'SURVEY'
                            ?
                            <TextField
                                id={`${question._id}-category`}
                                label="Category"
                                sx={{width: '25%', marginRight: '10px'}}
                                variant="standard"
                                defaultValue={question.category}
                                onBlur={handleQuestionChange}
                            />
                            :''
                    }
                    <TextField
                        id={`${question._id}-label`}
                        label="Label"
                        sx={{width: template.type === 'SURVEY' ? '70%' : '90%'}}
                        variant="standard"
                        defaultValue={question.label}
                        onBlur={handleQuestionChange}
                    />
                    {
                        template.type !== 'SURVEY'
                            ?
                            <ComboBox
                                required={false}
                                multiple={false}
                                options={questionLOV.filter( (field: any) => field.value !== question._id)}
                                label="Parent question"
                                placeholder="Select parent question"
                                value={questionLOV.find( (field: any) => field.value === question.parentQuestion)}
                                onChange={(event: any, val: any) => {
                                    handleQuestionChange(event, question._id, 'parentQuestion', val?.value );
                                }} />
                            : ''
                    }
                    <FormControlLabel
                        control={<Switch onChange={handleQuestionChange}
                                         id={`${question._id}-useComment`}
                                         value={question.useComment}
                                         checked={question.useComment}/>}
                        label="Comment"/><br/>
                    {
                        template.type === 'SURVEY' &&
                        <React.Fragment>
                            <FormControlLabel
                                control={<Switch
                                    onChange={handleQuestionChange}
                                    id={`${question._id}-useRating`}
                                    value={question.useRating}
                                    checked={question.useRating} />}
                                label="Rating" /><br/>
                        </React.Fragment>
                    }
                    <FormControlLabel control={<Switch onChange={handleQuestionChange} id={`${question._id}-useYesNo`} value={question.useYesNo} checked={question.useYesNo} />} label="Yes/No" /><br/>
                    {
                        question.useYesNo
                            ?
                            <React.Fragment>
                                <TextField
                                    id={`${question._id}-labelYes`}
                                    label="Label 'True'"
                                    sx={{width: '40%', paddingRight: '10px'}}
                                    variant="standard"
                                    defaultValue={question.labelYes}
                                    onBlur={handleQuestionChange}
                                />
                                <TextField
                                    id={`${question._id}-labelNo`}
                                    label="Label 'False'"
                                    sx={{width: '40%'}}
                                    variant="standard"
                                    defaultValue={question.labelNo}
                                    onBlur={handleQuestionChange}
                                />
                            </React.Fragment>
                            : ''
                    }

                </Grid>
            </Grid>
        )
    }
    const SignUp = ({question, index}: {question: TemplateQuestionType, index: number}) => {
        const sortArrayFunc = () => {
            const retVal = [{value: '-1'},{value: '0'}];
            const num = template?.fields.find( f => f.name === '__Questions')?.questions?.length;
            if ( num ) {
                let i = 1;
                do {
                    retVal.push({value: `${i}`});
                    i++;
                } while ( i <= (num-1) )
            }
            return retVal;

        }
        const [sortArray, setSortArray] = React.useState(sortArrayFunc())

        useEffect( () => {
            sortArrayFunc();
        }, [template.fields])
        const hasYesNoField: boolean = !!template.fields.find( (field: TemplateFieldType) => {
            return field?.questions?.find( (q: TemplateQuestionType) => q.useYesNo)
        })
        const [conditionalDisplay, setConditionalDisplay] = React.useState(!!question.parentQuestion);

        return (
            <Grid container xs={12} sx={{border: 'dotted thin', p:2, pb:3}}>
                <Grid item xs={9}>
                    <TextField
                        id={`${question._id}-label`}
                        fullWidth
                        inputProps={{style: {fontSize: 20}}} // font size of input text
                        error={!question.label}
                        helperText={!question.label ?`A label is mandatory, please give it a value`: ""}
                        variant="standard"
                        defaultValue={question.label??`Field ${index}`}
                        onBlur={handleQuestionChange}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton disabled={fieldTypes.find( (f: any) => f.value === question.fieldType)?.notselectable} aria-label="Delete this question" onClick={() => deleteQuestion(question._id)} >
                        <DeleteIcon sx={{fontSize: 30}}/>
                    </IconButton>
                </Grid>
                <Grid item xs={2}>
                    {
                        <ComboBox
                            required={true}
                            multiple={false}
                            options={sortArray}
                            label="Sort"
                            placeholder="Sort"
                            value={sortArray.find( (field: any) => field.value === question.sort?.toString())}
                            onChange={(e: any, val: any) => {
                                handleQuestionChange(e, question._id, 'sort', val?.value );
                            }} />

                    }
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <ComboBox
                            required={true}
                            multiple={false}
                            disabled={fieldTypes.find( (f: any) => f.value === question.fieldType)?.notselectable}
                            options={fieldTypes}
                            label="Field type"
                            placeholder="Select field type"
                            value={fieldTypes.find( (field: any) => field.value === question.fieldType)}
                            onChange={(e: any, val: any) => {
                                handleQuestionChange(e, question._id, 'fieldType', val?.value );
                            }} />
                    </Grid>
                    <Grid item xs={8}>
                        {
                            question.fieldType === 'YesNo' &&
                            <Box>
                                <TextField
                                    id={`${question._id}-labelYes`}
                                    label="Yes"
                                    sx={{width: '40%', paddingRight: '10px'}}
                                    variant="standard"
                                    defaultValue={question.labelYes}
                                    onBlur={handleQuestionChange}
                                />
                                <TextField
                                    id={`${question._id}-labelNo`}
                                    label="No"
                                    sx={{width: '40%'}}
                                    variant="standard"
                                    defaultValue={question.labelNo}
                                    onBlur={handleQuestionChange}
                                />
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={3}>
                        {
                            question.fieldType !== 'Text' &&
                            <FormControlLabel
                                control={<Checkbox onChange={handleQuestionChange}
                                                   id={`${question._id}-isRequired`}
                                                   value={question.isRequired}
                                                   checked={question.isRequired}/>}
                                label="Required"/>
                        }
                    </Grid>
                    <Grid item xs={9}>
                        {
                            question.defaultField &&
                            <FormControlLabel
                                control={<Checkbox onChange={handleQuestionChange}
                                                   id={`${question._id}-hidden`}
                                                   value={question.hidden}
                                                   checked={question.hidden}/>}
                                label="Hidden (Default fields cannot be removed, only hidden)"/>
                        }
                    </Grid>
                    {
                        index > 0 &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox onChange={(e) => setConditionalDisplay(e.target.checked)}
                                                           id={`${question._id}-conditionalDisplay`}
                                                           checked={!!question.parentQuestion}/>}
                                        label="Conditional display? (Advanced stuff)"/>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        conditionalDisplay &&
                                        <ComboBox
                                            required={false}
                                            multiple={false}
                                            options={questionLOV.filter( (field: any) => field.value !== question._id)}
                                            label="Parent field to use as filter"
                                            placeholder="Select parent field"
                                            value={questionLOV.find( (field: any) => field.value === question.parentQuestion)}
                                            onChange={(event: any, val: any) => {
                                                handleQuestionChange(event, question._id, 'parentQuestion', val?.value );
                                            }} />
                                    }
                                </Grid>

                            </React.Fragment>

                    }

                </Grid>
            </Grid>
        )
    }
    const Field = ({field, index}: {field: TemplateFieldType, index: number}) => {
        const sortArrayFunc = () => {
            const retVal = [{value: '-1'},{value: '0'}];
            const num = template?.fields.length;
            if ( num ) {
                let i = 1;
                do {
                    retVal.push({value: `${i}`});
                    i++;
                } while ( i <= (num-1) )
            }

            return retVal;

        }
        const [sortArray, setSortArray] = React.useState(sortArrayFunc())

        useEffect( () => {
            sortArrayFunc();
        }, [template.fields])
        const [conditionalDisplay, setConditionalDisplay] = React.useState(!!field.parentField);

        return (
            <Grid container xs={12} sx={{border: 'dotted thin', p:2, pb:3}}>
                <Grid item xs={9}>
                    <TextField
                        id={`${field._id}-label`}
                        fullWidth
                        inputProps={{style: {fontSize: 20}}} // font size of input text
                        error={!field.label && !field.name}
                        helperText={!field.label && !field.name ?`A label is mandatory, please give it a value`: ""}
                        variant="standard"
                        defaultValue={field.label??field.name}
                        onBlur={handleQuestionChange}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton disabled={fieldTypes.find( (f: any) => f.value === field.type)?.notselectable} aria-label="Delete this question" onClick={() => deleteQuestion(field._id)} >
                        <DeleteIcon sx={{fontSize: 30}}/>
                    </IconButton>
                </Grid>
                <Grid item xs={2}>
                    {
                        <ComboBox
                            required={true}
                            multiple={false}
                            options={sortArray}
                            label="Sort"
                            placeholder="Sort"
                            value={sortArray.find( (f: any) => f.value === field.sort?.toString())}
                            onChange={(e: any, val: any) => {
                                handleQuestionChange(e, field._id, 'sort', val?.value );
                            }} />

                    }
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <ComboBox
                            required={true}
                            multiple={false}
                            disabled={fieldTypes.find( (f: any) => f.value === field.type)?.notselectable}
                            options={fieldTypes}
                            label="Field type"
                            placeholder="Select field type"
                            value={fieldTypes.find( (f: any) => f.value === field.type)}
                            onChange={(e: any, val: any) => {
                                handleQuestionChange(e, field._id, 'fieldType', val?.value );
                            }} />
                    </Grid>
                    <Grid item xs={8}>
                        {
                            field.type === 'YesNo' &&
                            <Box>
                                <TextField
                                    id={`${field._id}-labelYes`}
                                    label="Yes"
                                    sx={{width: '40%', paddingRight: '10px'}}
                                    variant="standard"
                                    defaultValue={field.labelYes}
                                    onBlur={handleQuestionChange}
                                />
                                <TextField
                                    id={`${field._id}-labelNo`}
                                    label="No"
                                    sx={{width: '40%'}}
                                    variant="standard"
                                    defaultValue={field.labelNo}
                                    onBlur={handleQuestionChange}
                                />
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={3}>
                        {
                            field.type !== 'Text' &&
                            <FormControlLabel
                                control={<Checkbox onChange={handleQuestionChange}
                                                   id={`${field._id}-isRequired`}
                                                   value={field.isRequired}
                                                   checked={field.isRequired}/>}
                                label="Required"/>
                        }
                    </Grid>
                    <Grid item xs={9}>
                        {
                            field.defaultField &&
                            <FormControlLabel
                                control={<Checkbox onChange={handleQuestionChange}
                                                   id={`${field._id}-hidden`}
                                                   value={field.hidden}
                                                   checked={field.hidden}/>}
                                label="Hidden (Default fields cannot be removed, only hidden)"/>
                        }
                    </Grid>
                    {
                        index > 0 &&
                        <React.Fragment>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => setConditionalDisplay(e.target.checked)}
                                                       id={`${field._id}-conditionalDisplay`}
                                                       checked={!!field.parentField}/>}
                                    label="Conditional display? (Advanced stuff)"/>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    conditionalDisplay &&
                                    <ComboBox
                                        required={false}
                                        multiple={false}
                                        options={questionLOV.filter( (f: any) => f.value !== field._id)}
                                        label="Parent field to use as filter"
                                        placeholder="Select parent field"
                                        value={questionLOV.find( (f: any) => f.value === field.parentField)}
                                        onChange={(event: any, val: any) => {
                                            handleQuestionChange(event, field._id, 'parentField', val?.value );
                                        }} />
                                }
                            </Grid>

                        </React.Fragment>

                    }

                </Grid>
            </Grid>
        )
    }
    const GeneralDetails = () => {
        return (
            <React.Fragment>
                {
            // @ts-ignore
            template.fields.sort((prev: TemplateFieldType, curr: TemplateFieldType) => prev.sort - curr.sort).map( field => {

                if ( (field.name.substr(0,2) !== "__" || field.type === 'Image') && defaultFields.indexOf(field.name) === -1 && !!field.name) {
                    return (
                        <Grid item xs={12}>
                            {
                                field.type === 'Image'
                                    ?  <FormControlLabel
                                        sx={{mb: '10px', alignItems: 'unset!important', marginLeft: '0px!important', fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.6)'}} labelPlacement="top"
                                        control={    <Button
                                            onClick={() => setUploadImage({open: true, fieldName: field.name, imageWidth: field.imageWidth})}
                                            size="small" variant="contained" component="span">
                                            Upload new image
                                        </Button>} label={field.label ?? field.name} />

                                    : <TextField
                                        id={`${template._id}-${field._id}-value`}
                                        label={field.label ?? field.name}
                                        multiline
                                        fullWidth
                                        minRows={1}
                                        maxRows={5}
                                        variant="standard"
                                        defaultValue={field.value}
                                        onBlur={handleChange}
                                    />
                            }

                        </Grid>
                    )
                }
            })
                }
            </React.Fragment>
        )
    }
    const Fields = () => {
        /*
        return (
            <React.Fragment>
                <Typography variant="h4">Fields</Typography>
                <Button onClick={addQuestion} variant="contained">Add {typeLabel}</Button>
                {
                    template.fields.filter( field => field?.questions?.length).map( field =>  {
                        return(
                            // @ts-ignore
                            field.questions.sort((prev: TemplateQuestionType, curr: TemplateQuestionType) => prev.sort - curr.sort).map( (question: TemplateQuestionType, index: number) => {
                                return (
                                    <Grid key={question._id} container spacing={2} sx={{mt: 2, pb: 2}}>
                                        {
                                            template.type === 'SURVEY' ? <Survey question={question} index={index}/> :
                                                <SignUp index={index} question={question}/>
                                        }
                                    </Grid>
                                )
                            }))


                    })}
            </React.Fragment>
        )
         */
        return (
            <React.Fragment>
                <Typography variant="h4">Fields</Typography>
                <Button onClick={addQuestion} variant="contained">Add {typeLabel}</Button>
                {
                    // @ts-ignore
                    template.fields.sort((prev: TemplateFieldType, curr: TemplateFieldType) => prev.sort - curr.sort).map( (field: TemplateFieldType, index) =>  {
                        return(
                                <Grid key={field._id} container spacing={2} sx={{mt: 2, pb: 2}}>
                                    <Field index={index} field={field}/>
                                </Grid>
                            )
                    })}
            </React.Fragment>
        )
    }
    return (
        <Dialog open={open} maxWidth="xl">
            <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
            <DialogContent >
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    sx={{width: '800px', padding: 5}}
                >

                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography component="h6" variant="h6">General details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <GeneralDetails/>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography component="h6" variant="h6">{typeLabel}s</Typography>
                                </AccordionSummary>
                                <AccordionDetails  sx={{height: '100%'}}>
                                    <Fields/>
                                </AccordionDetails>
                            </Accordion>

                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=> setUpdateQuestions(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
export const QuestionDialog = ({open, template, handleChange, addQuestion, handleQuestionChange, setUpdateQuestions, setUploadImage, deleteQuestion}: QuestionDialogInput) => {
    const [questionLOV, setQuestionLOV] = useState([{name: '', value: ''}])

    useEffect( () => {
        try {
            if (template && template.fields) {
                setQuestionLOV(
                    // @ts-ignore
                    template.fields.filter( (f1: TemplateFieldType) => f1.name === '__Questions').map( (field: TemplateFieldType) => {
                        return (
                            // @ts-ignore
                            field.questions.map( (question: TemplateQuestionType) => {
                                return ({name: question.label, value: question._id});
                            })
                        )
                    })[0])

            }
        } catch (e: any) {
            console.error(e.toString())
        }


    }, [template])
    return (
        <Dialog open={open} maxWidth="xl">
            <Paper  sx={{width: '800px', padding: 5}}>

                        <Typography variant="h6">Questions</Typography>
                        <Button onClick={addQuestion} variant="contained">Add question</Button>
                        {// @ts-ignore
                            template.fields.map( field =>  {
                                return(
                                    // @ts-ignore
                                    field?.questions?.map( (question: TemplateQuestionType, index: number) => {
                                        return (
                                            <Grid key={question._id} container spacing={2} sx={{mt: 2, pb: 4, borderBottom: 2}}>
                                                <Typography component="h6" variant="h6" sx={{color: 'black', pl:2,  pt: '5px'}} > Question {index+1} </Typography>
                                                <IconButton aria-label="Delete this question" onClick={() => deleteQuestion(question._id)} >
                                                    <DeleteIcon sx={{fontSize: 30}}/>
                                                </IconButton>
                                                <Grid item xs={12}>
                                                    {
                                                        template.type === 'SURVEY'
                                                            ?
                                                            <TextField
                                                                id={`${question._id}-category`}
                                                                label="Category"
                                                                sx={{width: '25%', marginRight: '10px'}}
                                                                variant="standard"
                                                                defaultValue={question.category}
                                                                onBlur={handleQuestionChange}
                                                            />
                                                            :''
                                                    }
                                                    <TextField
                                                        id={`${question._id}-label`}
                                                        label="Label"
                                                        sx={{width: template.type === 'SURVEY' ? '70%' : '90%'}}
                                                        variant="standard"
                                                        defaultValue={question.label}
                                                        onBlur={handleQuestionChange}
                                                    />
                                                    {
                                                        template.type !== 'SURVEY'
                                                            ?
                                                            <ComboBox
                                                                required={false}
                                                                multiple={false}
                                                                options={questionLOV.filter( (field: any) => field.value !== question._id)}
                                                                label="Parent question"
                                                                placeholder="Select parent question"
                                                                value={questionLOV.find( (field: any) => field.value === question.parentQuestion)}
                                                                onChange={(event: any, val: any) => {
                                                                    handleQuestionChange(event, question._id, 'parentQuestion', val?.value );
                                                                }} />
                                                            : ''
                                                    }

                                                    {
                                                        template.type === 'SURVEY'
                                                            ?
                                                            <React.Fragment>
                                                                <FormControlLabel
                                                                    control={<Switch onChange={handleQuestionChange}
                                                                                     id={`${question._id}-useComment`}
                                                                                     value={question.useComment}
                                                                                     checked={question.useComment}/>}
                                                                    label="Comment"/><br/>
                                                                <FormControlLabel control={<Switch onChange={handleQuestionChange} id={`${question._id}-useRating`} value={question.useRating} checked={question.useRating} />} label="Rating" /><br/>
                                                            </React.Fragment>
                                                            : ''
                                                    }
                                                    <FormControlLabel control={<Switch onChange={handleQuestionChange} id={`${question._id}-useYesNo`} value={question.useYesNo} checked={question.useYesNo} />} label="Yes/No" /><br/>
                                                    {
                                                        question.useYesNo
                                                            ?
                                                            <React.Fragment>
                                                                <TextField
                                                                    id={`${question._id}-labelYes`}
                                                                    label="Label 'True'"
                                                                    sx={{width: '40%', paddingRight: '10px'}}
                                                                    variant="standard"
                                                                    defaultValue={question.labelYes}
                                                                    onBlur={handleQuestionChange}
                                                                />
                                                                <TextField
                                                                    id={`${question._id}-labelNo`}
                                                                    label="Label 'False'"
                                                                    sx={{width: '40%'}}
                                                                    variant="standard"
                                                                    defaultValue={question.labelNo}
                                                                    onBlur={handleQuestionChange}
                                                                />
                                                            </React.Fragment>
                                                            : ''
                                                    }
                                                    <FormControlLabel control={<Switch disabled={question.useRadio} onChange={handleQuestionChange} id={`${question._id}-useCombo`} value={question.useCombo} checked={question.useCombo} />} label="Combobox" /><br/>
                                                    <FormControlLabel control={<Switch disabled={question.useCombo} onChange={handleQuestionChange} id={`${question._id}-useRadio`} value={question.useRadio} checked={question.useRadio} />} label="Radio buttons" />
                                                    {
                                                        (question.useCombo || question.useRadio) &&
                                                            <Autocomplete
                                                                multiple
                                                                id={`${question._id}-choices`}
                                                                onChange={(event, value,reason ) => handleQuestionChange(event, question._id,'choices', value)}
                                                                value={question.choices}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="standard"
                                                                        label="Choices"
                                                                        placeholder="Add choices"
                                                                    />
                                                                )}
                                                                freeSolo options={[]}/>
                                                    }

                                                </Grid>
                                            </Grid>
                                        )
                                    }))


                            })}

            </Paper>
            <DialogActions>
                <Button onClick={()=> setUpdateQuestions(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

interface QuestionDialogInput {
    open: boolean;
    template: TemplateType;
    handleChange: any;
    addQuestion: any;
    deleteQuestion: any;
    handleQuestionChange: any;
    setUpdateQuestions: any;
    setUploadImage: any;
}

export const ChangeColor = ({open, setOpen, fieldName, fieldValue, handleColorChange, isRGB}: ChangeColorInput) => {
    const [color, setColor] = React.useState(fieldValue);
    const onSubmit = () => {
        const rgb = hexRgb(color);
        setOpen(false);
        if (isRGB)
            handleColorChange(fieldName, `${rgb.red}, ${rgb.green}, ${rgb.blue}`);
        else
            handleColorChange(fieldName, color);
    }
    useEffect(()=> {
        if (isRGB)
            setColor(`#${rgbHex(fieldValue)}`);
        else
            setColor(fieldValue);
    }, [fieldValue])
    const updateColor = (colorCode: string) => {
        setColor(colorCode);
    }
    return (
        <Dialog open={open} maxWidth="xs" sx={{color: '#000000'}}>
            <DialogTitle  sx={{color: '#000000'}}>Update {fieldName}</DialogTitle>
                    <Grid sx={{pl: '24px', pr: '24px'}}>
                        <TextField
                            type={'text'}
                            id={`cssBgColor`}
                            sx={{width: '100px'}}
                            variant="standard"
                            value={color}
                            onChange={(event)=> updateColor(event.target.value)}
                        />
                        <TextField
                            type={'color'}
                            id={`colorPicker`}
                            sx={{width: '100px'}}
                            variant="standard"
                            value={color}
                            onChange={(event)=> updateColor(event.target.value)}
                        />
                    </Grid>
            <DialogActions>
                <Button onClick={()=> setOpen(false)}>Close</Button>
                <Button onClick={onSubmit}>Save & Close</Button>
            </DialogActions>
        </Dialog>
    )
}

interface ChangeColorInput {
    open: boolean;
    handleColorChange: any;
    setOpen: any;
    fieldName: string;
    fieldValue: string;
    isRGB: boolean;
}

export const OpenRegistrationForm = ({url, country}: OpenRegistrationFormInput) => {
    //const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [consent, setConsent] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState(country);
    const [urlCopied, setUrlCopied] = React.useState('Click to copy');
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(()=> {

        setTimeout(() => {
            setUrlCopied('Click to copy')
        }, 1000)
    }, [anchorEl])
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <React.Fragment>
            <Tooltip title="Open register form in new window">
                <IconButton aria-label="Open register form in new window" onClick={handleClick} >
                    <OpenInNewIcon sx={{fontSize: 30}}/>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{padding: '15px'}}>
                    <FormControlLabel control={<Switch  onChange={ (event)=> {setConsent(event.target.checked)}} />} label="Ask for consent" />
                    {
                        !country ?
                            <React.Fragment>
                                <br/>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id="demo-simple-select-label">Select country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select country"
                                        onChange={ (event: any)=> {setSelectedCountry(event.target.value)}}
                                    >
                                        <MenuItem value={'DK'}>DK</MenuItem>
                                        <MenuItem value={'FI'}>FI</MenuItem>
                                        <MenuItem value={'NO'}>NO</MenuItem>
                                        <MenuItem value={'SE'}>SE</MenuItem>
                                    </Select>
                                </FormControl>
                            </React.Fragment>: ''
                    }

                    <br/><br/>
                    <Box sx={{marginLeft: '20px'}}>


                    <Button size="small" variant="contained"  onClick={() => {
                        navigator.clipboard.writeText(`${url}/?consent=${consent}&country=${selectedCountry}`);
                        setUrlCopied('Copied');
                        setTimeout( () => {
                            handleClose();
                        }, 2000)
                    }}>{urlCopied}</Button>
                    <br/><br/>
                    <Button size="small" variant="contained" onClick={() => window.open(`${url}/?consent=${consent}&country=${selectedCountry}`)}>Click to open</Button>
                    </Box>
                </Box>

            </Popover>
        </React.Fragment>

    )
}

interface OpenRegistrationFormInput {
    url: string;
    country?: string;
}
interface WebFormScheduleInput {
    isReg: boolean;
    activity: ActivityType;
    setActivityPayload: any;
    isArchived: boolean;
}
export const WebFormSchedule = ({isReg, activity,setActivityPayload,isArchived }: WebFormScheduleInput) => {
    //const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <React.Fragment>
            <Tooltip title={isReg ? 'Set Registration open time' : 'Set Survey open time'}>
                <IconButton aria-label="Schedule" onClick={handleClick} >
                    <ScheduleIcon sx={{fontSize: 30}}/>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{padding: '15px'}}>
                        <DateTimePicker
                            label={'Start'}
                            date={(isReg ? activity.start_registration : activity.start_survey)??new Date}
                            setDate={(newDateTime: Date) => setActivityPayload({refreshActivity: true, payload: {[isReg ? 'start_registration' : 'start_survey']: newDateTime.toUTCString()}})}
                            disabled={isArchived??false}/>
                        <DateTimePicker
                            label={'End'}
                            minDate={(isReg ? activity.start_registration : activity.start_survey)??new Date}
                            date={(isReg ? activity.end_registration : activity.end_survey)??new Date}
                            setDate={(newDateTime: Date) => setActivityPayload({refreshActivity: true, payload: {[isReg ? 'end_registration' : 'end_survey']: newDateTime.toUTCString()}})}
                            disabled={isArchived??false}/>
                </Box>

            </Popover>
        </React.Fragment>

    )
}

export const DialogConfirm = ({open, close, title, text, confirmAction}: DialogConfirmInput) => {
    const handleClose = () => {
        close();
    }
    const handleConfirm = () => {
        confirmAction();
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={handleConfirm}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}
interface DialogConfirmInput {
    open: boolean;
    close: any;
    confirmAction: any;
    title: string;
    text: string;
}


export const handleChange = (field: TemplateFieldType, fieldValue: any, handleTemplateFieldUpdate: any, template: TemplateType) => {
    handleTemplateFieldUpdate([{
        field: {
            ...field,
            value: fieldValue
        },
        template,
        isDeleted: false,
        action: 'Update'
    }])
}

import React, { useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    IconButton,
    Tooltip,
    Typography,
    FormControlLabel,
    Select, MenuItem, ThemeProvider, TextField, Grid, FormControl, InputLabel, RadioGroup, Radio, Dialog, DialogContent
} from "@mui/material";
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import { ActivityContext } from "../../../ActivityContext";

import {TemplateSuiteContext} from "../TemplateSuiteContext";
import {RichTextField} from "../../../../../components/EditDialog";
import {theme} from "../../../../../components/SmallTheme";
import {HowTo} from "../../HowTo";
import DialogActions from "@mui/material/DialogActions";

interface LimitParticipantsProps {
    template: TemplateType;
    country: string;
    templateFields: TemplateFieldType[];
}

export const LimitParticipants = ({ template, country,templateFields }: LimitParticipantsProps) => {
    const [limitation, setLimitation] = useState<LimitationType>(template?.limitation??{status: 'no-limitation', max: 0, message: 'Thank you for your interest. Sadly, the event is now fully booked.'});
    const [selectedField, setSelectedField] = useState<TemplateFieldType | undefined>(templateFields.find( (field: TemplateFieldType) => field.name === limitation.details?.field));
    const {handleTemplateUpdateV2} = useContext(TemplateSuiteContext);
    const { isArchived, isEditor, activity, setActivityPayload } = useContext(ActivityContext);
    const anchorRef = useRef(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [hasLimitation, setHasLimitation] = useState(template?.limitation?.status !== 'no-limitation');
    const [totalCategoryLimits, setTotalCategoryLimits] = useState(0);
    const [categoryFields, setCategoryFields] = useState<TemplateFieldType[]>([]);
    const [error, setError] = useState(false);

    const calculateTotalCategoryLimits = () => {
        if (limitation?.details?.values?.length) {
            const total = limitation.details.values.reduce((acc, curr) => acc + Number((curr?.max)), 0);
            setTotalCategoryLimits(total);
            if (total > limitation.max) setError(true);
            else setError(false);
        }

    }

    // Popover open and close handlers
    const handlePopoverOpen = () => {
        setPopoverOpen(true);
    };
    const handlePopoverClose = () => {
        setPopoverOpen(false);
    };
    const save = (inputLimitation: LimitationType = limitation) => {
        handleTemplateUpdateV2({
            template,
            activity,
            setActivityPayload,
            fieldName: 'limitation',
            fieldValue: inputLimitation,
            country
        })
        setLimitation(inputLimitation);
    }
    const handleMaxNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const max = Number(event.target.value);
        const tmpLimitation = {...limitation, max};
        if (totalCategoryLimits > max) setError(true);
        setSelectedField(undefined);
        setSelectedField(templateFields.find( (field: TemplateFieldType) => field.name === tmpLimitation.details?.field));
        save(tmpLimitation);
    };

    const handleLimitMessageChange = (value: string) => {
        save({...limitation, message: value});
    };

    const handleCategoryLimitMessageChange = (value: string) => {
        save({...limitation, details: {...limitation.details, message: value}});
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        save({...limitation, status: (event.target as HTMLInputElement).value});
        setHasLimitation((event.target as HTMLInputElement).value !== 'no-limitation');
    }

    const handleLimitationDetailValuesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, choice: string) => {
        const inputVal = event.target.value ? Number(event.target.value) : undefined;
        const field = selectedField?.choices?.find( (ch: string) => choice === ch);
        const values = limitation.details?.values?.map( (value: LimitationDetailValuesType) => value.value === field ? {...value, max: inputVal} : value);
        save({...limitation, details: {...limitation.details, values}});
    }

    const LimitCategory = () => <>
                        <Grid item xs={3} >
                            <FormControl fullWidth variant="standard">
                                <InputLabel sx={{fontSize: '14px'}}>
                                    Select category field
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="limitation.details.field"
                                    value={limitation.details?.field??''}
                                    onChange={event => setSelectedField(templateFields.find( (field: TemplateFieldType) => field.name === event.target.value))}
                                    label="Select category field"
                                    sx={{fontSize: '14px'}}
                                    disabled={!isEditor || isArchived || !hasLimitation}
                                >
                                    <MenuItem sx={{fontSize: '14px'}} value={'empty'}>{' '}</MenuItem>
                                    {
                                        categoryFields.map( (field: TemplateFieldType) => <MenuItem key={field.value} sx={{fontSize: '14px'}} value={field.name}>{field.value}</MenuItem>)
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            selectedField?.choices?.length &&
                            selectedField?.choices?.map( (choice: string, index: number) => {
                                const max = limitation.details?.values?.find( (value: LimitationDetailValuesType) => value.value === choice)?.max
                                return (
                                    <Grid item xs={9 / selectedField.choices!.length!} key={index} paddingLeft={3}>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            inputProps={{style: {fontStyle: '14px'}, max: limitation.max }}
                                            label={`Max number of "${choice}"`}
                                            id={'limitation.details.values.'+choice}
                                            disabled={!isEditor || isArchived || !hasLimitation}
                                            onChange={ (event => handleLimitationDetailValuesChange(event, choice))}
                                            value={max}
                                            onBlur={ (event => {
                                                    if (totalCategoryLimits > limitation.max) {
                                                        setError(true);
                                                        return;
                                                    }
                                                    setError(false);
                                                    save(limitation);
                                                }
                                            )}
                                            type="number"
                                            helperText={error ? `Total number cannot exceed ${limitation.max}` : ''}
                                            sx={{
                                                fontSize: '14px',
                                                '& .MuiInputBase-root': {
                                                    color: error ? 'red' : 'inherit',
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: error ? 'red' : 'inherit',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: error ? 'red' : 'inherit',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: error ? 'red' : 'inherit',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: error ? 'red' : 'primary.main',
                                                    },
                                                }
                                            }}
                                        />

                                    </Grid>
                                )
                            })
                        }
                        <Grid item xs={12} p={2} component="div" sx={{ '& > span': { display: 'inline-block', marginY: '8px' }, minHeight: '90px', width: '750px' }}>
                            <Typography component="div" variant="body1" style={{fontSize: '14px', color: 'gray', paddingBottom: '10px'}}>
                                Message to display when the category limit is reached:
                            </Typography>
                            <RichTextField maxLength={500} onBlur={handleCategoryLimitMessageChange} defaultValue={limitation.details?.message} readOnly={!isEditor || isArchived || (!hasLimitation)} fieldId={'limit.details.message'} />
                        </Grid>
                    </>

    useEffect(() => {
        if (selectedField?.name !== limitation.details?.field) {
            setError(false)
            save({
                ...limitation,
                details: {
                    ...limitation.details,
                    field: selectedField?.name,
                    values: selectedField?.choices?.map( (choice: string) => {
                        return {value: choice, max: 0}
                    })
                }});
        }
    }, [selectedField])
    useEffect(() => {
        setCategoryFields(templateFields?.filter( (field: TemplateFieldType) => field.type === 'Radiobutton'));
    }, [])

    useEffect(() => {
        setCategoryFields(templateFields?.filter( (field: TemplateFieldType) => field.type === 'Radiobutton'));
    }, [templateFields])

    useEffect(calculateTotalCategoryLimits, [limitation])

    return (
        <ThemeProvider theme={theme}>
            <Tooltip title="Limit the number of participants that can sign up">
                <IconButton onClick={handlePopoverOpen} ref={anchorRef}>
                    <PersonAddDisabledIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={popoverOpen} onClose={handlePopoverClose} fullWidth maxWidth={'md'}
                    PaperProps={{
                        style: {
                            maxWidth: '800px', // Set the max width here
                            width: '100%', // Ensures the dialog is responsive
                        }
            }}>
                <DialogContent>
                    <Grid container p={2} spacing={2} width={'750px'}>
                        <Grid item xs={12}><HowTo videoName={'How-to-limit-participants.mov'} /></Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div" >
                                Want to limit the number of participants?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={limitation.status}
                                    onChange={handleStatusChange}
                                >
                                    <FormControlLabel
                                        value="no-limitation"
                                        control={<Radio size={'small'} />}
                                        label="No limitations"
                                    />
                                    <FormControlLabel value="limitation" control={<Radio size={'small'} />} label="Limited" />
                                    <FormControlLabel value="limitation-per-category" control={<Radio size={'small'} />} label="Limits per category " />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}  >
                            <TextField
                                fullWidth
                                variant="standard"
                                sx={{fontSize: '14px'}}
                                label="Max number of participants"
                                disabled={!isEditor || isArchived || !hasLimitation}
                                defaultValue={limitation.max}
                                onBlur={handleMaxNumberChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={9} />

                        <Grid item xs={12} p={2} component="div" sx={{ '& > span': { display: 'inline-block', marginY: '8px' }, minHeight: '90px', width: '750px' }}>
                            <Typography component="div" variant="body1" style={{fontSize: '14px', color: 'gray', paddingBottom: '10px'}}>
                                Message to display when the limit is reached:
                            </Typography>
                            <RichTextField maxLength={500} onBlur={handleLimitMessageChange} defaultValue={limitation.message} readOnly={!isEditor || isArchived || (!hasLimitation)} fieldId={'limitMessage'} />
                        </Grid>
                        {limitation.status === 'limitation-per-category' && <LimitCategory />}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePopoverClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};
import React, {useContext} from 'react';
import {Button, Dialog, DialogActions} from "@mui/material";
import {Customers} from "../../../../components/customer";
import PropTypes, {InferProps} from "prop-types";
import {addParticipant} from "../../methods";
import {sendErrorMessage} from "../../../../Error";
import { useSnackbar } from 'notistack';
import {AppContext} from "../../../../AppContext";
export const SelectCustomers: any = ({open, setOpen, activity, setActivity, setActivityPayload}: InferProps<typeof SelectCustomers.propTypes>) => {
    const [selectedCustomers, setSelectedCustomers] = React.useState([]);
    const {setWaitingForBackendMessage} = useContext(AppContext);

    const { enqueueSnackbar } = useSnackbar();
    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        try {
            setWaitingForBackendMessage('Adding participants...')
            let tmpActivity;
            const numChunks = Math.ceil(selectedCustomers.length / 200);
            let start = 0;
            let end = 200;
            let total = 0;
            for( let i = 0; i <numChunks; i++) {
                tmpActivity = await addParticipant(activity._id,  selectedCustomers.slice(start, end).map( (customer: any) => customer._id));
                total = total + selectedCustomers.slice(start, end).length;
                end = end + 200;
                start = start + 200;
            }

            if ( tmpActivity.participants.length && !activity.participants.length && tmpActivity.status === 3) {
                setActivityPayload({
                    refreshActivity: false,
                    payload: {status: tmpActivity.status + 1}
                })
                setActivity({
                    ...tmpActivity,
                    status: tmpActivity.status + 1
                });
            } else {
                setActivity(tmpActivity);
            }
            setWaitingForBackendMessage('');
            setOpen(false);
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/activity/methods.addParticipant', "Cannot Add customer", enqueueSnackbar, activity._id);
        }

    };
    return (
        <Dialog
            maxWidth="xl"
            onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <Customers title="Select Customers" selectedCustomers={selectedCustomers}
                       setSelectedCustomers={setSelectedCustomers}
                       defaultFilter={activity.participants.map( (row: { customer: any; }) => row.customer)} />
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button autoFocus onClick={handleSave} color="primary" disabled={selectedCustomers.length === 0}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
SelectCustomers.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    activity: PropTypes.object.isRequired,
    setActivity: PropTypes.func.isRequired,
    setActivityPayload: PropTypes.func.isRequired
};

import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";
import {createTheme} from "@mui/material/styles";

export const WaitingForBackendResponse = ({messageIfNotEmpty}: {messageIfNotEmpty: string}) => {
    return (
        <Backdrop
            sx={{ color: '#fff', fontSize: '20px', zIndex: 999999 }}
            open={!!messageIfNotEmpty}
        >
            {messageIfNotEmpty}<CircularProgress color="inherit" />
        </Backdrop>
    )
}

import {handleTemplateFieldFieldType} from "./handleTemplateField";
import {getFooter, getFragment} from "../../../Fragment/methods";
interface DefaultInput {
    template: TemplateType;
    handleTemplateFieldUpdate: any;
    setTemplateFields: any;
}
interface HandleQuillChangeInput {
    updateFieldValue: any;
    updateField: any;
    country: 'SE' | 'DK' | 'FI' | 'NO' | "";
    templateFields: TemplateFieldType[];
    templateSuite: TemplateSuiteType;
    handleTemplateSuiteChange: any;
    setUpdateField: any;
}

const setFields = (templateSuite: TemplateSuiteType, fieldName: string, fieldValue: string, country?: string) => {
    const tmpTemplateSuite = JSON.parse(JSON.stringify(templateSuite));
    const updatesArray: handleTemplateFieldFieldType[] = [];
    tmpTemplateSuite?.templates?.forEach( (template: TemplateType) => {
        template.fields.forEach( (field: TemplateFieldType) => {
            if (field.name === fieldName && field.value !==fieldValue) {
                updatesArray.push({field: {...field, value: fieldValue}, template, country, action: 'Update'});
            }
        })
    })
    return updatesArray;
}
export const handleQuillChange = (
    {setUpdateField,updateFieldValue, templateFields, updateField, country, template, templateSuite, handleTemplateFieldUpdate, handleTemplateSuiteChange, setTemplateFields}: DefaultInput & HandleQuillChangeInput) => {
    // const fieldValue = previewSRC ? updateFieldValue.replace(/(<([^>]+)>)/gi, "") : updateFieldValue;
    const fieldValue = updateFieldValue;
    const field = JSON.parse(JSON.stringify(templateFields)).find( (field: TemplateFieldType ) => field.name === updateField.fieldName);
    if (field) {
        field.value = fieldValue;
        field.hidden = !fieldValue || fieldValue === '<p><br></p>';
    }

    if (['TypeOfEvent','SubHeader', 'DateTime','AboutTillotts', 'Contact'].indexOf(updateField.fieldName) > -1) {
        const updatesArray = setFields(templateSuite, updateField.fieldName, fieldValue, country);
        handleTemplateFieldUpdate(updatesArray);
    } else {
        handleTemplateFieldUpdate([{field, template, country,action: 'Update'}]);
    }


    //setTemplateFields(updatedFields);
    setUpdateField({open: false, fieldName: '', fieldValue: '', fieldType: ''});
}

export const handleHTMLChange = (updatedHtml: any, handleTemplateUpdate: any, template: TemplateType,setUpdateField:any ) => {
    handleTemplateUpdate({
        template,
        fieldName: 'html',
        fieldValue: updatedHtml
    })
    setUpdateField({open: false, fieldName: '', fieldValue: '', fieldType: ''});
}

export const handleSubjectChange = (event: any, country: string, template: TemplateType, handleTemplateUpdate: any) => {
    handleTemplateUpdate({
        template,
        fieldName: 'subject',
        fieldValue: event.target.value,
        country
    })
}

export const handleReadOnlyUpdate = (event: any, country: string, template: TemplateType, handleTemplateUpdate: any, setTemplateReadOnly: any, activity?: ActivityType) => {
    handleTemplateUpdate({
        template,
        fieldName: 'readOnly',
        fieldValue: event.target.checked,
        country
    })
    setTemplateReadOnly(event.target.checked);

}

export const handleImageUpdate = (fieldName: string, value: string, templateFields: TemplateFieldType[], country: string, template: TemplateType, handleTemplateWebFormFieldChange: any) => {

    const field = JSON.parse(JSON.stringify(templateFields)).find( (field: TemplateFieldType ) => field.name === fieldName);
    if (field) field.value = `${process.env.REACT_APP_UPLOAD_BUCKET}${value}`;
    handleTemplateWebFormFieldChange([{field, template, country, action: 'Update'}]);
}

export const generateOutlookSafeButton = ( label: string) => {
    return (
        `<table>
              <tbody>
                <tr>
                  <td align="center" class="CTA_BUTTON" style="font-size: 15px;font-weight: 700;">
                    ${label}
                  </td>
                </tr>
              </tbody>
            </table>
`
    )
}

export const getColorPreset = ( activity: ActivityType, templateSuite: TemplateSuiteType): ColorPresetBrandType | any => {
    if (templateSuite?.colorPresets) {
        const colorPresets: ColorPresetType = templateSuite?.colorPresets ? JSON.parse(templateSuite?.colorPresets) : {};
        let colorPresetBrand;
        if (activity?.jointEvent && colorPresets?.JointEvent) {
            colorPresetBrand = colorPresets?.JointEvent
        } else {
            // @ts-ignore
            colorPresetBrand = colorPresets?.brands[activity.brand]
        }
        return colorPresetBrand?? colorPresets?.Generic;
    }
    return {}

}

interface GetFragmentFooterInput {
    country?: string;
    activity: ActivityType;
    isUsingFragmentFooter: boolean;
    setFooter: any;
}
export const getFragmentFooter = async ({country, activity, isUsingFragmentFooter, setFooter}: GetFragmentFooterInput) => {
    if (isUsingFragmentFooter) {
        const isRepEmail = activity.templateSuite.type === 'RepEmail';
        const footer = await getFooter({
            country: country??activity.countries[0],
            jointEvent: activity.jointEvent,
            repEmail: isRepEmail

        })
        if (footer && setFooter) setFooter(footer?.value)
        return footer?.value;
    }
}
export const getRepEmailFragmentFooter = async ({country}: any) => {
        const footer = await getFooter({
            country: country,
            jointEvent: false,
            repEmail: true

        })
        return footer?.value;
}
export const getPrintFragmentFooter = async ({country, activity}: {country: string, activity: any}) => {
    const footer = await getFragment({
        type: 'Footer',
        country: country??activity.countries[0],
        jointEvent: activity.jointEvent,
        repEmail: false,
        print: true
    })
    return footer.value.replace('[[__approvalCode]]', activity.approvalCode || '--ApprovalCode--');
}
import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Box, Button, Checkbox, FormControlLabel, TextField} from "@mui/material/";
import PropTypes, {InferProps} from "prop-types";
import {styled} from "@mui/material/styles";
import {Api} from "../../../../Api";
import {ActivityContext} from "../../ActivityContext";
import {AppContext} from "../../../../AppContext";
import {enableApproval} from "../../methods";
const {REACT_APP_ACTIVITY_API} =  process.env;
export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2) ,
    paddingBottom: theme.spacing(3),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto',
    marginTop: 'calc(10% - 10px)',
    marginBottom: '40px',
    flexDirection: 'column',
    width: '500px',
    minHeight: '100px'
}))

export const Approval: any = ({lastMessage}: InferProps<typeof Approval.propTypes>) => {
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const {activity, isEditor, isArchived, setActivity}  = useContext(ActivityContext);
    const [approvalCodes] = React.useState(activity.approvalCodes??[]);
    const [shouldEnableApproval] = React.useState(enableApproval(activity));
    useEffect(() => {
        if (lastMessage !== null) {
            if (JSON.parse(lastMessage.data).event === 'APPROVE_TEMPLATES') {
                setTimeout( () => {
                    setWaitingForBackendMessage('');
                }, 2500)
            }
        }

    }, [lastMessage]);

    const ApprovalFormCountryVersion = ({country}: {country?: string}) => {
        const isDefaultTemplate = React.useRef(!country)
        const approvalCode = React.useRef(isDefaultTemplate.current ? activity.approvalCode : activity.approvalCodes.find( (aCode: any) => aCode.country === country)?.approvalCode)
        const label = country??'';
        const [error, setError] = useState(false);
        const [helperText, setHelperText] = useState("Format: [ApprovalCode]-YYYY-MM (e.g., PM-AT-DK-00037-2024-10)");
        const [confirmLegalDepositCopy, setConfirmLegalDepositCopy] = useState(!!approvalCode.current);

        const [isUpdated, setIsUpdated] = useState(false);
        const getLegalDepositCopy = (country?: string) => {
            if (country === 'SE') return 'LIF';
            if (country === 'NO') return 'LMI';
            if (country === 'DK') return 'ENLI';
            return ''
        }
        const getActivityTypeDescription = (type: string) => {
            if (type === 'RepEmail') return 'Rep Email';
            if (type === 'Event') return 'Invitation';
            if (type === 'BookingEmail') return 'Booking Email';
            return ''
        }

        const legalDepositCopyDetails = {
            instance: getLegalDepositCopy(country??activity.countries[0]),
            type: getActivityTypeDescription(activity.templateSuite.type),
        }

        const handleValidation = (event: any) => {
            const value = event.target.value;
            const regex = /^.+-\d{4}-\d{2}$/;

            if (!regex.test(value)) {
                // Specifik kontroll för att ge mer detaljerad feedback
                if (!value.includes('-')) {
                    setHelperText("Approval code must include a '-' before the year and month (e.g., PM-AT-DK-00037-2024-10).");
                } else if (!/\d{4}-\d{2}$/.test(value)) {
                    setHelperText("Year must be 4 digits and month must be 2 digits (e.g., PM-AT-DK-00037-2024-10).");
                } else {
                    setHelperText("Format should be [ApprovalCode]-YYYY-MM (e.g., PM-AT-DK-00037-2024-10).");
                }
                setError(true);
            } else {
                setHelperText("");
                setError(false);
            }

            if (value !== approvalCode.current) {
                setIsUpdated(true);
                setConfirmLegalDepositCopy(false);
            }

        };
        const buttonLabel = approvalCode.current ? 'Update' : 'Save';


        const handleSubmitCountryVersion = async (event: any) => {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            let result = {approvalCode: ''};
            // @ts-ignore
            for(const pair of data.entries()) {
                result = {...result, [pair[0]]: pair[1]}
            }
            if ( result.approvalCode ) {
                let status = activity.status === 2 ? activity.status + 1 : activity.status;
                const payload = {
                    isApproved: true,
                    status,
                    approvalCode: isDefaultTemplate.current ? result.approvalCode : activity.approvalCode,
                    approvalCodes: isDefaultTemplate.current ? activity.approvalCodes : approvalCodes?.find( (aCode: any) => aCode.country === country) ?
                        approvalCodes.map( (aCode: any) => {
                            if (aCode.country === country)
                                aCode.approvalCode = result.approvalCode;
                            return aCode
                        })
                        : [...approvalCodes, {country, approvalCode: result.approvalCode}]
                }
                setActivity({...activity, ...payload});
                setWaitingForBackendMessage('Preparing templates, this can take up to two minutes ');
                await Api( {
                    name: 'APPROVE_TEMPLATES',
                    type: 'PUT',
                    url: `${REACT_APP_ACTIVITY_API}activity`,
                    body: {
                        action: 'APPROVE_TEMPLATES',
                        payload: {
                            ...payload,
                            _id: activity._id,
                        }
                    }
                });
            }
        };
        return (
            <Box id="approvalForm" component="form" onSubmit={handleSubmitCountryVersion} noValidate sx={{ paddingLeft: '20px', pb: '10px' }} key={country}>
                    <Grid  sx={{marginTop: '10px'}} >
                        <TextField
                            required
                            id="approvalCode"
                            name="approvalCode"
                            fullWidth
                            disabled={!isEditor || isArchived}
                            label={`Approval code ${label}`}
                            defaultValue={approvalCode.current}
                            autoComplete="off"
                            variant="standard"
                            placeholder="e.g., PM-AT-DK-00037-2024-10"
                            helperText={isUpdated && helperText}
                            error={error}
                            onChange={handleValidation}
                            onBlur={handleValidation}
                            inputProps={{
                                pattern: '^.+-\\d{4}-\\d{2}$',
                            }}
                        />
                        {
                            country !== 'FI' && isUpdated &&
                            <FormControlLabel
                                sx={{mt: '15px', mb: '15px', '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
                                disabled={error || !isEditor || isArchived || !isUpdated}
                                control={<Checkbox checked={confirmLegalDepositCopy} onChange={(event, checked) => setConfirmLegalDepositCopy(checked)} />}
                                label={`Confirmed that if applicable, the material is reported to ${legalDepositCopyDetails.instance}.`}
                            />
                        }
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}>
                            <Button
                                variant="contained"
                                size="small"
                                disabled={!isEditor || isArchived || (!confirmLegalDepositCopy && country !== 'FI') || error || !isUpdated}
                                type="submit"
                                sx={{marginTop: '10px'}}

                            >
                                {buttonLabel}
                            </Button>
                        </Box>
                    </Grid>
            </Box>
        )
    }
    // @ts-ignore
    return (
        <Grid >
                {
                    !activity.approvalCode && !shouldEnableApproval.yes &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#fff',
                            fontSize: '20px',
                            zIndex: 999999,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)', // Semi-transparent black background
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Light shadow for a lifted effect
                            border: '1px solid rgba(255, 255, 255, 0.2)', // Thin white border for better edge definition

                            // Centering logic
                            position: 'fixed',   // or 'absolute' if you want it relative to the nearest positioned ancestor
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <strong>Attention!</strong>
                        <p>
                            Veeva number / Approval number cannot be set before all templates are done.
                        </p>
                        <p>
                            Please go to step "Template" and make sure that all templates are set to "Done"
                        </p>
                    </Box>

                }
                {
                    (shouldEnableApproval.yes || activity.approvalCode) &&
                    <StyledPaper elevation={24}>
                        {(activity.templateSuite.type !== 'RepEmail' || activity.countries.length === 1)
                            && <ApprovalFormCountryVersion/>
                        }
                        {
                            activity.templateSuite.type === 'RepEmail' && activity.countries.length > 1 && shouldEnableApproval.countries.map( (country: string) => {
                                return (
                                    <ApprovalFormCountryVersion country={country} />
                                )
                            })
                        }
                    </StyledPaper>
                }
        </Grid>
    );
}
Approval.propTypes = {
    lastMessage: PropTypes.object
};

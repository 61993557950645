import Grid from '@mui/material/Unstable_Grid2';
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {Menu} from "../../views/TemplatesTreeMenu";
import { AppContext } from '../../AppContext';
import {getTemplateSuites} from "../Template/methods";
import {createActivity} from "./methods";
import {sendErrorMessage} from "../../Error";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {ComboBox} from "../../components/FormElements";

export interface TemplateParams {
    templateSuite: TemplateSuiteType
    brand?: string;
    jointEvent?: boolean;
    type: string;
    nodeId: string;
    country?: string;
}
export const CreateActivity = ({openNewEventDialog, setOpenNewEventDialog, type}: CreateActivityInput) => {
    const history = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const {setWaitingForBackendMessage, user} = useContext(AppContext);
    const [templates, setTemplates] = React.useState<TemplateSuiteType[]>([]);
    const [selectedNodeItem, setSelectedNodeItem] = React.useState('0_0');
    const [selectedCountry, setSelectedCountry] = React.useState({value: user?.countries?.[0]});
    const [selectedTemplate, setSelectedTemplate] = React.useState<TemplateParams | undefined>();
    useEffect( () => {
        (async () => {
            const tmpTemplates: TemplateSuiteType[] = await getTemplateSuites(true, type === 'Event' ? ['Event'] : [type], user?.countries)
            if (tmpTemplates) {
                setTemplates(tmpTemplates);
            }
        })()
    }, [type]);

    const createNewActivity = (templateParams: TemplateParams) => {
        if (templateParams) {
            ( async() => {
                try {
                    setWaitingForBackendMessage('Just a sec, I am creating...')
                    templateParams.country = selectedCountry.value;
                    const activity: ActivityType = await createActivity(templateParams);
                    history(`/activity/?_id=${activity._id}`);
                } catch ( e: any ) {
                    await sendErrorMessage(e,  'front/activity/methods.createActivity', "Cannot create activity", enqueueSnackbar);
                }

            })()
        }

    }
    useEffect( () => {
        if (selectedTemplate?.nodeId)
            setSelectedNodeItem(selectedTemplate.nodeId)
    }, [selectedTemplate])
    return (
        <Dialog open={openNewEventDialog} maxWidth={'sm'}>
            <DialogTitle component="div" sx={{backgroundColor: '#1976d2', mb: '20px', color: 'white'}}><Typography component="h4" variant="h4">Create from template</Typography></DialogTitle>
            <DialogContent>
                {
                    (user?.countries?.length??0) > 1 &&
                    <ComboBox
                        sx={{pb: 2, width: '100px'}}
                        options={user?.countries?.map( (country: any) => {
                                return {
                                    value: country
                                }
                            }
                        )}
                        multiple={false}
                        required={true}
                        label="Country"
                        placeholder="Select country"
                        value={selectedCountry}
                        onChange={(e: any, val: any) => {
                            console.log('selectedCountry', val);
                            setSelectedCountry(val)}}
                     />

                }
                <Box sx={{ flexGrow: 1, width: '500px', height: '400px' }}>
                    <Menu addEVent={() => {}} defaultSelected={selectedNodeItem} templates={templates} onClick={(tmplateParams: TemplateParams) => setSelectedTemplate(tmplateParams)}/>
                </Box>
            </DialogContent>
            <DialogActions >
                <Button variant="outlined" onClick={() => setOpenNewEventDialog(false)}>Close</Button>
                <Button disabled={!selectedTemplate} variant="contained" onClick={() => selectedTemplate && createNewActivity(selectedTemplate)}>CREATE</Button>
            </DialogActions>
        </Dialog>


    );
}

interface CreateActivityInput {
    type: 'BookingEmail' | 'RepEmail' | 'Event';
    setOpenNewEventDialog: any;
    openNewEventDialog: boolean;
}

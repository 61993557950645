import React, {useEffect} from "react";
import {MenuType, StyledTreeItem} from "../../components/CustomTreeView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Label from "@mui/icons-material/Label";
import MedicationIcon from "@mui/icons-material/Medication";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import DoneIcon from "@mui/icons-material/Done";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import HistoryIcon from "@mui/icons-material/History";
import TreeView from '@mui/lab/TreeView';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {Tooltip} from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
interface MenuTypeInput {
    fragments: FragmentType[] | undefined,
    onClick: any,
    defaultSelected: string,
    addEVent: any,
    label: string
}
export const Menu = ({fragments, onClick, defaultSelected, addEVent, label}: MenuTypeInput) => {
    const [menu, setMenu] = React.useState<MenuType | undefined>()
    const sortMenu = (key: string, func: any) => {
        if (fragments) {
            fragments.sort((a: any, b: any) => (a[key] ?? '').localeCompare(b[key] ?? '')).map((f: any) => {
                func(f);
            })
        }
    }
    useEffect(() => {
        if (fragments) {
            let tmpMenu: any = [];
            sortMenu('brand', (f: any) => {
                if (f.brand)
                    tmpMenu[f.brand] = {};
            });
            sortMenu('product', (f: any) => {
                if (f.brand && f.product)
                    tmpMenu[f.brand][f.product] = {};
            });
            sortMenu('country', (f: any) => {
                if (f.brand && f.product && f.country && !f.parent)
                    tmpMenu[f.brand][f.product][f.country] = {
                        current: fragments.find((f1: FragmentType) => (f1.status === 'Active' && f1._id === f._id) || (f1.status === 'Active' && f1.parent === f._id)),
                        draft: fragments.find((f1: FragmentType) => (f1.status === 'Draft' && f1._id === f._id) || (f1.status === 'Draft' && f1.parent === f._id)),
                        history: fragments.sort((f1: FragmentType, f2: FragmentType) => (f2?.version??1) - (f1?.version??1)).filter((f1: FragmentType) => (f1.status === 'Inactive' && f1._id === f._id) || (f1.status === 'Inactive' && f1.parent === f._id))
                    };
            });
            setMenu(tmpMenu)
        }

    }, [fragments])
    return (
        <TreeView
            aria-label="Fragments"
            defaultExpanded={['0']}
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultExpandIcon={<ArrowRightIcon/>}
            defaultEndIcon={<div style={{width: 24}}/>}
            selected={defaultSelected}
            sx={{flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}
        >
            <StyledTreeItem key={`0`} nodeId="0" labelText={label} labelIcon={Label} labelAction={
                <Tooltip title="Add a new fragment">
                    <IconButton onClick={addEVent}  aria-label="add" color="primary" ><AddIcon  /></IconButton>
                </Tooltip>
            }>
                {
                    menu && Object.entries(menu).map((brand: any, index: number) => {
                        return (
                            <StyledTreeItem
                                key={`${index + 1}`}
                                nodeId={`${index + 1}`}
                                labelText={`${brand[0]}`}
                                labelIcon={MedicationIcon}
                                labelInfo={`${Object.entries(brand[1]).length}`}
                            >
                                {
                                    Object.entries(brand[1]).map((product: any, index2: number) => {
                                        return (
                                            <StyledTreeItem
                                                key={`${index + 1}_${index2}`}
                                                nodeId={`${index + 1}_${index2}`}
                                                labelText={`${product[0]}`}
                                                labelIcon={VaccinesIcon}
                                                labelInfo={`${Object.entries(product[1]).length}`}
                                            >
                                                {
                                                    Object.entries(product[1]).map((country: any, index3: number) => {
                                                        return (
                                                            <StyledTreeItem
                                                                key={`${index + 1}_${index2}_${index3}`}
                                                                nodeId={`${index + 1}_${index2}_${index3}`}
                                                                labelText=""
                                                                labelCountryFlag={`${country[0]}`}
                                                            >
                                                                <StyledTreeItem
                                                                    key={country[1]?.current?._id}
                                                                    hidden={!country[1].current?._id}
                                                                    nodeId={country[1].current?._id}
                                                                    labelText="Active version"
                                                                    onClick={() => onClick(country[1].current)}
                                                                    labelIcon={DoneIcon}
                                                                />
                                                                <StyledTreeItem
                                                                    key={country[1]?.draft?._id}
                                                                    hidden={!country[1].draft?._id}
                                                                    nodeId={country[1]?.draft?._id}
                                                                    labelText="Draft"
                                                                    onClick={() => onClick(country[1].draft)}
                                                                    labelIcon={ModeEditOutlineIcon}
                                                                />
                                                                <StyledTreeItem
                                                                    key={`${index + 1}_${index2}_${index3}_3`}
                                                                    nodeId={`${index + 1}_${index2}_${index3}_3`}
                                                                    labelText="History"
                                                                    labelIcon={HistoryIcon}
                                                                    labelInfo={country[1]?.history?.length}
                                                                >
                                                                    {
                                                                        country[1].history && country[1].history.map((historicVersion: FragmentType, index4: number) => {
                                                                            return (
                                                                                <StyledTreeItem
                                                                                    key={historicVersion?._id}
                                                                                    nodeId={historicVersion?._id??''}
                                                                                    labelText={`Version ${historicVersion.version}`}
                                                                                    onClick={() => onClick(historicVersion)}
                                                                                    labelIcon={HistoryIcon}
                                                                                />)
                                                                        })
                                                                    }
                                                                </StyledTreeItem>
                                                            </StyledTreeItem>
                                                        )
                                                    })
                                                }
                                            </StyledTreeItem>
                                        )
                                    })
                                }
                            </StyledTreeItem>
                        )
                    })
                }
            </StyledTreeItem>
        </TreeView>
    )
}
export const ImageMenu = ({fragments, onClick, defaultSelected, addEVent, label}: MenuTypeInput) => {
    const [menu, setMenu] = React.useState<MenuType[] | undefined>()
    const sortMenu = (key: string, func: any) => {
        if (fragments) {
            fragments.sort((a: any, b: any) => (a[key] ?? '').localeCompare(b[key] ?? '')).map((f: any) => {
                func(f);
            })
        }
    }
    useEffect(() => {
        if (fragments) {
            let tmpMenu: any = [];
            sortMenu('description', (f: any) => {
                    tmpMenu.push(f);
            })
            setMenu(tmpMenu)
        }
    }, [fragments])
    return (
        <TreeView
            aria-label="Fragments"
            defaultExpanded={['0']}
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultExpandIcon={<ArrowRightIcon/>}
            defaultEndIcon={<div style={{width: 24}}/>}
            selected={defaultSelected}
            sx={{flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}
        >
            <StyledTreeItem key={`0`} nodeId="0" labelText={label} labelIcon={Label} labelAction={
                <Tooltip title="Add a new fragment">
                    <IconButton onClick={addEVent}  aria-label="add" color="primary" ><AddIcon  /></IconButton>
                </Tooltip>
            }>
                {
                    menu && menu.map((row: any, index: number) => {
                            return (
                                <StyledTreeItem
                                    key={`${index + 1}`}
                                    nodeId={`${index + 1}`}
                                    labelText={`${row.description}`}
                                    labelIcon={ImageIcon}
                                    onClick={() => onClick(row)}
                                />
                            )

                    })
                }
            </StyledTreeItem>
        </TreeView>
    )
}

export const FooterMenu = ({fragments, onClick, defaultSelected, addEVent, label}: MenuTypeInput) => {
    const [menu, setMenu] = React.useState<MenuType | undefined>()
    useEffect(() => {
        const sortMenu = (key: string, func: any) => {
            if (fragments) {
                fragments.sort((a: any, b: any) => (a[key] ?? '')?.localeCompare(b[key] ?? ''))?.forEach((f: any) => {
                    func(f);
                })
            }
        }
        if (fragments) {
            let tmpMenu: any = [];
            tmpMenu['Rep Email'] = {};
            tmpMenu['Normal Event'] = {};
            tmpMenu['Joint Event'] = {};
            tmpMenu['Rep Email']['E-mail'] = {};
            tmpMenu['Normal Event']['E-mail'] = {};
            tmpMenu['Normal Event']['Print'] = {};
            tmpMenu['Joint Event']['E-mail'] = {};
            tmpMenu['Joint Event']['Print'] = {};
            sortMenu('country', (f: any) => {
                if (f.country){
                    const type = f.repEmail ? 'Rep Email' : f.jointEvent ? 'Joint Event' : 'Normal Event';
                    tmpMenu[type][f.print ? 'Print' : 'E-mail'][f.country] = {
                        current: fragments.find((f1: FragmentType) => (f1.status === 'Active' && f1._id === f._id) || (f1.status === 'Active' && f1.parent === f._id)),
                        draft: fragments.find((f1: FragmentType) => (f1.status === 'Draft' && f1._id === f._id) || (f1.status === 'Draft' && f1.parent === f._id)),
                        history: JSON.parse(JSON.stringify(fragments)).sort((f1: FragmentType, f2: FragmentType) => (f2?.version??1) - (f1?.version??1)).filter((f1: FragmentType) => (f1.status === 'Inactive' && f1._id === f._id) || (f1.status === 'Inactive' && f1.parent === f._id))
                    };
                }

            });
            setMenu(tmpMenu)
        }

    }, [fragments])
    return (
        <TreeView
            aria-label="Fragments"
            defaultExpanded={['0']}
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultExpandIcon={<ArrowRightIcon/>}
            defaultEndIcon={<div style={{width: 24}}/>}
            selected={defaultSelected}
            sx={{flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}
        >
            <StyledTreeItem key={`0`} nodeId="0" labelText={label} labelIcon={Label} labelAction={
                <Tooltip title="Add a new fragment">
                    <IconButton onClick={addEVent}  aria-label="add" color="primary" ><AddIcon  /></IconButton>
                </Tooltip>
            }>
                {
                    menu && Object.entries(menu).map((event: any, index: number) => {
                        return (
                            <StyledTreeItem
                                key={`${index + 1}`}
                                nodeId={`${index + 1}`}
                                labelText={`${event[0]}`}
                                labelIcon={MedicationIcon}
                                labelInfo={`${Object.entries(event[1]).length}`}
                            >
                                {
                                    Object.entries(event[1]).map((type: any, index2: number) => {
                                        return (
                                            <StyledTreeItem
                                                key={`${index + 1}_${index2}`}
                                                nodeId={`${index + 1}_${index2}`}
                                                labelText={`${type[0]}`}
                                                labelIcon={type[0] === 'Print' ? PrintIcon : EmailIcon}
                                            >
                                                {
                                                    Object.entries(type[1]).map((country: any, index3: number) => {
                                                        return (
                                                            <StyledTreeItem
                                                                key={`${index + 1}_${index2}_${index3}`}
                                                                nodeId={`${index + 1}_${index2}_${index3}`}
                                                                labelText=""
                                                                labelCountryFlag={`${country[0]}`}
                                                            >
                                                                <StyledTreeItem
                                                                    key={country[1]?.current?._id}
                                                                    hidden={!country[1].current?._id}
                                                                    nodeId={country[1].current?._id}
                                                                    labelText="Active version"
                                                                    onClick={() => onClick(country[1].current)}
                                                                    labelIcon={DoneIcon}
                                                                />
                                                                <StyledTreeItem
                                                                    key={country[1]?.draft?._id}
                                                                    hidden={!country[1].draft?._id}
                                                                    nodeId={country[1]?.draft?._id}
                                                                    labelText="Draft"
                                                                    onClick={() => onClick(country[1].draft)}
                                                                    labelIcon={ModeEditOutlineIcon}
                                                                />
                                                                <StyledTreeItem
                                                                    key={`${index + 1}_${index2}_${index3}_3`}
                                                                    nodeId={`${index + 1}_${index2}_${index3}_3`}
                                                                    labelText="History"
                                                                    labelIcon={HistoryIcon}
                                                                    labelInfo={country[1]?.history?.length}
                                                                >
                                                                    {
                                                                        country[1].history && country[1].history.map((historicVersion: FragmentType, index4: number) => {
                                                                            return (
                                                                                <StyledTreeItem
                                                                                    key={historicVersion?._id}
                                                                                    nodeId={historicVersion?._id ?? ''}
                                                                                    labelText={`Version ${historicVersion.version}`}
                                                                                    onClick={() => onClick(historicVersion)}
                                                                                    labelIcon={HistoryIcon}
                                                                                />)
                                                                        })
                                                                    }
                                                                </StyledTreeItem>
                                                            </StyledTreeItem>
                                                        )

                                                    })
                                                }
                                            </StyledTreeItem>
                                        )
                                    })
                                }
                            </StyledTreeItem>
                        )
                    })

                }
            </StyledTreeItem>
        </TreeView>
    )
}

const sendMessage = (type: string, cacheName: string, objectId?: string) => {
    if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
            type,
            cacheName,
            objectId,
            token: localStorage.getItem('jwtTokenEventManager')
        });
    }
}
export const refreshCache = async ({object, objectId}: {object: string, objectId?: string}) => {
    const type = "UPDATE_CACHE";
    switch (object) {
        case 'Activity':
            //Update /activities, for now, do nothing here
            //sendMessage(type, 'activities');
            //Update /activities/:id
           // if (objectId) sendMessage(type, 'activities', objectId);
            break;
    }

}
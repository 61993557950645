interface DefaultInput {
    activity?: ActivityType;
    template: TemplateType;
    handleTemplateUpdate?: any;
    setTemplateFields?: any;
}
interface AddFieldInput {
    setUpdateField: any;
    templateFields: TemplateFieldType[];
    handleTemplateFieldUpdate: any;
}
export const addField = ({templateFields, handleTemplateFieldUpdate, template, setUpdateField}: AddFieldInput & DefaultInput ) => {
    let counter = templateFields.length;
    let name: string;
    do {
        name = `Field_${counter}`;
        counter++;
    } while( templateFields.find( field => field.name === name))


    const field = {label: name, value: name, name, isRequired: false, sort: templateFields.length+1, type: 'TextBox'};

    handleTemplateFieldUpdate([{field, template, isDeleted: false, action: 'Create'}]);
    setTimeout( () => {
        setUpdateField(
            {fieldName: name, open: true, fieldValue: name, fieldType: 'TextBox'})
    }, 100)
}

const sortFields = (fieldArray: TemplateFieldType[]) => {
    const sortedTemplateFields = fieldArray.sort((prev: TemplateFieldType, curr: TemplateFieldType) => (prev.sort??0) - (curr.sort??0));
    return sortedTemplateFields.map( ( field: TemplateFieldType, index: number ) => {
        return {...field, sort: index}
    });
}

const getNextVisibleField = (sort: number, fieldArray: TemplateFieldType[]): TemplateFieldType | undefined => {
    return fieldArray.sort((prev: TemplateFieldType, curr: TemplateFieldType) => (prev.sort??0) - (curr.sort??0)).find( (field: TemplateFieldType) =>
        !field?.hidden &&
        field.type !=='HTML' &&
        field.name.indexOf('__') === -1 &&
        field.name !== 'Submit' &&
        // @ts-ignore
        (field?.sort > sort)
    )
}

const getPrevVisibleField = (sort: number, fieldArray: TemplateFieldType[]): TemplateFieldType | undefined => {
    return fieldArray.sort((prev: TemplateFieldType, curr: TemplateFieldType) => (curr.sort??0) - (prev.sort??0)).find( (field: TemplateFieldType) =>
        !field?.hidden &&
        field.type !=='HTML' &&
        field.name.indexOf('__') === -1 &&
        field.name !== 'Submit' &&
        // @ts-ignore
        (field?.sort < sort)
    )
}

const getFieldObject = (name: string, fieldArray: TemplateFieldType[]) => {
    return fieldArray.find( (field: TemplateFieldType) => field.name === name)
}

export const getFieldType = (fieldName: string, templateFields: TemplateFieldType[]) => templateFields.find( (f: TemplateFieldType) => f.name === fieldName)?.type??''

const fieldsWithMissingSortQuickFix = (templateFields: TemplateFieldType[], template: TemplateType) => {
    return templateFields.filter( (field: TemplateFieldType) => !field.sort).map( (field: TemplateFieldType) => (
        {field, template, isDeleted: false, action: 'Update'}));
}
export const moveFieldUp = (name: string, templateFields: TemplateFieldType[], handleTemplateFieldUpdate: any, template: TemplateType) => {
    let tmpTemplateFields = JSON.parse(JSON.stringify(templateFields));
    //Start to sort all fields
    tmpTemplateFields = sortFields(tmpTemplateFields);
    let field = getFieldObject(name, templateFields);
    if (!field?.sort) {
        field = getFieldObject(name, tmpTemplateFields);
    }
    if ( field?.sort ) {
        const parentField = getPrevVisibleField(field?.sort, tmpTemplateFields);
        if ( parentField?.name) {
            handleTemplateFieldUpdate(
                [
                    ...fieldsWithMissingSortQuickFix(tmpTemplateFields, template),
                    {field: {...parentField, sort: (field.sort)}, template, isDeleted: false, action: 'Update'},
                    {field: {...field, sort: (field.sort-1)}, template, isDeleted: false, action: 'Update'}
                ]
            );
        }

    }
}
export const moveFieldDown = (name: string, templateFields: TemplateFieldType[], handleTemplateFieldUpdate: any, template: TemplateType) => {
    //Start to sort all fields
    templateFields = sortFields(templateFields);
    const field = getFieldObject(name, templateFields);
    if ( field?.sort ) {
        const parentField = getNextVisibleField(field?.sort, templateFields);
        if ( parentField?.name) {
            handleTemplateFieldUpdate(
                [
                    ...fieldsWithMissingSortQuickFix(templateFields, template),
                    {field: {...parentField, sort: (field.sort)}, template, isDeleted: false, action: 'Update'},
                    {field: {...field, sort: (field.sort+1)}, template, isDeleted: false, action: 'Update'}
                ]
            );
        }

    }
}

export const deleteField = (name: string, template: TemplateType, handleTemplateFieldUpdate: any, setTemplateFields: any) => {
    //I need to make a copy because if I dont, then diff() will not work since we are updating the source object
    const tmpTemplate = JSON.parse(JSON.stringify(template));
    const field = tmpTemplate.fields.find( (field: TemplateFieldType) => field.name === name);
    if (field) {
        let isDeleted = true;
        if (field?.defaultField) {
            field.hidden = true;
            isDeleted = false;
        }
        handleTemplateFieldUpdate([{field, template, isDeleted, action: 'Delete'}]);
    }
}

export const handleTemplateFieldsChange = (updatedFields: TemplateFieldType[],
                                          setUpdateTemplateFields: any,
                                          clearUpdatedField: any,
                                           handleTemplateFieldUpdate: any,
                                          template: TemplateType) => {
    const arrayUpdates: any[] = [];
    updatedFields.forEach( (field: TemplateFieldType) => {
        arrayUpdates.push({field, template, action: 'Update'});

    })
    handleTemplateFieldUpdate(arrayUpdates);
    clearUpdatedField();
    setUpdateTemplateFields(false);
}
export const handleTemplateFieldChange = (updatedField: TemplateFieldType,
                                          templateFields: TemplateFieldType[],
                                          clearUpdatedField: any, handleTemplateFieldUpdate: any,
                                          template: TemplateType) => {
        clearUpdatedField();
        handleTemplateFieldUpdate([{field: updatedField, template, action: 'Update'}]);
}

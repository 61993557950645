import {Item} from "../../components/CustomTreeView";
import Grid from "@mui/material/Unstable_Grid2";
import {ComboBox} from "../../components/FormElements";
import {Box, Button, Checkbox, FormControlLabel, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {FormatDateFromUTC} from "../Activity/methods";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import EditIcon from "@mui/icons-material/Edit";
import React, {useContext, useEffect} from "react";
import {
    cloneFragment,
    updateFragment,
    activateFragmentRequest,
    deleteFragmentRequest
} from './methods';
import {AppContext} from "../../AppContext";
import {sendErrorMessage} from "../../Error";
import {useSnackbar} from "notistack";
import {FragmentTextArea} from "./FragmentTextArea";
import {Image} from "./Image";
interface FragmentFormType {
    fragment: FragmentType | undefined;
    setFragment(fragment: FragmentType | undefined): void;
    fragments: FragmentType[] | undefined;
    refreshFragments(): void;
    setSelectedNodeItem(item: string): void;
}
export const FragmentForm = ({fragment, setFragment, setSelectedNodeItem, fragments, refreshFragments}: FragmentFormType) => {
    const {setWaitingForBackendMessage, getLOV} = useContext(AppContext);
    const [payload, setPayload] = React.useState<FragmentType | undefined>()
    const quillValue = React.useRef<undefined | string>('');
    const [description, setDescription] = React.useState<undefined | string>();
    const [image, setImage] = React.useState<undefined | string>();
    const [isUpdated, setIsUpdated] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [hasVersionHistory, setHasVersionHistory] = React.useState(false);
    const [listOfValues] = React.useState({
        country: getLOV('country'),
        brand: getLOV('brand').map( (brand: any) => {
            return {...brand, product: getLOV('product', brand.value)}
        })
    });
    const { enqueueSnackbar } = useSnackbar();

    const saveQuillValue = ( value: string ) => {
        if (!isUpdated)
            setIsUpdated((!!value && value !== fragment?.value))
        quillValue.current = value;
    }
    useEffect( () => {
        if ((description || image)) {
            if ( description && image ) {
                setPayload({
                    ...payload,
                    description,
                    image
                })
            }
            if ( image && !description ) {
                setPayload({
                    ...payload,
                    image
                })
            }
            if ( !image && description ) {
                setPayload({
                    ...payload,
                    description
                })
            }
            if (!isUpdated)
                setIsUpdated((!!description && description !== fragment?.value) || (!!image && image !== fragment?.image))
        }
    }, [description, image])

    const filterProducts = () => {
        const selectedProducts: any = [];
        fragments?.map( (f: FragmentType) => {
            if (f.country === fragment?.country && f.brand === fragment?.brand) {
                if (!selectedProducts.includes(f.product))
                    selectedProducts.push(f.product);
            }
        })
        return selectedProducts
    }

    const deleteFragment = () => {
        ( async() => {
            if (window.confirm('Do you really like to delete this fragment?')) {
                setWaitingForBackendMessage('Deleting...')
                if ( fragment?._id)
                    await deleteFragmentRequest(fragment?._id)
                setFragment(undefined);
                refreshFragments();
                setWaitingForBackendMessage('');
            }

        })()
    }
    const saveFragment = () => {
        ( async() => {
            if (fragment?._id && (payload || quillValue.current)) {
                try {
                    let tmpPayload = payload;
                    if ( quillValue.current && payload) {
                        tmpPayload = {...payload, value: quillValue.current}
                    } else if (quillValue.current && !payload) {
                        tmpPayload = {value: quillValue.current}
                    }

                    if (tmpPayload) {
                        setWaitingForBackendMessage('Saving fragment...')
                        setFragment(await updateFragment(fragment._id, tmpPayload));
                        setIsUpdated(false);
                        setPayload(undefined);
                        refreshFragments();
                        setWaitingForBackendMessage('')
                        enqueueSnackbar(`Your changes has been saved`, {variant: 'success'});
                    }

                } catch( e ) {
                    await sendErrorMessage(e,  'front/fragment/methods.saveFragment', "Cannot Save Fragment", enqueueSnackbar);
                }

            }
        })()
    }
    const activateFragment = () => {
        ( async() => {
            if (fragment?._id) {
                try {
                    setWaitingForBackendMessage('Activating fragment...')
                    setFragment(await activateFragmentRequest(fragment._id));
                    refreshFragments();
                    setWaitingForBackendMessage('')
                    enqueueSnackbar(`Fragment is activated`, {variant: 'success'});
                    setIsUpdated(false);
                } catch( e ) {
                    await sendErrorMessage(e,  'front/fragment/methods.activateFragment', "Cannot Activate Fragment", enqueueSnackbar);
                }

            }
        })()
    }
    const cancelFragment = () => {
        setFragment(fragments?.find( (f: FragmentType) => f._id === fragment?._id))
        setIsUpdated(false);
    }
    const cloneActiveFragment = () => {
        ( async() => {
            if (fragment?._id) {
                try {
                    setWaitingForBackendMessage('Creating new version...')
                    setFragment(await cloneFragment(fragment._id));
                    refreshFragments();
                    setWaitingForBackendMessage('')
                    enqueueSnackbar(`New draft created`, {variant: 'success'});
                    setIsUpdated(false);
                } catch( e ) {
                    await sendErrorMessage(e,  'front/fragment/methods.cloneFragment', "Cannot Clone Fragment", enqueueSnackbar);
                }

            }
        })()
    }
    useEffect( () => {
        if ( payload ) {
            setFragment({
                ...fragment,
                ...payload
            })
            setIsUpdated(true);
        }

    }, [payload])
    useEffect( () => {
        setIsDisabled(fragment?.status === 'Active' || fragment?.status === 'Inactive' || !fragment?.status)
        setSelectedNodeItem(fragment?._id??'');
        setHasVersionHistory(['Regulatory', 'Footer'].includes(fragment?.type??''))
    }, [fragment]);

    return (
        <Box sx={{width: '720px'}}>
            <Item>
                <Typography variant={'h4'}>{fragment?.type}</Typography>
                {
                    hasVersionHistory &&
                    <Grid container spacing={2} sx={{zIndex: 9999999}} >
                        <Grid xs={3} sx={{zIndex: 9999999}}>
                            <ComboBox
                                options={listOfValues.country}

                                multiple={false}
                                required={true}
                                value={{
                                    value: fragment?.country??''
                                }}
                                onChange={(e: any, val: any) => {
                                    setPayload({
                                        ...payload,
                                        country: val.value
                                    });
                                }}
                                label="Country"
                                placeholder="Select country"
                                disabled={isDisabled}/>
                        </Grid>
                        { fragment?.type === 'Regulatory' &&
                        <Grid xs={3}>

                            <ComboBox
                                options={listOfValues.brand}
                                sx={{width: '200px'}}
                                multiple={false}
                                required={true}
                                value={{
                                    value: fragment?.brand??''
                                }}
                                onChange={(e: any, val: any) => {
                                    setPayload({
                                        ...payload,
                                        brand: val.value
                                    });
                                }}
                                label="Brand"
                                placeholder="Select brand"
                                disabled={isDisabled || !fragment?.country }/>

                        </Grid>
                        }
                        { fragment?.type === 'Regulatory' &&
                        <Grid xs={3}>

                            <ComboBox
                                options={listOfValues.brand.find( lb => lb.value === fragment?.brand)?.product.filter( (lp: any) => !filterProducts().includes(lp.value))??[]}
                                sx={{width: '200px'}}
                                multiple={false}
                                required={true}
                                value={{
                                    value: fragment?.product??''
                                }}
                                onChange={(e: any, val: any) => {
                                    setPayload({
                                        ...payload,
                                        product: val.value
                                    });
                                }}
                                label="Product"
                                placeholder="Select product?"
                                disabled={isDisabled || !fragment?.brand }/>

                        </Grid>
                        }
                        { fragment?.type === 'Footer' &&
                            <React.Fragment>
                                <Grid xs={3}>
                                    <FormControlLabel control={
                                        <Checkbox
                                            value={fragment?.repEmail}
                                            checked={fragment?.repEmail}
                                            disabled={isDisabled}
                                            onChange={(e: any, val: any) => {
                                                setPayload({
                                                    ...payload,
                                                    repEmail: val
                                                });
                                            }} />} label="Rep Email" />

                                </Grid>
                                <Grid xs={3}>
                                    <FormControlLabel control={
                                        <Checkbox
                                            value={fragment?.jointEvent}
                                            checked={fragment?.jointEvent}
                                            disabled={isDisabled || fragment?.repEmail}
                                            onChange={(e: any, val: any) => {
                                                setPayload({
                                                    ...payload,
                                                    jointEvent: val
                                                });
                                            }} />} label="Joint Event" />

                                </Grid>
                                <Grid xs={3}>
                                    <FormControlLabel control={
                                        <Checkbox
                                            value={fragment?.print}
                                            checked={fragment?.print}
                                            disabled={isDisabled || fragment?.repEmail}
                                            onChange={(e: any, val: any) => {
                                                setPayload({
                                                    ...payload,
                                                    print: val
                                                });
                                            }} />} label="Print version" />

                                </Grid>
                            </React.Fragment>

                        }
                    </Grid>

                }
                {
                    !hasVersionHistory &&
                    <Grid spacing={2} sx={{zIndex: 9999999}} >
                        <FormControlLabel control={
                            <Checkbox
                                value={fragment?.private}
                                checked={fragment?.private}
                                disabled={isDisabled}
                                onChange={(e: any, val: any) => {
                                    setPayload({
                                        ...payload,
                                        private: val
                                    });
                                }} />} label="Is Private" />

                    </Grid>
                }

            {
                hasVersionHistory &&
                <FragmentTextArea fragment={fragment!} saveQuillValue={saveQuillValue}/>
            }
            {
                !hasVersionHistory && fragment?._id &&
                <Image setImage={setImage} setDescription={setDescription} fragment={fragment}/>
            }
            <Box sx={{paddingTop: '20px'}}>
                <Typography variant="caption" component="div">
                    {fragment?.createdBy?.name && `Created by ${fragment?.createdBy?.name} on ${FormatDateFromUTC(fragment?.createdAt??'')} and `}
                    {fragment?.updatedBy?.name && `updated by ${fragment?.updatedBy?.name} on ${FormatDateFromUTC(fragment?.updatedAt??'')}`}
                </Typography>
            </Box>
            <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{pt: '40px'}}>
                {
                    (fragment?.status === 'Draft' || !hasVersionHistory) &&
                    <Button  onClick={deleteFragment} variant="contained" endIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                }
                {
                    hasVersionHistory &&
                    <Button disabled={!isUpdated} variant="outlined" startIcon={<CancelIcon />} onClick={cancelFragment}>
                        Cancel
                    </Button>
                }
                {
                    (fragment?.status === 'Draft' || !hasVersionHistory) &&
                    <Button disabled={!isUpdated} onClick={saveFragment} variant="contained" endIcon={<SaveIcon />}>
                        Save
                    </Button>
                }
                {
                    fragment?.status === 'Draft' &&
                    <Button disabled={isUpdated} onClick={activateFragment} variant="contained" endIcon={<ToggleOnIcon />}>
                        Activate
                    </Button>
                }
                {
                    fragment?.status !== 'Draft' && hasVersionHistory &&
                    <Button onClick={cloneActiveFragment} variant="contained" endIcon={< EditIcon />}>
                        Edit
                    </Button>
                }

            </Stack>
            </Item>
        </Box>

    )
}

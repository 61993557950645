import React, {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import PropTypes, {InferProps} from "prop-types";
import {
    Autocomplete, Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {TemplateTabs} from "./components/TemplateTabs";
import {getTemplateSuite, saveTemplate} from "./methods";
import { useSnackbar } from 'notistack';
import {sendErrorMessage} from "../../Error";
import {ComboBox} from "../../components/FormElements";
import {flattenJSON} from "../Activity/methods";
import {getLogoFragments} from "../Fragment/methods";
import {AppContext} from "../../AppContext";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Title = styled(Typography)(({ theme }) => ({
    backgroundColor: '#1862a8',
    marginLeft: theme.spacing(2),
    flex: 1,
}))
const {REACT_APP_UPLOAD_BUCKET} =  process.env;
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />;
});




// @ts-ignore
export const TemplateSuite = ({template, open, setOpen}: InferProps<typeof TemplateSuite.propTypes>) => {
    const [templateSuite, setTemplateSuite] = React.useState<TemplateSuiteType>( template );
    const { enqueueSnackbar } = useSnackbar();
    const [fragmentImages, setFragmentImages] = useState<FragmentType[] | undefined>()
    const {setWaitingForBackendMessage, getLOV} = useContext(AppContext);
    const [listOfValues] = React.useState({
        country: getLOV('country'),
        brand: getLOV('brand'),
        type: getLOV('type'),
    });
    const handleTemplateUpdate = async (updatedTemplate: TemplateType, save: boolean = false) => {
        // @ts-ignore
        const tmpTemplate = {...templateSuite, templates: templateSuite.templates.map( template => {
                return template._id === updatedTemplate._id ? updatedTemplate : template
            })};
        if (save) {
            try {
                // @ts-ignore
                await saveTemplate(tmpTemplate);
            } catch ( e: any ) {
                await sendErrorMessage(e,  'front/template/methods.saveTemplate', "Cannot save template", enqueueSnackbar);
            }

        }
        // @ts-ignore
        setTemplateSuite(tmpTemplate);

    }
    const addNewTemplate = async () => {
        // @ts-ignore
        const templates = [...templateSuite.templates, {type: 'New template', html: '', fields: [{name: "Field1", value: ''}]}];

        try {
            // @ts-ignore
            await saveTemplate({...templateSuite, templates: templates});
            setTemplateSuite(await getTemplateSuite( templateSuite._id || ''));
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/template/methods.saveTemplate, front/template/methods.getTemplateSuite', "Cannot save template", enqueueSnackbar);
        }

    }
    const handleChange = (event: any) => {

        setTemplateSuite({
            ...templateSuite,
            [event.target.id]: event.target.value
        })
    }
    const handleCheckBoxChange = (event: any) => {
        setTemplateSuite({
            ...templateSuite,
            [event.target.id]: event.target.checked
        })

    }
    const handleClose = async () => {
        setOpen(false);
    };
    const saveData = async () => {
        try {
            await saveTemplate(templateSuite);
            setOpen(false);
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/template/methods.saveTemplate', "Cannot save template", enqueueSnackbar);
        }
    }
    useEffect( () => {
        ( async() => {
            setWaitingForBackendMessage('Fetching images...')
            const fragments = await getLogoFragments()
            setFragmentImages(fragments);
            setWaitingForBackendMessage('');
        })()
    }, [])
    return (
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} >
                <AppBar sx={{backgroundColor: '#1862a8', position: 'relative',marginBottom:'15px'}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Title variant="h6" >
                            {templateSuite.brand} - {templateSuite.type}
                        </Title>
                        <Button autoFocus color="inherit" onClick={saveData}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>General details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} sx={{paddingLeft: '15px'}}>
                            <Grid item xs={2} sx={{marginLeft: '10px', marginBottom: '20px'}}>
                                <ComboBox
                                    options={listOfValues.country}
                                    multiple={false}
                                    required={true}
                                    label="Country"
                                    placeholder="Select country"
                                    value={templateSuite.brand && {value: templateSuite.country}}
                                    onChange={(event: any, val: any) => setTemplateSuite({
                                        ...templateSuite,
                                        country: val.value
                                    })} />
                            </Grid>
                            <Grid item xs={2} sx={{marginLeft: '10px', marginBottom: '20px'}}>
                                <ComboBox
                                    options={listOfValues.brand}
                                    multiple={false}
                                    required={true}
                                    label="Brand"
                                    placeholder="Select brand"
                                    value={templateSuite.brand && {value: templateSuite.brand}}
                                    onChange={(event: any, val: any) => setTemplateSuite({
                                        ...templateSuite,
                                        brand: val.value
                                    })} />
                            </Grid>
                            <Grid item xs={2}>
                                <ComboBox
                                    options={listOfValues.type}
                                    multiple={false}
                                    required={true}
                                    label="Type"
                                    placeholder="Select type"
                                    value={templateSuite.type && {value: templateSuite.type}}
                                    onChange={(event: any, val: any) => setTemplateSuite({
                                        ...templateSuite,
                                        type: val.value
                                    })} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    id="generation"
                                    label="Generation"
                                    fullWidth
                                    variant="standard"
                                    defaultValue={templateSuite.generation}
                                    onBlur={handleChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                {
                                    fragmentImages &&
                                    <Autocomplete
                                        multiple={true}
                                        fullWidth
                                        options={fragmentImages??[]}
                                        value={templateSuite.smallLogoImages}
                                        disabled={false}
                                        getOptionLabel={(option) => option.description??''}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="100"
                                                    src={`${REACT_APP_UPLOAD_BUCKET}${option.image}`}
                                                    srcSet={`${REACT_APP_UPLOAD_BUCKET}${option.image} 2x`}
                                                    alt=""
                                                />
                                                {option.description}
                                            </Box>
                                        )}
                                        filterSelectedOptions
                                        isOptionEqualToValue={(option, value) => {
                                            return option._id === value?._id;
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={'Select company'}
                                                disabled={false}
                                                placeholder={'Select company'}
                                                required={false}
                                                variant="standard"
                                            />
                                        )}
                                        onChange={(event: any, val: any) => {
                                            if ( val[0]) {
                                                setTemplateSuite({
                                                    ...templateSuite,
                                                    'smallLogoImages': val
                                                })
                                            }
                                        }}
                                    />
                                }

                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    id="description"
                                    label="Description"
                                    multiline
                                    fullWidth
                                    maxRows={4}
                                    variant="standard"
                                    defaultValue={templateSuite.description}
                                    onBlur={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid sx={{paddingLeft: '15px'}}>
                            <FormControlLabel
                                control={
                                    // @ts-ignore
                                    <Checkbox onChange={handleCheckBoxChange}
                                              id={`isRepEnabled`}
                                              value={templateSuite.isRepEnabled}
                                              checked={templateSuite?.isRepEnabled??false}/>}
                                label="Is Sales rep enabled (this means that reps can pick and use this)"/>
                            <FormControlLabel
                                control={
                                    // @ts-ignore
                                    <Checkbox onChange={handleCheckBoxChange}
                                              id={`enabled`}
                                              value={templateSuite.enabled}
                                              checked={templateSuite?.enabled??false}/>}
                                label="Enabled"/>

                        </Grid>
                        <Grid sx={{paddingLeft: '15px'}}>
                            <TextField
                                id="colorPresets"
                                label="Color Presets"
                                multiline
                                fullWidth
                                minRows={3}
                                defaultValue={flattenJSON(templateSuite?.colorPresets??'', 5)}
                                onBlur={(event: any) => {
                                    setTemplateSuite({
                                        ...templateSuite,
                                        colorPresets: flattenJSON(event.target.value)
                                    })
                                }}
                            />

                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Templates</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TemplateTabs isTemplateAdmin={true} templateSuite={templateSuite} handleTemplateUpdate={handleTemplateUpdate} addNewTemplate={addNewTemplate} />
                    </AccordionDetails>
                </Accordion>
            </Dialog>
    );
}
TemplateSuite.propTypes = {
    template: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

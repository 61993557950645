import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Button,
    IconButton,
    Tooltip,
    Typography,
    FormControlLabel,
    FormHelperText,
    ThemeProvider, TextField, Grid, FormControl, RadioGroup, Radio, Dialog, DialogContent
} from "@mui/material";
import { ActivityContext } from "../../../ActivityContext";

import { theme } from "../../../../../components/SmallTheme";
import { HowTo } from "../../HowTo";
import DialogActions from "@mui/material/DialogActions";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import {AdapterDateFns} from "@mui/x-date-pickers-pro/AdapterDateFns";
import { MultiInputDateTimeRangeField } from '@mui/x-date-pickers-pro/MultiInputDateTimeRangeField';
import {da, fi, nn, sv} from "date-fns/locale";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import {AppContext} from "../../../../../AppContext";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";

export const CalendarEntry = ({ templateReadOnly }: {templateReadOnly: any}) => {
    const {user} = useContext(AppContext);
    const { isArchived, isEditor, activity, setActivityPayload } = useContext(ActivityContext);
    const [calendarEntry, setCalendarEntry] = useState(activity.calendarEntry ?? {
        enabled: false,
        title: '',
        location: '',
        description: ''
    });
    const [local, setLocal] = React.useState(sv)
    useEffect( () => {
        if ( user?.countries) {
            const country = user?.countries[0];
            if (country === 'DK')
                setLocal(da);
            if (country === 'FI')
                setLocal(fi);
            if (country === 'NO')
                setLocal(nn);
        }

    }, [])

    const anchorRef = useRef(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    // Popover open and close handlers
    const handlePopoverOpen = () => {
        setPopoverOpen(true);
    };
    const handlePopoverClose = () => {
        setPopoverOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Tooltip title="Enable calendar event? Allow participants to add this event to their calendar.">
                <IconButton onClick={handlePopoverOpen} ref={anchorRef} disabled={templateReadOnly}>
                    <EditCalendarIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={popoverOpen} onClose={handlePopoverClose} fullWidth maxWidth={'md'}
                    PaperProps={{
                        style: {
                            maxWidth: '800px', // Set the max width here
                            width: '100%', // Ensures the dialog is responsive
                        }
                    }}>
                <DialogContent>
                    <Grid container p={2} spacing={2} width={'750px'}>
                        <Grid item xs={12}><HowTo videoName={'How-To-Enable-Calendar-Event.mov'} /></Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="div" >
                                Enable calendar event?
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Allow participants to add this event to their calendar.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="enable-calendar-event"
                                    name="enable-calendar-event"
                                    value={calendarEntry.enabled ? 'true' : 'false'}
                                    onChange={(event) => setCalendarEntry({ ...calendarEntry, enabled: event.target.value === 'true' })}
                                >
                                    <FormControlLabel value="false" control={<Radio size={'small'} disabled={templateReadOnly} />} label="No" />
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio size={'small'} disabled={templateReadOnly} />}
                                        label="Yes"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="standard"
                                label="Event Title"
                                disabled={!isEditor || isArchived || !calendarEntry.enabled || templateReadOnly}
                                defaultValue={calendarEntry.title}
                                onBlur={(event) => setCalendarEntry({ ...calendarEntry, title: event.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="standard"
                                label="Event Location/Address"
                                disabled={!isEditor || isArchived || !calendarEntry.enabled || templateReadOnly}
                                defaultValue={calendarEntry.location}
                                onBlur={(event) => setCalendarEntry({ ...calendarEntry, location: event.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="standard"
                                multiline
                                label="Event Description"
                                disabled={!isEditor || isArchived || !calendarEntry.enabled || templateReadOnly}
                                defaultValue={calendarEntry.description}
                                onBlur={(event) => setCalendarEntry({ ...calendarEntry, description: event.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={local}>
                                <DemoContainer
                                    sx={{ width: 400 }}
                                    components={[
                                        'MultiInputDateTimeRangeField'
                                    ]}
                                >
                                    <MultiInputDateTimeRangeField
                                        disabled={true}
                                        defaultValue={[new Date(activity.start), new Date(activity.end)]}
                                        slotProps={{
                                            textField: ({ position }) => ({
                                                variant: 'standard',
                                                label: position === 'start' ? 'Event Start' : 'Event End',
                                            }),
                                        }}
                                    />
                                </DemoContainer>
                                <FormHelperText>
                                    Event Start and Event End cannot be updated here. Please update these fields under "Details".
                                </FormHelperText>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePopoverClose}>Close</Button>
                    <Button
                        disabled={templateReadOnly}
                        onClick={() => {
                            setActivityPayload({ refreshActivity: false, payload: { calendarEntry: calendarEntry } });
                            handlePopoverClose();
                        }}
                    >Save</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};
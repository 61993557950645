import {Box, Grid} from '@mui/material';
import React, {useContext, useEffect} from "react";
import {
    createFragment,
    getFragments
} from './methods';

import {AppContext} from "../../AppContext";

import {FooterMenu, ImageMenu, Menu} from './Menu';
import {FragmentForm} from "./FragmentForm";


export const Fragment = () => {
    const [fragments, setFragments] = React.useState<FragmentType[]>();
    const {setAppHeader, setWaitingForBackendMessage, setActions, user} = useContext(AppContext);
    const [fragment, setFragment] = React.useState<FragmentType | undefined>();

    const [selectedNodeItem, setSelectedNodeItem] = React.useState('0');

    useEffect( () => {
        setAppHeader('Fragments')
        setActions()
        refreshFragments();
    }, [])

    const addFragment = (type: string) => {
        ( async() => {
            setWaitingForBackendMessage('Creating...')
            setFragment(await createFragment({
                type,
                description: '',
                status: ['Regulatory', 'Footer'].includes(type) ? 'Draft': 'Active'
            }))
            setWaitingForBackendMessage('');
        })()
    }

    const refreshFragments = () => {
        ( async () => {
            setWaitingForBackendMessage('Getting fragments...')
            const fragments = await getFragments()
            setFragments(fragments.filter( (fragment: FragmentType) => !fragment.description?.includes('__') || user?.role === 'Administrator'));
            setWaitingForBackendMessage('');
        })()
    }
    return (
        <Box sx={{ flexGrow: 1, pt: '80px' }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Menu
                                label='Regulatory text'
                                addEVent={()=> addFragment('Regulatory')}
                                defaultSelected={selectedNodeItem}
                                fragments={fragments?.filter(fragment => fragment.type === 'Regulatory')}
                                onClick={(selectedFragment: FragmentType) => {
                                    setFragment(undefined)
                                    setTimeout(()=>setFragment(selectedFragment), 100)
                                    }
                                }/>
                        </Grid>
                        {
                            user?.role !== 'Regulatory' &&
                            <Grid item xs={10} sx={{marginTop: '10px'}}>
                                <ImageMenu
                                    label='Images'
                                    addEVent={()=> addFragment('Image')}
                                    defaultSelected={selectedNodeItem}
                                    fragments={fragments?.filter(fragment => fragment.type === 'Image')}
                                    onClick={(selectedFragment: FragmentType) => {
                                        setFragment(undefined)
                                        setTimeout(()=>setFragment(selectedFragment), 100)
                                    }
                                    }/>
                            </Grid>
                        }
                        {
                            user?.role !== 'Regulatory' &&
                            <Grid item xs={10} sx={{marginTop: '10px'}}>
                                <ImageMenu
                                    label='Logos'
                                    addEVent={()=> addFragment('Logo')}
                                    defaultSelected={selectedNodeItem}
                                    fragments={fragments?.filter(fragment => fragment.type === 'Logo')}
                                    onClick={(selectedFragment: FragmentType) => {
                                        setFragment(undefined)
                                        setTimeout(()=>setFragment(selectedFragment), 100)
                                    }
                                    }/>
                            </Grid>
                        }
                        {
                            user?.role !== 'Regulatory' &&
                            <Grid item xs={10} sx={{marginTop: '10px'}}>
                                <FooterMenu
                                    label='Footers'
                                    addEVent={()=> addFragment('Footer')}
                                    defaultSelected={selectedNodeItem}
                                    fragments={fragments?.filter(fragment => fragment.type === 'Footer')}
                                    onClick={(selectedFragment: FragmentType) => {
                                        setFragment(undefined)
                                        setTimeout(()=>setFragment(selectedFragment), 100)
                                    }
                                    }/>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    {
                        fragment?._id &&
                        <FragmentForm
                            fragment={fragment}
                            setFragment={setFragment}
                            setSelectedNodeItem={setSelectedNodeItem}
                            fragments={fragments}
                            refreshFragments={refreshFragments}
                        />
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

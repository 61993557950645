import {
    Avatar,
    Dialog,
    DialogContent,
    Link, ListItemAvatar,
    ListItemSecondaryAction,
    Tooltip
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import React, {useContext, useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DropzoneArea } from 'material-ui-dropzone';
import {Api} from "../../../Api";
import axios from "axios";
import {AppContext} from "../../../AppContext";
import {ActivityContext} from "../ActivityContext";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {HowTo} from "./HowTo";

interface AttachmentListItemProps {
    key: number;
    attachment: AttachmentType;
}
export const Attachments = ({ckEditor}: {ckEditor: any}) => {
    const [openAttachments, setOpenAttachments] = React.useState(false);
    const { setWaitingForBackendMessage} = useContext(AppContext);
    const {activity, setActivityPayload} = useContext(ActivityContext);
    const [attachments, setAttachments] = useState(activity.attachments??[]);
    const handleClick = (attachment: AttachmentType) => {
        try {
            const model = ckEditor.model;
            const selection = model.document.selection;
            const range = selection.getFirstRange();
            let linkText = '';
            for (const item of range.getItems()) linkText += item.data;
            linkText = linkText === '' ? attachment.fileName : linkText;
            model.change((writer: any) => {
                // Skapa en länk element
                const linkElement = writer.createText(linkText, { linkHref: `${process.env.REACT_APP_UPLOAD_BUCKET}${attachment.key}` });
                // Infoga länken vid markörens nuvarande position
                model.insertContent(linkElement, range);
            });
        } catch (e: any) {
            console.error(e.toString());
        }
        handleClose(); // Antaget att du använder denna för att stänga dialogen
    };


    const handleFileUpload = async (files: File[]) => {
        const file = files[0]; // Assuming only one file is being uploaded at a time
        if (file) {
            const uploadResult = await uploadFile(file);
            if (uploadResult) {
                setActivityPayload({
                    refreshActivity: true,
                    payload: {
                        attachments: [...attachments, {
                            fileName: file.name,
                            key: uploadResult.key,
                            created: new Date().toISOString()
                        }],
                    }
                });
                // Create a new attachment entry with the result of the upload
                const newAttachment: AttachmentType = {
                    fileName: file.name,
                    key: uploadResult.key, // Assuming that the uploaded file's key is returned by your API
                    created: new Date().toISOString()
                };
                // Add the new attachment to your list of attachments
                setAttachments([...attachments, newAttachment]);
            }
        }
    };

    const uploadFile = async (file: File) => {
        setWaitingForBackendMessage('Uploading...');
        const path = `activity/${activity._id}/attachments/`;
        const fileName = file.name.split('.');
        const fileType = fileName[fileName.length - 1];
        const result = await Api({
            name: 'UploadFile',
            type: 'POST',
            url: `${process.env.REACT_APP_COMMON_API}uploadToS3`,
            body: { path, ContentType: file.type, extension: fileType }
        });
        if (result) {
            const options = {
                headers: {
                    'Content-Type': file.type
                }
            };
            let res;
            try {
                res = await axios.put(result.url, file, options);
            } catch (e: any) {
                console.error(e.toString());
                console.log(res);
            }
            setWaitingForBackendMessage('');
            return result;
        }
    };

    const deleteAttachment = (index: number) => {
        const updatedAttachments = [...attachments];
        updatedAttachments.splice(index, 1);
        setAttachments(updatedAttachments);
        setActivityPayload({
            refreshActivity: true,
            payload: {
                attachments: updatedAttachments,
            }
        });
    }

    const handleClose = () => {
        setOpenAttachments(false);
    }
    const AttachmentListItem = ({key, attachment }: AttachmentListItemProps) => {
        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <Link href={`${process.env.REACT_APP_UPLOAD_BUCKET}${attachment.key}`} target="_blank" rel="noopener">
                            <OpenInNewIcon/>
                        </Link>
                    </Avatar>
                </ListItemAvatar>
                    <ListItemText
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)', // Adjust the color as needed for your theme
                            },
                            cursor: 'pointer',
                        }}
                        primary={attachment.fileName}
                        secondary={`Uploaded: ${new Date(attachment.created).toLocaleString('sv-SE')} `}
                        onClick={() => handleClick(attachment)}
                    />
                <ListItemSecondaryAction>
                    <Tooltip title="Delete attachment">
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteAttachment(key)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
        );
    };

    return (
        <React.Fragment>
            <Tooltip title="Attach documents to the invitation">
                <IconButton onClick={()=> setOpenAttachments(true)}>
                    <AttachFileIcon  sx={{fontSize: 30}}/>
                </IconButton>
            </Tooltip>
            <Dialog open={openAttachments} onClose={handleClose} fullWidth maxWidth={'sm'} >
                <DialogContent>
                    <HowTo videoName={'How-To-Attach-Documents.mov'} />
                    <DropzoneArea
                        onDrop={handleFileUpload}
                        maxFileSize={600000000}
                        acceptedFiles={['image/*', 'application/*', 'text/*']} // Adjust this to the formats you want to accept
                        dropzoneText="Drag and drop your document here, or click to select"
                        showPreviews={false} // Set to false to hide the preview icon
                        showPreviewsInDropzone={false} // Set to false to hide previews inside the drop zone
                        showFileNames={false} // Set to false to hide the file names after upload
                        filesLimit={1}
                    />
                    <List>
                        {attachments.map((attachment: AttachmentType, index: number) =>
                            <AttachmentListItem
                                key={index}
                                attachment={attachment}
                            />
                        )}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> {setOpenAttachments(false); }}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

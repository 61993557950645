/* eslint-disable no-use-before-define */
import React, {useEffect} from 'react';
import {TextField} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {Api} from "../../../../Api";
import { useSnackbar } from 'notistack';
import {sendErrorMessage} from "../../../../Error";
const {REACT_APP_SPEAKERS_API} =  process.env;
const filter = createFilterOptions<SpeakerType>();
// @ts-ignore
export const SelectSpeakers =  ({activity, setSelectedSpeaker, isReadOnly, setActivityPayload}: SelectSpeakersType) => {
    const [options, setOptions] = React.useState<SpeakerType[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const getData = async () => {
        try {
        const res = await Api({
            name: 'SelectSpeakers',
            url: `${REACT_APP_SPEAKERS_API}speakers`
        })
        if (res) {
            setOptions(res.data.sort( (s: SpeakerType, s1: SpeakerType) => {
                if( s?.fullname! < s1?.fullname! )
                    return -1;
                else if (s?.fullname! > s1?.fullname!)
                    return 1;

                return 0;
            }));
        }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/activity/methods.SelectedSpeaker', "Cannot Get Speakers", enqueueSnackbar,activity._id);
        }

    }
    useEffect( () => {
        ( async () => {
                await getData();
        })();
    },[])
    return (
        <Autocomplete
            id='selectSpeaker'
            multiple
            fullWidth={true}
            autoHighlight
            options={options}
            value={activity.speakers}
            getOptionLabel={(option) => option.fullname??''}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => {
                return option._id === value?._id;
            }}
            disabled={isReadOnly}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => JSON.stringify(option).includes(inputValue));
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        company: '',
                        _id: 'CREATE',
                        fullname: `Create speaker ${inputValue}`,
                        email: '',
                        phone: '',
                        title: ''
                    });
                }

                return filtered;
            }}
            onChange={(e: any, val: any) => {
                const isNew = val.find( (speaker: SpeakerType) => speaker._id === 'CREATE');
                if (isNew) {
                    setSelectedSpeaker(isNew)
                } else {
                    setActivityPayload({refreshActivity: true, payload: {speakers: val}});
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onKeyDown={(event: any) => {
                        if (event.key === 'Backspace') {
                            event.stopPropagation();
                        }
                    }}
                    label="Speaker(s)"
                    disabled={isReadOnly}
                    placeholder="Select speaker(s)"
                    variant="standard"
                />
            )}
            renderTags={(option) => {
                return (<div></div>)
            }}
            renderOption={(props, option) => {
                return (
                    <span {...props} key={option._id}>
                        <div>
                            <div><b>{option.fullname}</b></div>
                            <div>{option.title}</div>
                        </div>
                    </span>
                )
            }}
        />
    );
}

interface SelectSpeakersType {
    activity: ActivityType;
    setSelectedSpeaker: any;
    setActivityPayload: any;
    isReadOnly: boolean;
}

import React, {useContext} from "react";
import {ActivityContext} from "../../ActivityContext";
import { SendEmailComponent } from "../SendEmailComponent";
export const Invitation: any = () => {
    const {activity, participants, isEmbedded, isEditor}  = useContext(ActivityContext);

    // @ts-ignore
    return (
        <SendEmailComponent
            srcDoc={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL INVITATION').compiledHtml}
            subject={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL INVITATION').subject}
            action={'SEND_INVITATION'}
            emailTypeName={'invitationSent'}
            participantTabs={{
                tab1: {
                    label: 'Invitation not sent ',
                    canSendEmail: true,
                    canAddParticipant: true,
                    canDeleteParticipant: false,
                    canSelectCustomers: true,
                    participants: participants.filter( (participant: ParticipantType) => !participant.participate && !participant.invitationSent),
                    showField: 'invitationNotSent',
                    title: 'Invitation not sent',
                    freeActionTitle: 'Send invitation to all the selected participants?',
                    actionTitle: 'Send invitation',
                    display: !isEmbedded
                },
                tab2: {
                    canSendEmail: false,
                    canAddParticipant: false,
                    canDeleteParticipant: false,
                    canSelectCustomers: false,
                    label: 'Invitation sent',
                    participants: participants.filter( (participant: ParticipantType) => participant.invitationSent),
                    showField: 'invitationSent',
                    title: 'Invitation sent',
                    display: !isEmbedded
                },
                tab3: {
                    canSendEmail: false,
                    canAddParticipant: false,
                    canDeleteParticipant: false,
                    canSelectCustomers: false,
                    label: 'Invitation viewed',
                    participants: participants.filter( (participant: ParticipantType) => participant.invitationViewed),
                    showField: 'invitationSent',
                    title: 'Invitation viewed',
                    display: !isEmbedded
                },
                isReadOnly: !isEditor
            }
            }
        />
    );
}

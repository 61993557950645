import {Autocomplete, Box, ImageList, ImageListItem, ImageListItemBar, ListSubheader, TextField} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {getImageFragments, getLogoFragments} from "./methods";
import {AppContext} from "../../AppContext";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {UploadFile} from "../../components/UploadFile";
const {REACT_APP_UPLOAD_BUCKET} =  process.env;

interface PickImageInput {
    open: boolean;
    cancel(): void;
    fieldName: string;
    object: string;
    _id: string;
    afterUpload(filePath: string): void;
}
interface PickLogoInput {
    open: boolean;
    cancel(): void;
    inputValue?: FragmentType;
    afterUpload(filePath: FragmentType | undefined): void;
}
export const PickImage = ({open, cancel,fieldName, object, _id, afterUpload}: PickImageInput) => {
    const [localOpen, setLocalOpen] = React.useState(false);
    const [fragmentImages, setFragmentImages] = useState<FragmentType[] | undefined>()
    const {setWaitingForBackendMessage, user} = useContext(AppContext);

    useEffect( () => {
        if (open) {
            ( async() => {
                setWaitingForBackendMessage('Fetching images...')
                setFragmentImages(await getImageFragments());
                setWaitingForBackendMessage('');
            })()
        }
        setLocalOpen(open);
    }, [open])
    const handleUpdate = (filePath: string) => {
        afterUpload(filePath);
        setLocalOpen(false);
    }
    return (
        <Dialog open={localOpen} maxWidth="xl">

            <DialogContent >
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    sx={{ padding: 0}}
                >
                    <ImageList sx={{ minWidth: 600, maxWidth: 800, height: 800 }}>
                        <ImageListItem key="Subheader" cols={2}>
                            <ListSubheader component="div">Background images</ListSubheader>
                        </ImageListItem>
                        {fragmentImages && fragmentImages.map((item) => (
                            <ImageListItem sx={{cursor: 'pointer'}} key={item.image} onClick={() => handleUpdate(`${item?.image}`)}>
                                <img
                                    src={`${REACT_APP_UPLOAD_BUCKET}${item.image}?w=248&fit=crop&auto=format`}
                                    srcSet={`${REACT_APP_UPLOAD_BUCKET}${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.description}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={item.description}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                    {
                        <UploadFile
                            fieldName={fieldName}
                            cancel={cancel}
                            open={false}
                            object={object}
                            _id={_id}
                            afterUpload={afterUpload}
                            uploadButtonLabel={'Upload image'}
                        />
                    }
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=> {setLocalOpen(false); cancel();}}>Close</Button>
            </DialogActions>
        </Dialog>
)
}

export const PickLogos = ({open, cancel, inputValue, afterUpload}: PickLogoInput) => {
    const [localOpen, setLocalOpen] = React.useState(false);
    const [fragmentImages, setFragmentImages] = useState<FragmentType[] | undefined>()
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const [value, setValue] = useState<FragmentType>();
    useEffect( () => {
        if (open) {
            if(inputValue) {
                setValue(inputValue)
            }

            ( async() => {
                setWaitingForBackendMessage('Fetching images...')
                const fragments = await getLogoFragments()
                setFragmentImages(fragments.filter( (fragment: FragmentType) => !fragment.description?.includes('__')));
                setWaitingForBackendMessage('');
            })()
        }
        setLocalOpen(open);
    }, [open])
    const onChange = (event: any, val: any) => {
        if ( val) {
            setValue(val)
        }
    }
    const handleUpdate = () => {
        afterUpload(value);
        setLocalOpen(false);
    }
    return (
        <Dialog open={localOpen} maxWidth="xl">

            <DialogContent >
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    sx={{ padding: 0}}
                >
                    {}
                    <Autocomplete
                        multiple={false}
                        fullWidth
                        sx={{width: '400px'}}
                        options={fragmentImages??[]}
                        value={value}
                        disabled={false}
                        getOptionLabel={(option) => option.description??''}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    loading="lazy"
                                    width="100"
                                    src={`${REACT_APP_UPLOAD_BUCKET}${option.image}`}
                                    srcSet={`${REACT_APP_UPLOAD_BUCKET}${option.image} 2x`}
                                    alt=""
                                />
                                {option.description}
                            </Box>
                        )}
                        filterSelectedOptions
                        isOptionEqualToValue={(option, value) => {
                            return option._id === value?._id;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Select logos'}
                                disabled={false}
                                placeholder={'Select logos'}
                                required={false}
                                variant="standard"
                            />
                        )}
                        onChange={onChange}
                    />

                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=> {setLocalOpen(false); cancel()}}>Close</Button>
                <Button onClick={handleUpdate}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

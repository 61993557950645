import Grid from "@mui/material/Grid";
import {
    Button,
    Fade,
    TextField
} from "@mui/material";
import validator from 'validator'
import React, {useContext} from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SendIcon from "@mui/icons-material/Send";
import {ActivityContext} from "../../ActivityContext";
import {AppContext} from "../../../../AppContext";
import {SendEmailComponent} from "../SendEmailComponent";
const StyledPaperInput = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto',
    marginTop: 'calc(10% - 10px)',
    flexDirection: 'column',
    width: '600px',
    height: '200px'
}))

export const SendMeetingDetails: any = () => {
    const {activity, setActivityPayload, participants, isEditor}  = useContext(ActivityContext);
    const {isEmbedded} = useContext(AppContext);
    const [url, setUrl] = React.useState('');


    return (
        <Grid >
            {
                !activity.webinarUrl && isEditor &&
                <StyledPaperInput elevation={24}>
                    <Grid container sx={{marginTop: '50px'}}>
                        <Fade in={!activity.webinarUrl}>
                            <Grid item xs={9} >
                                <TextField
                                    required
                                    id="webinarUrl"
                                    name="webinarUrl"
                                    disabled={activity.webinarUrl || !isEditor}
                                    label="Webinar URL"
                                    sx={{width: '450px'}}
                                    defaultValue={activity.webinarUrl}
                                    onChange={(e: any) => {
                                        if ( validator.isURL(e.target.value)) {
                                            setUrl(e.target.value);
                                        }
                                    }}
                                    autoComplete='off'
                                    variant="standard"
                                />
                            </Grid>
                        </Fade>
                        <Fade in={!activity.webinarUrl && url.length > 4}>
                            <Grid item xs={3} alignContent={{alignItems: 'flex-end'}}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{marginBottom: '0px'}}
                                    onClick={ () => {
                                        setActivityPayload({refreshActivity: true, payload: {webinarUrl: url}});
                                    }}
                                    endIcon={<SendIcon />}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Fade>
                    </Grid>
                </StyledPaperInput>
            }

            {
                activity.webinarUrl &&
                <SendEmailComponent
                    srcDoc={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL WEBINAR URL').compiledHtml?.replace('[[__URL]]', activity.webinarUrl)}
                    subject={activity.templateSuite.templates.find( (template: TemplateType) => template.type === 'E-MAIL WEBINAR URL').subject}
                    action={'SEND_WEBINAR_URL'}
                    emailTypeName={'meetingInformationSent'}
                    participantTabs={{
                        tab1: {
                            label: 'Not sent',
                            canSendEmail: true,
                            canAddParticipant: false,
                            canDeleteParticipant: false,
                            canSelectCustomers: true,
                            participants: participants.filter( (participant: ParticipantType) => participant.participate && !participant.meetingInformationSent),
                            showField: 'meetingInformationNotSent',
                            title: 'Webinar URL not sent',
                            freeActionTitle: 'Send webinar url to all the selected participants?',
                            actionTitle: 'Send webinar url',
                            display: !isEmbedded
                        },
                        tab2: {
                            canSendEmail: false,
                            canAddParticipant: false,
                            canDeleteParticipant: false,
                            canSelectCustomers: false,
                            label: 'Sent',
                            participants: participants.filter( (participant: ParticipantType) => participant.meetingInformationSent),
                            showField: 'meetingInformationSent',
                            title: 'Webinar URL sent',
                            display: !isEmbedded
                        },
                        tab3: {
                            canSendEmail: false,
                            canAddParticipant: false,
                            canDeleteParticipant: false,
                            canSelectCustomers: false,
                            label: 'Viewed',
                            participants: participants.filter( (participant: ParticipantType) => participant.meetingInformationViewed),
                            showField: 'meetingInformationSent',
                            title: 'Webinar URL viewed',
                            display: !isEmbedded
                        },
                        isReadOnly: !isEditor
                    }
                    }
                />
            }
        </Grid>
    );
}
